export const billData = {
    billPrice: [
        {
            point: 5000,
            price: 5500,
        }, {
            point: 10000,
            price: 11000,
        }, {
            point: 50000,
            price: 55000,
        }, {
            point: 100000,
            price: 110000,
        },{
            point: 200000,
            price: 220000,
        },
        {
            point: 300000,
            price: 330000,
        },
        {
            point: 500000,
            price: 550000,
        },
        {
            point: 1000000,
            price: 1100000,
        },

    ]
}
