import React from "react";
import GradientBack from "../common/GradientBack";
import Checkbox from '@mui/material/Checkbox';
import {my_clause, use_agreement} from '../../datas/clauseTxt';
import {Button} from "@mui/material";
import {useHistory} from "react-router-dom";
import {notificationAlert} from "../../helpers/common";

const Clause = ({check, setCheck, setStep, setAgreePhoneAd, setAgreeEmailAd}) => {
    const history = useHistory();
    const label = {inputProps: {'aria-label': 'Checkbox demo'}};
    const handleCheck = (bool, type) => {
        if (bool) {
            if (type === 4) {
                setAgreePhoneAd(1);
            }
            if (type === 5) {
                setAgreeEmailAd(1);
            }
            if (type === 3) {
                setAgreePhoneAd(1);
                setAgreeEmailAd(1);
                setCheck([1, 2, 4, 5]);
            } else {

                setCheck([...check, type])
            }

        } else {
            if (type === 4) {
                setAgreePhoneAd(0);
            }
            if (type === 5) {
                setAgreeEmailAd(0);
            }
            if (type === 3) {
                setAgreePhoneAd(0);
                setAgreeEmailAd(0);
                setCheck([])
            } else {
                const newCheck = check.filter(num => num !== type);
                setCheck(newCheck)
            }

        }
    }
    const handleNextStep = () => {
        if (check.includes(1) && check.includes(2)) {
            setStep(2)
        } else {
            notificationAlert('오류', '필수 동의 항목이 체크되지 않았습니다.')
        }
    }
    return (
        <GradientBack boxSize={600}>
            <div className='clause_wrapper'>
                <div className='clause_box'>
                    <div className='all_check_btn clause_item'>
                        <span>이용약관, 개인정보 수집 및 이용,광고성 정보수신 (선택)에 모두 동의합니다.</span>
                        <Checkbox
                            {...label}
                            checked={check.includes(1) && check.includes(2)}
                            onChange={event => handleCheck(event.target.checked, 3)}
                            sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                        />
                    </div>
                    <div className='use_clause_wrapper clause_item'>
                        <div><span>서버24 이용약관 동의</span><small>(필수)</small></div>
                        <Checkbox
                            {...label}
                            onChange={event => handleCheck(event.target.checked, 1)}
                            checked={check.includes(1)}
                            sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                        />
                    </div>
                    <div className='clause_txt'>
                        {use_agreement.split('\n').map(line => {
                            return (<p>{line}<br/></p>)
                        })}
                    </div>
                    <div className='my_clause_wrapper clause_item'>
                        <div><span>개인정보 수집 및 이용에 대한 안내</span><small>(필수)</small></div>
                        <Checkbox
                            {...label}
                            onChange={event => handleCheck(event.target.checked, 2)}
                            checked={check.includes(2)}
                            sx={{'& .MuiSvgIcon-root clause_item': {fontSize: 28}}}
                        />
                    </div>
                    <div className='clause_txt'>
                        {my_clause.split('\n').map(line => {
                            return (<p>{line}<br/></p>)
                        })}
                    </div>
                    <div className='my_clause_wrapper clause_item'>
                        <div><span>마케팅 활용 및 광고성 정보 수신에 동의</span></div>
                    </div>
                    <div className='marketing_clause_item'>
                        <div><span>SMS 수신 동의</span><small>(선택)</small></div>
                        <Checkbox
                            {...label}
                            onChange={event => handleCheck(event.target.checked, 4)}
                            checked={check.includes(4)}
                            sx={{'& .MuiSvgIcon-root clause_item': {fontSize: 28}}}
                        />
                    </div>
                    <div className='marketing_clause_item'>
                        <div><span>E-Mail 수신 동의</span><small>(선택)</small></div>
                        <Checkbox
                            {...label}
                            onChange={event => handleCheck(event.target.checked, 5)}
                            checked={check.includes(5)}
                            sx={{'& .MuiSvgIcon-root clause_item': {fontSize: 28}}}
                        />
                    </div>
                    <div className='clause_txt marketing_clause'>
                        <table>
                            <thead>
                            <tr>
                                <td><span>수집항목</span></td>
                                <td><span>수집 및 이용 목적</span></td>
                                <td><span>이용 기간</span></td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><p>이용자가 개인정보 수집 및 이용에 동의하고 작성한 개인정보의 이메일 및 휴대폰번호 항목</p>
                                </td>
                                <td><p>신규 서비스(제품) 개발 및 특화, 이벤트 등 광고성 정보 전달 및 참여기회 제공, 통계학적 특성에 따른 접속 빈도 파악, 기능개선, 서비스 이용에 대한 통계 등 신규 서비스 개발과 마케팅</p>
                                </td>
                                <td><p>회원 유지 기간 중 광고성 정보 수신에 동의한 기간</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div style={{marginTop: '1rem'}}>
                            <p>※ 본 동의는 거부하실 수 있습니다. 다만 거부 시 동의를 통해 제공 가능한 이벤트 및 관련 안내를 받아 보실 수 없습니다.</p>
                        </div>
                    </div>
                    <div className='clause_btn_wrapper'>
                        <Button onClick={() => history.push('/')} variant="outlined"><span>취소</span></Button>
                        <Button onClick={() => handleNextStep()} variant="contained"><span>동의</span></Button>
                    </div>
                </div>
            </div>
        </GradientBack>
    )
}
export default Clause;
