import React from "react";
import {Row, Col} from 'reactstrap';
import InstanceStatus from "./InstanceStatus";
import OsImgItem from "../common/OsImgItem";

const ProductMobileTable = ({serverList, moveLocation}) => {

    return (
        <div className='mobile_table_wrapper'>
            {serverList.map((server, index) => {
                return (
                    <Row key={index} onClick={() => moveLocation(server.vi_id, server.vi_server_status)} className='mobile_item align-items-center'>
                        <Col className='p-0' xs={2}>
                            <OsImgItem osId={server.vi_os_id}/>
                        </Col>
                        <Col className='p-0' xs={7}>
                            <div className='instance_label_wrapper'>
                                <span>{server.vi_label}</span>
                                <small>{`${server.vp_ram}MB - ${server.vi_main_ip}`}</small>
                            </div>
                        </Col>
                        <Col xs={3} className='server_status_info p-0'>
                            <InstanceStatus
                                vi_power_status={server.vi_power_status}
                                vi_server_status={server.vi_server_status}
                                vi_status={server.vi_status}/>
                        </Col>
                    </Row>
                )
            })}
        </div>
    )
}

export default ProductMobileTable;
