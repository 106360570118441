import React, {useEffect, useState} from "react";
import centerAngle from "../../assets/img/login/center_angle.png";
import cloudIcon from "../../assets/img/product/rocket.svg";
import {getUserName} from "./action";
import {checkErrorResponse} from "../../helpers/errorCheck";
import {useHistory} from "react-router-dom";


const UserWelcome = () => {
    const history = useHistory();
    const [userName, setUserName] = useState('');
    const [eventUser, setEventUser] = useState(false);
    useEffect(() => {
        getUserName().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    setUserName(response.request.data.name);
                    setEventUser(response.request.data.event);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [])
    return (
        <div className='product_welcome_box'>
            <div className='welcome_msg_wrapper'>
                <h4>{userName}님, 환영합니다!</h4>
                {!eventUser &&
                <div onClick={() => history.push('/account')} className='phone_event mt-3'>
                    <small>여기를 눌러서 휴대폰번호를 인증하시면, 서비스를 체험하실 수 있는 10,000포인트를 즉시 지급해드립니다!</small>
                </div>
                }
            </div>

            <img id='center_angle' src={centerAngle}/>
            <img id='right_angle' src={cloudIcon}/>
        </div>
    )
}

export default UserWelcome;
