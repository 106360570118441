export const _GET_SNAPSHOT = '_GET_SNAPSHOT';

export const _ADD_SNAPSHOT = '_ADD_SNAPSHOT';

export const _DEL_SNAPSHOT = '_DEL_SNAPSHOT';

export const _UPDATE_SNAPSHOT = '_UPDATE_SNAPSHOT';

export const _GET_BACKUP = '_GET_BACKUP';

export const _DEL_BACKUP = '_DEL_BACKUP';
