export const _INIT_DEPLOY = '_INIT_DEPLOY';

export const _SET_SERVICE = '_SET_SERVICE';

export const _SET_SERVER = '_SET_SERVER';

export const _SET_REGION = '_SET_REGION';

export const _SET_OS = '_SET_OS';

export const _SET_PRICE = '_SET_PRICE';

export const _SET_ADDITIONAL = '_SET_ADDITIONAL';

export const _SET_SCRIPT = '_SET_SCRIPT';

export const _SET_SETTING = '_SET_SETTING';

export const _GET_PLAN = '_GET_PLAN';

export const _UP_QTY = '_UP_QTY';

export const _DOWN_QTY = '_DOWN_QTY';

export const _INIT_ADDITIONAL = '_INIT_ADDITIONAL';

export const _SET_CPANEL_REGION = '_SET_CPANEL_REGION';

export const _SET_CPANEL_PRICE = '_SET_CPANEL_PRICE';

export const _SET_CPANEL_PLAN = '_SET_CPANEL_PLAN';
