import React, {Fragment, useEffect, useState} from "react";
import {getScripts} from "./action";
import {useDispatch, useSelector} from "react-redux";
import MyTxtTable from "../common/MyTxtTable";
import {Fab} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ScriptModal from "./ScriptModal";
import ListSkeleton from "../common/ListSkeleton";
import {useMediaQuery} from "react-responsive";
import MobileScriptTable from "./MobileScriptTable";
import {checkErrorResponse} from "../../helpers/errorCheck";
import {isIterableArray} from "../../utils/common";
import NotExistTable from "../common/NotExistTable";

const ScriptList = () => {
    const dispatch = useDispatch();
    const {scriptList} = useSelector(state => state.script);
    const [openModal, setOpenModal] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const isMobile = useMediaQuery({
        query: "(max-width:880px)"
    });
    const toggle = () => {
        setOpenModal(!openModal)
    }
    const scriptRow = [
        {
            name: 'Name',
            minWidth: 250,
            id: 'vsc_name',
            type: 'string'
        },
        {
            name: 'Type',
            minWidth: 50,
            id: 'vsc_type',
            type: 'img'
        },
        {
            name: 'Last Modified',
            minWidth: 50,
            id: 'vsc_date_modified',
            type: 'string'
        }
    ];

    useEffect(() => {
        getScripts().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    setSkeletonLoader(true);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [])

    return (
        <div className='script_list_wrapper container-md'>
            <div className='script_list_header'>
                <h2>스크립트</h2>
                <Fab onClick={() => setOpenModal(!openModal)} color="primary" aria-label="make script">
                    <AddIcon/>
                </Fab>
            </div>
            <div className='script_content'>
                <ListSkeleton loading={skeletonLoader}>
                    {isIterableArray(scriptList) ?

                        <Fragment>
                            {isMobile ?
                                <MobileScriptTable scriptList={scriptList}/>
                                :
                                <MyTxtTable detailType='script' dataRow={scriptList} topRow={scriptRow} type={'스크립트가'}/>
                            }
                        </Fragment>
                        :
                        <NotExistTable searchExist={false} type={'script'} clickEvent={toggle}/>
                    }
                </ListSkeleton>
            </div>
            <ScriptModal collapse={openModal} toggle={toggle}/>
        </div>
    )
}


export default ScriptList
