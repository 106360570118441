import * as types from './actionTypes';
import axios from "axios";

export const getScripts = async () => {
    const getScriptsRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/servermv/scripts`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._GET_SCRIPTS,
        request: getScriptsRequest
    }
}

export const addScripts = async (post) => {
    const addScriptsRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/servermv/scripts`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._ADD_SCRIPTS,
        request: addScriptsRequest
    }
}

export const updateScripts = async (post, id) => {
    const updateScriptsRequest = await axios.patch(`https://${process.env.REACT_APP_API_SERVER}/servermv/scripts/${id}`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._UPDATE_SCRIPTS,
        request: updateScriptsRequest,
        id: id,
        post: post
    }
}

export const deleteScripts = async (id) => {
    const deleteScriptsRequest = await axios.delete(`https://${process.env.REACT_APP_API_SERVER}/servermv/scripts/${id}`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._DEL_SCRIPTS,
        request: deleteScriptsRequest,
        id: id
    }
}
