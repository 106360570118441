export const DetailDescription = {
    manage: {
        label: '서비스 신청 후 서버 목록에서 노출되는 이름이며, 추후 변경하실 수 있습니다.',
        game_label: '서버에 접속하실 수 있는 도메인을 무료로 생성해드리며, 변경하실 수 없습니다.',
        hostname: 'www.server24.kr 와 같이 서버의 호스트네임을 지정하실 수 있습니다.',
        script: '선택하신 스크립트가 서버 생성 직후에 자동으로 실행됩니다.',
        ipv6: 'IPv4 외에 IPv6 주소를 1개 무료 제공하고 있습니다.',
        autoBackup: '자동 백업 기능을 활성화 하시면 설정한 스케줄에 맞게 무중단 백업이 진행됩니다.',
        ddosProtection: '최대 10Gbps 내외의 DDoS 공격을 방어하며, TCP/UDP 모든 서비스가 지원됩니다.',
        cPanelDomain: 'cPanel에서 사용하실 도메인을 입력해 주세요.',
        cPanelId: 'cPanel에서 사용하실 아이디를 입력해 주세요.',
        cPanelPwd: 'cPanel에서 사용하실 비밀번호를 입력해 주세요.'
    },
    account: {
        authBusiness: '- 기업 인증이 완료된 고객분들에 한해 후불 요금제 서비스를 제공하고 있습니다. \n' +
            '- 후불 요금제를 활성화하기 위해서는 티켓을 통해 문의주시기 바랍니다.'
    }
}
