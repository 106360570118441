import React, {Fragment, useState} from "react";
import {TextField} from "@mui/material";
import {Row, Col} from 'reactstrap';
import {useDispatch, useSelector} from "react-redux";
import LoaderButton from "../common/LoaderButton";
import {getfileSize} from "../../utils/size";
import {deleteSnapShot, updateSnapShot} from "./action";
import {restoreSnapShot} from "../manage/action";
import {updateAllStatusList} from "../Product/action";
import {checkErrorResponse} from "../../helpers/errorCheck";
import ConfirmModal from "../common/ConfirmModal";

const SnapshotDetail = ({info, location}) => {
    const dispatch = useDispatch();
    const {instanceInfo} = useSelector(state => state.manage);
    const [subject, setSubject] = useState(info.vs_description);
    const [delLoader, setDelLoader] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);

    const handleUpdateSnapShot = () => {
        setUpdateLoader(true);
        const post = {
            description: subject
        }
        updateSnapShot(post, info.vs_id).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    setUpdateLoader(false);
                    dispatch(response)
                }
            }
        }).catch(err => {
            setUpdateLoader(false);
            checkErrorResponse(err);
        })
    }
    const handleDeleteSnapShot = () => {
        setDelLoader(true);
        deleteSnapShot(info.vs_id).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    setDelLoader(false);
                    dispatch(response);
                }
            }
        }).catch(err => {
            setDelLoader(false);
            checkErrorResponse(err);
        })
    }

    const handleRestoreSnapShot = () => {
        //TODO 이미 복원중일 경우 에러처리 해주기


        return new Promise((resolve, reject) => {
            const post = {
                snapshot_id: info.vs_id
            }
            restoreSnapShot(instanceInfo.vi_id, post).then(response => {
                if (response) {
                    if (response.request.status === 202) {
                        //인스턴스 인포 자체를 락을 걸어야 됨
                        dispatch(response);
                        dispatch(updateAllStatusList('active', 'stopped', 'locked', instanceInfo.vi_id));
                        resolve(true);
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
                reject(err);
            })
        })
    }

    return (
        <div className='snapshot_detail_wrapper'>
            <Row className='snapshot_detail_row'>
                <Col className='h-100' xs={12} lg={6}>
                    <Row>
                        <Col xs={12} className='detail_left'>
                            <Row className='snapshot_detail_info'>
                                <Col xs={6} className='detail_info_left'>
                                    <span>Name : </span>
                                    <span>Original Size :</span>
                                </Col>
                                <Col xs={6} className='detail_info_right'>
                                    <span>{info.vs_description}</span>
                                    <span>{getfileSize(info.vs_size)}</span>
                                </Col>
                            </Row>


                        </Col>

                    </Row>
                </Col>
                <Col xs={12} lg={6}>
                    <TextField className='snapShot_name_input'
                               value={subject}
                               onChange={event => setSubject(event.target.value)}
                               fullWidth id="ticket_subject" label="Name" variant="outlined"/>

                    <Row className='snapShot_detail_btn'>
                        <Col xs={6}>
                            <LoaderButton disabled={delLoader} loader={updateLoader} onClick={() => handleUpdateSnapShot()} color='primary' size='large' variant="outlined">이름 수정</LoaderButton>
                        </Col>
                        <Col xs={6}>
                            {/*인스턴스 디테일 일경우에만 복원 일반 리스트는 삭제하기로*/}
                            {location === 'instance' ?
                                <Fragment>
                                    <ConfirmModal
                                        color='primary'
                                        size='large'
                                        variant="contained"
                                        btnDisabled={instanceInfo.vi_server_status === 'locked'}
                                        type={'restoreSnapshot'}
                                        handleEvent={handleRestoreSnapShot}/>
                                    {/*<Button*/}
                                    {/*    aria-describedby={'restore_snapshot'}*/}
                                    {/*    disabled={instanceInfo.vi_server_status === 'locked'}*/}
                                    {/*    onClick={event => setPopoverOpen(event.currentTarget)}*/}
                                    {/*    color='primary'*/}
                                    {/*    size='large'*/}
                                    {/*    variant="contained">*/}
                                    {/*    복원하기*/}
                                    {/*</Button>*/}
                                    {/*<ConfirmPopover*/}
                                    {/*    anchorEl={popoverOpen}*/}
                                    {/*    setAnchorEl={setPopoverOpen}*/}
                                    {/*    type={'reinstall'}*/}
                                    {/*    loader={delLoader}*/}
                                    {/*    title={'정말 스냅샷을 복원하시겠습니까?'}*/}
                                    {/*    btnTxt={'스냅샷 복원'}*/}
                                    {/*    checkLabel={'네, 복원 하겠습니다.'}*/}
                                    {/*    nodeId={'restore_snapshot'}*/}
                                    {/*    text={RestoreSnapshotTxt}*/}
                                    {/*    event={handleRestoreSnapShot}/>*/}
                                </Fragment>
                                :
                                <LoaderButton disabled={updateLoader} loader={delLoader} onClick={() => handleDeleteSnapShot()} color='error' size='large' variant="outlined">
                                    삭제하기
                                </LoaderButton>
                            }

                        </Col>
                    </Row>
                </Col>

            </Row>
        </div>
    )
}

SnapshotDetail.defaultProps = {
    info: {
        vsc_name: '',
        vsc_type: 'boot',
        vsc_script: '',
        vsc_no: -1,
        vsc_id: ''
    }
}
export default SnapshotDetail;
