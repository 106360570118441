import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {chargingPointRequest, paymentRequest} from "./action";
import Backdrop from "@mui/material/Backdrop";
import {Button, CircularProgress} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {useDispatch} from "react-redux";
import {checkErrorResponse} from "../../helpers/errorCheck";
import {moveNotificationAlert, notificationAlert} from "../../helpers/common";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const CardSuccess = ({amount, orderId, point}) => {
    return (
        <div>
            <div className='success_bill_title'>
                <h4><CheckCircleIcon fontSize={'large'}/>충전완료</h4>
                <span>포인트 충전이 완료되었습니다.</span>
            </div>
            <div className='success_bill'>
                <div className='bill_item'>
                    <span>주문번호</span>
                    <span>구매내역</span>
                    <span>총 주문금액</span>
                    <span>총 결제금액</span>
                    <span>결제수단</span>
                </div>
                <div className='bill_item'>
                    <span>{orderId}</span>
                    <span> {(parseInt(amount) * 10) / 11} 포인트</span>
                    <span>{point}원</span>
                    <span>{amount}원</span>
                    <span>카드</span>
                </div>

            </div>
        </div>
    )
}

const VrAccountSuccess = ({vrInfo, amount, orderId, point}) => {
    return (
        <div>
            <div className='success_bill_title'>
                <h4><CheckCircleIcon fontSize={'large'}/>가상계좌 충전</h4>
                <span>입금할 가상계좌를 확인해주세요.</span>
            </div>
            <div className='success_bill'>
                <div className='bill_item'>
                    <span>주문번호</span>
                    <span>구매내역</span>
                    <span>총 주문금액</span>
                    <span>총 결제금액</span>
                    <span>입금은행</span>
                    <span>입금자</span>
                    <span>입금기한</span>
                    <span>가상계좌번호</span>
                    <span>결제수단</span>
                </div>
                <div className='bill_item'>
                    <span>{orderId}</span>
                    <span> {(parseInt(amount) * 10) / 11} 포인트</span>
                    <span>{point}원</span>
                    <span>{amount}원</span>
                    <span>{vrInfo.bank}은행</span>
                    <span>{vrInfo.customerName}</span>
                    <span>{vrInfo.dueDate}</span>
                    <span>{vrInfo.accountNumber}</span>
                    <span>가상계좌</span>
                </div>

            </div>
        </div>
    )
}
const TransAccountSuccess = ({amount, orderId, point}) => {
    return (
        <div>
            <div className='success_bill_title'>
                <h4><CheckCircleIcon fontSize={'large'}/>충전완료</h4>
                <span>포인트 충전이 완료되었습니다.</span>
            </div>
            <div className='success_bill'>
                <div className='bill_item'>
                    <span>주문번호</span>
                    <span>구매내역</span>
                    <span>총 주문금액</span>
                    <span>총 결제금액</span>
                    <span>결제수단</span>
                </div>
                <div className='bill_item'>
                    <span>{orderId}</span>
                    <span> {(parseInt(amount) * 10) / 11} 포인트</span>
                    <span>{point}원</span>
                    <span>{amount}원</span>
                    <span>계좌이체</span>
                </div>

            </div>
        </div>
    )
}
const GiftSuccess = ({amount, orderId, point}) => {
    return (
        <div>
            <div className='success_bill_title'>
                <h4><CheckCircleIcon fontSize={'large'}/>충전완료</h4>
                <span>포인트 충전이 완료되었습니다.</span>
            </div>
            <div className='success_bill'>
                <div className='bill_item'>
                    <span>주문번호</span>
                    <span>구매내역</span>
                    <span>총 주문금액</span>
                    <span>총 결제금액</span>
                    <span>결제수단</span>
                </div>
                <div className='bill_item'>
                    <span>{orderId}</span>
                    <span> {(parseInt(amount) * 10) / 11} 포인트</span>
                    <span>{point}원</span>
                    <span>{amount}원</span>
                    <span>상품권</span>
                </div>

            </div>
        </div>
    )
}

const BillRequest = () => {
    const [successPay, setSuccessPay] = useState(false);
    const dispatch = useDispatch();
    let query = useQuery();
    const {status, point, payType} = useParams();
    const amount = query.get('amount');
    const orderId = query.get('orderId');
    const paymentKey = query.get('paymentKey');
    const errCode = query.get('code');
    const errMessage = query.get('message');
    const [vrAccountInfo, setVrAccountInfo] = useState({});
    const [receipUrl, setReceipUrl] = useState('');
    useEffect(() => {
        if (status === 'success') {
            if (amount !== point) {
                moveNotificationAlert('오류', '주문 금액과 결제 금액이 일치하지 않아 결제가 취소 되었습니다.', '/billing');
            }
            if (orderId && paymentKey) {
                const post = {
                    "amount": amount,
                    "orderId": orderId
                }
                paymentRequest(payType, paymentKey, post).then(response => {
                    if (response) {
                        if (response.request.status === 200) {
                            if (payType === 'vr-account')
                                setVrAccountInfo(response.request.data.virtualAccount);
                            else dispatch(chargingPointRequest(amount));
                            if (payType === 'card')
                                setReceipUrl(response.request.data.receiptUrl);
                            setSuccessPay(true);
                        }
                    }
                }).catch(err => {
                    checkErrorResponse(err);
                    moveNotificationAlert('오류', err.response.data.message, '/billing');

                })
            } else {
                moveNotificationAlert('오류', '잘못된 결제 요청 입니다.', '/billing');
            }
        } else {
            moveNotificationAlert('오류', errMessage, '/billing');
        }


    }, []);


    return (
        <div className='payment_request_wrapper'>
            {successPay ?
                <div className='success_pay_wrapper'>
                    {payType === 'card' && <CardSuccess amount={amount} orderId={orderId} point={point}/>}
                    {payType === 'vr-account' && <VrAccountSuccess vrInfo={vrAccountInfo} amount={amount} orderId={orderId} point={point}/>}
                    {payType === 'transfer-account' && <TransAccountSuccess amount={amount} orderId={orderId} point={point}/>}
                    {payType === 'gift' && <GiftSuccess amount={amount} orderId={orderId} point={point}/>}
                    {payType !== 'card' ?
                        <div className='pay_btn_wrapper'>
                            <Button onClick={() => window.location.href = '/'} fullWidth variant={'outlined'} color={'primary'}>홈으로</Button>
                        </div>
                        :
                        <div className='pay_btn_wrapper'>
                            <Button
                                onClick={() => window.open(receipUrl, 'console', 'width=400,height=500,location=no,status=no,scrollbars=yes')}
                                className='pay_btn_item'
                                variant={'outlined'}
                                color={'inherit'}>영수증 보기</Button>
                            <Button onClick={() => window.location.href = '/'}
                                    className='pay_btn_item'
                                    variant={'outlined'}
                                    color={'primary'}>홈으로</Button>
                        </div>
                    }

                </div>
                :
                <Backdrop
                    sx={{color: '#fff'}}
                    open
                >
                    <div className='payment_wrapper'>
                        <CircularProgress size={'4rem'} color="inherit"/>
                        <h3>포인트 충전중 입니다.</h3>
                        <h4>충전완료까지 다소 시간이 걸릴 수 있습니다.</h4>
                    </div>
                </Backdrop>
            }

        </div>
    )
}

export default BillRequest;
