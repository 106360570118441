import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import SettingIP from "./SettingIP";
import ChangeSetting from "./ChangeSetting";
import {useEffect} from "react";
import {getUpgradeList} from "../action";
import {useDispatch, useSelector} from "react-redux";
import {useMediaQuery} from "react-responsive";
import {checkErrorResponse} from "../../../helpers/errorCheck";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 0}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function SettingTabs() {
    const [value, setValue] = React.useState(0);
    const isMobile = useMediaQuery({
        query: "(max-width:1200px)"
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box
            sx={{flexDirection: isMobile ? 'column' : 'row', flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%'}}
        >
            <Tabs
                orientation={isMobile ? 'horizontal' : 'vertical'}
                variant="scrollable"
                id='setting_tabs'
                scrollButtons
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs"
            >
                <Tab label="네트워크 설정" {...a11yProps(0)} />
                <Tab label="호스트네임 변경" {...a11yProps(1)} />
                <Tab label="ISO 연결/해제" {...a11yProps(2)} />
                <Tab label="요금제 변경" {...a11yProps(3)} />
                <Tab label="디도스 방어" {...a11yProps(4)} />
                {/*<Tab label="스크립트 변경" {...a11yProps(4)} />*/}

                {/*<Tab label="OS 변경" {...a11yProps(4)} />*/}
            </Tabs>
            <TabPanel children={<SettingIP/>} style={{width: '100%'}} value={value} index={0}/>
            <TabPanel children={<ChangeSetting type={'hostname'}/>} style={{width: '100%'}} value={value} index={1}/>
            <TabPanel children={<ChangeSetting type={'iso'}/>} style={{width: '100%'}} value={value} index={2}/>
            <TabPanel children={<ChangeSetting type={'plan'}/>} style={{width: '100%'}} value={value} index={3}/>
            <TabPanel children={<ChangeSetting type={'ddos'}/>} style={{width: '100%'}} value={value} index={4}/>
            {/*<TabPanel children={<ChangeSetting type={'script'}/>} style={{width: '100%'}} value={value} index={4}/>*/}
            {/*<TabPanel children={<ChangeSetting type={'os'}/>} style={{width: '100%'}} value={value} index={4}/>*/}
        </Box>
    );
}
