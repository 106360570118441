import React, {useEffect, useState} from "react";
import {TabContext, TabPanel} from "@mui/lab";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {Row, Col} from 'reactstrap';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {Button} from "@mui/material";
import ViewTxtInput from "../../common/ViewTxtInput";
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import ListSkeleton from "../../common/ListSkeleton";
import {checkErrorResponse} from "../../../helpers/errorCheck";
import ConfirmModal from "../../common/ConfirmModal";
import GameManageDashBoard from "./GameManageDashBoard";
import {GameTypeImgItem} from "../common/GameTypeImgItem";
import {enterMineCraftPanel, getGameInstanceInfo, setGameInstanceLabel, setGameServerActive, startGameServer, stopGameServer, updateGameApp} from "../action";
import GameManageConsole from "./console/GameManageConsole";
import GameManageSetting from "./setting/GameManageSetting";
import GameFileManager from "./fileManager/GameFileManager";
import GameServerBackup from "./backup/GameServerBackup";
import GameChangePlanModal from "./manageModal/GameChangePlanModal";
import GameChangeTermModal from "./manageModal/GameChangeTermModal";
import {GameAppState} from "./GameAppState";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';



const GameManage = () => {
    const dispatch = useDispatch();
    let {no, id} = useParams();
    const {gameInstanceDetail, sessionId} = useSelector(state => state.gameDeploy);
    const [value, setValue] = useState('1');
    const [anchorEl, setAnchorEl] = useState(null);
    const [headerSkeleton, setHeaderSkeleton] = useState(false);
    const [planModal, setPlanModal] = useState(false);
    const [termModal, setTermModal] = useState(false);


    const planToggle = () => setPlanModal(!planModal);
    const termToggle = () => setTermModal(!termModal);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleServerStop = () => {
        return new Promise((resolve, reject) => {
            stopGameServer(no).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        resolve(true);
                        dispatch(setGameServerActive(false));
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
                reject(err);
            })
        })
    };

    const handleServerRestart = () => {
        return new Promise((resolve, reject) => {
            startGameServer(no).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        resolve(true);
                        dispatch(setGameServerActive(true));
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
                reject(err);
            })
        })
    };

    const handleGameUpdate = () => {
        return new Promise((resolve, reject) => {
            updateGameApp(sessionId, id).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        if (response.request.data.result.Status) {
                            resolve(true);
                        } else {
                            console.log('error',response);
                            reject(response.request.data.result.Reason);
                        }
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
                reject(err);
            })
        })
    };


    const handleSetLabel = (text) => {
        return new Promise(((resolve, reject) => {

            setGameInstanceLabel(no, text).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        dispatch(response)
                        resolve(true);
                    }
                }
            }).catch(err => {
                if (err) {
                    reject(err);
                }
            })
        }))
    }

    useEffect(() => {
        let bot;
        enterMineCraftPanel(no).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    getGameInstanceInfo(response.request.data.sessionId, id).then(getResult => {
                        if (getResult) {
                            if (getResult.request.status === 200) {
                                if (getResult.request.data.Message) {
                                    if (getResult.request.data.Message === 'Connection refused') {
                                        dispatch(setGameServerActive(false));
                                    }
                                } else {
                                    dispatch(getResult);
                                    dispatch(setGameServerActive(true));
                                    setHeaderSkeleton(true);
                                }
                            }

                        }
                    }).catch(err => {
                        console.log('//getError',err);
                        checkErrorResponse(err);
                    })
                }
            }
        }).catch(err => {
            console.log('//inError',err);
            checkErrorResponse(err);
        })

        return () => clearInterval(bot);

    }, [])


    useEffect(() => {
        let bot
        bot = setInterval(() => {
            if (sessionId) {
                getGameInstanceInfo(sessionId, id).then(response => {
                    if (response) {
                        if (response.request.status === 200) {
                            if (response.request.data.Title) {
                                if (response.request.data.Title === 'Unauthorized Access') {
                                    //정보 업데이트 인터벌중 세션이 만료 되었을 경우
                                    enterMineCraftPanel(no).then(response => {
                                        if (response) {
                                            if (response.request.status === 200) {
                                                dispatch(response);
                                            }
                                        }
                                    }).catch(err => {
                                        checkErrorResponse(err);
                                    })

                                } else if (response.request.data.Title === 'Http Request') {
                                    //정보 업데이트 인터벌중 서버가 꺼졌을 경우
                                    clearInterval(bot);
                                    dispatch(setGameServerActive(false));
                                }
                            } else {
                                dispatch(response);
                            }
                        }

                    }
                }).catch(err => {
                    checkErrorResponse(err);
                })
            }
        }, 5000)


        return () => clearInterval(bot);

    }, [sessionId])

    return (
        <div className='manage_wrapper container-md'>
            <ListSkeleton height={200} type={'rectangular'} itemNum={1} colNum={12} loading={headerSkeleton}>
                <div className='manage_header'>
                    <div className='server_info'>
                        <Row>
                            <Col className='server_info_title' xs={12}>
                                <div className='manage_os_title'>
                                    <GameTypeImgItem gameType={'Java'}/>
                                    <div className='manage_label game_manage_label'>
                                        <ViewTxtInput clickEvent={handleSetLabel} inputValue={gameInstanceDetail.label}>
                                            <h2>
                                                {gameInstanceDetail.label}
                                            </h2>
                                        </ViewTxtInput>
                                        <GameAppState no={no} id={id}/>


                                    </div>
                                </div>
                                <div className='manage_os_control'>
                                    <div>
                                        <ConfirmModal
                                            // btnDisabled={instanceInfo.vi_server_status === 'locked' || instanceInfo.vi_del_status === 'tDeleted'}
                                            type={'stop'}
                                            handleEvent={handleServerStop}/>
                                    </div>
                                    <div>
                                        <ConfirmModal
                                            // btnDisabled={instanceInfo.vi_server_status === 'locked' || instanceInfo.vi_del_status === 'tDeleted'}
                                            type={'start'}
                                            handleEvent={handleServerRestart}/>
                                    </div>
                                    <div>
                                        <ConfirmModal
                                            // btnDisabled={instanceInfo.vi_server_status === 'locked' || instanceInfo.vi_del_status === 'tDeleted'}
                                            type={'gameUpdate'}
                                            handleEvent={handleGameUpdate}/>
                                    </div>
                                    <div className='manage_os_more_btn'>
                                        <button aria-controls="manage-control"
                                                onClick={handleMenu}>
                                            <MoreHorizIcon/>
                                        </button>
                                        <Menu
                                            id="manage-control"
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                        >
                                            <MenuItem
                                                onClick={handleClose}
                                                className='game_manage_menu_item p-0'>
                                                <Button
                                                    className='p-2'
                                                    onClick={termToggle}>
                                                    <CalendarTodayIcon/>유효기간 연장
                                                </Button>
                                            </MenuItem>
                                            <MenuItem
                                                onClick={handleClose}
                                                className='game_manage_menu_item p-0'>
                                                <Button
                                                    className='p-2'
                                                    onClick={planToggle}>
                                                    <AttachMoneyIcon/>요금제 변경
                                                </Button>
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </ListSkeleton>
            <Box sx={{width: '100%', typography: 'body1'}}>
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleChange} aria-label="" className='game_manage_tab'>
                            <Tab label="DashBoard" value="1"/>
                            <Tab label="Console" value="2"/>
                            <Tab label="File Manager" value="3"/>
                            <Tab label="Setting" value="4"/>
                            <Tab label="Backup" value="5"/>
                            {/*<Tab label="Plugin" value="6"/>*/}

                        </TabList>
                    </Box>
                    <TabPanel className='tab_panel p-0' value="1"><GameManageDashBoard skeletonLoader={headerSkeleton}/></TabPanel>
                    <TabPanel className='tab_panel p-0' value="2"><GameManageConsole instanceId={id}/></TabPanel>
                    <TabPanel className='tab_panel p-0' value="3"><GameFileManager instanceId={id}/></TabPanel>
                    <TabPanel className='tab_panel p-0' value="4"><GameManageSetting/></TabPanel>
                    <TabPanel className='tab_panel p-0' value="5"><GameServerBackup/></TabPanel>
                    {/*<TabPanel className='tab_panel p-0' value="6"><MinecraftPlugin/></TabPanel>*/}

                </TabContext>
            </Box>
            <GameChangePlanModal modal={planModal} toggle={planToggle} mcNo={no} id={id}/>
            <GameChangeTermModal modal={termModal} toggle={termToggle} mcNo={no}/>
        </div>
    )
}

export default GameManage;
