import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getPlan, setPrice, setRegion, setService} from "./action";
import {notificationAlert} from "../../helpers/common";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {Row, Col} from 'reactstrap';
import {Alert, AlertTitle} from "@mui/lab";
import DeployTitle from "./DeployTitle";
import Swal from "sweetalert2";
import {isIterableArray} from "../../utils/common";
import {checkRegion} from "./function";
import {checkErrorResponse} from "../../helpers/errorCheck";
import {CircularProgress} from "@material-ui/core";

const serviceInfo = {
    vhf: {
        title: '고성능 가상 서버호스팅',
        description: '단일코어 성능이 우수한 3.7GHz 이상의 Intel CPU와 NVMe SSD 스토리지를 탑재한 고성능 컴퓨팅 인스턴스입니다.'
    },
    vc2: {
        title: '가상 서버호스팅',
        description: '최대 1분안에 생성되는 Intel CPU와 고성능 SSD 스토리지를 갖춘 강력한 컴퓨팅 인스턴스입니다.'
    },
    vbm: {
        title: '베어메탈(단독) 서버호스팅 (서비스 준비중)',
        description: '가상화 없이 즉시 사용 가능한 5.0Ghz 이상의 Intel 및 AMD 기반의 고성능 단독 서버입니다.'
    },
    vdc: {
        title: '성능보장형 가상 서버호스팅',
        description: '타 고객의 영향 없이 CPU, RAM, SSD의 실제 물리적 성능을 보장하는 가상 서버호스팅입니다.'
    }
}


const ServiceList = () => {
    const {service} = useSelector((state) => state.deploy.vultr);
    const dispatch = useDispatch();
    const [serviceType, setServiceType] = useState('vhf');
    const [loaderType, setLoaderType] = useState(false);
    const [loaderService, setLoaderService] = useState('');
    const handleService = (type) => {
        if (serviceType === type) {
            notificationAlert('알림', '이미 선택하신 서비스입니다.');
            return;
        }
        setLoaderType(true);
        setLoaderService(type);
        setServiceType(type);
        dispatch(setService(type));
    }
    const handleGetPlan = (response) => {
        const planList = response.data.planList.filter(plan => plan.vp_type === service.type);
        if (isIterableArray(planList)) {
            dispatch(setPrice(planList[0]));
            setLoaderType(false);
            setLoaderService('');
        } else {
            notificationAlert('오류', '죄송합니다, 현재 일시적으로 해당 지역에 재고가 부족합니다. 나중에 다시 시도해주세요.');
            setLoaderType(false);
            setLoaderService('');

        }
    }

    useEffect(() => {
        if (serviceType === 'vbm')
            return;
        let regionId;
        if (serviceType === 'vdc')
            regionId = 'nrt';
        else
            regionId = 'icn';
        dispatch(setRegion(regionId));
        checkRegion(regionId).then(response => {
            if (response) {
                if (response.status === 200) {
                    dispatch(getPlan(response.data.planList));
                    if (isIterableArray(response.data.planList)) {
                        handleGetPlan(response)
                    } else {
                        notificationAlert('오류', '죄송합니다, 현재 일시적으로 해당 지역에 재고가 부족합니다. 나중에 다시 시도해주세요.');
                        setLoaderType(false);
                        setLoaderService('');
                    }
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
            setLoaderType(false);
            setLoaderService('');
        })
    }, [serviceType])
    return (
        <Fragment>
            <DeployTitle title={'서버 호스팅은 다양한 성능을 제공합니다'}/>
            <Row className='deploy_service_list'>
                <Col xs={12} sm={6} lg={3} className={service.type === 'vhf' ? 'server_service select_service' : 'server_service'}>
                    {service.type === 'vhf' && <CheckCircleIcon/>}
                    <button disabled={loaderType} onClick={() => handleService('vhf')}>
                        {loaderService === 'vhf' ?
                            <CircularProgress/>
                            :
                            <img src={'https://s3.server24.kr/panel/img/service/HighService.png'}/>
                        }
                    </button>
                    <span>고성능 가상 서버호스팅</span>
                </Col>
                <Col xs={12} sm={6} lg={3} className={service.type === 'vc2' ? 'server_service select_service' : 'server_service'}>
                    {service.type === 'vc2' && <CheckCircleIcon/>}
                    <button disabled={loaderType} onClick={() => handleService('vc2')}>
                        {loaderService === 'vc2' ?
                            <CircularProgress/>
                            :
                            <img src={'https://s3.server24.kr/panel/img/service/CloudService.png'}/>
                        }
                    </button>
                    <span>가상 서버호스팅</span>
                </Col>
                <Col xs={12} sm={6} lg={3} aria-disabled={true}
                     onClick={() => {
                         setServiceType('vbm');
                         Swal.fire({
                             title: '알림',
                             text: '현재 서비스 준비중 입니다.',
                             confirmButtonText: '닫기'
                         }).then(result => {
                             if (result) {
                                 handleService('vc2');
                             }
                         })
                     }}
                     className={service.type === 'vbm' ? 'server_service select_service' : 'server_service'}>
                    {service.type === 'vbm' && <CheckCircleIcon/>}
                    <button disabled={loaderType}>
                        <img src={'https://s3.server24.kr/panel/img/service/BareService.png'}/>
                    </button>
                    <span>베어메탈(단독) 서버호스팅</span>
                </Col>
                <Col xs={12} sm={6} lg={3} className={service.type === 'vdc' ? 'server_service select_service' : 'server_service'}>
                    {service.type === 'vdc' && <CheckCircleIcon/>}
                    <button disabled={loaderType} onClick={() => handleService('vdc')}>
                        {loaderService === 'vdc' ?
                            <CircularProgress/>
                            :
                            <img src={'https://s3.server24.kr/panel/img/service/SingleService.png'}/>
                        }
                    </button>
                    <span>성능보장형 가상 서버호스팅</span>
                </Col>
                <Col xs={12}>
                    <div className='select_service_description'>
                        {serviceType.length !== 0 ?
                            <Alert severity="info">
                                <AlertTitle>{serviceInfo[serviceType].title}</AlertTitle>
                                {serviceInfo[serviceType].description}
                            </Alert>
                            :
                            <Alert severity="info">
                                <AlertTitle>서비스를 선택하여 주세요.</AlertTitle>
                                서비스에 대한 설명입니다.
                            </Alert>
                        }
                    </div>
                </Col>

            </Row>

        </Fragment>
    )
}


export default ServiceList
;
