import arkImg from '../assets/img/game/gameThumnail/ark.jpeg';
import arma3Img from '../assets/img/game/gameThumnail/arma3.jpeg';
import garymodImg from '../assets/img/game/gameThumnail/garymod.jpeg';
import left4dead2Img from '../assets/img/game/gameThumnail/left4dead2.jpg';
import minecraftImg from '../assets/img/game/gameThumnail/minecraft.jpeg';
import rusImg from '../assets/img/game/gameThumnail/rust.jpeg';
import teamfortressImg from '../assets/img/game/gameThumnail/teamfortress.jpeg';
import csImg from '../assets/img/game/gameThumnail/counterStrike.jpeg';

import grass from '../assets/img/game/minecraft/mineBlocks/GrassNew.png';
import wood from '../assets/img/game/minecraft/mineBlocks/wood.png';
import stone from '../assets/img/game/minecraft/mineBlocks/StoneNew.png';
import coal from '../assets/img/game/minecraft/mineBlocks/Block_of_Coal.png';
import iron from '../assets/img/game/minecraft/mineBlocks/IronBlockNew.png';
import copper from '../assets/img/game/minecraft/mineBlocks/copper.png';
import gold from '../assets/img/game/minecraft/mineBlocks/BlockOfGoldNew.png';
import diamond from '../assets/img/game/minecraft/mineBlocks/BlockOfDiamondNew.png';
import emerald from '../assets/img/game/minecraft/mineBlocks/BlockOfEmeraldNew.png';
import redStone from '../assets/img/game/minecraft/mineBlocks/RedstoneBlockNew.png';
import lapis from '../assets/img/game/minecraft/mineBlocks/LapisLazuliBlockNew.png';
import obsidian from '../assets/img/game/minecraft/mineBlocks/Obsidian.png';
import bedRock from '../assets/img/game/minecraft/mineBlocks/Bedrock_JE2_BE2.png';


export const gameList = [
    {
        title: 'MINECRAFT',
        img: minecraftImg
    },
    {
        title: 'ARK',
        img: arkImg
    },
    {
        title: 'ARMA 3',
        img: arma3Img
    },
    {
        title: 'COUNTER STRIKE',
        img: csImg
    },
    {
        title: 'LEFT 4 DEAD',
        img: left4dead2Img
    },
    {
        title: "GARRY'S MOD",
        img: garymodImg
    },
    {
        title: 'TEAM FORTRESS 2',
        img: teamfortressImg
    },
    {
        title: 'RUST',
        img: rusImg
    }
]

export const minecraftPriceList = [
    {
        id: 'GRASS-10P-1024M',
        title: 'GRASS',
        monthly_const: 7000,
        slots: 10,
        priceImg: grass,
        ram: 1024
    },
    {
        id: 'WOOD-20P-2048M',
        title: 'WOOD',
        monthly_const: 15000,
        slots: 20,
        priceImg: wood,
        ram: 2048
    },
    {
        id: 'STONE-30P-3072M',
        title: 'STONE',
        monthly_const: 25000,
        slots: 30,
        priceImg: stone,
        ram: 3072
    },
    {
        id: 'COAL-40P-4096M',
        title: 'COAL',
        monthly_const: 40000,
        slots: 40,
        priceImg: coal,
        ram: 4096
    },
    {
        id: 'IRON-50P-6144M',
        title: 'IRON',
        monthly_const: 60000,
        slots: 50,
        priceImg: iron,
        ram: 6144
    },
    {
        id: 'COPPER-60P-8192M',
        title: 'COPPER',
        monthly_const: 80000,
        slots: 60,
        priceImg: copper,
        ram: 8192
    },
    {
        id: 'GOLD-70P-10240M',
        title: 'GOLD',
        monthly_const: 100000,
        slots: 70,
        priceImg: gold,
        ram: 10240
    },
    {
        id: 'DIAMOND-80P-12288M',
        title: 'DIAMOND',
        monthly_const: 150000,
        slots: 80,
        priceImg: diamond,
        ram: 12288
    },
    {
        id: 'EMERALD-90P-14336M',
        title: 'EMERALD',
        monthly_const: 200000,
        slots: 90,
        priceImg: emerald,
        ram: 14336
    },
    {
        id: 'REDSTONE-100P-16384M',
        title: 'REDSTONE',
        monthly_const: 300000,
        slots: 100,
        priceImg: redStone,
        ram: 16384
    },
    {
        id: 'LAPIS-150P-32768M',
        title: 'LAPIS',
        monthly_const: 500000,
        slots: 150,
        priceImg: lapis,
        ram: 32768
    },
    {
        id: 'OBSIDIAN-200P-65536M',
        title: 'OBSIDIAN',
        monthly_const: 650000,
        slots: 200,
        priceImg: obsidian,
        ram: 1024
    },
    {
        id: 'BEDROCK-250P-81920M',
        title: 'BEDROCK',
        monthly_const: 990000,
        slots: 250,
        priceImg: bedRock,
        ram: 81920
    }

];


export const gameSettingNodes = {
    0: [
        "MinecraftModule.GameSettings.ForceGameMode",
        "MinecraftModule.GameSettings.GameMode",
        "MinecraftModule.GameSettings.Difficulty",
        "MinecraftModule.GameSettings.OpPermssions",
        "MinecraftModule.GameSettings.FuncPermissions",
        "MinecraftModule.GameSettings.SpawnProtectionRadius",
        "MinecraftModule.GameSettings.HardcoreMode",
        "MinecraftModule.GameSettings.EnableMonsters",
        "MinecraftModule.GameSettings.EnableAnimals",
        "MinecraftModule.GameSettings.EnableNPCs",
        "MinecraftModule.GameSettings.EnablePVPCombat",
        "MinecraftModule.GameSettings.AllowFlight",
        "MinecraftModule.GameSettings.AllowCommandBlocks",
        "MinecraftModule.GameSettings.TimeoutMins",
        "MinecraftModule.GameSettings.Whitelist",
        "MinecraftModule.GameSettings.MaxTickTime",
        "MinecraftModule.GameSettings.MaxBuildHeight"
    ],
    1: [
        "MinecraftModule.MinecraftSettings.ServerMOTD",
        "MinecraftModule.MinecraftSettings.ServerType",
        "MinecraftModule.MinecraftSettings.ReleaseStream",
        "MinecraftModule.MinecraftSettings.SpecificVersion",
        "MinecraftModule.MinecraftSettings.SpecificSpigotVersion",
        "MinecraftModule.MinecraftSettings.SpecificForgeVersion",
        "MinecraftModule.MinecraftSettings.SpecificSpongeVersion",
        "MinecraftModule.MinecraftSettings.SpecificPaperVersion",
        "MinecraftModule.MinecraftSettings.SpecificPurpurVersion",
        "MinecraftModule.MinecraftSettings.ServerJAR",
        "MinecraftModule.MinecraftSettings.AdditionalOpts",
        "MinecraftModule.MinecraftSettings.PreventProxy",
        "MinecraftModule.MinecraftSettings.WorldSeed",
        "MinecraftModule.MinecraftSettings.WorldType",
        "MinecraftModule.MinecraftSettings.ViewDistance",
        "MinecraftModule.MinecraftSettings.SimulationDistance",
        "MinecraftModule.MinecraftSettings.HideOnlinePlayers",
        "MinecraftModule.MinecraftSettings.LevelName",
        "MinecraftModule.MinecraftSettings.MaxWorldSize",
        "MinecraftModule.MinecraftSettings.WorldGenSettings",
        "MinecraftModule.MinecraftSettings.GenStructures",
        "MinecraftModule.MinecraftSettings.EnableNether",
        "MinecraftModule.MinecraftSettings.RequireResourcePack",
        "MinecraftModule.MinecraftSettings.ResourcePackPrompt",
        "MinecraftModule.MinecraftSettings.ResourcePackURL",
        "MinecraftModule.MinecraftSettings.ResourcePackSHA1",
        "MinecraftModule.MinecraftSettings.RestartOnSevere"
    ],
    2: [
        "MinecraftModule.LimitsSettings.MaxPlayers",
        "MinecraftModule.LimitsSettings.AutoWhitelistOfficial"
    ],
    3: [
        "MinecraftModule.JavaSettings.JavaVersion"
    ]
}
