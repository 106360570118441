import React from "react";
import {Col, Row} from "reactstrap";
import BillBox from "./BillBox";
import ChargingPoint from "./ChargingPoint";
import ChargeTable from "./ChargeTable";
import {useSelector} from "react-redux";
import ListSkeleton from "../common/ListSkeleton";
import {isGameMode} from "../../utils/common";


const BillInfo = ({loader}) => {
    const {account, payment} = useSelector(state => state.bill.billInfo);
    const {layoutMode} = useSelector(state => state.layout);
    const accountInfo = [
        {
            title: '이용자(담당자) 이름',
            key: 'name'
        },
        {
            title: '이메일 주소',
            key: 'email'
        },
        {
            title: '연락처',
            key: 'phone'
        }, {
            title: '개인/사업자 여부',
            key: 'seq'
        },
    ];

    const billInfo = [
        {
            title: '보유 포인트(크레딧)',
            key: 'allPoint'
        },
        {
            title: '이번달 차감된 포인트',
            key: 'monthUsePoint'
        },
        {
            title: '오늘 차감된 포인트',
            key: 'todayUsePoint'
        },
        {
            title: '포인트 최근 충전일',
            key: 'recentChargingTime'
        },
    ];


    return (
        <Row noGutters className='billing_info_container'>
            <Col className='billing_left_wrapper p-0' xs={12} lg={6}>
                <div className='billing_info_wrapper'>
                    <ListSkeleton height={250} type={'rectangular'} itemNum={1} colNum={12} loading={loader}>
                        <BillBox title={'계정 정보'} data={account} row={accountInfo}/>
                    </ListSkeleton>
                </div>
            </Col>
            <Col className='billing_right_wrapper p-0' xs={12} lg={6}>
                <div className='billing_info_wrapper'>
                    <ListSkeleton height={250} type={'rectangular'} itemNum={1} colNum={12} loading={loader}>
                        <BillBox title={'결제 정보'} data={payment} row={billInfo}/>
                    </ListSkeleton>
                </div>
            </Col>
            {/*<Col className='billing_left_wrapper p-0' xs={4}>*/}
            {/*    <div className='billing_info_wrapper'>*/}
            {/*        <BillBox title={'다른 정보'} row={billInfo}/>*/}
            {/*    </div>*/}
            {/*</Col>*/}
            <Col className='p-0' xs={12}>
                <div className='billing_list_wrapper p-0'>
                    <div  className={isGameMode(layoutMode) ? 'billing_list_title dark_bg_color1' :'billing_list_title'}>
                        <span>결제 내역</span>
                    </div>
                    <ChargeTable loader={loader}/>
                </div>
            </Col>
        </Row>
    )
}

export default BillInfo;
