export const _INIT_PROFILE = '_INIT_PROFILE';

export const _GET_USER_LOG = '_GET_USER_LOG';

export const _CHANGE_PHONE = '_CHANGE_PHONE';

export const _SESSION_EXPIRED = '_SESSION_EXPIRED';

export const _SESSION_LOGOUT = '_SESSION_LOGOUT';

export const _SESSION_EXTENSION = '_SESSION_EXTENSION';

export const _GET_AUTH_BUSINESS = '_GET_AUTH_BUSINESS';

export const _AUTHENTICATION_BUSINESS = '_AUTHENTICATION_BUSINESS';

export const _SMS_MARKETING_AGREE = '_SMS_MARKETING_AGREE';

export const _EMAIL_MARKETING_AGREE = '_EMAIL_MARKETING_AGREE';
