import React, {useState, useEffect} from "react";
import GradientBack from "../common/GradientBack";
import MyInput from "../common/MyInput";
import {Button, Form} from "reactstrap";
import passwordIcon from "../../assets/img/login/password.svg";
import classNames from "classnames";
import {useForm} from "react-hook-form";
import {resetPwdRequest} from "./function";
import {checkErrorResponse} from "../../helpers/errorCheck";
import {notificationAlert} from "../../helpers/common";
import Swal from "sweetalert2";

const PasswordReset = ({match, history}) => {
    const {register, handleSubmit, errors, watch} = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    });
    const [token, setToken] = useState('');
    useEffect(() => {
        if (match.params) {
            setToken(match.params.token);
        }
    }, [match.params])
    const onSubmitData = data => {
        resetPwdRequest({password: data.resetPassword}, token).then(response => {
            if (response) {
                if (response.status === 200) {
                    Swal.fire({
                        title: '알림',
                        text: '비밀번호가 변경 되었습니다.',
                        confirmButtonText: '닫기'
                    }).then(result => {
                        if (result.isConfirmed) {
                            window.location.href = 'https://panel.server24.kr';
                        }
                    })
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    };
    return (
        <GradientBack boxSize={450}>
            <div className='find_form_wrapper'>
                <h4 style={{textAlign: 'center'}}>비밀번호 변경</h4>
                <Form onSubmit={handleSubmit(onSubmitData)}>
                    <MyInput label={'비밀번호'}
                             icon={passwordIcon}
                             type='password'
                             id='resetPassword'
                             name='resetPassword'
                             placeholder='비밀번호를 입력해주세요.'
                             autoComplete='new-password'
                             innerRef={register({
                                 required: '비밀번호는 반드시 입력되어야 합니다',
                                 pattern: {
                                     value: /^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{6,20}$/,
                                     message: '6자 이상 20자 이하 영문 대소문자, 최소 1개의 숫자 및 특수 문자를 포함하여 입력하여 주세요.'
                                 }
                             })}
                             errors={errors}
                             className={classNames({'border-danger': errors.resetPassword})}
                    />
                    <MyInput label={'비밀번호 확인'}
                             icon={passwordIcon}
                             type='password'
                             placeholder="비밀번호 확인"
                             id="confirmNewPassword"
                             name="confirmNewPassword"
                             innerRef={register({
                                 required: '비밀번호 확인은 반드시 입력되어야 합니다',
                                 validate: value => value === watch('resetPassword') || '입력하신 비밀번호가 일치하지 않습니다'
                             })}
                             errors={errors}
                             className={classNames({'border-danger': errors.confirmNewPassword})}
                    />

                    <div className='findId_btn'>
                        <Button type='submit'>변경하기</Button>
                    </div>
                </Form>
            </div>
        </GradientBack>
    )
}


export default PasswordReset;
