import * as types from './actionTypes';
import axios from "axios";
import {_DELETE_GAME_BACKUP, _GET_GAME_INSTANCE_INFO, _GET_GAME_SERVERS, _GET_SETTING_NODES, _SET_GAME_DEPLOY_LOADER, _SET_GAME_NODE} from "./actionTypes";

export const setDeployGameType = (gameType) => {

    return {
        type: types._SET_GAME_TYPE,
        gameType
    }
}

export const setDeployGamePrice = (priceType) => {

    return {
        type: types._SET_GAME_PRICE,
        priceType
    }
}

export const setDeployGameRegion = (regionType) => {

    return {
        type: types._SET_GAME_REGION,
        regionType
    }
}

export const setDeployMcType = (mcType) => {

    return {
        type: types._SET_MC_TYPE,
        mcType
    }
}

export const setDeployGameQty = (qty) => {

    return {
        type: types._SET_DEPLOY_QTY,
        qty
    }
}

export const setGameDeployLoader = (loader) => {
    return {
        type: types._SET_GAME_DEPLOY_LOADER,
        loader
    }
}


export const setGameServerActive = (active) => {
    return {
        type: types._SET_GAME_SERVER_ACTIVE,
        active
    }
}


export const getGameServerList = async () => {
    const getGameServerListRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/game/mc`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._GET_GAME_SERVERS,
        request: getGameServerListRequest
    }
}

export const gameDeploy = async (post) => {
    const gameDeployRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/game/mc`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._GAME_DEPLOY,
        request: gameDeployRequest
    }
}

export const getGamePlanList = async (mcNo) => {
    const getGamePlanListRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/game/mc/${mcNo}/upgrade`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._GET_GAME_PLAN_LIST,
        request: getGamePlanListRequest
    }
}


export const enterMineCraftPanel = async (mcNo) => {
    const enterMineCraftPanelRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/game/mc/${mcNo}/in`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._SET_SESSION_ID,
        request: enterMineCraftPanelRequest
    }
}

export const startGameServer = async (mcNo) => {
    const startGameServerRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/game/mc/${mcNo}/start`, {}, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: startGameServerRequest
    }
}

export const stopGameServer = async (mcNo) => {
    const startGameServerRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/game/mc/${mcNo}/stop`, {}, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: startGameServerRequest
    }
}

export const getGameInstanceInfo = async (sessionId, instanceId) => {
    const getGameInstanceInfoRequest = await axios.post(`https://${process.env.REACT_APP_GAME_CONSOLE}/API/ADSModule/Servers/${instanceId}/API/Core/GetUpdates`, {
        SESSIONID: sessionId
    }, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._GET_GAME_INSTANCE_INFO,
        request: getGameInstanceInfoRequest
    }
}

export const sendConsoleCommand = async (sessionId, instanceId, message) => {

    const sendConsoleCommandRequest = await axios.post(`https://${process.env.REACT_APP_GAME_CONSOLE}/API/ADSModule/Servers/${instanceId}/API/Core/SendConsoleMessage`, {
        SESSIONID: sessionId,
        message
    }, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: sendConsoleCommandRequest
    }
}

export const getFileManagerDir = async (sessionId, instanceId, dir) => {
    const getFileManagerDirRequest = await axios.post(`https://${process.env.REACT_APP_GAME_CONSOLE}/API/ADSModule/Servers/${instanceId}/API/FileManagerPlugin/GetDirectoryListing`, {
        SESSIONID: sessionId,
        Dir: dir === '/' ? '' : dir
    }, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._GET_FILE_MANAGER_DIR,
        request: getFileManagerDirRequest
    }
}

export const delDirFileManager = async (sessionId, instanceId, dirName) => {
    const getFileManagerDirRequest = await axios.post(`https://${process.env.REACT_APP_GAME_CONSOLE}/API/ADSModule/Servers/${instanceId}/API/FileManagerPlugin/TrashDirectory`, {
        SESSIONID: sessionId,
        DirectoryName: dirName
    }, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: getFileManagerDirRequest
    }
}

export const delFileFileManager = async (sessionId, instanceId, fileName) => {
    const getFileManagerDirRequest = await axios.post(`https://${process.env.REACT_APP_GAME_CONSOLE}/API/ADSModule/Servers/${instanceId}/API/FileManagerPlugin/TrashFile`, {
        SESSIONID: sessionId,
        Filename: fileName
    }, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: getFileManagerDirRequest
    }
}

export const changeFileNameFileManager = async (sessionId, instanceId, fileName, newFileName) => {
    const changeFileNameFileManagerRequest = await axios.post(`https://${process.env.REACT_APP_GAME_CONSOLE}/API/ADSModule/Servers/${instanceId}/API/FileManagerPlugin/RenameFile`, {
        SESSIONID: sessionId,
        Filename: fileName,
        NewFilename: newFileName
    }, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: changeFileNameFileManagerRequest
    }
}

export const changeDirNameFileManager = async (sessionId, instanceId, fileName, newFileName) => {
    const changeDirNameFileManagerRequest = await axios.post(`https://${process.env.REACT_APP_GAME_CONSOLE}/API/ADSModule/Servers/${instanceId}/API/FileManagerPlugin/RenameDirectory`, {
        SESSIONID: sessionId,
        oldDirectory: fileName,
        NewDirectoryName: newFileName
    }, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: changeDirNameFileManagerRequest
    }
}

export const getFileChunk = async (sessionId, instanceId, fileName) => {
    const getFileChunkRequest = await axios.post(`https://${process.env.REACT_APP_GAME_CONSOLE}/API/ADSModule/Servers/${instanceId}/API/FileManagerPlugin/GetFileChunk`, {
        SESSIONID: sessionId,
        Filename: fileName,
        Position: 0,
        Length: 33554432


    }, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: getFileChunkRequest
    }
}

export const saveFileChunk = async (sessionId, instanceId, fileName, data) => {
    const saveFileChunkRequest = await axios.post(`https://${process.env.REACT_APP_GAME_CONSOLE}/API/ADSModule/Servers/${instanceId}/API/FileManagerPlugin/WriteFileChunk`, {
        SESSIONID: sessionId,
        Filename: fileName,
        Position: 0,
        Data: btoa(data)


    }, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: saveFileChunkRequest
    }
}

export const adminLogin = async () => {
    const adminLoginRequest = await axios.post(`https://${process.env.REACT_APP_GAME_CONSOLE}/API/Core/Login`, {
        "username": process.env.REACT_APP_GAME_ID,
        "password": process.env.REACT_APP_GAME_PWD,
        "token": "",
        "rememberMe": false

    }, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: adminLoginRequest
    }
}


export const getDownloadFileUrl = async (mcNo) => {
    const getDownloadFileUrlRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/game/mc/${mcNo}/file`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: getDownloadFileUrlRequest
    }
}

export const uploadUrlFileManager = async (sessionId, instanceId, url, dir) => {
    const uploadUrlFileManagerRequest = await axios.post(`https://${process.env.REACT_APP_GAME_CONSOLE}/API/ADSModule/Servers/${instanceId}/API/FileManagerPlugin/DownloadFileFromURL`, {
        SESSIONID: sessionId,
        Source: url,
        TargetDirectory: dir
    }, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: uploadUrlFileManagerRequest
    }
}

export const setGameSettingConfigs = async (sessionId, instanceId, data, node, value) => {
    const uploadUrlFileManagerRequest = await axios.post(`https://${process.env.REACT_APP_GAME_CONSOLE}/API/ADSModule/Servers/${instanceId}/API/Core/SetConfigs`, {
        SESSIONID: sessionId,
        data
    }, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._SET_GAME_NODE,
        request: uploadUrlFileManagerRequest,
        node,
        value
    }
}

export const getGameSettingConfigs = async (sessionId, instanceId, nodes) => {
    const getGameSettingConfigsRequest = await axios.post(`https://${process.env.REACT_APP_GAME_CONSOLE}/API/ADSModule/Servers/${instanceId}/API/Core/GetConfigs`, {
        SESSIONID: sessionId,
        nodes
    }, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._GET_SETTING_NODES,
        request: getGameSettingConfigsRequest
    }
}


export const gameSettingAction = async (sessionId, instanceId, module, method, argument) => {
    const gameSettingActionRequest = await axios.post(`https://${process.env.REACT_APP_GAME_CONSOLE}/API/ADSModule/Servers/${instanceId}/API/${module}/${method}`, {
        SESSIONID: sessionId,
        Node: argument ? argument : ''
    }, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: gameSettingActionRequest
    }
}


export const getSftpUser = async () => {
    const getSftpUserRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/game/sftp`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: getSftpUserRequest
    }
}

export const getGameBackups = async (sessionId, instanceId) => {
    const getGameBackupsRequest = await axios.post(`https://${process.env.REACT_APP_GAME_CONSOLE}/API/ADSModule/Servers/${instanceId}/API/LocalFileBackupPlugin/GetBackups`, {
        SESSIONID: sessionId,
    }, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._GET_GAME_BACKUPS,
        request: getGameBackupsRequest
    }
}


export const takeGameBackups = async (sessionId, instanceId, title) => {
    const takeGameBackupsRequest = await axios.post(`https://${process.env.REACT_APP_GAME_CONSOLE}/API/ADSModule/Servers/${instanceId}/API/LocalFileBackupPlugin/TakeBackup`, {
        SESSIONID: sessionId,
        Title: title,
        Description: '',
        Sticky: false
    }, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: takeGameBackupsRequest
    }
}


export const restoreGameBackups = async (sessionId, instanceId, backupId) => {
    const restoreGameBackupsRequest = await axios.post(`https://${process.env.REACT_APP_GAME_CONSOLE}/API/ADSModule/Servers/${instanceId}/API/LocalFileBackupPlugin/RestoreBackup`, {
        SESSIONID: sessionId,
        BackupId: backupId,

    }, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: restoreGameBackupsRequest
    }
}

export const deleteGameBackups = async (sessionId, instanceId, backupId) => {
    const deleteGameBackupsRequest = await axios.post(`https://${process.env.REACT_APP_GAME_CONSOLE}/API/ADSModule/Servers/${instanceId}/API/LocalFileBackupPlugin/DeleteBackup`, {
        SESSIONID: sessionId,
        BackupId: backupId,
    }, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._DELETE_GAME_BACKUP,
        request: deleteGameBackupsRequest,
        backupId
    }
}

export const setGameInstanceLabel = async (mcNo, label) => {
    const setGameInstanceLabelRequest = await axios.patch(`https://${process.env.REACT_APP_API_SERVER}/game/mc/${mcNo}/label`, {label}, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._SET_GAME_LABEL,
        request: setGameInstanceLabelRequest,
        label
    }
}

export const extendGameExpireDate = async (mcNo, month) => {
    const extendGameExpireDateRequest = await axios.patch(`https://${process.env.REACT_APP_API_SERVER}/game/mc/${mcNo}/extend`, {month}, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._EXTEND_GAME_EXPIRE_DATE,
        request: extendGameExpireDateRequest
    }
}

export const setAgreeEula = async (mcNo) => {
    const setAgreeEulaRequest = await axios.patch(`https://${process.env.REACT_APP_API_SERVER}/game/mc/${mcNo}/eula`, {eula: 1}, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._SET_EULA_AGREE,
        request: setAgreeEulaRequest
    }
}


export const updateGameApp = async (sessionId, instanceId) => {
    const updateGameAppRequest = await axios.post(`https://${process.env.REACT_APP_GAME_CONSOLE}/API/ADSModule/Servers/${instanceId}/API/Core/UpdateApplication`, {
        SESSIONID: sessionId,
    }, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: updateGameAppRequest,
    }
}

export const upgradeGamePlan = async (mcNo, plan) => {
    const upgradeGamePlanRequest = await axios.patch(`https://${process.env.REACT_APP_API_SERVER}/game/mc/${mcNo}/upgrade`, {plan}, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: upgradeGamePlanRequest
    }
}
