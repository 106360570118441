import React from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {Button} from "@mui/material";
import {isGameMode} from "../../utils/common";


const BillBox = ({row, data, title}) => {
    const history = useHistory();
    const {totalPoint} = useSelector(state => state.bill);
    const {layoutMode} = useSelector(state => state.layout);
    return (
        <div className={isGameMode(layoutMode) ? 'bill_box_wrapper dark_bg_color2' : 'bill_box_wrapper'}>
            <div className='bill_box_title'>
                <span className='box_title'>{title}</span>
            </div>
            <div className='bill_box_row'>
                {row.map((txt, index) => {
                    if (txt.key === 'allPoint')
                        return (
                            <div key={index} className='bill_box_item'>
                                <span>{txt.title}</span>
                                <span>{parseInt(totalPoint).toLocaleString()}P</span>
                            </div>
                        )
                    if (txt.key === 'phone') {
                        const phoneNum = data[txt.key];
                        return (
                            <div key={index} className='bill_box_item'>
                                <span>{txt.title}</span>
                                {phoneNum ?
                                    <span>{phoneNum}</span>
                                    :
                                    <Button
                                        color={'primary'}
                                        variant={'outlined'}
                                        id={'register_phone_btn'}
                                        onClick={() => history.push('/account')}>등록하기</Button>
                                }
                            </div>
                        )
                    }
                    if (txt.key === 'monthUsePoint' || txt.key === 'todayUsePoint')
                        return (
                            <div key={index} className='bill_box_item'>
                                <span>{txt.title}</span>
                                <span>{parseInt(data[txt.key]).toLocaleString()}P</span>
                            </div>
                        )
                    return (
                        <div key={index} className='bill_box_item'>
                            <span>{txt.title}</span>
                            <span>{data[txt.key]}</span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default BillBox;
