import MineCraftImg from "../../../assets/img/game/minecraft/mineBlocks/GrassNew.png";
import * as React from "react";

export const GameTypeImgItem = ({gameType}) => {
    let gameImg;
    switch (gameType) {

        case 'Bedrock':
            gameImg = MineCraftImg;
            break;

        case 'Java' :
            gameImg = MineCraftImg;
            break;

        default:
            gameImg = MineCraftImg;
            break;
    }
    return (
        <div className='game_server_region_img'>
            <img src={gameImg} alt={'game_type_img'}/>
        </div>
    )
}


export function getNumString(str) {
    if (str) {
        let answer;
        str = str.replace(/[^0-9]/g, '');
        answer = parseInt(str);
        return answer;
    } else {
        return str
    }
}


export const MinecraftVersionNode = (serverType, node) => {
    if (serverType === 10 && node === "MinecraftModule.MinecraftSettings.SpecificVersion") {
        return true;
    }
    if (serverType === 20 && node === "MinecraftModule.MinecraftSettings.SpecificSpigotVersion") {
        return true;
    }
    if (serverType === 30 && node === "MinecraftModule.MinecraftSettings.SpecificSpigotVersion") {
        return true;
    }
    if (serverType === 31 && node === "MinecraftModule.MinecraftSettings.SpecificPaperVersion") {
        return true;
    }
    if (serverType === 32 && node === "MinecraftModule.MinecraftSettings.SpecificPurpurVersion") {
        return true;
    }
    if (serverType === 50 && node === "MinecraftModule.MinecraftSettings.SpecificForgeVersion") {
        return true;
    }
    if (serverType === 60 && node === "MinecraftModule.MinecraftSettings.SpecificSpongeVersion") {
        return true;
    }
    return false
}
