import React, {Fragment, useEffect, useState} from "react";
import ToastBox from "../../common/ToastBox";
import {Button, TextField, Tooltip} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {publicIso} from "../../../datas/publicIso";
import {OSSixFour, OSThreeTwo} from "../../../datas/OS";
import {updateAllStatusList} from "../../Product/action";
import {attachIso, detachIso, getInstanceIso, getUpgradeList, infoStatusReinstall, reinstallServer, updateInstanceDetail, updateInstancePlan} from "../action";
import ConfirmPopover from "../../common/ConfirmPopover";
import {ActiveDdos, AttachIsoTxt, DetachIsoTxt, InActiveDdos, ReinstallServerTxt, RestartServerTxt, UpgradePlanTxt} from "../../constant/Popover";
import {convertPlanTxt} from "../../../utils/common";
import {getScripts} from "../../script/action";
import {Row, Col} from 'reactstrap';
import {ToastMsg} from "../../constant/ToastMsg";
import {checkErrorResponse} from "../../../helpers/errorCheck";
import ConfirmModal from "../../common/ConfirmModal";
import {notificationAlert} from "../../../helpers/common";
import {makeStyles} from "@material-ui/core/styles";
import ChangePlan from "./ChangePlan";

const ChangeHost = ({InstanceInfo, dispatch}) => {

    const [hostname, setHostname] = useState(InstanceInfo.vi_hostname ? InstanceInfo.vi_hostname : '');
    const handleChangeHostname = () => {

        return new Promise((resolve, reject) => {
            const id = InstanceInfo.vi_id;
            dispatch(updateAllStatusList('pending', 'stopped', 'none', id));
            dispatch(infoStatusReinstall(id, 'stopped'));
            reinstallServer(id, hostname).then(response => {
                if (response) {
                    if (response.request.status === 202) {
                        dispatch(response);
                        dispatch(updateAllStatusList('active', 'running', 'ok', id));
                        dispatch(infoStatusReinstall(id, 'running'));
                        resolve(true);
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
                reject(err);
            })
        })
    }

    return (
        <div className='change_hostname_wrapper'>
            <h3>호스트네임(Hostname) 변경</h3>
            <ToastBox type={'warning'} title={'경고'} text={ToastMsg.ChangeHostNameMsg}/>
            <div>
                <TextField className='change_hostname_input'
                           value={hostname}
                           onChange={event => setHostname(event.target.value)}
                           fullWidth label="Hostname" variant="outlined"/>
                <ConfirmModal
                    fullWidth
                    color={'error'}
                    variant={'contained'}
                    type={'reinstall'}
                    handleEvent={handleChangeHostname}/>

            </div>
        </div>
    )
}


const ChangeISO = ({InstanceInfo, dispatch}) => {
    const [iso, setISO] = useState(null);
    const {isoInfo} = useSelector(state => state.manage);
    const [mountIso, setMountIso] = useState({name: '', description: ''});
    const useStyles = makeStyles(() => ({

        menuPaper: {
            maxHeight: 400
        }
    }));
    const classes = useStyles();
    useEffect(() => {
        getInstanceIso(InstanceInfo.vi_id).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [])
    useEffect(() => {
        if (Object.keys(isoInfo).length !== 0) {
            const mountIso = publicIso.filter(iso => iso.id === isoInfo.vis_iso_id);
            setMountIso(mountIso[0]);
        }
    }, [isoInfo])

    const handleMountIso = (type) => {
        return new Promise((resolve, reject) => {
            let post = {};
            if (type) {
                if (!iso) {
                    notificationAlert('알림', 'ISO를 선택해 주세요.');
                    return;
                }
                post.iso_id = iso;
                attachIso(InstanceInfo.vi_id, post).then(response => {
                    if (response) {
                        if (response.request.status === 200) {
                            dispatch(response);
                            resolve(true);
                        }
                    }
                }).catch(err => {
                    checkErrorResponse(err);
                    reject(err);
                })
            } else {
                post.iso_id = isoInfo.vis_iso_id;
                detachIso(InstanceInfo.vi_id, post).then(response => {
                    if (response) {
                        if (response.request.status === 200) {
                            dispatch(response);
                            resolve(true);
                        }
                    }
                }).catch(err => {
                    checkErrorResponse(err);
                    reject(err);
                })
            }
        })
    }

    return (
        <div className='change_iso_wrapper'>
            <h3>ISO 변경</h3>
            {Object.keys(isoInfo).length === 0 ?
                <Fragment>
                    <ToastBox type={'warning'} title={'경고'} text={ToastMsg.MountIsoMsg}/>
                    <div style={{marginTop: '3rem'}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">ISO</InputLabel>
                            <Select
                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                labelId="demo-simple-select-label"
                                label="ISO"
                                onChange={event => setISO(event.target.value)}
                            >
                                {publicIso.map((iso, index) => {
                                    return (
                                        <MenuItem key={index} className='select_list_item' value={iso.id}>{`${iso.name} - ${iso.description}`}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <ConfirmModal
                            fullWidth
                            color={'primary'}
                            variant={'contained'}
                            type={'isoMount'}
                            handleEvent={() => handleMountIso(true)}/>
                    </div>
                </Fragment>
                :
                <Fragment>
                    <ToastBox type={'warning'} title={'경고'} text={ToastMsg.UnmountIsoMsg}/>
                    <span>{`마운트 ISO : ${mountIso.name} - ${mountIso.description}`}</span>
                    <div style={{marginTop: '3rem'}}>
                        <ConfirmModal
                            fullWidth
                            color={'primary'}
                            variant={'contained'}
                            type={'isoUnMount'}
                            handleEvent={() => handleMountIso(false)}/>
                    </div>
                </Fragment>
            }

        </div>
    )
}
const ChangeOS = ({osList}) => {
    const [os, setOS] = useState('');

    const handleChangeOS = () => {

    }

    return (
        <div className='change_os_wrapper'>
            <h3>OS 변경</h3>
            <ToastBox type={'warning'} title={'경고'} text={'다른 운영 체제로 변경하면 서버의 모든 데이터 가 지워 집니다.'}/>
            <div style={{marginTop: '3rem'}}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">OS</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        label="OS"
                        onChange={event => setOS(event.target.value)}
                    >
                        {osList.map((os, index) => {
                            return (
                                <MenuItem key={index} className='select_list_item' value={os.id}>{os.name}</MenuItem>
                            )
                        })}

                        {/*<MenuItem className='select_list_item' value={1}>영업 문의</MenuItem>*/}
                    </Select>
                </FormControl>
                <Button fullWidth color={'error'} variant={'contained'}>
                    <span>변경하기</span>
                </Button>
            </div>
        </div>
    )
}

const ChangeScript = ({dispatch, defaultHost}) => {
    const {scriptList} = useSelector(state => state.script);
    const [script, setScript] = useState(null);
    useEffect(() => {
        getScripts().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response)
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [])

    return (
        <div className='change_script_wrapper setting_list_wrapper p-0'>
            <h3>스크립트 변경</h3>
            <ToastBox type={'warning'} title={'경고'} text={'운영 체제를 다시 설치하게 될 경우 서버의 모든 데이터 가 지워 집니다.'}/>
            <div className='setting_scripts p-0'>
                <Row className='script_list_wrapper'>
                    {scriptList.map((script, index) => {
                            const isSelect = false
                            return (
                                <Col key={index} lg={3}>
                                    <Tooltip placement={'top'} title={script.vsc_name}>
                                        <div
                                            className={isSelect ? 'script_item select_script' : 'script_item'}>
                                            <img src={isSelect ?
                                                'https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/f91b504a-525e-407d-fa7c-a8e6aa09cb00/service'
                                                :
                                                'https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/f5be0360-2274-40e2-2c63-0eeefbd6ab00/service'}/>
                                            <span>{script.vsc_name}</span>
                                        </div>
                                    </Tooltip>
                                </Col>
                            )
                        }
                    )}


                </Row>
                <Button fullWidth color={'error'} variant={'contained'}>
                    <span>변경하기</span>
                </Button>
            </div>
        </div>
    )
}

const ChangeDdos = ({dispatch, InstanceInfo}) => {
    const {featureInfo} = useSelector(state => state.manage);


    const handleActiveDdos = (type) => {
        return new Promise((resolve, reject) => {
            const post = {
                ddos_protection: type
            }
            updateInstanceDetail(InstanceInfo.vi_id, post, 'ddos_protection').then(response => {
                if (response) {
                    if (response.request.status === 202) {
                        dispatch(response);
                        resolve(true);
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
                reject(err);
            })
        })
    }

    return (
        <div className='change_ddos_wrapper'>
            <h3>디도스 방어 서비스</h3>
            {featureInfo.includes('ddos_protection') ?
                <div>
                    <ToastBox type={'info'} title={'알림'} text={ToastMsg.EnabledDdosMsg}/>
                    <ToastBox type={'warning'} title={'경고'} text={ToastMsg.WarningDdosMsg}/>
                    <div>
                        <ConfirmModal
                            fullWidth
                            color={'inherit'}
                            variant={'contained'}
                            type={'disableDdos'}
                            handleEvent={() => handleActiveDdos(false)}/>
                    </div>
                </div>
                :
                <Fragment>
                    {InstanceInfo.vi_enable_ddos === 0 ?
                        <ToastBox type={'error'} title={'접근 불가'} text={ToastMsg.NeverActiveDdosMsg}/>
                        :
                        <div>
                            <ToastBox type={'info'} title={'알림'} text={ToastMsg.ActiveDdosMsg}/>
                            <div>
                                <ConfirmModal
                                    fullWidth
                                    color={'primary'}
                                    variant={'contained'}
                                    type={'enableDdos'}
                                    handleEvent={() => handleActiveDdos(true)}/>
                            </div>
                        </div>
                    }

                </Fragment>
            }

        </div>
    )
}

const TypeSetting = ({type, info, upgradeList, dispatch}) => {
    switch (type) {
        case 'hostname' :
            return <ChangeHost dispatch={dispatch} InstanceInfo={info}/>
        case 'plan' :
            return <ChangePlan instanceInfo={info} />
        case 'os' :
            return <ChangeOS osList={upgradeList.os}/>
        case 'iso' :
            return <ChangeISO dispatch={dispatch} InstanceInfo={info}/>
        case 'script' :
            return <ChangeScript dispatch={dispatch}/>
        case 'ddos' :
            return <ChangeDdos dispatch={dispatch} InstanceInfo={info}/>
        default :
            return <ChangeHost/>
    }
}

const ChangeSetting = ({type}) => {
    const dispatch = useDispatch();
    const {instanceInfo, upgradeList} = useSelector(state => state.manage);

    return (
        <div className='change_setting_wrapper'>
            <TypeSetting dispatch={dispatch} upgradeList={upgradeList} type={type} info={instanceInfo}/>
        </div>
    )
}


export default ChangeSetting;
