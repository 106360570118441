import React, {Fragment} from "react";
import {Row, Col} from 'reactstrap';
import {Chip} from "@mui/material";

const MobileIpv4Table = ({ipList}) => {

    return (
        <div className='mobile_ipv4_table'>
            {ipList.map((ip, index) => {
                return (
                    <Row key={index} className='mobile_ipv4_item'>
                        <Col xs={3} className='row flex-column ip_key'>
                            <span>Address</span>
                            <span>Netmask</span>
                            <span>Gateway</span>
                            <span>Reverse DNS</span>
                        </Col>
                        <Col xs={9} className='row flex-column fw-bold'>
                            {ip.vi4_type === 'main_ip' ?
                                <div className='main_ip_item'>
                                    <span>{ip.vi4_ip}</span>
                                    <Chip
                                        className='main_ip_chip'
                                        size={'small'}
                                        style={{
                                            maxWidth: '100px',
                                            marginLeft: '1rem',
                                            color: '#20A144',
                                            backgroundColor: '#E7FBF0'
                                        }}
                                        label="Main IP"/>
                                </div>
                                :
                                <span>{ip.vi4_ip}</span>
                            }

                            <span>{ip.vi4_netmask}</span>
                            <span>{ip.vi4_gateway}</span>
                            <span>{ip.vi4_reverse}</span>
                        </Col>
                    </Row>
                )
            })}
        </div>
    )
}

export default MobileIpv4Table;
