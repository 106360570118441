import * as types from './actionTypes';
import axios from "axios";
import {_SET_CPANEL_PLAN} from "./actionTypes";

export const initDeploy = () => {
    return {
        type: types._INIT_DEPLOY
    }
}

export const setServer = (server) => {

    return {
        type: types._SET_SERVER,
        server: server
    }
}

export const setService = (service) => {

    return {
        type: types._SET_SERVICE,
        service: service
    }
}

export const setRegion = (region) => {

    return {
        type: types._SET_REGION,
        region: region
    }
}


export const setOS = (OS, osType) => {

    return {
        type: types._SET_OS,
        OS: OS,
        osType: osType
    }
}


export const setPrice = (price) => {

    return {
        type: types._SET_PRICE,
        price: price
    }
}

export const setAdditional = (type, use, monthPrice, dayPrice) => {

    return {
        type: types._SET_ADDITIONAL,
        additionalType: type,
        use: use,
        monthPrice: monthPrice,
        dayPrice: dayPrice
    }
}

export const setScript = (script) => {

    return {
        type: types._SET_SCRIPT,
        script: script
    }
}
export const setSetting = (setting) => {

    return {
        type: types._SET_SETTING,
        setting: setting
    }
}


export const getPlan = (plan) => {

    return {
        type: types._GET_PLAN,
        plan: plan
    }
}


export const initAdditional = () => {
    return {
        type: types._INIT_ADDITIONAL
    }
}

export const setCpanelRegion = (region) => {
    return {
        type: types._SET_CPANEL_REGION,
        region
    }
}

export const setCpanelPrice = (price) => {
    return {
        type: types._SET_CPANEL_PRICE,
        price
    }
}

export const setCpanelPlan = (plan) => {
    return {
        type: types._SET_CPANEL_PLAN,
        plan
    }
}
