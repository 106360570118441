import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ToastBox from "../../common/ToastBox";
import {Button} from "@mui/material";
import ConfirmPopover from "../../common/ConfirmPopover";
import ManageBackupDetail from "./ManageBackupDetail";
import {activationAutoBackupTxt} from "../../constant/Popover";
import {updateInstanceDetail} from "../action";
import {NotActiveBackupMsg, ToastMsg} from "../../constant/ToastMsg";
import {checkErrorResponse} from "../../../helpers/errorCheck";
import ConfirmModal from "../../common/ConfirmModal";


const ManageBackup = () => {
    const dispatch = useDispatch();
    const {instanceInfo, featureInfo} = useSelector(state => state.manage);
    const handleActiveBackup = () => {
        return new Promise((resolve, reject) => {
            const post = {
                backups: 'enabled'
            }
            updateInstanceDetail(instanceInfo.vi_id, post, 'backups').then(response => {
                if (response) {
                    if (response.request.status === 202) {
                        dispatch(response);
                        resolve(true);
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
                reject(err);
            })
        })
    }

    return (
        <div className='manage_backup_wrapper'>
            {featureInfo.includes('auto_backups') ?
                <ManageBackupDetail />
                :
                <Fragment>
                    {instanceInfo.vi_enable_backup === 1 ?
                        <div className='not_auto_backups'>
                            <ToastBox type={'info'} title={'알림'} text={ToastMsg.NotActiveBackupMsg}/>
                            <ConfirmModal
                                className={'enable_backup_btn'}
                                color={'primary'}
                                variant={'contained'}
                                type={'enableBackup'}
                                handleEvent={handleActiveBackup}/>
                        </div>
                        :
                        <div>
                            <ToastBox type={'error'} title={'접근 불가'} text={ToastMsg.NeverActiveBackupMsg}/>
                        </div>
                    }
                </Fragment>
            }
        </div>
    )
}

export default ManageBackup;
