import React, {Fragment, useState} from "react";
import {Col, Row} from "reactstrap";
import OsImgItem from "../common/OsImgItem";
import {Collapse} from "@mui/material";
import SnapshotDetail from "./SnapShotDetail";


const SnapShotMobileTable = ({snapShotList, type}) => {
    const [open, setOpen] = useState(-1);
    const handleOpen = (index) => {
        if (open === index) setOpen(-1);
        else setOpen(index);
    }
    const dataKey = {
        backup: {
            os_id: 'vb_os_id',
            description: 'vb_description',
            id: 'vb_id',
            status: 'vb_status'
        },
        snapShot: {
            os_id: 'vs_os_id',
            description: 'vs_description',
            id: 'vs_id',
            status: 'vs_status'
        },
        allBackup: {
                os_id: 'vi_os_id',
                description: 'vb_description',
                id: 'vb_id',
                status: 'vb_status'
        }
    }
    return (
        <div className='mobile_table_wrapper'>
            {snapShotList.map((snapShot, index) => {
                return (
                    <Fragment key={index}>
                        <Row onClick={() => handleOpen(index)} className='mobile_item align-items-center'>
                            {type !== 'backup' &&
                            <Col className='p-0' xs={2}>
                                <OsImgItem osId={snapShot[dataKey[type].os_id]}/>
                            </Col>
                            }
                            <Col className='p-0' xs={type === 'backup' ? 9 : 7}>
                                <div className='instance_label_wrapper'>
                                    <span>{snapShot[dataKey[type].description]}</span>
                                    <small>{snapShot[dataKey[type].id]}</small>
                                </div>
                            </Col>
                            <Col xs={3} className='server_status_info p-0'>
                                {snapShot[dataKey[type].status] === 'complete' ?
                                    <span className='status_success'>사용가능</span>
                                    :
                                    <span className='status_pending'>처리중</span>
                                }
                            </Col>
                        </Row>
                        {type === 'snapShot' &&
                        <Collapse in={open === index} timeout="auto" unmountOnExit>
                            <SnapshotDetail snapshotStatus={snapShot[dataKey[type].status]} location={'instance'} info={snapShot}/>
                        </Collapse>
                        }
                    </Fragment>
                )
            })}
        </div>
    )
}

export default SnapShotMobileTable
