import React, {Fragment} from "react";
import DeployTitle from "../../../deploy/DeployTitle";
import {Col, Input, Label, Row} from "reactstrap";
import {Chip, Tooltip} from "@mui/material";
import {DetailDescription} from "../../../constant/DetailDescription";
import HelpIcon from "@mui/icons-material/Help";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {useForm} from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";


const MineServiceSection = ({setDomain, setServerLabel}) => {

    const {register, errors} = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    return (
        <Row className='setting_list_wrapper minecraft_service_section'>
            <DeployTitle title={'서버 서비스 설정\n'}/>
            <Col xs={12} lg={6}>
                <Row className='setting_list_host_wrapper'>
                    <Col xs={12}>
                        <div>
                            <Label>무료 도메인 생성</Label>
                            <span>*</span>
                            <Tooltip title={DetailDescription.manage.game_label} placement="top">
                                <HelpIcon fontSize={'small'} color={'disabled'}/>
                            </Tooltip>
                        </div>
                        <Row>
                            <Col xs={8}>
                                <Input className='setting_list_label'
                                       type={'text'}
                                       maxLength={20}
                                       placeholder={'example.sv24.kr'}
                                       onChange={event => setDomain(event.target.value)}
                                       name={'game_deploy_server_domain'}
                                       innerRef={register({
                                           required: '도메인은 반드시 입력되어야 합니다',
                                           pattern: {
                                               value: /^[A-Za-z0-9-+]{3,20}$/,
                                               message: '서버이름은 영어 대소문자 또는 숫자만 허용 가능하며 최소 3글자이상 20자이하로 설정해주세요.'
                                           }
                                       })}
                                       id="setting_label"/>
                            </Col>
                            <Col xs={4}>
                                <FormControl fullWidth className='game_main_domain_form'>
                                    <InputLabel id="game_main_domain">Domain</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="game_main_domain_select"
                                        value={0}
                                        label="Domain"
                                    >
                                        <MenuItem className='select_list_item' value={0}>.sv24.kr</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row>
                        {errors.game_deploy_server_domain && <p style={{padding: '0.5rem 0', color: 'red', fontSize: '0.875rem'}} className="text-danger">{errors.game_deploy_server_domain.message}</p>}
                    </Col>
                    <Col xs={12}>
                        <div>
                            <Label>서버 이름 지정</Label>
                            <span>*</span>
                            <Tooltip title={DetailDescription.manage.label} placement="top">
                                <HelpIcon fontSize={'small'} color={'disabled'}/>
                            </Tooltip>
                        </div>
                        <Input className='setting_list_label'
                               type={'text'}
                               maxLength={20}
                               name={'game_deploy_server_name'}
                               placeholder={'Minecraft1'}
                               onChange={event => setServerLabel(event.target.value)}
                               innerRef={register({
                                   required: '서버 이름은 반드시 입력되어야 합니다',
                                   pattern: {
                                       value: /^[A-Za-z0-9+]{3,20}$/,
                                       message: '서버이름은 영어 대소문자 또는 숫자만 허용 가능하며 최소 3글자이상 20자이하로 설정해주세요.'
                                   }
                               })}
                               id="setting_label"/>
                        {errors.game_deploy_server_name && <p style={{padding: '0.5rem 0', color: 'red', fontSize: '0.875rem'}} className="text-danger">{errors.game_deploy_server_name.message}</p>}
                    </Col>
                </Row>
            </Col>
            <Col lg={6} className='setting_additional'>
                <span style={{fontSize: '20px'}} className='fw-bold'>부가서비스 신청</span>
                <FormGroup className='provide_option_wrapper'>
                    {/*<div className='provide_option_item'>*/}
                    {/*    <FormControlLabel control={<Checkbox/>} checked disabled label="CDN77 80Tbps+ 무제한 디도스 방어 "/>*/}
                    {/*    <div className='option_chip_wrapper'>*/}
                    {/*        <Chip label="기본제공" color="primary"/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className='provide_option_item'>
                        <FormControlLabel className='option_form' checked disabled control={<Checkbox/>} label="가용공간 내 무제한 SSD 저장공간"/>
                        <div className='option_chip_wrapper'>
                            <Chip label="기본제공" color="primary"/>
                            {/*<Tooltip title={DetailDescription.manage.ddosProtection} placement={'top'}>*/}
                            {/*    <HelpIcon fontSize={'small'} color={'disabled'}/>*/}
                            {/*</Tooltip>*/}
                        </div>
                    </div>


                </FormGroup>

            </Col>

        </Row>
    )
}

export default MineServiceSection;
