import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import MinecraftEulaModal from "./manageModal/MinecraftEulaModal";
import {saveFileChunk, setAgreeEula, setGameServerActive, startGameServer} from "../action";
import {notificationAlert} from "../../../helpers/common";
import {checkErrorResponse} from "../../../helpers/errorCheck";


/*
 0 : stopped
 10 : starting
 20 : running
 40 : stopping
 50 : sleeping
 70 : install instance
 100 : unable to run
 200 : Preparing Starting
 */


const stateTxt = (state) => {
    switch (state) {
        case 0 : {
            return (
                <small className='d-flex align-items-center stop_status'>
                    <div id='status_circle_run'/>
                    서버 꺼짐</small>
            )
        }
        case 10 : {
            return (
                <small className='d-flex align-items-center start_status'>
                    <div id='status_circle_run'/>
                    서버 시작중</small>
            )
        }
        case 20 : {
            return (
                <small className='d-flex align-items-center run_status'>
                    <div id='status_circle_run'/>
                    서버 가동중</small>
            )
        }
        case 40 : {
            return (
                <small className='d-flex align-items-center stop_status'>
                    <div id='status_circle_run'/>
                    서버 정지중</small>
            )
        }
        case 50 : {
            return (
                <small className='d-flex align-items-center error_status'>
                    <div id='status_circle_run'/>
                    서버 준비중</small>
            )
        }
        case 70 : {
            return (
                <small className='d-flex align-items-center error_status'>
                    <div id='status_circle_run'/>
                    서버 초기 설정중</small>
            )
        }
        case 100 : {
            return (
                <small className='d-flex align-items-center error_status'>
                    <div id='status_circle_run'/>
                    서버 문제발생</small>
            )
        }
        case 200 : {
            return (
                <small className='d-flex align-items-center start_status'>
                    <div id='status_circle_run'/>
                    서버 시작 준비중</small>
            )
        }
        default: {
            return (
                <small className='d-flex align-items-center stop_status'>
                    <div id='status_circle_run'/>
                    서버 꺼짐</small>
            )
        }
    }

}

export const GameAppState = ({id, no}) => {
    const dispatch = useDispatch();
    const {gameInstanceInfo, gameInstanceDetail, sessionId} = useSelector(state => state.gameDeploy);
    const [state, setState] = useState(0);

    useEffect(() => {
        if (gameInstanceInfo.Status.State === 100) {
            if (gameInstanceDetail.eula === 0) {
                eulaAgreeChange();
            }
        }
        setState(gameInstanceInfo.Status.State);
    }, [gameInstanceInfo]);

    const eulaAgreeChange = () => {
        saveFileChunk(sessionId, id, 'eula.txt', 'eula=true').then(response => {
            if (response) {
                if (response.request.status === 200) {
                    if (!response.request.data) {
                        setAgreeEula(no).then(eulaResponse => {
                            if (eulaResponse) {
                                if (eulaResponse.request.status === 200) {
                                    dispatch(eulaResponse)
                                    startGameServer(no).then(response => {
                                        if (response) {
                                            if (response.request.status === 200) {
                                                dispatch(setGameServerActive(true));
                                            }
                                        }
                                    }).catch(err => {
                                        checkErrorResponse(err);
                                    })
                                }
                            }
                        }).catch(err => {
                            checkErrorResponse(err);
                        })

                    } else {
                        notificationAlert('알림', ' 저장 실패');
                    }
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }

    return (
        <div>
            {stateTxt(state)}
        </div>
    )
}
