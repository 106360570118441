import React, {useEffect, useState} from "react";
import GameCpuStatus from "./StatusChart/GameCpuStatus";
import {Col, Row} from "reactstrap";
import GameUserStatus from "./StatusChart/GameUserStatus";
import GameRamStatus from "./StatusChart/GameRamStatus";
import {useSelector} from "react-redux";
import {GameStatusGraph} from "./ statusGraph/GameStatusGraph";
import ramIcon from "../../../assets/img/server_info/game_ram.svg";
import consoleIcon from "../../../assets/img/server_info/game_console.svg";
import portIcon from "../../../assets/img/server_info/game_port.svg";
import calendarIcon from "../../../assets/img/server_info/game_calendar.svg";
import GameImgInfo from "../common/GameImgInfo";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Tooltip} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeployTitle from "../../deploy/DeployTitle";
import ListSkeleton from "../../common/ListSkeleton";


const GameManageDashBoard = ({skeletonLoader}) => {

    const {gameInstanceActive, gameInstanceDetail, gameInstanceInfo} = useSelector(state => state.gameDeploy);




    return (
        <div className='game_manage_dashboard'>
            {gameInstanceActive &&
            <div className='game_server_status_wrapper'>
                <ListSkeleton height={400} type={'rectangular'} itemNum={1} colNum={12} loading={skeletonLoader}>
                <Row>
                    <Col xs={4}>
                        <GameCpuStatus/>
                    </Col>
                    <Col xs={4}>
                        <GameRamStatus/>
                    </Col>
                    <Col xs={4}>
                        <GameUserStatus/>
                    </Col>
                    <Col xs={4}>
                        <GameStatusGraph type={'CPU Usage'}/>
                    </Col>
                    <Col xs={4}>
                        <GameStatusGraph type={'Memory Usage'}/>
                    </Col>
                    <Col xs={4}>
                        <div className='game_status_graph_wrapper'>
                            <GameStatusGraph type={'Active Users'}/>
                        </div>
                    </Col>
                </Row>
                </ListSkeleton>
                <ListSkeleton height={300} top={2} type={'rectangular'} itemNum={1} colNum={12} loading={skeletonLoader}>
                <Row>
                    <Col xs={12}>
                        <DeployTitle title={'게임서버 정보'}/>
                        <Row className='game_server_info'>
                            <Col xs={4}>
                                <GameImgInfo img={consoleIcon} label={'GAME'} data={`${gameInstanceDetail.Module}`}/>
                            </Col>
                            <Col xs={4}>
                                <GameImgInfo img={ramIcon} label={'RAM'} data={`${gameInstanceInfo.Status.Metrics['Memory Usage'].MaxValue} MB`}/>
                            </Col>
                            <Col xs={4}>
                                <div className='game_access_address'>
                                    <GameImgInfo img={portIcon} label={'ADDRESS'} data={`${gameInstanceDetail.domain}.sv24.kr`}/>
                                    <CopyToClipboard text={`${gameInstanceDetail.domain}.sv24.kr`}>
                                        <Tooltip title='주소 복사' placement='top'>
                                            <ContentCopyIcon className='hide_pwd'/>
                                        </Tooltip>
                                    </CopyToClipboard>
                                </div>
                            </Col>
                            <Col xs={4}>
                                <GameImgInfo img={calendarIcon} label={'유효기간'} data={`${gameInstanceDetail.expire_date} 까지`}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                </ListSkeleton>
            </div>
            }
        </div>
    )
}

export default GameManageDashBoard;
