import React from "react";
import SettingTabs from "./SettingTabs";


const ManageSetting = () => {


    return (
        <div className='manage_setting_wrapper' style={{backgroundColor: 'white'}}>
            <SettingTabs/>
        </div>
    )
}


export default ManageSetting;
