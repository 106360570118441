import * as React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import {useLocation} from 'react-router-dom'
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import {useHistory} from 'react-router-dom';
import {logoutRequest} from "../login/function";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import {useDispatch, useSelector} from "react-redux";
import {Button} from "@mui/material";
import {useEffect, useState} from "react";
import {initProfile, sessionExtension} from "../account/action";
import PaymentIcon from '@mui/icons-material/Payment';
import MenuIcon from '@mui/icons-material/Menu';
import mainLogImg from '../../assets/img/nav/main_logo.png';
import gameMainLogoImg from '../../assets/img/nav/game_main_logo.png';
import {useMediaQuery} from "react-responsive"
import {PointBox} from "./PointBox";
import MobileDrawer from "./MobileDrawer";
import AccountMenu from "./AccountMenu";
import {checkErrorResponse} from "../../helpers/errorCheck";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import {getNavNotice} from "../notice/action";
import {isGameMode, isIterableArray} from "../../utils/common";
import StorageIcon from '@mui/icons-material/Storage';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import {Label} from "reactstrap";
import {changeLayoutLoader, changeLayoutMode} from "../../layouts/action";
import ModeChangeLoader from "./ModeChangeLoader";
import gameSwitchImg from '../../assets/img/nav/gameSwitch.svg';
import serverSwitchImg from '../../assets/img/nav/serverSwitch.svg';
import MineDeployLoader from "../game/deploy/minecraft/MineDeployLoader";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import GridViewIcon from '@mui/icons-material/GridView';
const drawerWidth = 240;

const MaterialUISwitch = styled(Switch)(({theme}) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url(${gameSwitchImg})`,
                backgroundSize: 'cover'
                // backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                //     '#fff',
                // )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#4d99ff',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            // backgroundRepeat: 'no-repeat',
            // backgroundPosition: 'center',
            backgroundImage: `url(${serverSwitchImg})`,
            backgroundSize: 'cover'
            // backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            //     '#fff',
            // )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '0',
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function NavBar({children}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const {sessionInfo} = useSelector(state => state.account);
    const {navNotice} = useSelector(state => state.notice);
    const {layoutMode, layoutLoader} = useSelector(state => state.layout);
    const {deployLoader} = useSelector(state => state.gameDeploy);
    const userInfo = useSelector(state => state.account.userInfo)
    const location = useLocation();
    const [open, setOpen] = React.useState(true);
    const [modeLoader, setModeLoader] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openMenu, setOpenMenu] = React.useState(false);
    const [mobileOpen, setMobileOpen] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setMobileOpen({...mobileOpen, [anchor]: open});
    };


    const isMobile = useMediaQuery({
        query: "(max-width:880px)"
    });

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClick = () => {
        if (!openMenu) {
            if (!open) setOpen(!open)
        }
        setOpenMenu(!openMenu);

    };
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpenMenu(false);
        setOpen(false);
    };

    useEffect(() => {

        initProfile().then(response => {
            const request = response.request;
            if (request.status === 200) {
                dispatch(response);
            }
        }).catch(err => {
            checkErrorResponse(err);
        })

        getNavNotice().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [])

    useEffect(() => {
        let sessionTime;
        if (sessionInfo) {
            if (sessionInfo.expired) {
                sessionTime = window.setTimeout(() => {
                    localStorage.setItem('session_expired', 'expired');
                    handleLogOut()
                }, 300000)
            }
        }
        return () => {
            window.clearTimeout(sessionTime)
        }
    }, [sessionInfo])
    const handleLogOut = () => {
        logoutRequest().then(response => {
            if (response) {
                if (response.status === 200) {
                    localStorage.removeItem('auth_login');
                    window.location.href = '/';
                }
            }

        }).catch(err => {
            checkErrorResponse(err);

        })
    }
    const handleSessionExtension = () => {
        sessionExtension().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleModeChange = (mode) => {
        dispatch(changeLayoutLoader(true));
        if (mode) {
            setTimeout(() => {
                dispatch(changeLayoutMode('game'));
                history.push('/');
                dispatch(changeLayoutLoader(false));
            }, 2000);
        } else {
            setTimeout(() => {
                dispatch(changeLayoutMode('origin'));
                history.push('/');
                dispatch(changeLayoutLoader(false));
            }, 2000);
        }

    }


    return (
        <div>
            {layoutLoader ?
                <ModeChangeLoader/>
                :
                <div>{deployLoader ?
                    <MineDeployLoader/>
                    :
                    <Box sx={{display: 'flex'}} className={isGameMode(layoutMode) ? 'dark_mode_nav_wrapper' : ''}>
                        <CssBaseline/>
                        <AppBar position="fixed" open={!isMobile}>
                            <div
                                className={sessionInfo.expired ? 'session_expired_wrapper show' : 'session_expired_wrapper hide'}>
                                <span>5분뒤 세션 만료로 인해 자동으로 로그아웃이 됩니다. </span>
                                <Button color='primary' onClick={handleSessionExtension}
                                        variant={'contained'}>세션연장</Button>
                            </div>
                            <Toolbar className={isGameMode(layoutMode) ? 'dark_bg_color1 dark_toolbar' : ''}>
                                {isMobile &&
                                    <IconButton onClick={toggleDrawer('left', true)}>
                                        <MenuIcon/>
                                    </IconButton>
                                }
                                <div className='navbar_top_wrapper container-md'>
                                    <Box sx={{flexGrow: 1}}>
                                        {isIterableArray(navNotice) &&
                                            <div className='notice_nav_wrapper'>
                                                <span className='notice_title' style={{color: '#057BFC'}}>공지사항 :</span>
                                                <span onClick={() => window.open(navNotice[0].url, '_blank')}
                                                      className='mx-1 notice_go'>{navNotice[0].title}</span>
                                            </div>
                                        }
                                    </Box>
                                    {/*<FormGroup className='mode_change_switch_wrapper'>*/}
                                    {/*    <Label>서버 콘솔</Label>*/}
                                    {/*    <MaterialUISwitch onChange={event => handleModeChange(event.target.checked)} sx={{m: 1}} checked={layoutMode === 'game'}/>*/}
                                    {/*    <Label>게임 콘솔</Label>*/}
                                    {/*</FormGroup>*/}
                                    <AccountMenu handleLogOut={handleLogOut}/>

                                </div>
                            </Toolbar>
                        </AppBar>
                        {isMobile ?
                            <MobileDrawer state={mobileOpen} toggleDrawer={toggleDrawer}/>
                            :
                            <Drawer variant="permanent" open={open}>
                                <DrawerHeader className={isGameMode(layoutMode) ? 'dark_bg_color1' : ''}>
                                    <img style={{cursor: 'pointer'}} onClick={() => history.push('/')}
                                         id={'main_logo_img'}
                                         src={isGameMode(layoutMode) ? gameMainLogoImg : mainLogImg}/>
                                </DrawerHeader>
                                <Divider/>
                                <List
                                    className={isGameMode(layoutMode) ? 'dark_bg_color3 nav_list_wrapper' : 'nav_list_wrapper'}>
                                    <ListItem
                                        className={(location.pathname === '/' || location.pathname.includes('manage')) ? 'visit_page' : ''}
                                        onClick={() => history.push('/')} button>
                                        <ListItemIcon>
                                            <DashboardOutlinedIcon
                                                color={(location.pathname === '/' || location.pathname.includes('manage')) ? 'primary' : ''}/>
                                        </ListItemIcon>
                                        <ListItemText primary={'대시보드'}/>
                                    </ListItem>
                                    <ListItem className={location.pathname === '/deploy' ? 'visit_page' : ''}
                                              onClick={() => window.location.href = '/deploy'} button>
                                        <ListItemIcon>
                                            <StorageIcon color={location.pathname === '/deploy' ? 'primary' : ''}/>
                                        </ListItemIcon>
                                        {isGameMode(layoutMode) ?
                                            <ListItemText primary={'게임서비스 신청'}/>
                                            :
                                            <ListItemText primary={'서비스 신청'}/>
                                        }
                                    </ListItem>
                                    {/*{((userInfo.email === process.env.REACT_APP_SHOW_CONSOLE_ONE) ||*/}
                                    {/*        (userInfo.email === process.env.REACT_APP_SHOW_CONSOLE_TWO) ||*/}
                                    {/*        (userInfo.email === process.env.REACT_APP_SHOW_CONSOLE_THREE) ||*/}
                                    {/*        (userInfo.email === process.env.REACT_APP_SHOW_CONSOLE_FOUR)) &&*/}
                                    {/*    <ListItem*/}
                                    {/*        className={location.pathname.includes('/centosPanel') ? 'visit_page' : ''}*/}
                                    {/*        onClick={() => window.open('/centosPanel')} button>*/}
                                    {/*        <ListItemIcon>*/}
                                    {/*            <GridViewIcon*/}
                                    {/*                color={location.pathname.includes('/centosPanel') ? 'primary' : ''}/>*/}
                                    {/*        </ListItemIcon>*/}
                                    {/*        <ListItemText primary={'센토스 패널'}/>*/}
                                    {/*    </ListItem>*/}
                                    {/*}*/}
                                    <ListItem className={location.pathname.includes('/support') ? 'visit_page' : ''}
                                              onClick={() => history.push('/support')} button>
                                        <ListItemIcon>
                                            <MailOutlineRoundedIcon
                                                color={location.pathname.includes('/support') ? 'primary' : ''}/>
                                        </ListItemIcon>
                                        <ListItemText primary={'고객지원'}/>
                                    </ListItem>
                                    {!isGameMode(layoutMode) &&
                                        <ListItem className={location.pathname === '/snapshot' ? 'visit_page' : ''}
                                                  onClick={() => history.push('/snapshot')} button>
                                            <ListItemIcon>
                                                <CameraAltOutlinedIcon
                                                    color={location.pathname === '/snapshot' ? 'primary' : ''}/>
                                            </ListItemIcon>
                                            <ListItemText primary={'스냅샷'}/>
                                        </ListItem>
                                    }
                                    {!isGameMode(layoutMode) &&
                                        <ListItem className={location.pathname === '/script' ? 'visit_page' : ''}
                                                  onClick={() => history.push('/script')} button>
                                            <ListItemIcon>
                                                <DescriptionOutlinedIcon
                                                    color={location.pathname.includes('/script') ? 'primary' : ''}/>
                                            </ListItemIcon>
                                            <ListItemText primary={'스크립트'}/>
                                        </ListItem>
                                    }
                                    <ListItem className={location.pathname === '/billing' ? 'visit_page' : ''}
                                              onClick={() => history.push('/billing')} button>
                                        <ListItemIcon>
                                            <PaymentIcon
                                                color={location.pathname.includes('/billing') ? 'primary' : ''}/>
                                        </ListItemIcon>
                                        <ListItemText primary={'결제 및 비용'}/>
                                    </ListItem>
                                    <ListItem className={location.pathname === '/notice' ? 'visit_page' : ''}
                                              onClick={() => history.push('/notice')} button>
                                        <ListItemIcon>
                                            <ContentPasteIcon
                                                color={location.pathname.includes('/notice') ? 'primary' : ''}/>
                                        </ListItemIcon>
                                        <ListItemText primary={'공지사항'}/>
                                    </ListItem>
                                    {/*<ListItem className={location.pathname === '/handBook' ? 'visit_page' : ''}*/}
                                    {/*          onClick={() => window.open('https://datacredit.notion.site/SERVER24-KR-f16ad353b6b849bdbe057dea86bd8dfd')}*/}
                                    {/*          button>*/}
                                    {/*    <ListItemIcon>*/}
                                    {/*        <MenuBookOutlinedIcon*/}
                                    {/*            color={location.pathname.includes('/handBook') ? 'primary' : ''}/>*/}
                                    {/*    </ListItemIcon>*/}
                                    {/*    <ListItemText primary={'사용설명서'}/>*/}
                                    {/*</ListItem>*/}
                                </List>
                                <PointBox/>
                            </Drawer>
                        }

                        <Box className={isGameMode(layoutMode) ? 'dark_bg_color1' : 'origin_bg_color'}
                             sx={{width: '100%', paddingBottom: '3rem'}} component="main">
                            <DrawerHeader/>
                            {children}
                        </Box>
                    </Box>
                }</div>
            }
        </div>
    );
}
