import React, {useState, useEffect} from "react";
import {Modal, ModalBody, ModalFooter} from "reactstrap";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {TextField, Button} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {addSnapShot} from "./action";
import LoaderButton from "../common/LoaderButton";
import {getInstance} from "../Product/action";
import {checkErrorResponse} from "../../helpers/errorCheck";
import {notificationAlert} from "../../helpers/common";

const SnapShotModal = ({collapse, location, toggle}) => {
    const dispatch = useDispatch();
    const {instanceList} = useSelector(state => state.instance);
    const {instanceInfo} = useSelector(state => state.manage);
    const [server, setServer] = useState('');
    const [description, setDescription] = useState('');
    const [loader, setLoader] = useState(false);
    const handleMakeScript = () => {
        if (description.length === 0) {
            notificationAlert('오류', '스냅샷 라벨을 입력해주세요.');
            return;
        }
        if (server.length === 0 && location !== "instance") {
            notificationAlert('오류', '스냅샷을 적용할 서버를 선택해주세요.');
            return;
        }
        setLoader(true);
        let post = {
            description: description,
        }
        if (location === 'instance') {
            post.instance_id = instanceInfo.vi_id;
        } else {
            post.instance_id = server;
        }
        addSnapShot(post).then(response => {
            if (response) {
                if (response.request.status === 201) {
                    setLoader(false);
                    dispatch(response);
                    toggle();
                }
            }

        }).catch(err => {
            setLoader(false);
            checkErrorResponse(err);
        })
    }

    useEffect(() => {
        getInstance().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [])
    return (
        <Modal className='script_modal' backdrop={'static'} centered aria-labelledby="contained-modal-title-vcenter" isOpen={collapse} toggle={toggle}>
            <ModalBody style={{minHeight: '220px'}}>
                <div className='ticket_modal_title'>
                    <h4>스냅샷 생성하기</h4>
                </div>
                <TextField className='ticket_modal_input'
                           onChange={event => setDescription(event.target.value)}
                           fullWidth id="ticket_subject" label="Name" variant="outlined"/>
                {location !== 'instance' &&
                <Box className='ticket_modal_input' sx={{minWidth: 120}}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Server</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="ticket_category"
                            value={server}
                            label="Type"
                            onChange={event => setServer(event.target.value)}
                        >
                            {instanceList.map((instance, index) => {
                                return (
                                    <MenuItem key={index} className='select_list_item' value={instance.vi_id}>
                                        {`${instance.vi_label === '' ? 'Instance Name' : instance.vi_label} / ${instance.vp_ram}MB / ${instance.vi_main_ip}`}
                                    </MenuItem>
                                )
                            })}

                        </Select>
                    </FormControl>
                </Box>
                }
                <ul className='take_snapshot_info'>
                    <li><small>스냅샷 비용은 매일 3원/GB당 이며, 스냅샷에서 서버를 생성할 때에는 스냅샷 용량과 같거나 더 큰 디스크로만 복원할 수 있습니다.</small></li>
                    <li><small>스냅샷을 추후 편리하게 이용하기 위해서는 대상 서버의 네트워크를 DHCP(자동할당)으로 변경하시거나 기본 설정을 유지하시기 바랍니다.</small></li>
                    <li><small>스냅샷 생성과 복원은 용량에 따라 10분~120분까지 소요될 수 있습니다.</small></li>
                    <li><small>스냅샷은 무중단으로 생성(복제)되기 때문에 대상 서버의 디스크 I/O가 많을 때에는 가급적 삼가하시기 바랍니다.</small></li>
                </ul>
            </ModalBody>
            <ModalFooter className='ticket_modal_footer'>
                <Button onClick={toggle} variant="outlined">취소</Button>
                <LoaderButton loader={loader} variant="contained" onClick={() => handleMakeScript()}>
                    생성하기
                </LoaderButton>


            </ModalFooter>
        </Modal>
    )
}


export default SnapShotModal;
