export const DelServerTxt = '서버에 저장된 모든 데이터가 영구적으로 삭제되며 취소할 수 없습니다. 또한 서버에 연결하신 추가 IP도 함께 계정에서 삭제됩니다.';

export const ReinstallServerTxt = '서버 재설치 작업은 현재 서버를 초기 스냅샷/백업/OS 상태로 재설치됩니다. 이 작업은 약 10~120분가량 소요될 수 있으며, 서버의 모든 데이터는 영구적으로 삭제됩니다.';

export const RestartServerTxt = '이 기능으로 서버를 재시작하게 되면 서버의 전원이 강제로 꺼지고 다시 켜집니다.';

export const StopServerTxt = '이 기능으로 서버를 종료하게 되면 서버의 전원이 강제로 꺼지며, 서버 재시작 버튼으로 서버를 부팅하셔야 합니다.';

export const Ipv6AssignTxt = '추가한 IP는 서버 내부에서 직접 설정하셔야 합니다. 자세한 방법은 FAQ 혹은 문서를 참고하시거나 티켓으로 문의주시기 바랍니다.';

export const Ipv4AssignTxt = '추가한 IP는 서버 내부에서 직접 설정하셔야 합니다. 자세한 방법은 FAQ 혹은 문서를 참고하시거나 티켓으로 문의주시기 바랍니다. 추가 IP에 경우 추가 비용(1개당 1일 기준 340P)가 발생됩니다.';

export const Ipv4DeleteTxt = '이 작업은 취소할 수 없으며 서버의 네트워크가 순단되거나 전원이 강제로 재시작될 수 있습니다. 데이터 손실을 방지하려면 IP를 제거하기 전에 서버를 먼저 종료하시기 바랍니다.';

export const activationAutoBackupTxt = '자동 백업 기능은 사전에 설정한 예약 일정에 따라 자동 백업이 무중단으로 진행됩니다. 생성된 백업본으로 현재 서버를 복원하거나 다른 서버를 복제할 수 있습니다.';

export const InactivationAutoBackupTxt = '자동 백업 기능은 비활성화 이후에 다시 활성화하실 수 없으며, 기존에 생성된 백업이 수일 내 영구적으로 삭제됩니다.';

export const RestoreBackupTxt = '서버에 저장된 모든 데이터가 백업 데이터로 대체되며 시작 후 취소할 수 없습니다. 이 작업은 약 10~120분가량 소요될 수 있습니다.';

export const RestoreSnapshotTxt = '서버에 저장된 모든 데이터가 스냅샷 데이터로 대체되며 시작 후 취소할 수 없습니다. 이 작업은 약 10~120분가량 소요될 수 있습니다';

export const DelBackupTxt = '이 작업은 취소할 수 없으며, 백업 데이터가 영구적으로 삭제됩니다.';

export const UpgradePlanTxt = '요금제는 업그레이드 이후 다운그레이드가 불가능합니다. 먼저 스냅샷으로 서버를 백업하시는 것이 바람직합니다. 요금제 업그레이드 후 시스템 내 디스크 공간이 추가로 확보되지 않았다면 고객센터로 문의주시기 바랍니다.';

export const ActiveDdos = '적용까지는 약 1~10분 정도 소요되며, 현재 서버로 10G 이상의 공격이 발생하면 전체 네트워크를 보호하기 위해 일시적으로 Null Route 처리될 수 있습니다.';

export const InActiveDdos = '이 기능은 비활성화 이후에 다시 활성화하실 수 없습니다. 적용까지는 약 1~10분 정도 소요되며, 이후 DDoS 공격이 발생하면 전체 네트워크를 보호하기 위해 서버가 Null Route 처리될 수 있습니다. 공격이 소강되면 Null Route 조치는 자동으로 해제됩니다.';

export const AttachIsoTxt = 'ISO를 서버에 연결하기 위해 서버가 즉시 재부팅 됩니다. 데이터 손실을 방지하려면 서버를 먼저 종료하시기 바랍니다.';

export const DetachIsoTxt = 'ISO 연결을 해제하기 위해 서버가 즉시 재부팅 됩니다. 데이터 손실을 방지하려면 서버를 먼저 종료하시기 바랍니다.';

export const InActiveAccountTxt = '계정이 해킹이나 기타 위협으로부터 서버 삭제나 조작을 방지할 목적으로만 이 기능을 사용하시기 바랍니다.';

export const UserLeftTxt = '정말 계정을 탈퇴하시겠습니까? 이 작업은 취소할 수 없습니다.';

export const DelWebHostingTxt = '정말 웹 호스팅을 삭제하시겠습니까? 이 작업은 취소할 수 없으며, 백업과 데이터가 영구적으로 삭제됩니다.';

export const WebHostingUpgradePlanTxt = '요금제는 업그레이드 이후 다운그레이드가 불가능합니다. 먼저 스냅샷으로 서버를 백업하시는 것이 바람직합니다. 요금제 업그레이드 후 시스템 내 디스크 공간이 추가로 확보되지 않았다면 고객센터로 문의주시기 바랍니다.';

export const delFileManagerTxt = '정말 파일을 삭제하시겠습니까? 이 작업은 취소할 수 없으며, 영구적으로 파일이 삭제 됩니다.';

export const restoreGameBackupTxt = '서버에 저장된 모든 데이터가 백업 데이터로 대체되며 시작 후 취소할 수 없습니다. 이 작업은 약 5~20분가량 소요될 수 있습니다'

export const deleteGameBackupTxt = '이 작업은 취소할 수 없으며, 백업 데이터가 영구적으로 삭제됩니다.';

export const extendGameDateTxt = '연장된 기간은 다시 복구 또는 줄일수 없으며, 즉시 포인트가 차감됩니다.';

export const gameUpdateTxt = '업데이트 작업은 최소 1분이상 소요되며, 최대 15분 정도 걸릴 수 있습니다.';

export const gamePlanUpgradeTxt = '요금제는 업그레이드 이후 다운그레이드가 불가능합니다. 데이터 분실이 발생할수 있으며 백업을 권장드립니다. 또한 업그레이드 적용을 위해 게임 서버가 자동으로 재시작이 됩니다.';
