import React, {useState} from "react";
import GradientBack from "../common/GradientBack";
import { Button} from "reactstrap";
import userIcon from "../../assets/img/login/user.svg";
import MyInput from "../common/MyInput";
import {findPwdRequest} from "./function";
import {checkErrorResponse} from "../../helpers/errorCheck";
import LoaderButton from "../common/LoaderButton";


const FindPwd = ({history}) => {
    const [loader, setLoader] = useState(false);
    const [email, setEmail] = useState('');
    const [pageStep, setPageStep] = useState(1);
    const handlePwdFind = () => {
        setLoader(true);
        findPwdRequest({email: email}).then(response => {
            if (response) {
                if (response.status === 200) {
                    setPageStep(2);
                    setLoader(false);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
            setLoader(false);
        })

    };

    return (
        <GradientBack boxSize={450}>
            <div className='find_form_wrapper'>
                <h2>비밀번호 찾기</h2>
                <p>회원정보에 등록한 이메일을 입력하여 주세요.</p>
                {pageStep === 1 ?
                    <MyInput
                        icon={userIcon}
                        label={'이메일'}
                        placeholder={'이메일을 입력해주세요.'}
                        value={email}
                        onChange={({target}) => setEmail(target.value)}
                        type="text"
                    />
                    :
                    <div className='send_email_pwd'>
                        <span>이메일이 발송 되었습니다.</span>
                        <span>이메일 확인 후 이메일에 있는 링크를 클릭하여 주세요.</span>
                    </div>
                }


                <div className='findId_btn'>
                    {pageStep === 1 ?
                        <LoaderButton loader={loader} onClick={handlePwdFind}>이메일 보내기</LoaderButton>
                        :
                        <Button onClick={() => history.goBack()}>돌아가기</Button>
                    }

                </div>
            </div>
        </GradientBack>
    )
}

export default FindPwd;
