import React, {useEffect} from "react";
import {cpanelRegions} from '../../../datas/region';
import {useDispatch, useSelector} from "react-redux";
import {setCpanelPlan, setCpanelPrice, setCpanelRegion} from "../action";
import {Row, Col} from 'reactstrap';
import {CheckTriangle} from "../../common/CheckTriangle";
import DeployTitle from "../DeployTitle";
import {isIterableArray} from "../../../utils/common";
import {notificationAlert} from "../../../helpers/common";
import {checkCpanelRegion} from "../function";
import {checkErrorResponse} from "../../../helpers/errorCheck";

const WebHostingRegionList = () => {
    const {region} = useSelector((state) => state.deploy.cpanel);
    const dispatch = useDispatch();
    const handleRegion = (id) => {
        dispatch(setCpanelRegion(id))
    }

    useEffect(() => {
        checkCpanelRegion(region.type).then(response => {
            if (response) {
                if (response.status === 200) {
                    if (isIterableArray(response.data.plans)) {
                        dispatch(setCpanelPlan(response.data.plans));
                        dispatch(setCpanelPrice(response.data.plans[0]));
                    } else {
                        notificationAlert('오류', '죄송합니다, 현재 일시적으로 해당 지역에 재고가 부족합니다. 나중에 다시 시도해주세요.');
                    }
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [region.type])

    return (
        <Row className='deploy_region_list'>
            <DeployTitle title={'서비스 지역'}/>
            {cpanelRegions.map(item => {
                return (
                    <Col xs={6} lg={3} key={item.cp_region_id}
                         onClick={() => handleRegion(item.cp_region_id)}
                         className={item.cp_region_id === region.type ? 'server_region select_region' : 'server_region'}>
                        <div className='deploy_region_item'>
                            {item.cp_region_id === region.type && <CheckTriangle height={40} width={40}/>}
                            <div className='region_img'>
                                <img src={`https://s3.server24.kr/panel/img/country/${(item.country).replace(" ", "")}.png`}/>
                            </div>
                            <div className='region_country'>
                                <span>{item.cp_city}</span>
                                <small>{item.country}</small>
                            </div>
                        </div>
                    </Col>
                )
            })}
        </Row>
    )
}

export default WebHostingRegionList;
