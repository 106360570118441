import React, {useState} from "react";
import GradientBack from "../common/GradientBack";
import {Form, FormGroup} from "reactstrap";
import MyInput from "../common/MyInput";
import userIcon from "../../assets/img/login/user.svg";
import emailIcon from "../../assets/img/login/email.svg";
import passwordIcon from "../../assets/img/login/password.svg";
import {useForm} from "react-hook-form";
import classNames from 'classnames';
import {isBadWord} from "../../utils/common";
import {newUserRequest} from "./function";
import {useHistory} from 'react-router-dom';
import {notificationAlert} from "../../helpers/common";
import GoogleReCaptcha from "../../hoc/GoogleReCaptcha";
import LoaderButton from "../common/LoaderButton";
import {checkErrorResponse} from "../../helpers/errorCheck";

const RegisterForm = ({agreeEmailAd, agreePhoneAd}) => {
    const history = useHistory();
    const {register, handleSubmit, errors, watch} = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    });
    const [loader, setLoader] = useState(false);


    const onSubmitData = data => {
        setLoader(true);
        GoogleReCaptcha().then(result => {
            if (result) {
                if (result === 'human') {
                    if (data) {
                        const post = {
                            email: data.email,
                            password: data.register_password,
                            name: data.name,
                            agreeEmail: agreeEmailAd,
                            agreePhone: agreePhoneAd
                        }
                        newUserRequest(post).then(result => {
                            if (result.data.success) {
                                setLoader(false);
                                notificationAlert('알림', '서버24 회원이 되신 것을 진심으로 축하드립니다!');
                                history.push('/')
                            }
                        }).catch(err => {
                            setLoader(false);
                            checkErrorResponse(err);

                        })
                    }
                }
            }
        }).catch(err => {
            setLoader(false);
            checkErrorResponse(err);
        })


    };

    return (
        <GradientBack boxSize={500}>
            <div className='register_wrapper'>
                <div className='register_box'>
                    <h2>회원가입</h2>
                    <Form onSubmit={handleSubmit(onSubmitData)}>
                        <MyInput label={'이메일'}
                                 name={'email'}
                                 id={'email'}
                                 icon={emailIcon}
                                 type='email'
                                 placeholder={'이메일을 입력해주세요.'}
                                 maxLength={50}
                                 innerRef={register({
                                     required: '이메일 주소는 반드시 입력되어야 합니다',
                                     pattern: {
                                         value: /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
                                         message: '입력하신 이메일 주소의 형식이 올바르지 않습니다'
                                     }
                                 })}
                                 errors={errors}
                                 className={classNames({'border-danger': errors.email})}
                        />
                        <MyInput label={'비밀번호'}
                                 icon={passwordIcon}
                                 type='password'
                                 id='register_password'
                                 name='register_password'
                                 placeholder='비밀번호를 입력해주세요.'
                                 autoComplete='new-password'
                                 innerRef={register({
                                     required: '비밀번호는 반드시 입력되어야 합니다',
                                     pattern: {
                                         value: /^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{6,20}$/,
                                         message: '6자 이상 20자 이하 영문 대소문자, 최소 1개의 숫자 및 특수 문자'
                                     }
                                 })}
                                 errors={errors}
                                 className={classNames({'border-danger': errors.register_password})}
                        />
                        <MyInput label={'비밀번호 확인'}
                                 icon={passwordIcon}
                                 type='password'
                                 placeholder="비밀번호 확인"
                                 id="confirmPassword"
                                 name="confirmPassword"
                                 innerRef={register({
                                     required: '비밀번호 확인은 반드시 입력되어야 합니다',
                                     validate: value => value === watch('register_password') || '입력하신 비밀번호가 일치하지 않습니다'
                                 })}
                                 errors={errors}
                                 className={classNames({'border-danger': errors.confirmPassword})}
                        />
                        {/*{errors.confirmPassword && <span className="text-danger fs--1">{errors.confirmPassword.message}</span>}*/}
                        <MyInput
                            icon={userIcon}
                            label={'이름'}
                            placeholder={'이름을 입력해주세요.'}
                            id='name'
                            name='name'
                            type="text"
                            maxLength={20}
                            innerRef={register({
                                required: '이름은 필수로 입력되어야 합니다',
                                validate: value => !isBadWord(value) || '금지된 단어가 포함되어 있습니다.'
                            })}
                            errors={errors}
                            className={classNames({'border-danger': errors.name})}
                        />
                        <FormGroup className='register_submit_btn'>
                            <LoaderButton loader={loader} type='submit' block className="mt-3 register_btn">
                                회원가입
                            </LoaderButton>
                        </FormGroup>
                    </Form>
                </div>
            </div>
        </GradientBack>
    )
}

export default RegisterForm;
