import React from "react";
import {Row, Col} from 'reactstrap';
import {LinearProgress} from "@material-ui/core";
import TableCell from "@mui/material/TableCell";
import WebHostingInstanceMore from "./WebHostingInstanceMore";


const WebHostingMobileProductTable = ({serverList}) => {

    return (
        <div className='mobile_table_wrapper'>
            {serverList.map(server => {
                const trafficPercent = (server.cb_used_byte / (server.cp_day_traffic * (1024 * 1024 * 1024))) * 100;
                const storagePercent = (server.cu_usage_disk / (server.cp_disk * (1024 * 1024))) * 100;
                const cpanelUrl = server.cp_region === 'kr' ? 'https://cpanel.server24.kr/' : 'https://cpanelkorea.com:2083/';
                return (
                    <Row className='mobile_item align-items-center'>
                        <Col className='p-0' xs={2}
                             onClick={() => window.open(cpanelUrl)}>
                            <img width={30} src={'https://s3.server24.kr/panel/img/os/Cpanel.png'} alt='web_hosting_os_img'/>
                        </Col>
                        <Col className='p-0' xs={5}
                             onClick={() => window.open(cpanelUrl)}>
                            <div className='instance_label_wrapper'>
                                <span>{server.cu_main_domain}</span>
                                <small>{server.cu_user_name}</small>
                            </div>
                        </Col>
                        <Col xs={4} className='server_status_info p-0'
                             onClick={() => window.open(cpanelUrl)}>
                            <div className='web_hosting_bar_wrapper'>
                                <LinearProgress
                                    id={'web_hosting_traffic_bar'}
                                    className={trafficPercent < 50 ? 'save_traffic_bar' : trafficPercent < 90 ? ' warning_bar' : 'error_bar'}
                                    color={'primary'}
                                    variant="determinate"
                                    value={trafficPercent >= 100 ? 100 : trafficPercent}/>
                                <small>{trafficPercent.toFixed(2)}%</small>
                            </div>
                            <div className='web_hosting_bar_wrapper'>
                                <LinearProgress
                                    id={'web_hosting_storage_bar'}
                                    className={storagePercent < 50 ? 'save_save_storage_bar' : storagePercent < 90 ? ' warning_bar' : 'error_bar'}
                                    color={'primary'}
                                    variant="determinate"
                                    value={storagePercent}/>
                                <small>{storagePercent.toFixed(2)}%</small>
                            </div>
                        </Col>
                        <Col xs={1}>
                            <div className='instance_more_btn'>
                                <div className='server_more_item'>
                                    <WebHostingInstanceMore payStatus={server.cu_del_status} no={server.cu_no}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                )
            })}
        </div>
    )
}

export default WebHostingMobileProductTable;
