import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DeployTitle from "../../deploy/DeployTitle";
import {useHistory} from "react-router-dom";
import {useEffect} from "react";
import {getGameServerList} from "../action";
import {checkErrorResponse} from "../../../helpers/errorCheck";
import {useDispatch, useSelector} from "react-redux";
import {isIterableArray} from "../../../utils/common";
import NotExistTable from "../../common/NotExistTable";
import {regions} from "../../../datas/region";
import MineCraftImg from '../../../assets/img/game/minecraft/mineBlocks/GrassNew.png';
import GameInstanceMore from "./GameInstanceMore";
import {GameTypeImgItem} from "../common/GameTypeImgItem";
import {changePerNum} from "../../../helpers/common";

const columns = [
    {id: 'gm_domain', label: 'DOMAIN', minWidth: 170},
    {id: 'gm_type', align: 'center', label: 'GAME_TYPE', minWidth: 170},
    {id: 'gm_region', align: 'center', label: 'LOCATION', minWidth: 100},
    {id: 'gmp_memory', align: 'center', label: 'RAM', minWidth: 100},
    {id: 'gm_expire_date', align: 'center', label: 'EXPIRE_DATE', minWidth: 100},

];

const RegionImgItem = ({regionId}) => {
    const regionInfo = regions.filter(region => region.vr_id === regionId);
    return (
        <div className='game_server_region_img'>
            <img src={`https://s3.server24.kr/panel/img/country/${regionInfo[0].country.replace(" ", "")}.png`}/>
            <span>{regionInfo[0].vr_city}</span>
        </div>
    )
}


export default function GameServerTable() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(localStorage.getItem('pageNum') ? localStorage.getItem('pageNum') : 10);
    const {gameServerList} = useSelector(state => state.gameDeploy);

    useEffect(() => {
        getGameServerList().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, []);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const perNum = parseInt(event.target.value, 10);
        changePerNum(perNum)
        setRowsPerPage(perNum);
        setPage(0);
    };

    return (
        <div className='game_server_table_wrapper'>
            <DeployTitle title={'게임서버 목록'}/>
            {isIterableArray(gameServerList) ?
                <Paper sx={{width: '100%', overflow: 'hidden'}}>
                    <TableContainer>
                        <Table className='game_server_table' aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{minWidth: column.minWidth}}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {gameServerList
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow
                                                className='game_table_row'
                                                style={{cursor: 'pointer'}}
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.gm_no}>
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    if (column.id === 'gm_domain') {
                                                        return (
                                                            <TableCell
                                                                onClick={() => history.push(`/gameManage/${row.gm_no}/${row.gm_id}`)}
                                                                key={column.id} align={column.align}>
                                                                {`${value}.sv24.kr`}
                                                            </TableCell>
                                                        )
                                                    }
                                                    if (column.id === 'gm_region') {
                                                        return (
                                                            <TableCell
                                                                onClick={() => history.push(`/gameManage/${row.gm_no}/${row.gm_id}`)}
                                                                key={column.id} align={column.align}>
                                                                <RegionImgItem regionId={value}/>
                                                            </TableCell>
                                                        )
                                                    }

                                                    if (column.id === 'gm_type') {
                                                        return (
                                                            <TableCell
                                                                onClick={() => history.push(`/gameManage/${row.gm_no}/${row.gm_id}`)}
                                                                key={column.id} align={column.align}>
                                                                <GameTypeImgItem gameType={value}/>
                                                            </TableCell>
                                                        )
                                                    }

                                                    if (column.id === 'gmp_memory') {
                                                        return (
                                                            <TableCell
                                                                onClick={() => history.push(`/gameManage/${row.gm_no}/${row.gm_id}`)}
                                                                key={column.id} align={column.align}>
                                                                {value}MB
                                                            </TableCell>
                                                        )
                                                    }
                                                    return (
                                                        <TableCell
                                                            onClick={() => history.push(`/gameManage/${row.gm_no}/${row.gm_id}`)}
                                                            key={column.id} align={column.align}>
                                                            {value}
                                                        </TableCell>
                                                    );
                                                })}
                                                {/*<TableCell>*/}
                                                {/*    <GameInstanceMore mcNo={row.gm_no}/>*/}
                                                {/*</TableCell>*/}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={gameServerList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                :
                <NotExistTable searchExist={false} type={'gameServer'}/>
            }
        </div>
    );
}
