import React from "react";
import {useSelector} from "react-redux";
import {CircularProgressWithLabel} from "./CircleProgress";


const GameUserStatus = () => {
    const {gameInstanceInfo, gameInstanceActive, sessionId} = useSelector(state => state.gameDeploy);

    return (
        <div>
            <div className='game_status_title'>
                <span>접속중인 유저</span>
            </div>
            <div className='game_status_wrapper'>
                <CircularProgressWithLabel value={gameInstanceInfo.Status.Metrics['Active Users'].RawValue} type={'user'}>
                    {`${gameInstanceInfo.Status.Metrics['Active Users'].RawValue}명`}
                </CircularProgressWithLabel>
                <div className='game_status_value'>
                    <span>{`${gameInstanceInfo.Status.Metrics['Active Users'].RawValue}`}</span>
                    <span>/</span>
                    <span>{`${gameInstanceInfo.Status.Metrics['Active Users'].MaxValue}명`}</span>
                </div>
            </div>
        </div>
    )
}

export default GameUserStatus;
