import React, {Fragment} from "react";
import {Row, Col} from 'reactstrap';
import {Chip} from "@mui/material";

const MobileIpv6Table = ({ipv6}) => {

    return (
        <div className='mobile_ipv4_table'>
            <Row className='mobile_ipv4_item'>
                <Col xs={3} className='row flex-column ip_key'>
                    <span>Address</span>
                    <span>Network</span>
                    <span>Netmask</span>
                    <span>Default Gateway</span>
                </Col>
                <Col xs={9} className='row flex-column fw-bold'>
                    <span style={{wordBreak : 'break-all'}}>{ipv6.vi_v6_main_ip}</span>
                    <span>{ipv6.vi_v6_network}</span>
                    <span>{ipv6.vi_v6_network_size}</span>
                    <span>{ipv6.vi_reverse_dns}</span>
                </Col>
            </Row>
        </div>
    )
}

export default MobileIpv6Table;
