import * as types from './actionTypes';
import {_GET_ALL_BACKUP} from "./actionTypes";


let initialState = {
    instanceInfo: {
        vi_os_id: '',
        vi_label: '',
        vi_power_status: '',

    },
    currentPlan: {},
    bandWidthList: [],
    featureInfo: [],
    ipv4List: [],
    backupList: [],
    backupSchedule: {
        vbs_instance_id: null,
        vbs_type: "daily",
        vbs_hour: 0,
        vbs_dow: null,
        vbs_dom: null
    },
    upgradeList: [],
    isoInfo: {},
    nextBackupSchedule: ''

}


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types._GET_INSTANCE_DETAIL: {
            if (action.request.data.instance.vi_features !== '')
                state.featureInfo = action.request.data.instance.vi_features.split(',');
            return {
                ...state,
                instanceInfo: action.request.data.instance
            }
        }

        case types._GET_INSTANCE_IPV4: {
            return {
                ...state,
                ipv4List: action.request.data.ipv4s
            }
        }

        case types._ADD_INSTANCE_IPV4: {
            return {
                ...state,
                ipv4List: [...state.ipv4List, action.request.data.ipv4]
            }
        }

        case types._DEL_INSTANCE_IPV4: {
            const newIpv4List = state.ipv4List.filter(ip => ip.vi4_ip !== action.ip);
            return {
                ...state,
                ipv4List: [...newIpv4List]
            }
        }

        case types._UPDATE_INSTANCE_DETAIL: {
            switch (action.updateType) {
                case 'label': {
                    state.instanceInfo.vi_label = action.data;
                    return {
                        ...state,
                        instanceInfo: {...state.instanceInfo}
                    }
                }

                case 'backups' : {
                    if (action.data === 'enabled') {
                        state.featureInfo.push('auto_backups');
                        return {
                            ...state,
                            featureInfo: [...state.featureInfo]
                        }
                    } else {
                        state.instanceInfo.vi_enable_backup = 0;
                        const newFeatureInfo = state.featureInfo.filter(feature => feature !== 'auto_backups');
                        return {
                            ...state,
                            featureInfo: [...newFeatureInfo],
                            instanceInfo: {...state.instanceInfo}
                        }
                    }

                }
                case 'enable_ipv6': {
                    if (action.data) {
                        const {v6_main_ip, v6_network, v6_network_size} = action.request.data.instance;
                        state.featureInfo.push('ipv6');
                        state.instanceInfo.vi_v6_main_ip = v6_main_ip;
                        state.instanceInfo.vi_v6_network = v6_network;
                        state.instanceInfo.vi_v6_network_size = v6_network_size;
                        return {
                            ...state,
                            featureInfo: [...state.featureInfo],
                            instanceInfo: {...state.instanceInfo}
                        }
                    } else {
                        const newFeatureInfo = state.featureInfo.filter(feature => feature !== 'ipv6');
                        return {
                            ...state,
                            featureInfo: [...newFeatureInfo]
                        }
                    }
                }

                case 'ddos_protection': {
                    if (action.data) {
                        state.featureInfo.push('ddos_protection');
                        return {
                            ...state,
                            featureInfo: [...state.featureInfo]
                        }
                    } else {
                        state.instanceInfo.vi_enable_ddos = 0;
                        const newFeatureInfo = state.featureInfo.filter(feature => feature !== 'ddos_protection');

                        return {
                            ...state,
                            instanceInfo: {...state.instanceInfo},
                            featureInfo: [...newFeatureInfo]
                        }
                    }
                }

                case 'tag': {
                    state.instanceInfo.vi_tag = action.data;
                    return {
                        ...state,
                        instanceInfo: {...state.instanceInfo}
                    }
                }
                default:
                    break;
            }
            return {
                ...state
            }

        }

        case types._UPDATE_INSTANCE_PLAN: {
            const newPlan = state.upgradeList.filter(plan => plan.vp_id === action.planId);
            Object.assign(state.instanceInfo, newPlan[0]);

            return {
                ...state,
                instanceInfo: {...state.instanceInfo}
            }
        }

        case types._STOP_SERVER: {
            state.instanceInfo.vi_power_status = 'stopped';
            return {
                ...state,
            }
        }

        case types._REBOOT_SERVER: {
            state.instanceInfo.vi_power_status = 'running';
            return {
                ...state
            }
        }
        case types._REINSTALL_SERVER: {
            state.instanceInfo.vi_power_status = action.status;
            return {
                ...state
            }
        }

        case types._CHANGE_HOSTNAME: {
            state.instanceInfo.vi_hostname = action.hostname;
            return {
                ...state
            }
        }

        case types._DEL_SERVER: {
            return {
                ...state,
                instanceInfo: {}
            }
        }

        case types._UPDATE_REVERSE_DNS: {
            const reverseIndex = state.ipv4List.findIndex(ip => ip.vi4_ip === action.data.ip);
            state.ipv4List[reverseIndex].vi4_reverse = action.data.reverse;
            return {
                ...state
            }
        }

        case types._UPDATE_BACKUP_SCHEDULE: {
            const scheduleType = action.data.type;
            state.backupSchedule.vbs_instance_id = action.instanceId;
            state.backupSchedule.vbs_type = action.data.type;
            state.backupSchedule.vbs_hour = action.data.hour;
            if (scheduleType === 'weekly') {
                state.backupSchedule.vbs_dow = action.data.dow;
            }
            if (scheduleType === 'monthly') {
                state.backupSchedule.vbs_dom = action.data.dom;
            }
            return {
                ...state,
                nextBackupSchedule: action.request.data.nextBackupSchedule
            }
        }

        case types._GET_ALL_BACKUP: {
            return {
                ...state,
                backupList: action.backups,
                backupSchedule: action.backupSchedule ? action.backupSchedule : {vbs_type: 'daily', vbs_hour: 0},
                nextBackupSchedule: action.nextBackupSchedule
            }
        }

        case types._RESTORE_BACKUP: {
            state.instanceInfo.vi_server_status = 'locked';
            state.instanceInfo.vi_power_status = 'stopped';
            state.instanceInfo.vi_status = 'active';
            return {
                ...state
            }
        }

        case types._RESTORE_SNAPSHOT: {
            state.instanceInfo.vi_server_status = 'locked';
            state.instanceInfo.vi_power_status = 'stopped';
            state.instanceInfo.vi_status = 'active';
            return {
                ...state
            }
        }

        case types._GET_UPGRADE_LIST: {
            return {
                ...state,
                upgradeList: action.request.data.upgradePlans,
                currentPlan: action.request.data.currentPlan

            }
        }
        case types._GET_BANDWIDTH: {
            return {
                ...state,
                bandWidthList: action.request.data.bandwidths
            }
        }

        case types._GET_ISO_INFO: {
            const IsoInfo = action.request.data.isoStatus ? action.request.data.isoStatus : {};
            return {
                ...state,
                isoInfo: {...IsoInfo}
            }
        }

        case types._ATTACH_ISO: {
            state.isoInfo.vis_instance_id = action.instanceId;
            state.isoInfo.vis_iso_id = action.isoId;
            return {
                ...state,
                isoInfo: {...state.isoInfo}
            }
        }
        case types._DETACH_ISO: {
            return {
                ...state,
                isoInfo: {}
            }
        }

        default:
            return state;
    }

}
