export const _INIT_TICEKT_LIST = '_INIT_TICEKT_LIST';

export const _ADD_TICKET = '_ADD_TICKET';

export const _CLOSE_TICKET = '_CLOSE_TICKET';

export const _ADD_TICKET_COMMENT = '_ADD_TICKET_COMMENT';

export const _GET_TICEKT = '_GET_TICEKT'


