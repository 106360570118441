import React, {useEffect} from "react";
import {Row, Col} from 'reactstrap';
import OsImgItem from "../../common/OsImgItem";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {GameTypeImgItem} from "../common/GameTypeImgItem";
import GameInstanceMore from "./GameInstanceMore";
import DeployTitle from "../../deploy/DeployTitle";
import {getGameServerList} from "../action";
import {checkErrorResponse} from "../../../helpers/errorCheck";
import {isIterableArray} from "../../../utils/common";
import NotExistTable from "../../common/NotExistTable";


const GameMobileDashboard = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {gameServerList} = useSelector(state => state.gameDeploy);

    useEffect(() => {
        getGameServerList().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, []);

    return (
        <div className='mobile_table_wrapper dark_mobile_table'>
            <DeployTitle title={'게임서버 목록'}/>
            {isIterableArray(gameServerList) ?
                <div>
                    {gameServerList.map((server, index) => {
                        return (
                            <Row key={index}
                                 className='mobile_item align-items-center'>
                                <Col
                                    onClick={() => history.push(`/manage`)}
                                    className='p-0' xs={2}>
                                    <GameTypeImgItem gameType={server.gm_type}/>
                                </Col>
                                <Col
                                    onClick={() => history.push(`/manage`)}
                                    className='p-0' xs={10}>
                                    <div className='instance_label_wrapper'>
                                        <span>{server.gm_domain}</span>
                                        <small>{`${server.gmp_memory}MB - ${server.gm_expire_date}`}</small>
                                    </div>
                                </Col>
                                {/*<Col xs={1} className='server_status_info p-0'>*/}
                                {/*    <GameInstanceMore mcNo={server.gm_no}/>*/}
                                {/*</Col>*/}
                            </Row>
                        )
                    })}
                </div>
                :
                <NotExistTable searchExist={false} type={'gameServer'}/>
            }
        </div>
    )
}

export default GameMobileDashboard;
