import React from "react";


const NotManageExistTable = ({txt, isBackup, toggle, btnTxt}) => {


    return (
        <div className='not_manage_exist_wrapper'>
            <span>{txt}</span>
            {isBackup ?
                <span id='not_manage_backup_txt'>다음 백업 예정 시간:{btnTxt}</span>
                :
                <button onClick={toggle}>{btnTxt}</button>
            }

        </div>
    )
}


export default NotManageExistTable;
