import React, {Fragment, useState} from "react";
import {Form, FormGroup, Input, Label} from "reactstrap";
import MyInput from "../common/MyInput";
import classNames from "classnames";
import passwordIcon from "../../assets/img/login/password.svg";
import LoaderButton from "../common/LoaderButton";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {changePwd, emailMarketingAgree, inActiveUser, smsMarketingAgree, userLeftRequest} from "./action";
import {logoutRequest} from "../login/function";
import ChangePhoneModal from "./ChangePhoneModal";
import Button from '@mui/material/Button';
import AuthBusiness from "./AuthBusiness";
import {checkErrorResponse} from "../../helpers/errorCheck";
import {notificationAlert} from "../../helpers/common";
import {Row, Col} from 'reactstrap';
import ConfirmModal from "../common/ConfirmModal";
import Switch from '@mui/material/Switch';
import {isGameMode} from "../../utils/common";

const label = {inputProps: {'aria-label': 'Switch demo'}};

const Profile = () => {
    const dispatch = useDispatch();
    const {userInfo} = useSelector((state => state.account));
    const {register, handleSubmit, errors, watch} = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    });
    const [loader, setLoader] = useState(false);
    const [phoneModal, phoneModalOpen] = useState(false);
    const onSubmitData = data => {
        setLoader(true);
        if (data) {
            const post = {
                oldPassword: data.old_password,
                newPassword: data.new_password
            }
            changePwd(post).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        logoutRequest().then(response => {
                            if (response) {
                                if (response.status === 200) {
                                    setLoader(false);
                                    notificationAlert('알림', '비밀번호가 변경되었습니다. 다시 로그인 해주세요.');
                                    window.location.href = '/';
                                }
                            }

                        }).catch(err => {
                            setLoader(false);
                            checkErrorResponse(err);
                        })
                    }
                }
            }).catch(err => {
                setLoader(false);
                checkErrorResponse(err);
            })
        }

    };

    const handleInactiveUser = () => {
        return new Promise((resolve, reject) => {
            inActiveUser().then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        resolve(true);
                        logoutRequest().then(response => {
                            if (response) {
                                if (response.status === 200) {
                                    localStorage.removeItem('auth_login');
                                    notificationAlert('알림', '계정이 정상적으로 비활성화 되었습니다.');
                                    window.location.href = '/';
                                }
                            }

                        }).catch(err => {
                            checkErrorResponse(err);
                        })
                    }
                }
            }).catch(err => {
                reject(err);
                checkErrorResponse(err);
            })
        })
    }
    const handleUserLeft = () => {
        return new Promise((resolve, reject) => {
            userLeftRequest().then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        resolve(true);
                        logoutRequest().then(response => {
                            if (response) {
                                if (response.status === 200) {
                                    localStorage.removeItem('auth_login');
                                    notificationAlert('알림', '회원탈퇴가 완료되었습니다.');
                                    window.location.href = '/';
                                }
                            }

                        }).catch(err => {
                            checkErrorResponse(err);
                        })
                    }
                }
            }).catch(err => {
                reject(err);
                checkErrorResponse(err);
            })
        })
    }

    const handleMarketingSmsAgree = () => {
        let isAgree;
        if (userInfo.agreePhone)
            isAgree = 0;
        else
            isAgree = 1;
        smsMarketingAgree(isAgree).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    if (isAgree === 0) {
                        notificationAlert('알림', '마케팅정보(SMS)가 수신거부로 변경되었습니다.');
                    } else {
                        notificationAlert('알림', '마케팅정보(SMS)가 수신동의로 변경되었습니다.');
                    }
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }


    const handleMarketingEmailAgree = () => {
        let isAgree;
        if (userInfo.agreeEmail)
            isAgree = 0;
        else
            isAgree = 1;
        emailMarketingAgree(isAgree).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    if (isAgree === 0) {
                        notificationAlert('알림', '마케팅정보(이메일)이 수신거부로 변경되었습니다.');
                    } else {
                        notificationAlert('알림', '마케팅정보(이메일)이 수신동의로 변경되었습니다.');
                    }
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }

    return (
        <div className={'account_info_wrapper'}>
            <Row className='profile_wrapper'>
                <ChangePhoneModal isNew={!!userInfo.phone} phoneModal={phoneModal} phoneModalOpen={phoneModalOpen}/>
                <Col xs={12} lg={6} className='profile_left_form'>
                    <h4>내 정보</h4>
                    <div className='profile_detail'>
                        <FormGroup>
                            <Label>이메일
                            </Label>
                            <Input
                                id="profile_name"
                                readOnly
                                value={userInfo.email}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>이름</Label>
                            <Input
                                id="profile_name"
                                readOnly
                                value={userInfo.name}/>
                        </FormGroup>
                        {userInfo.phone ?
                            <Fragment>
                                <FormGroup>
                                    <Label>휴대전화 번호</Label>
                                    <Input
                                        id="profile_name"
                                        readOnly
                                        value={userInfo.phone}/>
                                </FormGroup>
                                <Button variant={'contained'} color='primary' onClick={() => phoneModalOpen(true)} id='phone_btn'>휴대전화 번호 수정</Button>
                            </Fragment>
                            : <Fragment>
                                <Label style={{color: 'black'}}>휴대전화 번호를 인증하시면 <strong>10,000포인트</strong> 즉시 지급</Label>
                                <Button variant={'contained'} color='primary' onClick={() => phoneModalOpen(true)} id='phone_btn'>휴대전화 번호 인증</Button>
                            </Fragment>
                        }


                    </div>

                </Col>
                <Col xs={12} lg={6} className='profile_right_form'>
                    <h4>비밀번호 변경</h4>
                    <Form id='change_password_form' className='change_password_form' onSubmit={handleSubmit(onSubmitData)}>
                        <MyInput label={'기존 비밀번호'}
                                 icon={passwordIcon}
                                 type='password'
                                 id='old_password'
                                 name='old_password'
                                 placeholder='비밀번호를 입력해주세요.'
                                 innerRef={register({
                                     required: '기존 비밀번호는 반드시 입력되어야 합니다',
                                 })}
                                 errors={errors}
                                 className={classNames({'border-danger': errors.old_password})}
                        />
                        <MyInput label={'새 비밀번호'}
                                 icon={passwordIcon}
                                 type='password'
                                 id='new_password'
                                 name='new_password'
                                 placeholder='새 비밀번호를 입력해주세요.'
                                 autoComplete='new-password'
                                 innerRef={register({
                                     required: '새 비밀번호는 반드시 입력되어야 합니다',
                                     pattern: {
                                         value: /^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{6,20}$/,
                                         message: '6자 이상 20자 이하 영문 대소문자, 최소 1개의 숫자 및 특수 문자를 포함하여 입력하여 주세요.'
                                     }
                                 })}
                                 errors={errors}
                                 className={classNames({'border-danger': errors.new_password})}
                        />
                        <MyInput label={'새 비밀번호 확인'}
                                 icon={passwordIcon}
                                 type='password'
                                 placeholder="새 비밀번호 확인"
                                 id="new_confirmPassword"
                                 name="new_confirmPassword"
                                 innerRef={register({
                                     required: '비밀번호 확인은 반드시 입력되어야 합니다',
                                     validate: value => value === watch('new_password') || '입력하신 비밀번호가 일치하지 않습니다'
                                 })}
                                 errors={errors}
                                 className={classNames({'border-danger': errors.new_confirmPassword})}
                        />

                        <FormGroup className='change_password_submit_btn'>
                            <LoaderButton form='change_password_form' loader={loader} variant={'contained'} color='primary' type='submit' className="mt-3 change_password_btn">
                                비밀번호 변경
                            </LoaderButton>
                        </FormGroup>
                    </Form>
                </Col>
                <Col xs={12} style={{fontSize: '0.8rem'}}>
                    <div className='marketing_agree_wrapper'>
                        <div>
                            <h4>마케팅정보 수신동의</h4>
                        </div>
                        <div className={'marketing_sms'}>
                            <Row>
                                <Col xs={8}>
                                    <span>SMS 수신</span>
                                    <div className='marketing_description'>
                                        <p>SMS를 이용한 마케팅 정보 (이벤트,안내,행사,쿠폰발급 등)수신에 동의합니다.</p>
                                    </div>
                                </Col>
                                <Col xs={4} style={{textAlign: 'right'}}>
                                    <Switch onChange={handleMarketingSmsAgree} {...label} checked={userInfo.agreePhone}/>
                                </Col>
                            </Row>

                        </div>
                        <div className={'marketing_email'}>
                            <Row>
                                <Col xs={8}>
                                    <span>이메일 수신</span>
                                    <div className='marketing_description'>
                                        <p>이메일을 이용한 마케팅 정보 (이벤트,안내,행사,쿠폰발급 등)수신에 동의합니다.</p>
                                    </div>
                                </Col>
                                <Col xs={4} style={{textAlign: 'right'}}>
                                    <Switch onChange={handleMarketingEmailAgree} {...label} checked={userInfo.agreeEmail}/>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <span className='d-block'>계정 비활성화를 원한다면? <ConfirmModal handleEvent={handleInactiveUser} type={'inActiveUser'}/></span>
                    <span>서버24를 더 이상 이용하지 않는다면? <ConfirmModal handleEvent={handleUserLeft} type={'userLeft'}/></span>
                </Col>
            </Row>
            <AuthBusiness/>
        </div>
    )
}


export default Profile;
