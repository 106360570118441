import React, {useState} from "react";
import {Button, TextareaAutosize, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Row, Col} from 'reactstrap';
import {deleteScripts, updateScripts} from "./action";
import {useDispatch} from "react-redux";
import LoaderButton from "../common/LoaderButton";
import {checkErrorResponse} from "../../helpers/errorCheck";

const ScriptDetail = ({info}) => {
    const dispatch = useDispatch();
    const [subject, setSubject] = useState(info.vsc_name);
    const [category, setCategory] = useState(info.vsc_type);
    const [script, setScript] = useState(info.vsc_script);
    const [delLoader, setDelLoader] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);
    const handleUpdateScript = () => {
        setUpdateLoader(true);
        const post = {
            name: subject,
            type: category,
            script: script,
        }
        updateScripts(post, info.vsc_id).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    setUpdateLoader(false);
                    dispatch(response)
                }
            }
        }).catch(err => {
            setUpdateLoader(false);
            checkErrorResponse(err);
        })
    }
    const handleDeleteScript = () => {
        setDelLoader(true);
        deleteScripts(info.vsc_id).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    setDelLoader(false);
                    dispatch(response);
                }
            }
        }).catch(err => {
            setDelLoader(true);
            checkErrorResponse(err);
        })
    }
    return (
        <div className='script_detail_wrapper'>
            <Row>
                <Col xs={12} lg={6}>
                    <TextareaAutosize
                        className='script_table_txtArea '
                        aria-label="maximum height"
                        placeholder="스크립트를 입력하세요."
                        value={script}
                        onChange={event => setScript(event.target.value)}
                        style={{width: '100%', minHeight: '200px', maxHeight: '600px'}}
                    />
                </Col>
                <Col xs={12} lg={6}>
                    <TextField className='script_name_input'
                               value={subject}
                               onChange={event => setSubject(event.target.value)}
                               fullWidth id="ticket_subject" label="Name" variant="outlined"/>
                    <Box className='ticket_modal_input' sx={{minWidth: 120}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="script_type_select"
                                value={category}
                                label="Type"
                                onChange={event => setCategory(event.target.value)}
                            >
                                <MenuItem className='select_list_item' value={'boot'}>Boot</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Row className='script_detail_btn'>
                        <Col xs={6}>
                            <LoaderButton disabled={delLoader} loader={updateLoader} onClick={() => handleUpdateScript()} color='primary' size='large' variant="outlined">수정하기</LoaderButton>
                        </Col>
                        <Col xs={6}>
                            <LoaderButton disabled={updateLoader} loader={delLoader} onClick={() => handleDeleteScript()} color='error' size='large' variant="outlined">
                                삭제하기
                            </LoaderButton>
                        </Col>
                    </Row>
                </Col>

            </Row>
        </div>
    )
}

ScriptDetail.defaultProps = {
    info: {
        vsc_name: '',
        vsc_type: 'boot',
        vsc_script: '',
        vsc_no: -1,
        vsc_id: ''
    }
}
export default ScriptDetail;
