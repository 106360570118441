import React from "react";
import {CircularProgress} from "@material-ui/core";
import {useSelector} from "react-redux";
import {isGameMode} from "../../utils/common";


const ModeChangeLoader = () => {
    const {layoutMode} = useSelector(state => state.layout);
    return (
        <div className={!isGameMode(layoutMode) ? 'mode_change_loader dark_loader' :'mode_change_loader origin_loader'}>
            <div className='loader_wrapper'>
                <CircularProgress />
                <div  style={{marginTop : '2rem'}}>
                <span className='fw-bold'>모드 변경중</span>
                </div>
            </div>
        </div>
    )
}
export default ModeChangeLoader;
