import React from "react";
import {FormGroup, Input, Label} from "reactstrap";


const MyInput = ({label, icon, id, name, placeholder, errors, type, ...rest}) => {

    return (
        <FormGroup className='my_input_group'>
            <Label for={name}>{label}</Label>
            <div className='my_input_wrapper'>
                <img src={icon}/>
                <Input
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    type={type}
                    {...rest}
                />
            </div>
            {errors[name] && <span className="text-danger my_input_error_msg">{errors[name].message}</span>}
        </FormGroup>
    )
}

MyInput.defaultProps = {
    label: '',
    icon: '',
    placeholder: '',
    type: 'text',
    errors: {}
}

export default MyInput;
