import React, {useState} from "react";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import SnapShotsTable from "./SnapShotsTable";
import BackupsTable from "./BackupsTable";
import {Fab} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SnapShotModal from "./SnapShotModal";

const SnapShotList = () => {
    const [value, setValue] = React.useState('1');
    const [openModal, setOpenModal] = useState(false);
    const toggle = () => {
        setOpenModal(!openModal)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

//TODO 네브바에 스냅샷으로 넣을지 복원 이런걸로 넣을지 정하고 리스트에서도 수정하기
    return (
        <div className='snapshot_list_wrapper container-md'>
            <div className='snapshots_header'>
                <h2>스냅샷</h2>
                <Fab onClick={toggle} color="primary" aria-label="add"> <AddIcon/> </Fab>
            </div>
            <Box sx={{width: '100%', typography: 'body1'}}>
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleChange} aria-label="">
                            <Tab label="Snapshots" value="1"/>
                            <Tab label="Backups" value="2"/>
                        </TabList>
                    </Box>
                    <TabPanel className='tab_panel p-0' value="1"><SnapShotsTable toggle={toggle}/></TabPanel>
                    <TabPanel className='tab_panel p-0' value="2"><BackupsTable/></TabPanel>
                </TabContext>
            </Box>
            <SnapShotModal toggle={toggle} collapse={openModal}/>
        </div>
    )
}

export default SnapShotList;
