import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DeployTitle from "../../../deploy/DeployTitle";
import {useHistory, useParams} from "react-router-dom";
import {useEffect} from "react";
import {deleteGameBackups, getGameBackups, getGameServerList, restoreGameBackups, takeGameBackups} from "../../action";
import {checkErrorResponse} from "../../../../helpers/errorCheck";
import {useDispatch, useSelector} from "react-redux";
import {formatBytes, isIterableArray} from "../../../../utils/common";
import NotExistTable from "../../../common/NotExistTable";
import moment from "moment";
import {getNumString} from "../../common/GameTypeImgItem";
import {Button} from "@mui/material";
import ConfirmModal from "../../../common/ConfirmModal";
import {changePerNum, notificationAlert} from "../../../../helpers/common";
import Swal from "sweetalert2";

export default function GameBackupTable() {
    let {id} = useParams();
    const dispatch = useDispatch();
    const {sessionId, gameBackups} = useSelector(state => state.gameDeploy);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(localStorage.getItem('pageNum') ? localStorage.getItem('pageNum') : 10);

    useEffect(() => {
        getGameBackups(sessionId, id).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, []);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const perNum = parseInt(event.target.value, 10);
        changePerNum(perNum)
        setRowsPerPage(perNum);
        setPage(0);
    };

    const handleRestoreBackup = (backupId) => {
        return new Promise((resolve, reject) => {
            restoreGameBackups(sessionId, id, backupId).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        if (response.request.data.result.Status) {
                            resolve(true);
                        } else {
                            reject(false);
                            notificationAlert('알림', '복원에 실패하였습니다.');
                        }
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
            })
        });
    }

    const handleDeleteBackup = (backupId) => {
        return new Promise((resolve, reject) => {
            deleteGameBackups(sessionId, id, backupId).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        if (!response.request.data) {
                            dispatch(response);
                            resolve(true);
                        } else {
                            reject(false);
                            notificationAlert('알림', '삭제에 실패하였습니다.');
                        }

                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
                reject(err);
            })
        });
    }

    const handleTakeBackup = async () => {
        const {value: backupLabel} = await Swal.fire({
            title: '생성하실 백업에 라벨을 입력하여 주세요.',
            input: 'text',
            inputLabel: 'Label',
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return '라벨을 입력하여 주세요.'
                }
            }
        })
        if (backupLabel) {
            takeGameBackups(sessionId, id, backupLabel).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        if (response.request.data.Status) {
                            notificationAlert('알림', '백업이 생성중 입니다. 5 ~ 10분 정도 소요됩니다.');
                        }
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
            })
        }
    }


    return (
        <div className='game_server_table_wrapper'>
            <div className='game_make_backup_box'>
                <DeployTitle title={'게임백업 목록'}/>
                <Button onClick={handleTakeBackup} variant={'contained'} color={'primary'}>백업 생성</Button>
            </div>

            {isIterableArray(gameBackups) ?
                <Paper sx={{width: '100%', overflow: 'hidden'}}>
                    <TableContainer>
                        <Table className='game_server_table' aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align={'center'}>
                                        라벨
                                    </TableCell>
                                    <TableCell
                                        align={'center'}>
                                        생성일자
                                    </TableCell>
                                    <TableCell
                                        align={'center'}>
                                        크기
                                    </TableCell>
                                    <TableCell
                                        align={'right'}>
                                        {''}
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {gameBackups
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow
                                                className='game_table_row'
                                                style={{cursor: 'pointer'}}
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.gm_no}>
                                                <TableCell
                                                    align={'center'}>
                                                    {row.Name}
                                                </TableCell>
                                                <TableCell
                                                    align={'center'}>
                                                    {moment(new Date(getNumString(row.Timestamp))).format('YYYY년 MM월 DD일 HH:mm:ss')}
                                                </TableCell>
                                                <TableCell
                                                    align={'center'}>
                                                    {formatBytes(row.TotalSizeBytes)}
                                                </TableCell>
                                                <TableCell align={'right'}>
                                                    <ConfirmModal
                                                        id={'game_backup_restore_btn'}
                                                        type={'gameBackupRestore'}
                                                        handleEvent={() => handleRestoreBackup(row.Id)}/>
                                                    <ConfirmModal
                                                        id={'game_backup_delete_btn'}
                                                        type={'gameBackupDelete'}
                                                        handleEvent={() => handleDeleteBackup(row.Id)}/>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={gameBackups.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                :
                <div className={'game_backup_not_exist_wrapper'}>
                    <div className='not_exist_txt'>
                        <span>백업이 존재하지 않습니다.</span>
                        <Button onClick={handleTakeBackup} variant={'contained'} color={'primary'}>백업 생성하기</Button>
                    </div>
                </div>
            }
        </div>
    );
}
