import axios from "axios";

export const loginRequest = async (post) => {
    return await axios.post(`https://${process.env.REACT_APP_API_SERVER}/auth/local`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    })
}


export const logoutRequest = async () => {
    return await axios.delete(`https://${process.env.REACT_APP_API_SERVER}/auth/local`, {
        headers: {
            "Content-Type": 'application/json',
        }
    })
}

export const testLogin = async (post) => {
    return await axios.post(`https://${process.env.REACT_APP_API_SERVER}/auth/testLogin`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    })
}

