import React, {Fragment, useEffect, useState} from "react";
import {Modal, ModalBody, Row, Col} from 'reactstrap';
import ToastBox from "../../../common/ToastBox";
import {ToastMsg} from "../../../constant/ToastMsg";
import {isIterableArray} from "../../../../utils/common";
import ConfirmModal from "../../../common/ConfirmModal";
import {useDispatch, useSelector} from "react-redux";
import {checkErrorResponse} from "../../../../helpers/errorCheck";
import {Slider} from "@material-ui/core";
import upgradeArrow from '../../../../assets/img/product/upgradeArrow.svg';
import {Alert, AlertTitle} from "@mui/lab";
import {Button} from "@mui/material";
import {extendGameExpireDate, getGamePlanList} from "../../action";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {oneMonthDay} from "../../../../helpers/common";
import MenuItem from "@mui/material/MenuItem";
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";

const ExtendMonthItem = () => {
    const item = [];

    for (let i = 1; i <= 99; i++) {
        item.push(
            <MenuItem key={i} className='select_list_item' value={i}>{i}개월</MenuItem>
        )
    }
    return item;
}


const GameChangeTermModal = ({modal, toggle, mcNo}) => {
    const dispatch = useDispatch();
    const [month, setMonth] = useState(1);
    const {gameInstanceDetail} = useSelector(state => state.gameDeploy);

    const useStyles = makeStyles(() => ({

        menuPaper: {
            maxHeight: 400
        }
    }));
    const classes = useStyles();

    const handleChangeTerm = () => {
        return new Promise((resolve, reject) => {
            extendGameExpireDate(mcNo, month).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        dispatch(response);
                        resolve(true);
                        toggle();
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
                reject(err);
            })
        })
    };


    return (
        <Modal centered isOpen={modal} toggle={toggle} className='game_extend_date_modal'>
            <ModalBody>
                <div className='change_plan_wrapper'>
                    <div className='change_plan_title'>
                        <h4>유효기간 변경</h4>
                        <div onClick={toggle} className="close close3"/>
                    </div>
                    <div className='now_expire_date'>
                        <span className='d-block'>현재 유효기간 : {gameInstanceDetail.expire_date}</span>
                        <span style={{color: '#4d99ff'}} className='d-block mt-2'>연장예정 유효기간 : {moment(gameInstanceDetail.expire_date).add(30 * month, 'days').format('YYYY년 MM월 DD일')}</span>
                    </div>
                    <div className='select_wrapper'>
                        <FormControl fullWidth>
                            <InputLabel id="extend_month_label">연장 개월수</InputLabel>
                            <Select
                                MenuProps={{classes: {paper: classes.menuPaper}}}
                                className='extend_month_select'
                                fullWidth
                                labelId="extend_month_label"
                                value={month}
                                label="연장 개월수"
                                onChange={event => setMonth(event.target.value)}
                            >

                                {ExtendMonthItem()}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <ConfirmModal
                            fullWidth
                            color={'primary'}
                            variant={'contained'}
                            type={'extendGameDate'}
                            handleEvent={handleChangeTerm}/>
                    </div>
                </div>
            </ModalBody>

        </Modal>
    );
}

export default GameChangeTermModal;
