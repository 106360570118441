import React, {useState} from "react";
import {Label, Modal, ModalBody, ModalFooter} from "reactstrap";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {TextField, TextareaAutosize, Button} from "@mui/material";
import {useDispatch} from "react-redux";
import {addScripts} from "./action";
import LoaderButton from "../common/LoaderButton";
import {checkErrorResponse} from "../../helpers/errorCheck";
import {notificationAlert} from "../../helpers/common";

const ScriptModal = ({collapse, toggle}) => {
    const dispatch = useDispatch();
    const [category, setCategory] = useState('');
    const [subject, setSubject] = useState('');
    const [loader, setLoader] = useState(false);
    const [script, setScript] = useState('');
    const handleMakeScript = () => {
        if (subject.length === 0) {
            notificationAlert('오류', '스크립트 라벨을 입력해주세요.');
            return;
        }
        if (category.length === 0) {
            notificationAlert('오류', '스크립트 종류를 선택해주세요.');
            return;
        }
        if (script.length === 0) {
            notificationAlert('오류', '스크립트 명령어를 입력해주세요.');
            return;
        }
        setLoader(true);
        const post = {
            name: subject,
            type: category,
            script: script
        }
        addScripts(post).then(response => {
            if (response) {
                if (response.request.status === 201) {
                    setLoader(false);
                    dispatch(response);
                    toggle();

                }
            }

        }).catch(err => {
            checkErrorResponse(err);
            setLoader(false);
        })
    }

    return (
        <Modal className='script_modal' backdrop={'static'} centered aria-labelledby="contained-modal-title-vcenter" isOpen={collapse} toggle={toggle}>
            <ModalBody>
                <div className='ticket_modal_title'>
                    <h4>스크립트 생성하기</h4>
                </div>
                <TextField className='ticket_modal_input'
                           onChange={event => setSubject(event.target.value)}
                           fullWidth id="ticket_subject" label="Name" variant="outlined"/>
                <Box className='ticket_modal_input' sx={{minWidth: 120}}>
                    <FormControl fullWidth>
                        <InputLabel id="script_label_kind">스크립트 종류를 선택해주세요</InputLabel>
                        <Select
                            labelId="script_label_kind"
                            id="ticket_category"
                            value={category}
                            label="스크립트 종류를 선택해주세요"
                            onChange={event => setCategory(event.target.value)}
                        >
                            <MenuItem className='select_list_item' value={'boot'}>최초 부팅 시 실행</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Label id={'script_txt_label'}>스크립트 명령어</Label>
                <TextareaAutosize
                    className='ticket_modal_input ticket_modal_txtArea'
                    aria-label="maximum height"
                    placeholder="#!/bin/sh"
                    onChange={event => setScript(event.target.value)}
                    style={{width: '100%', minHeight: '200px', maxHeight: '600px'}}
                />

            </ModalBody>
            <ModalFooter className='ticket_modal_footer'>
                <Button onClick={toggle} variant="outlined">취소</Button>
                <LoaderButton loader={loader} variant="contained" onClick={() => handleMakeScript()}>
                    생성하기
                </LoaderButton>


            </ModalFooter>
        </Modal>
    )
}


export default ScriptModal;
