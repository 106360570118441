import * as types from './actionTypes';
import axios from "axios";
import {getBackupSchedule} from "../snapshot/action";
import {_GET_ALL_BACKUP} from "./actionTypes";

export const getInstanceDetail = async (id) => {
    const getInstanceDetailRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/${id}`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._GET_INSTANCE_DETAIL,
        request: getInstanceDetailRequest
    }
}

export const getInstanceIpv4 = async (id) => {
    const getInstanceIpv4Request = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/${id}/ipv4`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._GET_INSTANCE_IPV4,
        request: getInstanceIpv4Request
    }
}

export const addInstanceIpv4 = async (id, post) => {
    const addInstanceIpv4Request = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/${id}/ipv4`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._ADD_INSTANCE_IPV4,
        request: addInstanceIpv4Request
    }
}

export const delInstanceIpv4 = async (id, ip) => {
    const delInstanceIpv4Request = await axios.delete(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/${id}/ipv4/${ip}`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._DEL_INSTANCE_IPV4,
        request: delInstanceIpv4Request,
        ip: ip
    }
}


export const updateInstanceDetail = async (id, post, updateType) => {
    const updateInstanceDetailRequest = await axios.patch(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/${id}`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._UPDATE_INSTANCE_DETAIL,
        request: updateInstanceDetailRequest,
        updateType: updateType,
        data: post[updateType]
    }
}

export const updateInstancePlan = async (id, post) => {
    const updateInstanceDetailRequest = await axios.patch(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/${id}/plan`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });
    return {
        type: types._UPDATE_INSTANCE_PLAN,
        request: updateInstanceDetailRequest,
        planId: post.plan
    }
}

export const stopServer = async (post) => {
    const stopServerRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/halt`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._STOP_SERVER,
        request: stopServerRequest
    }
}

export const restartServer = async (post) => {
    const stopServerRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/reboot`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._REBOOT_SERVER,
        request: stopServerRequest
    }
}

export const deleteServer = async (id) => {
    const deleteServerRequest = await axios.delete(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/${id}`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._DEL_SERVER,
        request: deleteServerRequest,
        instanceId: id
    }
}

export const infoStatusReinstall = (id, status) => {
    return {
        type: types._REINSTALL_SERVER,
        status: status,
        instanceId: id
    }
}

export const reinstallServer = async (id, hostname) => {
    const post = hostname ? {hostname: hostname} : {};
    const reinstallServerRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/${id}/reinstall`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._CHANGE_HOSTNAME,
        request: reinstallServerRequest,
        hostname: hostname ? hostname : null
    }
}


export const updateReverseDns = async (id, post) => {
    const updateReverseDnsRequest = await axios.patch(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/${id}/ipv4/reverse`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._UPDATE_REVERSE_DNS,
        request: updateReverseDnsRequest,
        data: post
    }
}

export const getInstanceBackup = async (instanceId) => {
    return await axios.get(`https://${process.env.REACT_APP_API_SERVER}/servermv/backups/${instanceId}`, {
        headers: {
            "Content-Type": 'application/json',
        }
    })
}

export const getInstanceBackupAll = (instanceId) => {
    return new Promise((resolve, reject) => {
        axios.all([getInstanceBackup(instanceId), getBackupSchedule(instanceId)])
            .then(axios.spread(function (backups, backupSchedule) {
                if (backups.status === 200 && backupSchedule.status === 200) {
                    resolve({
                        type: types._GET_ALL_BACKUP,
                        backups: backups.data.backups,
                        backupSchedule: backupSchedule.data.backupSchedule ? backupSchedule.data.backupSchedule : null,
                        nextBackupSchedule: backupSchedule.data.nextBackupSchedule
                    })
                }

            })).catch(err => {
            if (err) {
                reject(err);
            }
        });
    })

}


export const updateBackupSchedule = async (id, post) => {
    const updateBackupScheduleRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/servermv/backups/${id}/backup-schedule`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });
    return {
        type: types._UPDATE_BACKUP_SCHEDULE,
        request: updateBackupScheduleRequest,
        data: post,
        instanceId: id
    }
}

export const restoreSnapShot = async (instanceId, post) => {
    const restoreInstanceRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/${instanceId}/restore`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });
    return {
        type: types._RESTORE_SNAPSHOT,
        request: restoreInstanceRequest,
    }
}


export const restoreBackup = async (id, post) => {
    const restoreBackupRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/${id}/restore`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });
    return {
        type: types._RESTORE_BACKUP,
        request: restoreBackupRequest
    }
}

export const getUpgradeList = async (id) => {
    const getUpgradeListRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/${id}/upgrade`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });
    return {
        type: types._GET_UPGRADE_LIST,
        request: getUpgradeListRequest
    }
}

export const getBandWidth = async (id) => {
    const getBandWidthRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/${id}/bandwidth`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });
    return {
        type: types._GET_BANDWIDTH,
        request: getBandWidthRequest
    }
}

export const getInstanceIso = async (id) => {
    const getInstanceIsoRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/servermv/iso/${id}`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });
    return {
        type: types._GET_ISO_INFO,
        request: getInstanceIsoRequest
    }
}

export const attachIso = async (id, post) => {
    const attachIsoRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/servermv/iso/${id}/attach`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });
    return {
        type: types._ATTACH_ISO,
        request: attachIsoRequest,
        instanceId: id,
        isoId: post.iso_id
    }
}

export const detachIso = async (id, post) => {
    const detachIsoRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/servermv/iso/${id}/detach`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });
    return {
        type: types._DETACH_ISO,
        request: detachIsoRequest
    }
}
