import React, {useEffect, useState} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Button from '@mui/material/Button';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import {getVrAccountInfo} from "./action";
import {checkErrorResponse} from "../../helpers/errorCheck";

const checkBillStatus = (status) => {
    switch (status) {
        case 'DONE':
            return '결제완료'
        case 'WAITING_FOR_DEPOSIT':
            return '입금 대기중'
        case 'CANCELED':
            return '결제취소'
        default:
            return ''
    }
}

const CharginMoreModal = ({payment, mobile}) => {
    const [modal, setModal] = useState(false);
    const [vrInfo, setVrInfo] = useState({});
    useEffect(() => {
        if (payment.method === '가상계좌') {
            if (modal) {
                getVrAccountInfo(payment.pt_no).then(response => {
                    if (response) {
                        if (response.request.status === 200) {
                            setVrInfo(response.request.data.data);
                        }
                    }
                }).catch(err => {
                    checkErrorResponse(err);
                })
            }
        }
    }, [modal]);

    const toggle = () => setModal(!modal);
    return (
        <div>
            {mobile ?
                <Button variant={'outlined'} color={'primary'} size={'small'} onClick={toggle}>결제 상세</Button>
                :
                <Button variant={'outlined'} color={'primary'} onClick={toggle}>자세히 보기</Button>
            }
            <Modal centered isOpen={modal} toggle={toggle} className={'charging_receipturl_modal'}>
                <ModalBody>
                    <div className={'charging_receipturl_wrapper'}>
                        <div className='point_title'>
                            <h4 className='fw-bold'>{((parseInt(payment.totalAmount) * 10) / 11).toLocaleString()} 포인트</h4>
                        </div>
                        <section className='point_pay_info'>
                            <div className='pay_type'>
                                <span>승인상태</span>
                                <span>승인일시</span>
                                <span>결제수단</span>
                            </div>
                            <div className='pay_info'>
                                <span>{checkBillStatus(payment.status)}</span>
                                <span>{payment.approvedAt}</span>
                                <span>{payment.method}</span>
                            </div>
                        </section>
                        <section>
                            <div id={'pay_info_date'} className='pay_type'>
                                <span>결제정보</span>
                            </div>
                            <div className='pay_info'>
                                <span>{payment.requestedAt}</span>
                            </div>
                        </section>
                        {payment.method === '가상계좌' &&
                        <section>
                            <div id={'pay_info_date'} className='pay_type'>
                                <span>입금은행</span>
                                <span>입금계좌</span>
                                <span>입금마감일</span>
                            </div>
                            <div className='pay_info'>
                                <span>{vrInfo.bank}은행</span>
                                <span>{vrInfo.accountNumber}</span>
                                <span>{vrInfo.dueDate}</span>
                            </div>
                        </section>
                        }
                        <section>
                            <div className='pay_type'>
                                <span>상품금액</span>
                                <span style={{marginTop: '1rem'}}>결제금액</span>
                            </div>
                            <div className='pay_info'>
                                <span>
                                    {payment.totalAmount.toLocaleString()}원
                                </span>
                                <span style={{color: 'red', marginTop: '1rem'}}>
                                    {
                                        (payment.method === '가상계좌' && payment.status === 'WAITING_FOR_DEPOSIT') ?
                                            0
                                            :
                                            payment.totalAmount.toLocaleString()
                                    }원
                                </span>
                            </div>
                        </section>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {payment.receiptUrl && <button onClick={() => window.open(payment.receiptUrl)}><ReceiptOutlinedIcon/>영수증 보기</button>}
                    <button onClick={toggle}><CheckOutlinedIcon/>확인</button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default CharginMoreModal;
