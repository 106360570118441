import {combineReducers} from "redux";
import ticket from '../components/ticket/reducer';
import account from '../components/account/reducer';
import deploy from '../components/deploy/reducer';
import script from '../components/script/reducer';
import snapShot from '../components/snapshot/reducer';
import instance from '../components/Product/reducer';
import manage from '../components/manage/reducer';
import bill from '../components/bill/reducer';
import notice from '../components/notice/reducer';
import layout from '../layouts/reducer';
import gameDeploy from '../components/game/reducer';

const rootReducer = combineReducers({
    ticket,
    account,
    deploy,
    script,
    snapShot,
    instance,
    manage,
    bill,
    notice,
    layout,
    gameDeploy
});

export default rootReducer;
