import React, {useState} from "react";
import OSListItem from "./OSListItem";
import {publicIso} from "../../../datas/publicIso";

const ISOLibrary = () => {
    return (
        <div className='iso_library_wrapper'>
            <OSListItem name='name'
                        img='https://s3.server24.kr/panel/img/os/Iso.svg'
                        loader={false}
                        osData={publicIso}
                        osType={'iso'}
                        atr='description'/>
        </div>
    )
}

export default ISOLibrary;
