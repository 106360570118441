import React from "react";
import GameManageTabs from "./GameManageTabs";


const GameManageSetting = () => {

    return (
        <div className='game_manage_setting'>
            <GameManageTabs/>
        </div>
    )
}


export default GameManageSetting;
