import React, {useEffect, useState} from 'react';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';
import {getFileChunk, saveFileChunk} from "../../action";
import {checkErrorResponse} from "../../../../helpers/errorCheck";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {TextareaAutosize} from "@mui/material";
import Parser from 'html-react-parser';
import {notificationAlert} from "../../../../helpers/common";

const FileModifyModal = ({fileName, modal, toggle}) => {
    const {sessionId} = useSelector(state => state.gameDeploy);
    const [fileData, setFileData] = useState('');
    let {id} = useParams();
    const [base64Data, setBase64Data] = useState('');

    useEffect(() => {
        getFileChunk(sessionId, id, fileName).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    setBase64Data(response.request.data.result.Base64Data);
                    setFileData(Parser(atob(response.request.data.result.Base64Data)));

                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })

        return () => {
            setFileData('');
            setBase64Data('');
        }
    }, [fileName])

    const handleSaveFile = () => {
        saveFileChunk(sessionId, id, fileName, fileData).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    if (!response.request.data) {
                        toggle();
                        notificationAlert('알림', '성공적으로 저장 되었습니다.');
                    }

                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }


    return (
        <Modal centered isOpen={modal} toggle={toggle} className={'file_manager_editor_modal'}>
            <ModalBody>
                <TextareaAutosize
                    defaultValue={Parser(atob(base64Data))}
                    className='file_manager_file_ta'
                    aria-label="maximum height"
                    placeholder="내용을 입력하세요."
                    onChange={event => setFileData(event.target.value)}
                    style={{width: '100%'}}
                />
            </ModalBody>
            <ModalFooter className='modal_footer'>
                <button onClick={handleSaveFile}>저장</button>
                <button onClick={toggle}>닫기</button>
            </ModalFooter>
        </Modal>
    );
}

export default FileModifyModal;
