import React, {useState, useEffect} from "react";
import {Chip} from "@mui/material";
import {useSelector} from "react-redux";
import {isIterableArray, timeToString} from "../../../utils/common";
import docsIcon from '../../../assets/img/ticket/docs.svg';

const UserTicket = ({comment}) => {
    const {ticketUsers} = useSelector((state) => state.ticket);
    const [user, setUser] = useState({});
    const [attachType, setAttachType] = useState('docs');
    useEffect(() => {

        if (comment) {
            const user = ticketUsers.filter(user => user.id === comment.author_id);
            if (isIterableArray(comment.attachments)) {
                const fileType = comment.attachments[0].content_type.split('/');
                setAttachType(fileType[0]);
            }
            setUser(user[0]);
        }
    }, [comment, ticketUsers]);


    return (
        <div className={user.agent ? 'user_ticket_wrapper manager_ticket' : 'user_ticket_wrapper customer_ticket'}>
            <div className='ticket_info'>
                <div className='user_info'>
                <span id='ticket_nickname'>
                    {user.name}
                </span>
                    {user.agent ?
                        <Chip id='user_rank' size='small' color='success' label='고객만족센터'/>
                        :
                        <Chip id='user_rank' size='small' color='primary' label='고객'/>
                    }

                </div>
                <div>
                        <span id='ticket_date'>
                    {timeToString(comment.created_at)}
                </span>
                </div>

            </div>

            <div className='ticket_content'>
                <div className='ticket_comment'>
                    <p>
                        {comment.body.split('\n')
                            .map((line, index) => {
                                return (
                                    <span key={index}>{line}<br/></span>
                                )
                            })}
                    </p>
                </div>
                {isIterableArray(comment.attachments) &&
                <div className='user_ticket_attachment'>
                    <div className='ticket_attachment_item d-flex flex-column'>
                        <span>첨부파일</span>
                        {attachType === 'image' ? !comment.attachments[0].thumbnails ?
                                <a href={comment.attachments[0].content_url} target='_blank' download="">
                                    <img src={comment.attachments[0].content_url}/>
                                </a>
                                :
                                <a href={comment.attachments[0].content_url} target='_blank' download="">
                                    <img src={comment.attachments[0].thumbnails}/>
                                </a>
                            :
                            <a href={comment.attachments[0].content_url} target='_blank' download="">
                                <img src={docsIcon}/>
                            </a>
                        }
                        <span style={{marginTop: '0.5rem'}}>{comment.attachments[0].file_name}</span>
                    </div>
                </div>
                }
            </div>

        </div>
    )
}


export default UserTicket;
