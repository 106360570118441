import React from "react";
import {setOS} from "../action";
import {useDispatch, useSelector} from "react-redux";
import {Col, Row} from "reactstrap";
import {Tooltip} from "@mui/material";
import {CheckTriangle} from "../../common/CheckTriangle";

const OSListItem = ({osData, img, img_white, name, atr, date, osType}) => {
    const {OS} = useSelector(state => state.deploy.vultr);
    const dispatch = useDispatch();

    const handleOsSet = (osInfo) => {
        dispatch(setOS(osInfo, osType))
    }


    return (
        <Row className='os_list_item_wrapper'>
            {osData.map((item, index) => {
                let isSelect = false;
                isSelect = item.id === OS.type.id;
                if (osType === 'snapshot') isSelect = item.vs_id === OS.type.vs_id;
                if (osType === 'backup') isSelect = item.vb_id === OS.type.vb_id;
                return (
                    <Col key={index} xs={12} sm={6} lg={4} xl={3}
                         onClick={() => handleOsSet(item)}
                         className={isSelect ? 'os_item select_item' : 'os_item'}>
                        <Tooltip placement={'top'} title={item[name]}>
                            <div className='os_content'>
                                {isSelect && <CheckTriangle height={40} width={40}/>}
                                <div className='os_img'>
                                    <img src={img} alt='os_img'/>
                                </div>
                                <div className='os_description'>
                                    <span id='os_item_name'>{item[name]}</span>
                                    <small id='os_item_atr'>{item[atr]}</small>
                                    {date !== '' && <small id='os_item_date'>{item[date]}</small>}
                                </div>
                            </div>
                        </Tooltip>
                    </Col>
                )
            })}
        </Row>
    )
}

OSListItem.defaultProps = {
    osData: [],
    img: '',
    loader: false,
    name: '',
    atr: '',
    date: ''
}
export default OSListItem;
