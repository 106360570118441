import React, {Fragment, useEffect, useState} from "react";
import {Modal, ModalBody, Row, Col} from 'reactstrap';
import ToastBox from "../../common/ToastBox";
import {ToastMsg} from "../../constant/ToastMsg";
import {isIterableArray} from "../../../utils/common";
import ConfirmModal from "../../common/ConfirmModal";
import {getWebHostingPlan, updateWebHostingPlan} from "../action";
import {useDispatch, useSelector} from "react-redux";
import {checkErrorResponse} from "../../../helpers/errorCheck";
import {Slider} from "@material-ui/core";
import upgradeArrow from '../../../assets/img/product/upgradeArrow.svg';
import {Alert, AlertTitle} from "@mui/lab";
import {Button} from "@mui/material";

const ChangePlanModal = ({cuNo, modal, toggle}) => {
    const dispatch = useDispatch();
    const [planStep, setPlanStep] = useState(0);
    const {webHostingPlanList, currentPlan} = useSelector(state => state.instance);
    const handleChangeHostingPlan = () => {
        return new Promise((resolve, reject) => {
            const planId = webHostingPlanList[planStep].cp_id;
            updateWebHostingPlan(cuNo, planId).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        dispatch(response);
                        resolve(true);
                        toggle();
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
                reject(err);
            })
        })
    };

    useEffect(() => {
        if (modal) {
            getWebHostingPlan(cuNo).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        dispatch(response);
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
            })
        }
    }, [modal]);

    const handleChange = (event, newValue) => {
        if (typeof newValue === 'number') {
            setPlanStep(newValue);
        }
    };
    return (
        <Modal centered isOpen={modal} toggle={toggle} className='web_hosting_plan_modal' id={'web_hosting_modal'}>
            <ModalBody>
                <div className='change_plan_wrapper'>
                    <div className='change_plan_title'>
                        <h4>요금제 변경</h4>
                        <div onClick={toggle} className="close close3"/>
                    </div>
                    {isIterableArray(webHostingPlanList) ?
                        <Fragment>
                            <ToastBox type={'warning'} title={'경고'} text={ToastMsg.ChangePlanMsg}/>
                            <div className='use_plan_txt py-2'>
                                <Row>
                                    <Col xs={7}>
                                        <div className='use_plan_now'>
                                            <span>{`현재 요금 : ${(currentPlan.cp_monthly_cost).toLocaleString()} P/월`}</span>
                                            <small>{`(${(currentPlan.cp_day_cost).toLocaleString()} P/일)`}</small>
                                        </div>
                                        <div className='use_plan_upgrade'>
                                            <span>{`업그레이드 요금 : ${(webHostingPlanList[planStep].cp_monthly_cost).toLocaleString()} P/월`}</span>
                                            <small>{`(${(webHostingPlanList[planStep].cp_day_cost).toLocaleString()} P/일)`}</small>
                                        </div>

                                    </Col>
                                    <Col xs={5}>
                                        <div className='use_plan_compare'>
                                            <h4>{`+${(webHostingPlanList[planStep].cp_monthly_cost) - (currentPlan.cp_monthly_cost)} P/월`}</h4>
                                            <span>{`+${(webHostingPlanList[planStep].cp_day_cost) - (currentPlan.cp_day_cost)} P/일`}</span>
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className='use_plan_upgrade'>
                                            <Alert severity="info">
                                                <AlertTitle>트래픽 요금(할당량 초과시 부과)</AlertTitle>
                                                <div>
                                                    <span className='fw-bold'>{`1000 P/ 1GB당`}</span>
                                                </div>
                                            </Alert>

                                        </div>
                                    </Col>
                                </Row>


                            </div>
                            <div style={{marginTop: '1.5rem'}}>
                                <Fragment>
                                    <Slider
                                        color={'secondary'}
                                        aria-label="PlanInfo"
                                        defaultValue={0}
                                        valueLabelDisplay="auto"
                                        onChange={handleChange}
                                        step={1}
                                        marks
                                        min={0}
                                        max={webHostingPlanList.length - 1}
                                    />
                                    <div className='plan_info_wrapper'>
                                        <span>요금제 정보</span>
                                        <Row className='plan_info_box'>
                                            <Col xs={12} lg={5} className='now_plan_info'>
                                                <Row>
                                                    <Col xs={6} className='plan_key_list fw-bold'>
                                                        <span>CPU : </span>
                                                        <span>DISK : </span>
                                                        <span>MEMORY : </span>
                                                        <span>DOMAIN : </span>
                                                        <span>TRAFFIC : </span>
                                                        {currentPlan.cp_region === 'us' && <span>DDOS : </span>}
                                                        <span>REGION : </span>

                                                    </Col>
                                                    <Col xs={6} className='plan_key_list' style={{textAlign: 'right'}}>
                                                        <span>{currentPlan.cp_cpu} CPU</span>
                                                        <span>{currentPlan.cp_disk} GB</span>
                                                        <span>{currentPlan.cp_memory} MB</span>
                                                        <span>최대 {currentPlan.cp_domain_limit} 개</span>
                                                        <span>{currentPlan.cp_monthly_traffic} GB</span>
                                                        {currentPlan.cp_region === 'us' &&
                                                        <span>{currentPlan.cp_ddos} GB</span>
                                                        }
                                                        <span>{currentPlan.cp_region}</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12} lg={2} className='text-center'>
                                                <img id={'upgrade_arrow_img'} className='w-75' src={upgradeArrow} alt={'upgrade_arrow'}/>
                                            </Col>
                                            <Col xs={12} lg={5} className='upgrade_plan_info'>
                                                <Row>
                                                    <Col xs={6} className='plan_key_list fw-bold'>
                                                        <span>CPU : </span>
                                                        <span>DISK : </span>
                                                        <span>MEMORY : </span>
                                                        <span>DOMAIN : </span>
                                                        <span>TRAFFIC : </span>
                                                        {currentPlan.cp_region === 'us' && <span>DDOS : </span>}
                                                        <span>REGION : </span>
                                                    </Col>
                                                    <Col xs={6} className='plan_key_list' style={{textAlign: 'right'}}>
                                                        <span>{webHostingPlanList[planStep].cp_cpu} CPU</span>
                                                        <span>{webHostingPlanList[planStep].cp_disk} GB</span>
                                                        <span>{webHostingPlanList[planStep].cp_memory} MB</span>
                                                        <span>최대 {webHostingPlanList[planStep].cp_domain_limit} 개</span>
                                                        <span>{webHostingPlanList[planStep].cp_monthly_traffic} GB</span>
                                                        {currentPlan.cp_region === 'us' &&
                                                        <span>{webHostingPlanList[planStep].cp_ddos} GB</span>
                                                        }
                                                        <span>{webHostingPlanList[planStep].cp_region}</span>
                                                    </Col>
                                                </Row>
                                            </Col>

                                        </Row>
                                    </div>
                                </Fragment>
                                <ConfirmModal
                                    fullWidth
                                    color={'primary'}
                                    variant={'contained'}
                                    type={'webHostingChangePlan'}
                                    handleEvent={handleChangeHostingPlan}/>
                            </div>
                        </Fragment>
                        :
                        <Fragment>
                            <Alert severity="info">
                                <AlertTitle>알림</AlertTitle>
                                <span>더 이상 업그레이드 가능한 요금제가 존재하지 않습니다.</span>
                            </Alert>
                            <Button onClick={toggle} fullWidth color={'primary'} variant={'contained'}>닫기</Button>
                        </Fragment>
                    }
                </div>
            </ModalBody>

        </Modal>
    );
}

export default ChangePlanModal;
