export const _GET_INSTANCE = '_GET_INSTANCE';

export const _GET_WEB_HOSTING_INSTANCE = '_GET_WEB_HOSTING_INSTANCE';

export const _ADD_INSTANCE = '_ADD_INSTANCE';

export const _DEL_INSTANCE = '_DEL_INSTANCE';

export const _UPDATE_INSTANCE = '_UPDATE_INSTANCE';

export const _UPDATE_STATUS_LIST = '_UPDATE_STATUS_LIST';

export const _UPDAET_ALL_STATUS = '_UPDAET_ALL_STATUS';

export const _UPDATE_MAIN_IP = '_UPDATE_MAIN_IP';

export const _DEL_WEB_HOSTING_INSTANCE = '_DEL_WEB_HOSTING_INSTANCE';

export const _GET_WEB_HOSTING_PLAN = '_GET_WEB_HOSTING_PLAN';

export const _UPDATE_WEB_HOSTING_PLAN = '_UPDATE_WEB_HOSTING_PLAN';

export  const _GET_USER_TOTAL_BANDWIDTH = '_GET_USER_TOTAL_BANDWIDTH';
