import React from "react";
import {useSelector} from "react-redux";

const WebTotalPrice = () => {
    const {price} = useSelector((state) => state.deploy.cpanel);
    return (
        <div id='total_price'>
            <div className='deploy_month_price'>
                <h3>{price.type.cp_monthly_cost}P</h3><small>/월</small>
            </div>
            <div className='deploy_month_price'>
                <span>({price.type.cp_day_cost}P</span><small>/일</small>)
            </div>
        </div>
    )
}

export default WebTotalPrice
