import * as types from './actionTypes';


let initialState = {
    layoutMode: '',
    layoutLoader: false
}


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types._CHANGE_LAYOUT_MODE: {
            return {
                ...state,
                layoutMode: action.mode
            }
        }

        case types._CHANGE_LAYOUT_LOADER: {
            return {
                ...state,
                layoutLoader: action.mode
            }
        }

        default:
            return state;
    }

}
