export const _GET_INSTANCE_DETAIL = '_GET_INSTANCE_DETAIL';

export const _GET_INSTANCE_IPV4 = '_GET_INSTANCE_IPV4';

export const _ADD_INSTANCE_IPV4 = '_ADD_INSTANCE_IPV4';

export const _DEL_INSTANCE_IPV4 = '_DEL_INSTANCE_IPV4';

export const _UPDATE_INSTANCE_DETAIL = '_UPDATE_INSTANCE_DETAIL';

export const _UPDATE_INSTANCE_PLAN = '_UPDATE_INSTANCE_PLAN';

export const _STOP_SERVER = '_STOP_SERVER';

export const _REBOOT_SERVER = '_REBOOT_SERVER';

export const _REINSTALL_SERVER = '_REINSTALL_SERVER';

export const _CHANGE_HOSTNAME = '_CHANGE_HOSTNAME';

export const _DEL_SERVER = '_DEL_SERVER';

export const _UPDATE_REVERSE_DNS = '_UPDATE_REVERSE_DNS';

export const _UPDATE_BACKUP_SCHEDULE = '_UPDATE_BACKUP_SCHEDULE';

export const _GET_ALL_BACKUP = '_GET_ALL_BACKUP';

export const _RESTORE_BACKUP = '_RESTORE_BACKUP';

export const _RESTORE_SNAPSHOT = '_RESTORE_SNAPSHOT';

export const _GET_UPGRADE_LIST = '_GET_UPGRADE_LIST';

export const _GET_BANDWIDTH = '_GET_BANDWIDTH';

export const _GET_ISO_INFO = '_GET_ISO_INFO';

export const _ATTACH_ISO = '_ATTACH_ISO';

export const _DETACH_ISO = '_DETACH_ISO';
