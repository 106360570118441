import React from "react";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import SixFourBit from "./os/SixFourBit";
import ThreeTwoBit from "./os/ThreeTwoBit";
import ISOLibrary from "./os/ISOLibrary";
import Snapshot from "./os/Snapshot";
import Backup from "./os/Backup";
import DeployTitle from "./DeployTitle";


const OSList = () => {
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <div className='deploy_os_wrapper'>
            <DeployTitle title={'서비스 운영체제'}/>
            <Box sx={{width: '100%', typography: 'body1'}}>
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleChange} aria-label="">
                            <Tab label="64 Bit OS" value="1"/>
                            <Tab label="32 Bit OS" value="2"/>
                            <Tab label="ISO Library" value="3"/>
                            <Tab label="Backup" value="4"/>
                            <Tab label="Snapshot" value="5"/>
                        </TabList>
                    </Box>
                    <TabPanel className='tab_panel' value="1"><SixFourBit/></TabPanel>
                    <TabPanel className='tab_panel' value="2"><ThreeTwoBit/></TabPanel>
                    <TabPanel className='tab_panel' value="3"><ISOLibrary/></TabPanel>
                    <TabPanel className='tab_panel' value="4"><Backup/></TabPanel>
                    <TabPanel className='tab_panel' value="5"><Snapshot/></TabPanel>
                </TabContext>
            </Box>
        </div>
    )
}

export default OSList;
