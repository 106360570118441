import React, {useEffect} from "react";
import PointUsageTable from "./PointUsageTable";
import {getPointUsage} from "./action";
import {useDispatch, useSelector} from "react-redux";
import {checkErrorResponse} from "../../helpers/errorCheck";
import pointUsageIcon from '../../assets/img/bill/pointUsageIcon.svg'
import {Row, Col} from "reactstrap";
import {isGameMode} from "../../utils/common";

const PointUsage = () => {
    const dispatch = useDispatch();
    const {layoutMode} = useSelector(state => state.layout);

    useEffect(() => {
        getPointUsage(`point/usage?page=${0}&per_page=${20}`).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [])

    return (
        <div className={isGameMode(layoutMode) ? 'point_usage_wrapper dark_bg_color1' : 'point_usage_wrapper' }>
            <div className='billing_list_wrapper'>
                <div className='billing_list_title'>
                    <span>포인트 사용 내역</span>
                </div>
                <PointUsageTable loader={true}/>
            </div>
        </div>
    )
}


export default PointUsage;
