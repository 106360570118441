import React, {Fragment, useState} from "react";
import {TextField} from "@mui/material";
import CreateIcon from '@mui/icons-material/Create';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {Spinner} from "reactstrap";
import {checkErrorResponse} from "../../helpers/errorCheck";

const ViewTxtInput = ({children, inputValue, clickEvent, data}) => {
    //WORK 클릭이벤트 비동기로 놓기
    //WORK 에러 처리 꼭 해주기
    const [changeShow, setChangeShow] = useState(false);
    const [inputTxt, setInputTxt] = useState(inputValue);
    const [loader, setLoader] = useState(false);
    const handleClick = () => {
        setLoader(true);
        let runEvent;
        if (data) {
            runEvent = clickEvent(inputTxt, data);
        } else {
            runEvent = clickEvent(inputTxt);
        }
        runEvent.then(result => {
            if (result) {
                setLoader(false);
                setChangeShow(false);
            }
        }).catch(err => {
            setLoader(false);
            setChangeShow(false);
            checkErrorResponse(err);
        })
    }
    return (
        <div className='view_txt_input_wrapper'>
            {!changeShow ?
                <div className='view_txt'
                     onClick={() => setChangeShow(true)}>
                    {children}
                    <CreateIcon fontSize={'small'} id='view_txt_pen'/>
                </div>
                :
                <div className='view_input'>
                    <TextField className='view_txt_field' onChange={event => setInputTxt(event.target.value)} defaultValue={inputValue} id="view_input_label" label="Label" variant="standard"/>
                    {loader ?
                        <Spinner size={'sm'}
                                 aria-hidden="true"
                                 as="span"
                                 animation="border"
                                 color='primary'
                                 variant="warning">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        :
                        <Fragment>
                            <CheckIcon onClick={handleClick} id='view_check_icon'/>
                            <CloseIcon id='view_close_icon' onClick={() => setChangeShow(false)}/>
                        </Fragment>

                    }
                </div>
            }
        </div>
    )
}

export default ViewTxtInput;
