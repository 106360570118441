import axios from "axios";
import * as types from './actionTypes'
import {_SMS_MARKETING_AGREE} from "./actionTypes";

export const initProfile = async () => {
    const initProfileRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/user`, {
        headers: {
            "Content-Type": 'application/json',
        }
    })
    return {
        type: types._INIT_PROFILE,
        request: initProfileRequest
    }
}

export const getUserActivityLog = async (link) => {
    const getUserActivityLogRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}${link}`, {
        headers: {
            "Content-Type": 'application/json',
        }
    })
    return {
        type: types._GET_USER_LOG,
        request: getUserActivityLogRequest
    }
}


export const changePwd = async (post) => {
    const changePwdRequest = await axios.put(`https://${process.env.REACT_APP_API_SERVER}/auth/account/password`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    })
    return {
        request: changePwdRequest
    }
}

export const changePhone = async (post) => {
    const changePhoneRequest = await axios.patch(`https://${process.env.REACT_APP_API_SERVER}/auth/account/phone`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    })
    return {
        type: types._CHANGE_PHONE,
        request: changePhoneRequest,
        phone: post.phone
    }
}

export const phonePointEvent = async () => {
    const phonePointEventRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/user/event`, {type: 0}, {
        headers: {
            "Content-Type": 'application/json',
        }
    })
    return {
        request: phonePointEventRequest
    }
}

export const sessionExpired = () => {
    return {
        type: types._SESSION_EXPIRED
    }
}

export const sessionLogout = () => {
    return {
        type: types._SESSION_LOGOUT
    }
}

export const sessionExtension = async () => {
    const sessionExtensionRequest = await axios.put(`https://${process.env.REACT_APP_API_SERVER}/auth/extension`, {}, {
        headers: {
            "Content-Type": 'application/json',
        }
    })

    return {
        type: types._SESSION_EXTENSION,
        request: sessionExtensionRequest
    }
}

export const getAuthBusiness = async () => {
    const getAuthBusinessRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/user/company`, {
        headers: {
            "Content-Type": 'application/json',
        }
    })
    return {
        type: types._GET_AUTH_BUSINESS,
        request: getAuthBusinessRequest
    }
}

export const authenticationBusiness = async (post, companyName) => {
    const authenticationBusinessRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/user/company`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    })
    return {
        type: types._AUTHENTICATION_BUSINESS,
        request: authenticationBusinessRequest,
        companyName: companyName
    }
}

export const inActiveUser = async () => {
    const inActiveUserRequest = await axios.patch(`https://${process.env.REACT_APP_API_SERVER}/auth/account/disabled`, {}, {
        headers: {
            "Content-Type": 'application/json',
        }
    })
    return {
        request: inActiveUserRequest,
    }
}

export const userLeftRequest = async () => {
    const userLeftAxiosRequest = await axios.delete(`https://${process.env.REACT_APP_API_SERVER}/user`, {
        headers: {
            "Content-Type": 'application/json',
        }
    })
    return {
        request: userLeftAxiosRequest,
    }
}

export const smsMarketingAgree = async (agree) => {
    const smsMarketingAgreeRequest = await axios.patch(`https://${process.env.REACT_APP_API_SERVER}/auth/account/agree/phone`, {agree}, {
        headers: {
            "Content-Type": 'application/json',
        }
    })
    return {
        type: types._SMS_MARKETING_AGREE,
        request: smsMarketingAgreeRequest,
        agree
    }
}

export const emailMarketingAgree = async (agree) => {
    const emailMarketingAgreeRequest = await axios.patch(`https://${process.env.REACT_APP_API_SERVER}/auth/account/agree/email`, {agree}, {
        headers: {
            "Content-Type": 'application/json',
        }
    })
    return {
        type: types._EMAIL_MARKETING_AGREE,
        request: emailMarketingAgreeRequest,
        agree
    }
}
