import React, {Fragment, useEffect, useState} from "react";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {useDispatch, useSelector} from "react-redux";
import {initAdditional, setAdditional, setScript} from "../action";
import {getScripts} from "../../script/action";
import {Row, Col, Label, Input} from 'reactstrap'
import {Chip, TextField, Tooltip} from "@mui/material";
import ListSkeleton from "../../common/ListSkeleton";
import HelpIcon from '@mui/icons-material/Help';
import {DetailDescription} from "../../constant/DetailDescription";
import {checkErrorResponse} from "../../../helpers/errorCheck";
import {LoaderImg} from "../../common/LoaderImg";
import {CheckTriangle} from "../../common/CheckTriangle";
import {isIterableArray} from "../../../utils/common";
import {useHistory} from "react-router-dom";
import DeployTitle from "../DeployTitle";

const SettingList = ({setLabel, setHostName}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {service, setting, price, OS} = useSelector(state => state.deploy.vultr);
    const {scriptList} = useSelector(state => state.script);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const handleSetAdditional = (type, event) => {
        switch (type) {
            case 'ipv6' : {
                dispatch(setAdditional(type, event.target.checked, 0, 0));
                break;
            }
            case 'autoBackup': {
                dispatch(setAdditional(type, event.target.checked, price.type.vp_monthly_cost_backup_kr, price.type.vp_day_cost_backup_kr));
                break;
            }
            case 'ddos': {
                if (service.type === 'vdc')
                    dispatch(setAdditional(type, event.target.checked, 0, 0));
                else {
                    dispatch(setAdditional(type, event.target.checked, 99000, 3300));
                }
                break;
            }
            default:
                return;
        }

    }
    const handleSetScript = (script) => {
        dispatch(setScript(script))
    }

    useEffect(() => {
        dispatch(initAdditional());
        getScripts().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    setSkeletonLoader(true);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })

        return () => {

        }
    }, [])


    return (
        <Row className='setting_list_wrapper'>
            <DeployTitle title={'서비스 설정'}/>
            <Col xs={12} lg={6}>
                <Row className='setting_list_host_wrapper'>
                    <Col xs={12}>
                        <div>
                            <Label>서버 식별자(Label)</Label>
                            <span>*</span>
                            <Tooltip title={DetailDescription.manage.label} placement="top">
                                <HelpIcon fontSize={'small'} color={'disabled'}/>
                            </Tooltip>
                        </div>
                        <Input className='setting_list_label'
                               type={'text'}
                               onChange={event => setLabel(event.target.value)}
                               id="setting_label"/>
                    </Col>
                    <Col xs={12}>
                        <div>
                            <Label>호스트네임(Hostname)</Label>
                            <Tooltip title={DetailDescription.manage.hostname}
                                     placement="top">
                                <HelpIcon fontSize={'small'} color={'disabled'}/>
                            </Tooltip>
                        </div>
                        <Input className='setting_list_host'
                               type={'text'}
                               placeholder={'example.server24.kr'}
                               onChange={event => setHostName(event.target.value)}
                               id="setting_hostName"/>
                    </Col>
                </Row>
            </Col>
            <Col lg={4} className='setting_additional'>
                <span style={{fontSize: '20px'}} className='fw-bold'>부가서비스 신청</span>
                <FormGroup className='provide_option_wrapper'>
                    <div>
                        <FormControlLabel onChange={event => handleSetAdditional('ipv6', event)} control={<Checkbox/>} label="IPv6 추가"/>
                        <Tooltip title={DetailDescription.manage.ipv6} placement={'left-start'}>
                            <HelpIcon fontSize={'small'} color={'disabled'}/>
                        </Tooltip>
                    </div>
                    {service.type !== 'vdc' &&
                    <div className='provide_option_item'>
                        <FormControlLabel className='option_form' onChange={event => handleSetAdditional('autoBackup', event)} control={<Checkbox/>} label="서버 자동 백업"/>
                        <Chip className='option_chip' label={`${price.type.vp_monthly_cost_backup_kr.toLocaleString()}원/월`} color="primary"/>
                        <Tooltip title={DetailDescription.manage.autoBackup} placement={'left-start'}>
                            <HelpIcon fontSize={'small'} color={'disabled'}/>
                        </Tooltip>
                    </div>
                    }
                    <div className='provide_option_item'>
                        <FormControlLabel className='option_form' onChange={event => handleSetAdditional('ddos', event)} control={<Checkbox/>} label="10G 디도스 방어"/>
                        {service.type === 'vdc' ?
                            <Chip label="FREE" color="primary"/>
                            :
                            <Chip label="99,000원/월" color="primary"/>}
                        <Tooltip title={DetailDescription.manage.ddosProtection} placement={'left-start'}>
                            <HelpIcon fontSize={'small'} color={'disabled'}/>
                        </Tooltip>
                    </div>


                </FormGroup>

            </Col>
            <Col lg={12} className='setting_scripts'>
                <div>
                    <h4 className='d-inline fw-bold'>자동 실행 스크립트</h4>
                    <Tooltip title={DetailDescription.manage.script} placement="top">
                        <HelpIcon fontSize={'small'} color={'disabled'}/>
                    </Tooltip>
                </div>
                <ListSkeleton colNum={3} itemNum={4} height={120} loading={skeletonLoader}>
                    {OS.osType === 'snapshot' || OS.osType === 'backup' ?
                        <div className='not_exist_script_deploy'>
                            <span>백업 및 스냅샷에 경우 시작 스크립트를 선택하실수 없습니다.</span>
                        </div>
                        :
                        <Fragment>
                            {isIterableArray(scriptList) ?
                                <Row className='script_list_wrapper'>
                                    {scriptList.map((script, index) => {
                                            const isSelect = setting.type.scripts.vsc_id === script.vsc_id;
                                            return (
                                                <Col key={index} lg={4}>
                                                    <Tooltip title={script.vsc_script.split('\n')
                                                        .map((line, index) => {
                                                            return (
                                                                <span key={index}>{line}<br/></span>
                                                            )
                                                        })} placement={'top'}>
                                                        <div onClick={() => handleSetScript(script)}
                                                             className={isSelect ? 'script_item select_script' : 'script_item'}>
                                                            {isSelect && <CheckTriangle height={40} width={40}/>}
                                                            <img src={'https://s3.server24.kr/panel/img/os/Script.svg'}/>
                                                            <div>
                                                                <span className='d-block'>{script.vsc_name}</span>
                                                                <small className='d-block'>{script.vsc_type}</small>
                                                            </div>
                                                        </div>
                                                    </Tooltip>
                                                </Col>
                                            )
                                        }
                                    )}
                                </Row>
                                :
                                <div className='not_exist_script_deploy'>
                                    <span>스크립트가 존재하지 않습니다.</span>
                                    <button onClick={() => history.push('/script')}>스크립트 생성</button>
                                </div>
                            }
                        </Fragment>
                    }
                </ListSkeleton>
            </Col>

        </Row>
    )
}


export default SettingList;
