export const OSKinds = [
    {
        display: 'Windows(BYOL)',
        name: 'windows',
        img: 'https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/9ea2ba0d-f46e-44fe-1403-c171ce1c2900/country'
    },
    // {
    //     display: 'Windows',
    //     name: 'windows',
    //     img: 'https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/9ea2ba0d-f46e-44fe-1403-c171ce1c2900/country'
    // },
    {
        display: 'Ubuntu',
        name: 'ubuntu',
        img: 'https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/104d53f4-c0af-4ac9-2b60-21b8682a3400/country'
    },
    {
        display: 'CentOS',
        name: 'centos',
        img: 'https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/d22bb64b-739c-4095-6ad2-8e9bc4324400/country'
    },
    {
        display: 'Debian',
        name: 'debian',
        img: 'https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/91235dd9-0c4e-4aa8-7c34-cb1cf272a200/country'
    },
    {
        display: 'Fedora',
        name: 'fedora',
        img: 'https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/51ea8165-57a2-43e8-7ff2-e08002c12900/country'
    },
    {
        display: 'AlmaLinux',
        name: 'almalinux',
        img: 'https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/b221d999-ad8a-4e77-67b6-cb26317aff00/country'
    },
    {
        display: 'FreeBSD',
        name: 'freebsd',
        img: 'https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/c8d49d8e-6bf6-4219-08a7-c1f0383b0b00/country'
    },
    {
        display: 'OpenBSD',
        name: 'openbsd',
        img: 'https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/37340499-335c-4a5c-08a8-73631f190f00/country'
    },
    {
        display: 'Rocky Linux',
        name: 'rockylinux',
        img: 'https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/35d9e2a0-66b5-4f35-0f34-9011e34a1800/country'
    },
    {
        display: 'VzLinux',
        name: 'vzlinux',
        img: 'https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/2cb5981d-fab1-4dc7-acc7-658742765f00/country'
    }
];
export const allOsList = [
    {
        "id": '194',
        "name": "Debian 8 i386 (jessie)",
        "arch": "i386",
        "family": "debian",
        "imgName": 'Debian',
    },
    // {
    //     "id": '124',
    //     "name": "Windows 2012 R2 x64",
    //     "arch": "x64",
    //     "family": "windows",
    //     "imgName": 'Windows',
    // },
    {
        "id": '159',
        "name": "Custom",
        "arch": "x64",
        "family": "iso",
        "imgName": 'Iso',
    },
    {
        "id": '164',
        "name": "Snapshot",
        "arch": "x64",
        "family": "snapshot",
        "imgName": 'Snapshot',
    },
    {
        "id": '167',
        "name": "CentOS 7 x64",
        "arch": "x64",
        "family": "centos",
        "imgName": 'CentOS',
    },
    {
        "id": '180',
        "name": "Backup",
        "arch": "x64",
        "family": "script",
        "imgName": 'Backup',
    },
    {
        "id": '186',
        "name": "Application",
        "arch": "x64",
        "family": "application"
    },
    {
        "id": '240',
        "name": "Windows 2016 x64",
        "arch": "x64",
        "family": "windows",
        "imgName": 'Windows',
    },
    {
        "id": '244',
        "name": "Debian 9 x64 (stretch)",
        "arch": "x64",
        "family": "debian",
        "imgName": 'Debian',

    },
    {
        "id": '270',
        "name": "Ubuntu 18.04 x64",
        "arch": "x64",
        "family": "ubuntu",
        "imgName": 'Ubuntu',
    },
    {
        "id": '327',
        "name": "FreeBSD 12 x64",
        "arch": "x64",
        "family": "freebsd",
        "imgName": 'FreeBSD',
    },
    {
        "id": '352',
        "name": "Debian 10 x64 (buster)",
        "arch": "x64",
        "family": "debian",
        "imgName": 'Debian',
    },
    {
        "id": '362',
        "name": "CentOS 8 x64",
        "arch": "x64",
        "family": "centos",
        "imgName": 'CentOS',
    },
    {
        "id": '371',
        "name": "Windows 2019 x64",
        "arch": "x64",
        "family": "windows",
        "imgName": 'Windows',
    },
    {
        "id": '381',
        "name": "CentOS 7 SELinux x64",
        "arch": "x64",
        "family": "centos",
        "imgName": 'CentOS',
    },
    {
        "id": '387',
        "name": "Ubuntu 20.04 x64",
        "arch": "x64",
        "family": "ubuntu",
        "imgName": 'Ubuntu',
    },
    {
        "id": '401',
        "name": "CentOS 8 Stream x64",
        "arch": "x64",
        "family": "centos",
        "imgName": 'CentOS',
    },
    {
        "id": '415',
        "name": "Fedora 33 x64",
        "arch": "x64",
        "family": "fedora",
        "imgName": 'Fedora',
    },
    {
        "id": '445',
        "name": "Ubuntu 21.04 x64",
        "arch": "x64",
        "family": "ubuntu",
        "imgName": 'Ubuntu',
    },
    {
        "id": '446',
        "name": "Fedora 34 x64",
        "arch": "x64",
        "family": "fedora",
        "imgName": 'Fedora',
    },
    {
        "id": '447',
        "name": "FreeBSD 13 x64",
        "arch": "x64",
        "family": "freebsd",
        "imgName": 'FreeBSD',
    },
    {
        "id": '448',
        "name": "Rocky Linux x64",
        "arch": "x64",
        "family": "rockylinux",
        "imgName": 'RockyLinux',
    },
    {
        "id": '450',
        "name": "OpenBSD 6.9 x64",
        "arch": "x64",
        "family": "openbsd",
        "imgName": 'OpenBSD',
    },
    {
        "id": '452',
        "name": "AlmaLinux x64",
        "arch": "x64",
        "family": "almalinux",
        "imgName": 'AlmaLinux',
    },
    {
        "id": '462',
        "name": "VzLinux x64",
        "arch": "x64",
        "family": "vzlinux",
        "imgName": 'VzLinux',
    },
    {
        "id": '477',
        "name": "Debian 11 x64 (bullseye)",
        "arch": "x64",
        "family": "debian",
        "imgName": 'Debian',
    },
    {
        "id": '516',
        "name": "Fedora 35 x64",
        "arch": "x64",
        "family": "fedora",
        "imgName": 'Fedora',
    },
    {
        "id": '517',
        "name": "Ubuntu 21.10 x64",
        "arch": "x64",
        "family": "ubuntu",
        "imgName": 'Ubuntu',
    },
    {
        "id": '518',
        "name": "OpenBSD 7.0 x64",
        "arch": "x64",
        "family": "openbsd",
        "imgName": 'OpenBSD',
    }
]
export const OSThreeTwo = [
    {
        "id": '194',
        "name": "Debian 8 i386 (jessie)",
        "arch": "i386",
        "family": "debian"
    },
]
export const OSSixFour =
    [
        // {
        //     "id": '124',
        //     "name": "Windows 2012 R2 x64",
        //     "arch": "x64",
        //     "family": "windows"
        // },
        // {
        //     "id": '159',
        //     "name": "Custom",
        //     "arch": "x64",
        //     "family": "iso"
        // },
        // {
        //     "id": '164',
        //     "name": "Snapshot",
        //     "arch": "x64",
        //     "family": "snapshot"
        // },
        {
            "id": '167',
            "name": "CentOS 7 x64",
            "arch": "x64",
            "family": "centos"
        },
        // {
        //     "id": '180',
        //     "name": "Backup",
        //     "arch": "x64",
        //     "family": "script"
        // },
        // {
        //     "id": '186',
        //     "name": "Application",
        //     "arch": "x64",
        //     "family": "application"
        // },
        {
            "id": '65a72045-b9b4-404f-bf20-bb592207127b',
            "name": "Windows 2016 x64",
            "arch": "x64",
            "family": "windows"
        },
        {
            "id": '244',
            "name": "Debian 9 x64 (stretch)",
            "arch": "x64",
            "family": "debian"
        },
        {
            "id": '270',
            "name": "Ubuntu 18.04 x64",
            "arch": "x64",
            "family": "ubuntu"
        },
        {
            "id": '327',
            "name": "FreeBSD 12 x64",
            "arch": "x64",
            "family": "freebsd"
        },
        {
            "id": '352',
            "name": "Debian 10 x64 (buster)",
            "arch": "x64",
            "family": "debian"
        },
        {
            "id": '362',
            "name": "CentOS 8 x64",
            "arch": "x64",
            "family": "centos"
        },
        {
            "id": 'ddb10d92-8f08-405d-8111-d581f119a598',
            "name": "Windows 2019 x64",
            "arch": "x64",
            "family": "windows"
        },
        {
            "id": '381',
            "name": "CentOS 7 SELinux x64",
            "arch": "x64",
            "family": "centos"
        },
        {
            "id": '387',
            "name": "Ubuntu 20.04 x64",
            "arch": "x64",
            "family": "ubuntu"
        },
        {
            "id": '401',
            "name": "CentOS 8 Stream x64",
            "arch": "x64",
            "family": "centos"
        },
        {
            "id": '415',
            "name": "Fedora 33 x64",
            "arch": "x64",
            "family": "fedora"
        },
        {
            "id": '445',
            "name": "Ubuntu 21.04 x64",
            "arch": "x64",
            "family": "ubuntu"
        },
        {
            "id": '446',
            "name": "Fedora 34 x64",
            "arch": "x64",
            "family": "fedora"
        },
        {
            "id": '447',
            "name": "FreeBSD 13 x64",
            "arch": "x64",
            "family": "freebsd"
        },
        {
            "id": '448',
            "name": "Rocky Linux x64",
            "arch": "x64",
            "family": "rockylinux"
        },
        {
            "id": '450',
            "name": "OpenBSD 6.9 x64",
            "arch": "x64",
            "family": "openbsd"
        },
        {
            "id": '452',
            "name": "AlmaLinux x64",
            "arch": "x64",
            "family": "almalinux"
        },
        {
            "id": '462',
            "name": "VzLinux x64",
            "arch": "x64",
            "family": "vzlinux"
        },
        {
            "id": '477',
            "name": "Debian 11 x64 (bullseye)",
            "arch": "x64",
            "family": "debian"
        },
        {
            "id": '516',
            "name": "Fedora 35 x64",
            "arch": "x64",
            "family": "fedora"
        },
        {
            "id": '517',
            "name": "Ubuntu 21.10 x64",
            "arch": "x64",
            "family": "ubuntu"
        },
        {
            "id": '518',
            "name": "OpenBSD 7.0 x64",
            "arch": "x64",
            "family": "openbsd"
        }
    ];
