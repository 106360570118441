import React, {Fragment, useEffect, useState} from "react";
import {Col, Row} from "reactstrap";
import {getFileManagerDir, uploadUrlFileManager} from "../../action";
import {checkErrorResponse, gameCheckErrorResponse} from "../../../../helpers/errorCheck";
import {useDispatch, useSelector} from "react-redux";
import FileManagerItem from "./FileManagerItem";
import FileManagerItemBox from "./FileManagerItemBox";
import FolderIcon from "@mui/icons-material/Folder";
import {isIterableArray} from "../../../../utils/common";
import AddBoxIcon from '@mui/icons-material/AddBox';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import {Tooltip} from "@material-ui/core";
import Swal from "sweetalert2";
import {notificationAlert} from "../../../../helpers/common";
import FileManagerSftpModal from "./FileManagerSftpModal";

const GameFileManager = ({instanceId}) => {
    const dispatch = useDispatch();
    const {sessionId, fileManagerDirs} = useSelector(state => state.gameDeploy);
    const [route, setRoute] = useState(['/']);
    const [dirIndex, setDirIndex] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const toggle = () => {
        setOpenModal(!openModal)
    }
    useEffect(() => {
        getFileManagerDir(sessionId, instanceId, '').then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [])


    const handleBackDir = () => {
        const newRoute = route.filter((value, index) => index !== (route.length - 1));
        setRoute(newRoute);
        getFileManagerDir(sessionId, instanceId, newRoute[newRoute.length - 1]).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }

    const handleUploadUrl = async () => {
        const {value: uploadUrl} = await Swal.fire({
            title: '업로드 하실 파일의 URL을 입력하여 주세요.',
            input: 'text',
            inputLabel: 'URL',
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'URL을 입력하여 주세요.'
                }
            }
        })
        if (uploadUrl) {
            let reloadRoute = route.filter(r => r !== '/');
            uploadUrlFileManager(sessionId, instanceId, uploadUrl, reloadRoute.join('')).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        if (response.request.data.result.Status) {
                            getFileManagerDir(sessionId, instanceId, reloadRoute.join('/')).then(response => {
                                if (response) {
                                    if (response.request.status === 200) {
                                        notificationAlert('알림', '파일 업로드에 성공 하였습니다.');
                                    }
                                }
                            }).catch(err => {
                                checkErrorResponse(err);
                            })
                        } else {
                            gameCheckErrorResponse(response.request.data);
                        }
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
            })
        }
    }


    return (
        <div className='game_file_manager_wrapper'>
            <Row className='file_manager_route'>
                <Col xs={8}>
                    <h4>{route.map((route, index) => {
                        return (
                            <span key={index}>{route === '/' ? route : `${route}/`}</span>
                        )
                    })}</h4>
                </Col>
                <Col xs={4} className='file_upload_btn_wrapper'>
                    <Tooltip title={'URL 파일 업로드'} placement="top">
                        <button onClick={handleUploadUrl}><AddBoxIcon/></button>
                    </Tooltip>
                    <Tooltip title={'SFTP 파일 업로드'} placement="top">
                        <button onClick={toggle}><DriveFolderUploadIcon/></button>
                    </Tooltip>
                </Col>
            </Row>
            <div className='file_manager_box'>
                <Row noGutters className='file_row'>
                    <Col className='p-0' xs={8}>
                        <div className='file_list'>
                            {route.length !== 1 &&
                            <Row onDoubleClick={handleBackDir} noGutters className='file_manager_item'>
                                <Col xs={1}>
                                    <div className='file_manager_icon'>
                                        <FolderIcon/>
                                    </div>
                                </Col>
                                <Col xs={11}>
                                    <div className='file_manager_item_info'>
                                        <span className='file_name'>...</span>
                                        <span className='file_type'>뒤로가기</span>

                                    </div>
                                </Col>
                            </Row>
                            }
                            {isIterableArray(fileManagerDirs) &&
                            <Fragment>
                                {fileManagerDirs.map((file, index) => {
                                    return (
                                        <FileManagerItem
                                            instanceId={instanceId}
                                            route={route}
                                            setRoute={setRoute}
                                            file={file}
                                            itemNo={index}
                                            setDirIndex={setDirIndex}
                                            key={index}/>
                                    )
                                })}
                            </Fragment>
                            }
                        </div>
                    </Col>
                    <Col className='p-0 dark_bg_color3' xs={4}>
                        <div className='file_info_box'>
                            <FileManagerItemBox route={route} itemNo={dirIndex}/>
                        </div>
                    </Col>
                </Row>
            </div>
            <FileManagerSftpModal modal={openModal} toggle={toggle}/>
        </div>
    )
}

export default GameFileManager;
