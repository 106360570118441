import {
    activationAutoBackupTxt,
    ActiveDdos,
    AttachIsoTxt,
    DelBackupTxt,
    deleteGameBackupTxt,
    delFileManagerTxt,
    DelServerTxt,
    DelWebHostingTxt,
    DetachIsoTxt,
    extendGameDateTxt, gamePlanUpgradeTxt,
    gameUpdateTxt,
    InactivationAutoBackupTxt,
    InActiveAccountTxt,
    InActiveDdos,
    Ipv4AssignTxt,
    Ipv4DeleteTxt,
    Ipv6AssignTxt,
    ReinstallServerTxt,
    RestartServerTxt,
    RestoreBackupTxt,
    restoreGameBackupTxt,
    RestoreSnapshotTxt,
    StopServerTxt,
    UpgradePlanTxt,
    UserLeftTxt,
    WebHostingUpgradePlanTxt
} from "../components/constant/Popover";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DiscFullIcon from "@mui/icons-material/DiscFull";
import DeleteIcon from "@mui/icons-material/Delete";
import BackupIcon from '@mui/icons-material/Backup';
import RestoreIcon from '@mui/icons-material/Restore';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import DeveloperBoardOffIcon from '@mui/icons-material/DeveloperBoardOff';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SecurityIcon from '@mui/icons-material/Security';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

export const serverConfirmError = (error) => {
    switch (error) {
        case 'Update data is not yet available, please wait a minute and try again.' :
            return '게임서버 환경 구성중입니다. 잠시후 업데이트를 진행하여 주세요.'

        default :
            return ''
    }

}

export const ServerConfirm = {
    start: {
        icon: <RestartAltIcon/>,
        title: '정말 서버를 시작 하시겠습니까?',
        successTitle: '서버가 성공적으로 시작되었습니다.',
        content: RestartServerTxt,
        btnTxt: '시작',
        toggleBtnTxt: ' 서버 시작 ',
        password: false,
    },
    restart: {
        icon: <RestartAltIcon/>,
        title: '정말 서버를 재시작 하시겠습니까?',
        successTitle: '서버가 성공적으로 재시작되었습니다.',
        content: RestartServerTxt,
        btnTxt: '재시작',
        toggleBtnTxt: ' 서버 재시작 ',
        password: false,
    },
    stop: {
        icon: <PowerSettingsNewIcon/>,
        title: '정말 서버를 종료 하시겠습니까?',
        successTitle: '서버가 성공적으로 종료되었습니다.',
        content: StopServerTxt,
        btnTxt: '종료',
        toggleBtnTxt: ' 서버 종료 ',
        password: false,
    },
    reinstall: {
        icon: <DiscFullIcon/>,
        title: '정말 서버를 재설치 하시겠습니까?',
        successTitle: '서버가 초기 상태로 재설치되고 있습니다.',
        content: ReinstallServerTxt,
        btnTxt: '초기화',
        toggleBtnTxt: ' 서버 초기화 ',
        password: true,
    },
    delete: {
        icon: <DeleteIcon/>,
        title: '정말 서버를 삭제 하시겠습니까?',
        successTitle: '서버가 삭제되고 있습니다.',
        content: DelServerTxt,
        btnTxt: '삭제',
        toggleBtnTxt: ' 서버 삭제 ',
        password: true,
    },
    deleteBackup: {
        icon: <DeleteIcon/>,
        title: '정말 백업을 삭제 하시겠습니까?',
        successTitle: '백업 삭제에 성공하였습니다.',
        content: DelBackupTxt,
        btnTxt: '삭제',
        toggleBtnTxt: ' 백업 삭제 ',
        password: true,
    },
    enableBackup: {
        icon: <BackupIcon/>,
        title: '자동 백업 기능을 활성화 하시겠습니까?',
        successTitle: '자동 백업 기능이 활성화 되였습니다.',
        content: activationAutoBackupTxt,
        btnTxt: '활성화',
        toggleBtnTxt: ' 자동 백업 활성화 ',
        password: false,
    },
    disableBackup: {
        icon: <BackupIcon/>,
        title: '정말 자동 백업 기능을 비활성화 하시겠습니까?',
        successTitle: '자동 백업 기능이 비활성화 되였습니다.',
        content: InactivationAutoBackupTxt,
        btnTxt: '비활성화',
        toggleBtnTxt: ' 자동 백업 비활성화 ',
        password: true,
    },
    restoreBackup: {
        icon: <RestoreIcon/>,
        title: '정말 백업으로 서버를 복원 하시겠습니까?',
        successTitle: '현재 서버가 복원되고 있습니다.',
        content: RestoreBackupTxt,
        btnTxt: '복원',
        toggleBtnTxt: ' 백업 복원 ',
        password: true,
    },
    restoreSnapshot: {
        icon: <RestoreIcon/>,
        title: '정말 스냅샷으로 서버를 복원 하시겠습니까?',
        successTitle: '현재 서버가 복원되고 있습니다.',
        content: RestoreSnapshotTxt,
        btnTxt: '복원',
        toggleBtnTxt: ' 스냅샷 복원 ',
        password: true,
    },
    deleteIpv4: {
        icon: <DeleteIcon/>,
        title: '정말 연결된 IP를 삭제 하시겠습니까?',
        successTitle: 'IP 삭제에 성공하였습니다.',
        content: Ipv4DeleteTxt,
        btnTxt: '삭제',
        toggleBtnTxt: ' IP 삭제 ',
        password: true,
    },
    addIpv4: {
        icon: <ControlPointIcon/>,
        title: '정말 IPv4 1개를 추가로 할당하시겠습니까?',
        successTitle: '신규 IPv4 추가 할당에 성공하였습니다.',
        content: Ipv4AssignTxt,
        btnTxt: '추가',
        toggleBtnTxt: ' IPv4 추가 ',
        password: true,
    },
    enableIpv6: {
        icon: <ControlPointIcon/>,
        title: '정말 IPv6 1개를 추가로 할당하시겠습니까?',
        successTitle: '신규 IPv6 추가 할당에 성공하였습니다.',
        content: Ipv6AssignTxt,
        btnTxt: '추가',
        toggleBtnTxt: ' IPv6 추가 ',
        password: true,
    },
    isoMount: {
        icon: <DeveloperBoardIcon/>,
        title: '해당 ISO 파일을 서버에 연결하시겠습니까?',
        successTitle: 'ISO 파일을 성공적으로 연결하였습니다.',
        content: AttachIsoTxt,
        btnTxt: '연결',
        toggleBtnTxt: ' ISO 연결 ',
        password: true,
    },
    isoUnMount: {
        icon: <DeveloperBoardOffIcon/>,
        title: '연결된 ISO 파일의 연결을 해제하시겠습니까?',
        successTitle: 'ISO 파일 연결 해제에 성공하였습니다.',
        content: DetachIsoTxt,
        btnTxt: '해제',
        toggleBtnTxt: ' ISO 연결 해제 ',
        password: true,
    },
    changePlan: {
        icon: <AttachMoneyIcon/>,
        title: '정말 요금제를 변경하시겠습니까?',
        successTitle: '성공적으로 요금제를 변경하였습니다.',
        content: UpgradePlanTxt,
        btnTxt: '변경',
        toggleBtnTxt: ' 요금제 변경 ',
        password: false,
    },
    enableDdos: {
        icon: <SecurityIcon/>,
        title: '10G DDoS 방어를 활성화 하시겠습니까?',
        successTitle: '10G DDoS 방어를 성공적으로 활성화하였습니다.',
        content: ActiveDdos,
        btnTxt: '활성화',
        toggleBtnTxt: ' 10G DDoS 방어 활성화 ',
        password: true,
    },
    disableDdos: {
        icon: <RemoveModeratorIcon/>,
        title: '10G DDoS 방어를 비활성화 하시겠습니까?',
        successTitle: '10G DDoS 방어를 성공적으로 비활성화하였습니다.',
        content: InActiveDdos,
        btnTxt: '비활성화',
        toggleBtnTxt: ' 10G DDoS 방어 비활성화 ',
        password: true,
    },
    inActiveUser: {
        title: '계정을 비활성화 하시겠습니까?',
        successTitle: '계정을 성공적으로 비활성화하였습니다.',
        content: InActiveAccountTxt,
        btnTxt: '비활성화',
        toggleBtnTxt: ' 계정비활성화 바로가기 ',
        password: true,
    },
    userLeft: {
        title: '회원 탈퇴를 하시겠습니까?',
        successTitle: '회원 탈퇴에 성공하였습니다.',
        content: UserLeftTxt,
        btnTxt: '탈퇴',
        toggleBtnTxt: '회원탈퇴 바로가기',
        password: true,
    },
    webHostingDel: {
        icon: <DeleteIcon/>,
        title: '정말 웹 호스팅 서버를 삭제하시겠습니까?',
        successTitle: '웹 호스팅 서버 삭제를 성공하였습니다.',
        content: DelWebHostingTxt,
        btnTxt: '삭제',
        toggleBtnTxt: '서버 삭제',
        password: true,
    },
    webHostingChangePlan: {
        icon: <AttachMoneyIcon/>,
        title: '정말 요금제를 변경하시겠습니까?',
        successTitle: '성공적으로 요금제를 변경하였습니다.',
        content: WebHostingUpgradePlanTxt,
        btnTxt: '변경',
        toggleBtnTxt: ' 요금제 변경 ',
        password: false,
    },
    delFileManager: {
        icon: '',
        title: '정말 파일을 삭제하시겠습니까?',
        successTitle: '성공적으로 삭제하였습니다.',
        content: delFileManagerTxt,
        btnTxt: '삭제',
        toggleBtnTxt: ' 파일 삭제',
        password: false,
    },
    gameBackupRestore: {
        icon: '',
        title: '정말 복원을 진행하시겠습니까?',
        successTitle: '성공적으로 복원되었습니다.',
        content: restoreGameBackupTxt,
        btnTxt: '복원',
        toggleBtnTxt: ' 백업 복원',
        password: false,
    },
    gameBackupDelete: {
        icon: '',
        title: '정말 백업을 삭제하시겠습니까?',
        successTitle: '성공적으로 삭제하였습니다.',
        content: deleteGameBackupTxt,
        btnTxt: '삭제',
        toggleBtnTxt: ' 백업 삭제',
        password: true,
    },
    extendGameDate: {
        icon: <CalendarTodayIcon/>,
        title: '정말 유효기간을 연장하시겠습니까?',
        successTitle: '성공적으로 연장하였습니다.',
        content: extendGameDateTxt,
        btnTxt: '연장',
        toggleBtnTxt: '유효기간 연장',
        password: true,
    },
    gameUpdate: {
        icon: <SystemUpdateAltIcon/>,
        title: '정말 업데이트를 진행하시겠습니까?',
        successTitle: '성공적으로 업데이트 되었습니다.',
        content: gameUpdateTxt,
        btnTxt: '업데이트',
        toggleBtnTxt: '업데이트',
        password: false,
    },
    gameUpgradePlan: {
        icon: <AttachMoneyIcon/>,
        title: '정말 요금제를 변경하시겠습니까?',
        successTitle: '성공적으로 요금제를 변경하였습니다.',
        content: gamePlanUpgradeTxt,
        btnTxt: '변경',
        toggleBtnTxt: ' 요금제 변경 ',
        password: false,
    },

}
