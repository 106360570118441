import * as types from './actionTypes';
import {regions} from "../../datas/region";

let initialState = {
    server: {
        isSet: false,
        type: 'server'
    },
    cpanel: {
        region: {
            isSet: false,
            type: 'us'
        },
        price: {
            isSet: false,
            type: {
                cp_cpu: "0",
                cp_day_cost: 0,
                cp_day_traffic: 0,
                cp_ddos: null,
                cp_disk: 0,
                cp_domain_limit: 0,
                cp_id: "",
                cp_memory: 0,
                cp_monthly_cost: 0,
                cp_monthly_traffic: 0,
                cp_no: 0,
                cp_region: ""
            }
        },
        planList: [],
    },
    vultr: {
        service: {
            isSet: false,
            type: 'vhf'
        },
        region: {
            isSet: false,
            type: {
                continent: "Asia",
                country: "South Korea",
                vr_city: "Seoul",
                vr_country: "KR",
                vr_id: "icn",
                vr_img: "https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/84cb4123-971b-42a7-90d5-4a2ac7596900/country",
                vr_no: 7,
                vr_options_block: false,
                vr_options_ddos: true
            }
        },
        OS: {
            isSet: false,
            type: {
                id: ''
            },
            osType: ''
        },
        price: {
            isSet: false,
            type: {
                vp_bandwidth: 0,
                vp_disk: 0,
                vp_disk_count: 0,
                vp_day_cost_backup_kr: 0,
                vp_day_cost_event_kr: 0,
                vp_day_cost_kr: 0,
                vp_id: "",
                vp_monthly_cost_backup_kr: 0,
                vp_monthly_cost_event_kr: 0,
                vp_monthly_cost_kr: 0,
                vp_ram: 0,
                vp_type: "",
                vp_vcpu_count: 0
            },
            monthPrice: 0,
            dayPrice: 0,
        },
        setting: {
            isSet: false,
            type: {
                additional: {
                    ipv6: false,
                    autoBackup: false,
                    ddos: false,
                    privateNetwork: false

                },
                price: {
                    month: {
                        autoBackup: 0,
                        ddos: 0,
                        ipv6: 0,
                    },
                    day: {
                        autoBackup: 0,
                        ddos: 0,
                        ipv6: 0,
                    }
                },
                scripts: {
                    vsc_id: ''
                },
                qty: 1
            }
        },
        snapShot: [],
        planList: [],
        scripts: []
    }
}


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case types._SET_SERVER: {
            state.server.isSet = true;
            state.server.type = action.server;

            return {
                ...state
            };
        }

        case types._SET_SERVICE: {
            state.vultr.service.isSet = true;
            state.vultr.service.type = action.service;

            return {
                ...state
            };
        }

        case types._SET_REGION: {
            const region = regions.filter(region => region.vr_id === action.region);
            state.vultr.region.isSet = true;
            state.vultr.region.type = region[0];
            return {
                ...state
            };
        }

        case types._SET_OS: {
            state.vultr.OS.isSet = true;
            state.vultr.OS.type = action.OS;
            state.vultr.OS.osType = action.osType;
            return {
                ...state
            };
        }

        case types._SET_PRICE: {
            state.vultr.price.isSet = true;
            state.vultr.price.type = action.price;
            state.vultr.price.monthPrice = action.price.vp_monthly_cost_event_kr;
            state.vultr.price.dayPrice = action.price.vp_day_cost_event_kr;
            return {
                ...state
            };
        }

        case types._SET_ADDITIONAL: {
            state.vultr.setting.type.additional[action.additionalType] = action.use;
            if (action.use) {
                state.vultr.setting.type.price.month[action.additionalType] = action.monthPrice;
                state.vultr.setting.type.price.day[action.additionalType] = action.dayPrice;
            } else {
                state.vultr.setting.type.price.month[action.additionalType] = 0;
                state.vultr.setting.type.price.day[action.additionalType] = 0;
            }

            return {
                ...state
            }
        }

        case types._SET_SCRIPT: {
            if (state.vultr.setting.type.scripts.vsc_id === action.script.vsc_id) {
                state.vultr.setting.type.scripts = {vsc_id: ''}
            } else {
                state.vultr.setting.type.scripts = action.script;
            }

            return {
                ...state
            }
        }

        case types._SET_SETTING: {
            state.vultr.setting.isSet = true;
            state.vultr.setting.type = action.setting;
            return {
                ...state
            };
        }

        case types._GET_PLAN: {
            state.vultr.planList = action.plan;
            return {
                ...state,
            }
        }


        case types._INIT_ADDITIONAL: {
            state.vultr.setting.type.price.month.autoBackup = 0;
            state.vultr.setting.type.price.month.ddos = 0;
            state.vultr.setting.type.price.day.autoBackup = 0;
            state.vultr.setting.type.price.day.ddos = 0;
            return {
                ...state
            }
        }

        case types._SET_CPANEL_REGION: {
            state.cpanel.region.isSet = true;
            state.cpanel.region.type = action.region;

            return {
                ...state
            };
        }

        case types._SET_CPANEL_PRICE: {
            state.cpanel.price.isSet = true;
            state.cpanel.price.type = action.price;

            return {
                ...state
            };
        }

        case types._SET_CPANEL_PLAN: {
            state.cpanel.planList = action.plan;

            return {
                ...state
            }
        }

        default:
            return state;
    }

}
