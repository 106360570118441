import * as types from './actionTypes';
import axios from "axios";

export const getSnapShot = async () => {
    const getSnapShotRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/servermv/snapshots`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._GET_SNAPSHOT,
        request: getSnapShotRequest
    }
}

export const addSnapShot = async (post) => {
    const addScriptsRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/servermv/snapshots`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._ADD_SNAPSHOT,
        request: addScriptsRequest
    }
}

export const updateSnapShot = async (post, id) => {
    const updateScriptsRequest = await axios.put(`https://${process.env.REACT_APP_API_SERVER}/servermv/snapshots/${id}`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._UPDATE_SNAPSHOT,
        request: updateScriptsRequest,
        id: id,
        post: post
    }
}

export const deleteSnapShot = async (id) => {
    const deleteScriptsRequest = await axios.delete(`https://${process.env.REACT_APP_API_SERVER}/servermv/snapshots/${id}`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._DEL_SNAPSHOT,
        request: deleteScriptsRequest,
        id: id
    }
}



export const getBackup = async () => {
    const getBackupRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/servermv/backups`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._GET_BACKUP,
        request: getBackupRequest
    }
}

export const getBackupSchedule = async (id) => {
    return await axios.get(`https://${process.env.REACT_APP_API_SERVER}/servermv/backups/${id}/backup-schedule`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });
}

export const delBackup = async (id) => {
    const delBackupRequest = await axios.delete(`https://${process.env.REACT_APP_API_SERVER}/servermv/snapshots/${id}`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._DEL_BACKUP,
        request: delBackupRequest,
        id: id
    }
}
