import React from "react";
import {Col, Row} from "reactstrap";
import {isIterableArray} from "../../../../utils/common";
import {ValTypeForm} from "./ValTypeForm";
import {useSelector} from "react-redux";
import {minecraftSettingDesc, minecraftSettingTitle} from "../../../../datas/TranslatorTxt";


const PerformanceSetting = () => {
    const {gameSettingNodes} = useSelector(state => state.gameDeploy);

    return (
        <div className='setting_panel_wrapper'>
            {isIterableArray(gameSettingNodes) &&
            <Row>
                {gameSettingNodes.map((settingNode, index) => {
                        if (settingNode.Name !== 'Max Players') {
                            return (
                                <Col key={index} xs={4}>
                                    <div className='setting_panel_item'>
                                        <div className={'setting_description'}>
                                            <span>{minecraftSettingTitle[settingNode.Name]}</span>
                                            <p>{minecraftSettingDesc[settingNode.Description]}</p>
                                        </div>
                                        <div className='setting_form'>
                                            <ValTypeForm
                                                node={settingNode.Node}
                                                tag={settingNode.Tag}
                                                type={settingNode.ValType}
                                                defaultValue={settingNode.CurrentValue}
                                                EnumValues={settingNode.EnumValues}/>
                                        </div>
                                    </div>
                                </Col>
                            )
                        }

                    }
                )}

            </Row>
            }
        </div>
    )
}

export default PerformanceSetting;
