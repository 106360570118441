import React, {Fragment} from "react";
import {ServerConfirm, serverConfirmError} from "../../datas/ServerConfirm";
import {Button} from "@mui/material";
import {checkPassword} from "../Product/action";
import Swal from "sweetalert2";
import {checkErrorResponse} from "../../helpers/errorCheck";

const ConfirmModal = ({onlyIcon, handleEvent, btnDisabled, type, ...rest}) => {

    const handleCheckPassword = () => {
        Swal.fire({
            title: '추가 인증이 필요합니다',
            input: 'password',
            inputAttributes: {
                placeHolder: '계정 비밀번호를 입력해주세요',
                autocapitalize: 'off'
            },
            showCancelButton: true,
            cancelButtonText: '취소',
            confirmButtonText: '인증',
            showLoaderOnConfirm: true,
            preConfirm: (password) => {
                return checkPassword({password}).then(response => {
                    if (response) {
                        if (response.status === 200) {
                            return response.status
                        }
                    }
                }).catch(err => {
                    if (err) {
                        checkErrorResponse(err);
                        return err.response.status
                    }
                })

            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(result => {
            if (result) {
                if (result.isConfirmed && (result.value === 200)) {
                    Swal.fire({
                        title: ServerConfirm[type].title,
                        text: ServerConfirm[type].content,
                        showCancelButton: true,
                        cancelButtonText: '취소',
                        confirmButtonText: ServerConfirm[type].btnTxt,
                        showLoaderOnConfirm: true,
                        preConfirm: async () => {
                            return await handleEvent()
                        },
                        allowOutsideClick: () => !Swal.isLoading()
                    }).then(result => {
                        if (result) {
                            if (result.isConfirmed && result.value) {
                                Swal.fire({
                                    title: ServerConfirm[type].successTitle,
                                    confirmButtonText: '닫기'
                                })
                            }
                        }
                    })
                }
            }
        })
    }

    const showModal = () => {
        Swal.fire({
            title: ServerConfirm[type].title,
            text: ServerConfirm[type].content,
            showCancelButton: true,
            cancelButtonText: '취소',
            confirmButtonText: ServerConfirm[type].btnTxt,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return await handleEvent()
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(result => {
            if (result) {
                if (result.isConfirmed && result.value) {
                    Swal.fire({
                        title: ServerConfirm[type].successTitle,
                        confirmButtonText: '닫기'
                    })
                }
            }
        }).catch(err => {
            if (err) {
                if (!err.response) {
                    Swal.fire({
                        title: '오류',
                        text: serverConfirmError(err),
                        confirmButtonText: '닫기'
                    })
                }
            } else {
                Swal.fire({
                    title: "서비스 처리에 문제가 발생하였습니다.",
                    confirmButtonText: '닫기'
                })
            }
        })

    }

    return (
        <Fragment>
            <Button {...rest} disabled={btnDisabled} onClick={ServerConfirm[type].password ? handleCheckPassword : showModal}>
                {ServerConfirm[type].icon}
                {!onlyIcon &&
                <strong style={{marginLeft: '0.4rem'}}>
                    {
                        ServerConfirm[type].toggleBtnTxt}
                </strong>
                }
            </Button>
        </Fragment>
    )
}


export default ConfirmModal
