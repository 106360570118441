import axios from "axios";

export const checkRegion = async (id) => {
    return await axios.get(`https://${process.env.REACT_APP_API_SERVER}/servermv/regions/${id}/availability`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });
}


export const checkCpanelRegion = async (regionId) => {
    return await axios.get(`https://${process.env.REACT_APP_API_SERVER}/cpanel/${regionId}/plan`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });
}
