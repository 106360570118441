import React from "react";


export const CheckTriangle = ({width, height}) => {
    const triangleStyle = {
        width: 0,
        height: 0,
        borderLeft: `${width}px solid #1F74FF`,
        borderTop: `${height}px solid transparent`,
        transform : 'rotate(180deg)',
        position : 'absolute',
        right : 0,
        top : 0
    }
    return (
        <div style={triangleStyle} className='check_triangle_wrapper'/>
    )
}
