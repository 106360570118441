import React, {Fragment, useState} from "react";
import {Col, Row} from "reactstrap";
import {useSelector} from "react-redux";
import CharginMoreModal from "../CharginMoreModal";

const checkBillStatus = (status) => {
    switch (status) {
        case 'DONE':
            return '결제완료'
        case 'WAITING_FOR_DEPOSIT':
            return '입금 대기중'
        case 'CANCELED':
            return '결제취소'
        default:
            return ''
    }
}


const MobileBillTable = () => {
    const {paymentRecord} = useSelector(state => state.bill.billInfo);

    return (
        <div className='mobile_table_wrapper'>
            {paymentRecord.map((bill, index) => {
                return (
                    <Fragment key={index}>
                        <Row className='mobile_item align-items-center'>
                            <Col className='p-0' xs={8}>
                                <span className='fw-bold d-block'>{`${((bill.totalAmount * 10) / 11).toLocaleString()}포인트`}</span>
                                <span className={'small_font'}>{bill.approvedAt}</span>
                            </Col>
                            <Col style={{textAlign: 'right'}} className='p-0' xs={4}>
                                <span className='fw-bold d-block'>{bill.totalAmount.toLocaleString()}원</span>
                                <span className={'small_font'}>{bill.method}</span>
                            </Col>
                            <Col style={{textAlign: 'left'}} xs={8} className='p-0'>
                                <span>{checkBillStatus(bill.status)}</span>
                            </Col>
                            <Col style={{textAlign : 'right'}} xs={4} className='p-0'>
                                <CharginMoreModal mobile={true} payment={bill}/>
                            </Col>
                        </Row>
                    </Fragment>
                )
            })}
        </div>
    )
}

export default MobileBillTable;
