import axios from "axios";

const backEndCaptcha = async (token) => {
    const captchaRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/auth/captcha`, {token: token}, {
        headers: {
            "Content-Type": 'application/json',
        }
    });
    return captchaRequest;
}

const GoogleReCaptcha = () => {
    return new Promise((resolve, reject) => {
        window.grecaptcha.ready(function () {
            window.grecaptcha.execute(process.env.REACT_APP_CAPCHA_KEY, {action: 'submit'}).then(function (token) {
                if (token) {
                    backEndCaptcha(token).then(result => {
                        if (result.status === 200 && result.data.captcha.success) {
                            if (result.data.captcha.score >= 0.7) {
                                resolve('human');
                            }
                        }
                        reject('robot');
                    }).catch(err => {
                        reject(err);
                    })
                }
            }).catch(err => {
                reject(err);
            });
        });
    })
};
export default GoogleReCaptcha;
