import {badWords} from "../datas/badWords";
import moment from 'moment';
import {allOsList} from "../datas/OS";
import {regions} from "../datas/region";

export const showConsoleArt = () => {
    console.log('%cSERVER24', 'background: #222; color: #4D99FF; width: 100%; font-size: 60px; font-weight : bold;');
}


export const isBadWord = (des) => {
    const description = des;
    let isInclude = false;
    badWords.forEach(word => {
        if (description.includes(word)) {
            isInclude = true
        }
    })
    return isInclude;
}
export const randomNum = () => {
    let value = ""
    for (let i = 0; i < 5; i++) {
        value += random(0, 9)
    }

    return value;
}

function random(n1, n2) {
    return parseInt(Math.random() * (n2 - n1 + 1)) + n1;
}

export const timeToString = (time) => {
    return moment(time).format('YYYY-MM-DD HH:mm:ss')
}

export const isIterableArray = array => Array.isArray(array) && !!array.length;

export const maskingPhone = function (str) {
    let originStr = "" + str;
    let phoneStr;
    let maskingStr;

    if (originStr.toString().split('-').length !== 3) {
        // 1) -가 없는 경우
        phoneStr = originStr.length < 11 ? originStr.match(/\d{10}/gi) : originStr.match(/\d{11}/gi);
        if (!phoneStr) {
            return originStr;
        }
        if (originStr.length < 11) {
            // 1.1) 0110000000
            maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{3})(\d{4})/gi, '$1-***-$3'));
        } else {
            // 1.2) 01000000000
            maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{4})(\d{4})/gi, '$1-****-$3'));
        }
    } else {
        // 2) -가 있는 경우
        phoneStr = originStr.match(/\d{2,3}-\d{3,4}-\d{4}/gi);
        if (!phoneStr) {
            return originStr;
        }
        if (/-[0-9]{3}-/.test(phoneStr)) {
            // 2.1) 00-000-0000
            maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/-[0-9]{3}-/g, "-***-"));
        } else if (/-[0-9]{4}-/.test(phoneStr)) {
            // 2.2) 00-0000-0000
            maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/-[0-9]{4}-/g, "-****-"));
        }
    }
    return maskingStr;
}

export const convertToGB = (byte) => {
    return (parseInt(byte) / (1000 * 1000 * 1000)).toFixed(2);
}

export const getOsImg = (osId) => {
    if (osId) {
        const osInfo = allOsList.filter(os => parseInt(os.id) === osId);
        return osInfo[0].img;
    }

}
export const getOsBigImg = (osId) => {
    if (osId) {
        const osInfo = allOsList.filter(os => parseInt(os.id) === osId);
        let osImgName;
        if (osInfo[0].imgName === 'Snapshot' || osInfo[0].imgName === 'Backup' || osInfo[0].imgName === 'Iso') {
            osImgName = `${osInfo[0].imgName}.svg`;
        } else {
            osImgName = `${osInfo[0].imgName}.png`;

        }
        return osImgName;
    }

}
export const getRegionInfo = (regionId) => {
    if (regionId) {
        const regionInfo = regions.filter(region => region.vr_id === regionId);
        return regionInfo[0];
    }

}

export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    if (bytes === '0') return '0 Bytes';
    if (parseInt(bytes) < 1) return `${bytes} Bytes`;
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


export const getCookie = function (name) {
    var value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return value ? value[2] : null;
};

export const setCookie = (cookie) => {
    document.cookie = cookie;
}


export const convertPlanTxt = (plan) => {
    return `${plan.vp_vcpu_count} vCPU,${plan.vp_ram} MB RAM, ${plan.vp_disk} GB SSD,${plan.vp_bandwidth / 1024} TB Transfer`
}

export const toHHMMSS = (sec_num) => {

    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    return minutes + ':' + seconds;
}

export const isGameMode = (mode) => {
    return mode === 'game'
}

export const isGameModeTxt = (mode) => {
    return mode === 'game' ? 'dark_mode_txt' : ''
}
