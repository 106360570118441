export const use_agreement =
    '제 1장 총 칙\n' +
    '\n' +

    '제 1조 [ 목 적 ]\n' +
    '\n' +

    '이 약관은 서버 24(server24.kr)(이하 “당사”)가 제공하는 서버호스팅, 코 로케이션, 웹호스팅, 디도스 방어 및 보안 관제 서비스 등의 “당사”가 제공하는 모든 서비스를 사용함에 있어, “당사”와 “사용자”(이하 “이용자”)의 권리와 의무 및 책임 그리고 기타 제반 사항을 규정함을 목적으로 합니다.\n' +
    '\n' +
    ' \n' +
    '제 2조 [ 용어의 정의 ]\n' +
    '\n' +
    ' \n' +
    '\n' +
    '이 약관에서 사용되는 용어의 정의는 다음과 같습니다. 그리고 다음 항목에 정해지지 않은 용어는 관계 법령이나 홈페이지에 명시된 서비스별 안내에 의하여 유추하여 정의합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '1) 서버(Server) : 인터넷으로 연결된 클라이언트 프로그램으로부터 데이터를 주고받기 위한 하드웨어와 소프트웨어의 총칭을 말합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '2) 인터넷 데이터 센터(Internet Data Center : IDC) : 서버가 대용량의 인터넷 백본에 접속토록 하여 주며, 운영 및 관리가 용이하도록 만들어진 서버 전용 건물 및 그 시설 일체를 말합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '3) 상면(Rack) : 이용자의 장비(서버 및 스위칭 허브 등)를 보관하는 공간을 말하며, 단위로는 U(Unit), Rack(1/8, 1/4, 1/2, full), 평 혹은 제곱미터 등의 단위를 사용합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '4) 회선(Line) : 인터넷과 접속되도록 "당사"가 제공하는 물리적인 회선을 말하며, 단위로는 속도의 단위인 Mbit/sec, GBit/sec 등을 사용합니다.\n' +
    '\n' +
    '5) 트래픽 량(파일 전송량) : 클라이언트 혹은 관련 프로그램이 일정 시간 동안 서버로 데이터를 보내거나(Upload) 내려받은(Download) 데이터의 양을 말하며, 단위로는 Mbyte/sec, Gbyte/sec를 사용합니다. 또한 트래픽 양에는 실제로 통신을 하면서 네트워크 혹은 장비를 점유 또는 경유한 실제 사용량을 말합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '6) 대역폭(Bandwidth) : 클라이언트 프로그램이 물리적인 회선을 이용할 때 데이터를 주고받기 위하여 실제로 점유하는 크기를 말하며, 이의 측정은 사용한 트래픽 량을 일정 시간 단위로 이동 평균 그래프를 그리는 경우(Multi Router Traffic Grapher : MRTG)의 폭으로 하며, 단위로는 Mbit/sec, Gbit/sec 등을 사용합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '7) 코로케이션(Colocation) 서비스 : 이용자 소유의 서버와 네트워크 장비를 IDC를 이용하여 인터넷에 접속할 수 있도록 회선과 상면을 제공하는 서비스를 말합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '8) 서버 호스팅(Server Hosting) 서비스 : 서버를 보유하고 있지 않은 이용자에 대하여 서버를 판매 혹은 임대하여 주고 이를 이용하여 인터넷을 이용한 서비스를 할 수 있도록 회선과 상면을 제공하는 서비스를 말합니다. 서버 호스팅에는 하나의 물리 서버를 가상화 기술을 사용하여 제공하는 가상 서버 호스팅, 실제 물리 서버를 단독으로 사용하는 단독 서버 호스팅(베어메탈 서버 호스팅), 웹사이트 등을 운영하기 위한 웹호스팅, 데이터베이스를 운영하기 위한 데이터베이스 호스팅, 서버를 이용자가 관리하지 않는 서버리스 호스팅 등을 모두 포함합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '9) 부가 서비스 : 이용자가 서비스를 이용하여 서버의 운영을 원활하게 하기 위하여 당사에서 기본으로 제공 서비스 이외의 제공되는 서비스를 말합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '10) 기술 지원 서비스 : 이용자가 서버의 기술적인 관리 업무를 당사에 위탁하는 부가 서비스를 말하며, 서비스의 기본 사항과 옵션 사항은 홈페이지에 명시되어 있습니다.\n' +
    '\n' +
    '11) 보안 서비스 : 이용자의 서버가 불순한 접속자에 의하여 침입되거나 서비스 거부 공격 등으로 인해 서비스 운영을 방해받지 않도록 제공하는 부가 서비스를 말하며, 기본 사항과 옵션 사항은 홈페이지에 명시되어 있습니다. 자세한 내용이 필요한 경우 당사에 상주하는 전문 기술자를 통해 상세한 설명을 들을 수 있습니다. 이 서비스에는 디도스 방어 혹은 관련 보안 관제 서비스 등을 포함하고 있습니다.\n' +
    '\n' +
    '12) 백업(Backup) 서비스 : 데이터 저장 장치의 고장, 불순한 침입 혹은 서버 운영자의 실수에 의한 데이터의 망실에 대비하여, 여벌의 데이터 복제본을 별도의 저장 장치에 보관하여 주는 서비스를 말하며, 기본 사항과 옵션 사항은 홈페이지에 명시되어 있습니다.\n' +
    '\n' +
    '13) 서비스 이용요금 : 본 서비스 계약을 수행하기 위하여 이용자에게 청구하는 당사의 제반 서비스 비용으로, 계약된 정규성 경비와 추가적인 트래픽 이용료, 서버의 설치, 기술 지원 등 계약서 내에 규정되어 있지는 않으나 쌍방 합의에 의하여 수행된 추가적인 서비스 업무에 대한 실비 기준의 비정규성 경비를 말합니다.\n' +
    '\n' +
    '\n' +
    '14) 제휴 : 제휴는 “당사”와 직접 또는 간접적으로 맺어진 공동 관리 및 운영을 말합니다.\n' +
    '\n' +
    '15) 계정 : 계정은 “당사”가 이용자에게 할당한 정보를 의미합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '16) 기밀 정보 : 기밀 정보는 한 당사자가 본 계약에 따라 상대방이 일부 정보를 기밀로 유지하며 간주하는 것을 의미합니다. 사용자 데이터는 기밀 정보로 간주합니다. 사용자 데이터는 계약의 종료 시점까지 암호화하여 보관합니다. 사용자의 요청이 있다면 사용자와 관련된 기밀 정보를 파기합니다.\n' +
    '\n' +
    '17) 피드백 : 피드백은 사용자가 “당사”에게 제공하는 문제 보고 나 의견, 제안을 의미합니다.\n' +
    '\n' +
    '18) 비용 : 비용은 각각의 서비스에 해당하는 이용요금이나 세금에 의해 발생한 요금을 의미합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '19) 기타 약관에 위반되는 행위 : 기타 약관에 위반되는 행위는 서비스의 사용으로 고장, 상해 또는 환경 피해를 초래할 수 있는 핵 시설, 항공 교통관제, 또는 이와 관련된 서비스의 사용을 의미합니다. 일부 “당사”가 제공한 별도 약관도 의미합니다.\n' +
    '\n' +
    '20) 지적 재산권 : 지적 재산권은 현재와 미래의 “당사”의 특허, 기술력, 영업 비밀, 상표에 따른 권리나 법, 기타 유사한 권리를 의미합니다.\n' +
    '\n' +
    '\n' +
    '21) 법적 절차 : 법적 절차는 법률의 규제, 법원 명령, 소환장, 영장, 정부 규제 또는 기관의 요청, 또 다른 유효한 법적 권한, 법적 절차 또는 이와 유사한 과정에서 만들어진 데이터 공개 요청을 의미합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    ' \n' +
    '\n' +
    ' \n' +
    '\n' +
    '제 3조 [ 약관의 명시 및 변경 ]\n' +
    '\n' +
    ' \n' +
    '\n' +
    '1. 이 약관은 당사의 홈페이지(https://www.server24.kr)에 공지함으로써 효력이 발생합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '2. 당사가 약관을 개정할 경우에는 시행일자 및 개정사유를 명시하여 현행 약관과 함께 당사의 홈페이지에 게시하며 홈페이지에 가입된 이용자에게 공지하여야 합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '3. 이용자는 변경된 약관에 동의하지 않을 경우 이의를 제기할 수 있으며, 약관의 효력 발생일로부터 7일 이후에도 거부의사를 표시하지 아니하고 서비스를 계속 사용할 경우 약관의 변경 사항에 동의한 것으로 간주됩니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '1. 당사가 약관을 개정할 경우에는 시행일자 및 개정 사유를 명시하여 현행 약관과 함께 당사의 홈페이지에 게시하며 홈페이지에 가입된 이용자에게 공지하여야 합니다.\n' +
    '\n' +
    '2. 이용자는 변경된 약관에 동의하지 않을 경우 이의를 제기할 수 있으며, 약관의 효력 발생일로부터 7일 이후에도 거부 의사를 표시하지 아니하고 서비스를 계속 사용할 경우 약관의 변경 사항에 동의한 것으로 간주됩니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    ' \n' +
    '\n' +
    ' \n' +
    '\n' +
    '제 4조 [ 약관 외 적용 ]\n' +
    '\n' +
    ' \n' +
    '\n' +
    '이 약관에 명시되지 않은 부분은 관계법령 및 홈페이지에 명시된 서비스별 안내에 따릅니다.\n' +
    '\n' +
    '\n' +
    ' \n' +
    '\n' +
    '제 2장 서비스 이용계약\n' +
    '\n' +
    ' \n' +
    '\n' +
    '제 5조 [ 자율 계약의 원칙 ]\n' +
    '\n' +
    ' \n' +
    '\n' +
    '이용자는 반드시 타인의 강요나 강매가 아닌 자의로만 서비스 이용계약을 체결할 수 있으며, 계약 체결 전에 미리 이용약관을 읽고 동의한 후에 서비스 계약을 신청해야 합니다. 만약 이용자가 당사의 서비스를 가입/신청/결제/사용 등의 행위를 한 경우에는 본 이용약관을 모두 숙지하고 동의한 것으로 간주합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    ' \n' +
    '\n' +
    ' \n' +
    '\n' +
    '제 6조 [ 서비스 종류 및 변경 ]\n' +
    '\n' +
    ' \n' +
    '\n' +
    '1. 당사는 서비스 종류, 서비스 내용, 가격 및 기타 서비스 관련 사항을 당사의 홈페이지를 통하여 게시하며, 이용자는 게시된 내용을 기준으로 서비스 이용 신청을 해야 합니다.\n' +
    '\n' +
    '2. 당사는 이용자에게 제공하는 서비스의 종류, 상세 성능, 정보, 과금 내용 등 서비스 전반에 대한 서비스 특성과 상품 정보를 추가, 변경, 삭제할 수 있습니다.\n' +
    '\n' +
    '3. 당사는 서비스 종류의 신설 또는 기타 변경 사항에 대해서도 홈페이지를 통하여 게시하며, 이미 서비스 이용 중인 이용자에 대한 서비스의 변경사항은 홈페이지에 게시하거나 이용자의 사전에 입력한 연락 가능한 주소를 통해서 통보하며, 게시 또는 통보 후 7일 이내에 이의를 제기하지 않으면 이용자가 변경사항에 대해서 동의한 것으로 간주합니다.\n' +
    '\n' +
    '\n' +
    ' \n' +
    '\n' +
    '제 7조 [ 약관 동의 및 이용신청 ]\n' +
    '\n' +
    ' \n' +
    '\n' +
    '1. 이용자는 홈페이지에 게시되어 있는 약관에 동의하여야만 서비스를 이용하실 수 있으며, 이용자가 당사의 홈페이지 등에서 관련 약관 중 동의 절차에 \'동의함\' 혹은 ‘동의’와 관련된 버튼을 누르고 계속 진행함으로써 서비스 이용 약관에 동의하게 됩니다.\n' +
    '\n' +
    '2. 약관 동의 절차 이후 당사가 정한 방식으로 기입하는 것을 완료하면 서비스 이용 신청이 완료되며, 당사는 계약의 중요도나 업무상 필요한 경우에, 별도의 서면 신청 서류 작성과 계약에 관련한 증빙 서류 사본 제출, 사업자등록증 사본 혹은 개인의 경우 주민등록증 사본 혹은 그에 준하는 서류를 이용자에게 요구할 수 있습니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '3. 이용자는 이용 신청 시 반드시 실명과 실제 본인 혹은 본인 소속 당사에 대한 정보만을 입력하여야 하며, 이를 위반한 이용자는 서비스 이용 혹은 해지 시에 이용자 소유 장비의 IDC 밖으로 반출이 불가능하게 되어 재산상의 손해를 입을 수 있으며, 법적인 보호를 받을 수 없고 서비스 이용에 제한을 받을 수 있습니다.\n' +
    '\n' +
    '4. 이용자가 이용 신청 시 제공한 개인 정보는 관계법령 및 당사의 개인 정보보호 정책에 따라 보호를 받습니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '5. 이용자의 당사로부터 별도의 승인 절차 없이 구매한 서비스 상품을 제3자에게 재판매하거나 본래에 신청한 목적 이외의 용도로 사용하는 경우 당사는 이용자의 서비스 상품을 일시 중지하거나 별도의 통보 없이 삭제할 수 있습니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    ' \n' +
    '\n' +
    ' \n' +
    '\n' +
    '제 8조 [ 이용 신청 승낙 ]\n' +
    '\n' +
    ' \n' +
    '\n' +
    '1. 당사는 이용자가 제7조에서 정한 사항을 정확히 기재하여 이용 신청을 하였을 때 특별한 사정이 없는 한 접수 순서에 따라서 이용 신청을 승낙합니다.\n' +
    '\n' +
    '2. 당사는 다음 각호에 대해서는 서비스 신청을 거절할 수 있습니다.\n' +
    '\n' +
    '\n' +
    '1) 비 실명으로 서비스를 신청하는 경우\n' +
    '2) 타인 명의를 사용하여 서비스를 신청한 경우\n' +
    '3) 신청서의 내용을 허위로 기재한 경우\n' +
    '\n' +
    '4) 자사 또는 타사 서비스 이용 중 네트워크 장애를 유발하는 공격을 받은 전례가 있거나, 그와 유사한 서비스로 기존 이용자의 정상적인 서비스 이용에 악영향을 줄 것으로 판단되는 경우\n' +
    '5) 국내법 혹은 국제법상의 불법적인 홈페이지를 운영하는 경우 (예: 불법 성인물, 불법 도박, 반 국가 이적 단체 등)\n' +
    '6) 당사의 다른 서비스 이용요금을 체납하고 있는 경우\n' +
    '7) 과거 서비스 이용 비용의 연체 이력 혹은 불량 사용 이력이 있는 경우\n' +
    '\n' +
    '3. 당사는 다음 각호의 경우에는 승낙을 유보할 수 있습니다.\n' +
    '\n' +
    '\n' +
    '1) 당사 사정으로 이용 승낙이 곤란한 경우\n' +
    '2) 이용 신청 이용자가 관계법령에서 규정하는 미성년자일 경우\n' +
    '3) 당사의 사정으로 인해 제공 가능한 시스템 자원 등이 부족한 경우\n' +
    '\n' +
    ' \n' +
    '\n' +
    ' \n' +
    '\n' +
    ' \n' +
    '\n' +
    '제 9조 [ 서비스 이용계약의 성립 및 개통 ]\n' +
    '\n' +
    ' \n' +
    '\n' +
    '1. 이용계약은 이용자가 당사의 홈페이지에서 서비스를 신청하고 신청한 상품의 이용요금을 납입하면 당사가 이를 승낙하여 성립합니다.\n' +
    '\n' +
    '2. 이용계약이 성립되면 당사는 전항의 이용요금의 입금을 확인한 후, 해당 신청 상품의 서버를 IDC 혹은 자사 구축 전산실에 설치하고 네트워크를 개통한 뒤 이용자의 접속할 서버의 주소 정보와 운영 관련 계정 정보를 이용자가 신청서에 기입한 방식으로 통보합니다. 서비스 개통 통보 일이 서비스 이용요금 정산 기준 일자가 됩니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    ' \n' +
    '\n' +
    '3. 이용자는 당사와 별도의 서면 계약 서류를 작성하지 않은 경우, 서비스 이용 약관 사본, 홈페이지에 공지된 신청 서비스의 상품 정보 사본, 당사 홈페이지의 서비스 이용 신청 내역, 입금한 통장 사본 혹은 신용카드 매출전표, 당사가 발행한 세금계산서 등을 보존함으로써 계약 서류를 대체할 수 있습니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '\n' +
    ' \n' +
    '\n' +
    '제 3장 의 무\n' +
    '\n' +
    ' \n' +
    '\n' +
    '제 10조 [ 당사의 의무 ]\n' +
    '\n' +
    ' \n' +
    '\n' +
    '본 계약이 수정되거나 변경된 경우 서버 24(server24.kr)은 이용자에게 그 사실을 안내하여야 합니다. 변경된 계약에 동의하지 않는 경우 변경일로부터 3일 이내 당사의 온라인 이용자 센터에 거부 의사를 밝혀야 합니다.\n' +
    '\n' +
    '서비스 접속. 당사는 이용자에게 호스팅에 접근할 수 있는 충분한 권한을 제공합니다. 당사가 제공하는 방법을 통해 호스팅 및 관련 서비스를 이용할 수 있습니다.\n' +
    '\n' +
    '서비스 및 데이터 전송. 당사는 이용자에게 제공하는 서비스의 품질 및 관리를 위해 이용자가 사용하는 빈도나 기본적인 통신 방법을 분석할 수 있습니다. 이 데이터는 이용자가 서비스를 탈퇴하거나 제공하는 서비스가 만료되면 다시 복구할 수 없는 방법으로 기록을 파기합니다. 이 데이터는 이용자의 기밀성을 보장합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '당사는 환율 변동 등의 이유로 이용자에게 제공하는 서비스의 가격을 합리적으로 조정할 수 있습니다. 당사가 서비스의 가격을 변경한다면 신규 이용자에게 이를 안내하여야 합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    ' \n' +
    '\n' +
    ' \n' +
    '\n' +
    '1. 당사는 이용자가 신청한 서비스를 안정적이고 지속적으로 제공해야 합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '2. 당사는 이용자가 계약한 네트워크 대역의 운영에 문제가 발생하지 않도록 충분한 여유 대역을 확보하여야 합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '3. 당사는 서비스 운영에 지장이 되는 장애가 발생했을 경우 이를 빠른 시일 내 수리 또는 복구해야 하며, 안정적인 서비스 운영을 위해 최선을 다해야 합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    ' \n' +
    '\n' +
    '4. 당사는 이용자가 정당하게 요구하는 의견이나 불만에 대해서 즉시 또는 당사가 정한 기간과 절차에 따라 처리해야 합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '5. 당사는 원활한 서비스 신청 및 운영을 위해 이용자의 개인 정보를 수집하여 보관하며 본인의 허락 없이는 제3자에게 이를 제공할 수 없습니다. 다만, 관계법령에 의한 수사상의 목적으로 관계 기관으로부터 요구받은 경우나 정보통신윤리위원회의 요청이 있는 경우, 당사의 이용요금을 체납하여 신용 정보사업자 또는 신용 정보집중기관에 제공하는 경우는 예외로 합니다.\n' +
    '\n' +
    '6. 당사는 이용계약의 체결, 계약사 항의 변경 및 해지 등 이용자와의 계약 관련 절차 및 내용 등에 있어 이용자에게 편의를 제공하도록 노력합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '7. 당사는 타 이용자에게 보다 안정적인 서비스 제공을 위하여 불법적인 프리게임 서버나 분산 서비스 거부 공격을 조종하는 C&C 서버 및 관련 Dos 공격 등 정보통신 법률에 위반되는 행위를 한 서비스 상품에 대해서는 별도의 통보 없이 이용자의 계정을 정지하거나 서비스를 해지할 수 있습니다.\n' +
    '\n' +
    '8. 당사는 이용자에게 보다 유연한 서비스를 제공하기 위해 이용자 센터를 상시 운영하며, 별도의 인원 감축이나 공휴일 등과 같은 사유로 기술 지원에 영향이 있을 때에는 홈페이지를 통해 사전에 공지하여야 합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    ' \n' +
    '\n' +
    ' \n' +
    '\n' +
    '\n' +
    ' \n' +
    '\n' +
    '제 11조 [ 이용자의 의무 ]\n' +
    '\n' +
    ' \n' +
    '\n' +
    '1. 당사가 제공하는 재화 또는 용역이 이용자에게 제공되는 시점부터 서버 24(server24.kr)과 이용자는 아래와 같은 조항 및 항목에 동의하는 것으로 간주합니다. 만약 이용자가 본 조약 및 관련 조항 항목에 동의하지 않는 경우 당사가 제공하는 서비스를 이용하지 마십시오.\n' +
    '\n' +
    '이용자가 서비스를 사용하는 계정이나 제공하는 서비스의 정보를 담은 보안 계정은 이용자만 사용해야 하며, 이 항목을 위반함으로 인해서 발생한 피해에 대해서는 당사가 책임지지 않습니다. 또한 본인 외 사용이 감지되면 당사에서는 보안을 목적으로 계정을 일시정지하거나, 추가 인증을 요구할 수 있습니다.\n' +
    '\n' +
    '당사는 이용자에게 보다 다양한 서비스를 제공하기 위해 관련 서비스에 대한 약관을 수정하거나 삽입, 제거, 변경할 수 있습니다. 변경된 계약에 동의하지 않는 경우 변경일로부터 3일 이내 당사의 온라인 이용자 센터에 거부 의사를 밝혀야 합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '이용자는 당사가 제공하는 서비스를 올바른 용도로만 사용해야 합니다. 이와 관련하여 당사는 이용자에게 제공한 이용자 서비스의 데이터를 검토할 권리를 보유합니다. 이용자는 아래 당사가 제공하는 이용 약관에 어긋나는 목적으로 사용할 수 없습니다. 만약 이를 위반한 것이 확실한 경우 당사는 정도에 따라 해당 서비스를 일시정지하거나 별도 통보 없이 삭제할 수 있습니다.\n' +
    '\n' +
    '당사는 관련 법률에 위촉되는 부분이나 관련 기관의 공식적인 협조 요청이 있는 경우 이에 적극 협조합니다. 이용자 정보는 상시 암호화하여 보관합니다. 당사는 저작권자가 온라인 지적 재산권을 관리하는 데 도움이 되는 정보를 일부 제공하지만, 당사는 공식적인 절차 혹은 서류상의 증명 없이 해당 절차를 진행하지 않습니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '2. 이용자는 당사가 정한 서비스 이용요금을 지정된 일자에 납입할 의무가 있습니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '3. 이용자는 당사와 타 이용자의 서비스 운영에 방해가 되는 행위를 할 수 없습니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '4. 이용자는 당사의 서비스를 이용하여 국내법 혹은 국제법상의 불법적인 행위를 할 수 없습니다.\n' +
    '\n' +
    '5. 이용자는 자신이 운영 중인 서버가 인가받지 않은 침입자로부터 안전하게 보호받을 수 있도록 시스템 운영과 관련한 정기적인 보안 업데이트를 하여야 하며, 별도로 시스템 보안 서비스와 관련한 계약을 당사와 체결한 경우를 제외하고는 발생한 보안 사고에 대하여 당사는 책임을 지지 아니합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '6. 이용자는 자신이 운영 중인 서버의 데이터 등에 대해 별도로 저장할 의무가 있으며 하드웨어의 망실, 외부 침입 등으로 인한 정보의 유출, 누락 또는 자료의 손실에 대하여 별도의 데이터 백업에 대한 계약을 당사와 체결한 경우를 제외하고는 당사는 이에 대하여 책임이 없습니다.\n' +
    '\n' +
    '7. 이용자는 자신이 운영 중인 서버에서 발생하는 저작권과 관련한 문제에 대하여 책임져야 하며, 설치하는 소프트웨어 프로그램에 대하여 라이선스를 취득하거나, 라이선스로부터 자유로운 소프트웨어만을 설치하여야 합니다.\n' +
    '\n' +
    '8. 각종 아이디(ID)와 비밀번호, DB 등 개인 정보에 대한 관리 책임은 이용자에게 있으며, 이의 유출로 발생하는 손해에 대한 책임은 이용자에게 있습니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '9. 이용자는 서비스 신청 시 당사에게 제공한 정보의 변경 내용이 발생했을 경우 해당 절차를 거쳐 빠른 시일 내에 수정·보완해야 하며, 그로 인하여 발생하는 문제에 대한 책임은 이용자에게 있습니다.\n' +
    '\n' +
    '10. 이용자는 당사가 제공하는 서비스를 올바른 용도로만 사용해야 합니다. 이와 관련하여 당사는 이용자에게 제공한 이용자 서비스의 데이터를 검토할 권리를 보유합니다. 이용자는 당사가 제공하는 이용 약관에 어긋나는 목적으로 사용할 수 없습니다. 만약 이를 위반한 것이 확실한 경우 당사는 정도에 따라 해당 서비스를 일시정지하거나 별도의 통보 없이 삭제할 수 있습니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '11. 이용자는 당사로부터 할당받은 서비스를 이용하여 제3자에게 서비스를 다시 제공하는 경우 제3자의 개인 정보를 안전하게 사용하고 보호할 의무가 있습니다. 만약 이와 관련하여 법적 문제가 발생하면 이에 대한 책임은 전적으로 이용자에게 있습니다.\n' +
    '\n' +
    '12. 이용자는 서비스를 다음과 같은 사유나 관련 이용약관 및 환불 약관에 어긋나는 행위를 하지 않아야 합니다.\n' +
    'a. 원 저작권자와 합의되지 않은 복사 수정 번역기 컴파일 리버스 엔지니어링 패러디 파생 작품 제작 혹은 분해하거나 소스 코드의 일부를 임의로 변경하거나 전부를 추출하려고 시도하는 서비스\n' +
    'b. 라이선스를 재판매하거나 재화 등을 허가 없이 판매하는 서비스\n' +
    '\n' +
    'c. 포르노, 불법 도박사이트, 반사회적 사이트 혹은 이와 유사한 서비스, 불법 프리게임 서버, C&C 서버, 분산 서비스 거부 공격 용도의 서버, 저작권법에 위반되는 서비스를 제공하는 서버, 사전 동의 없이 운영하는 토렌트 서버, 사전 승인 없이 진행되는 벤치마킹, 서버 과부하 테스트, 사전 승인 없이 진행되는 보안 취약점 실험 등\n' +
    '\n' +
    '위 각 호에 기재된 사항 이외에 당사가 지정한 용도 이외의 목적으로 서비스를 사용하는 경우 당사는 별도의 통보 없이 서비스의 일부 또는 전체를 제한하거나 삭제할 권리가 있습니다.\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    ' \n' +
    '\n' +
    '부칙\n' +
    '\n' +
    ' \n' +
    '\n' +
    'I. 타사 구성 요소. 제 3자의 구성 요소 및 관련 서비스를 사용할 경우 이용자가 직접 해당 라이선스 보유자 또는 제공하는 자에게 구매하여야만 하며, 이로 인해 발생하는 법적 문제에 대해 전적으로 이용자가 책임져야합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    'II. 정지 / 제거. 이용자가 당사가 제공한 약관이나 관련 법률을 위반한 것으로 판단하면 그 즉시 제공했던 서비스의 일부 또는 전부를 일시적으로 제한하거나 삭제할 수 있습니다. 만약 서비스가 일시적으로 제한된 경우 이용자는 서버24(server24.kr)에게 서비스 정상화를 요구할 수 있습니다. 단, 해당 요청은 1회에만 유효합니다. 또한 당사는 해당 요청을 거부할 수 있습니다. 경우에 따라 최대 24시간 내 해당 문제가 해결되지 않는 경우 당사는 해당 서비스를 완전히 삭제할 수 있으며, 이용자의 데이터 손망실에 대해서 보장하지 않습니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    'III. 긴급 보안 문제. 긴급 보안 문제가 발생한 경우 상기 안내에도 불구하고 이용자가 해당 문제를 수정하지 않거나 긴급한 보안 문제를 조속히 수정해야 할 경우에 당사는 이용자에게 별도의 통보 없이 서비스를 정지하고 수정할 권리가 있습니다. 당사는 이용자가 해당 문제를 해결하지 않거나 해결되지 않는 치명적인 결함인 경우, 보안상의 이유로 이용자의 서비스를 반영구적으로 정지할 수 있습니다. 또한 당사는 서비스를 중지한 사유를 이용자에게 통보할 의무가 있습니다.\n' +
    '\n' +
    '\n' +
    ' \n' +
    '\n' +
    '제 12조 [ 지적 재산권의 귀속 및 침해 금지 ]\n' +
    '\n' +
    ' \n' +
    '\n' +
    '1. 당사가 작성한 저작물에 대한 저작권 기타 지식 재산권은 당사에 귀속합니다. 이용자는 당사 및 제3자의 지식 재산권을 침해하여서는 안 됩니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '2. 이용자 및 당사 홈페이지 방문자는 당사의 서비스를 이용하여 얻은 정보를 당사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '3. 당사가 보유한 기술력이나 온라인, 오프라인 상으로 제공하는 서비스 및 관련 이미지, 사진, I. 타사 구성 요소. 제3자의 구성 요소 및 관련 서비스를 사용할 경우 이용자가 직접 해당 라이선스 보유자 또는 제공하는 자에게 구매하여야만 하며, 이로 인해 발생하는 법적 문제에 대해 전적으로 이용자가 책임져야 합니다.\n' +
    '\n' +
    'II. 정지 / 제거. 이용자가 당사가 제공한 약관이나 관련 법률을 위반한 것으로 판단하면 그 즉시 제공했던 서비스의 일부 또는 전부를 일시적으로 제한하거나 삭제할 수 있습니다. 만약 서비스가 일시적으로 제한된 경우 이용자는 서버 24(server24.kr)에게 서비스 정상화를 요구할 수 있습니다. 단, 해당 요청은 1회에만 유효합니다. 또한 당사는 해당 요청을 거부할 수 있습니다. 경우에 따라 최대 24시간 내 해당 문제가 해결되지 않는 경우 당사는 해당 서비스를 완전히 삭제할 수 있으며, 이용자의 데이터 소망실에 대해서 보장하지 않습니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '4. 당사의 보유 기술, 서비스 내부 구성도, 당사와의 제휴 혹은 파트너사, 당사의 시스템 구현 방식 등 당사가 운용하는 시스템 혹은 설비를 포함한 일부 혹은 전체의 기술에 대한 부분을 이용자가 조사하거나 상업적으로 이용하는 등 당사가 허가하지 않은 내용을 저장하거나 공유해서는 안 됩니다. 또한 별도의 기록물 혹은 기록 장치에 보관하거나 웹사이트 등에 게시하는 등의 행위는 엄격하게 금지됩니다. 당사는 이용자가 이를 위반함으로 인해서 발생한 피해(조사 과정 등에서 발생한 기집행비용, 영업손실액, 영업실적 부진 등)에 대해서 이용자에게 책임을 물을 수 있으며, 이용자는 해당 행위를 즉시 중단하고 금전적 손해에 적극적으로 보상해야 합니다.\n' +
    '\n' +
    '\n' +
    ' \n' +
    '\n' +
    '제 4장 이 용\n' +
    '\n' +
    ' \n' +
    '\n' +
    '제 13조 [ 서비스 제공의 원칙 ]\n' +
    '\n' +
    ' \n' +
    '\n' +
    '서비스 이용은 당사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간 중단이 없는 서비스 제공을 원칙으로 합니다. 단, 정기점검이나 시스템의 업그레이드가 필요한 시간에 서비스를 일시 중단할 수 있으며, 예정되어 있는 작업으로 인한 서비스 일시 중단은 당사 홈페이지 또는 이용자에게 연락 가능한 수단을 이용하여 사전에 공지합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    ' \n' +
    '\n' +
    '이용자는 당사가 지원 가능한 일부 응용 프로그램 및 제공받은 서비스에 대한 기술 지원을 요청할 수 있습니다. 단, 당사는 이용자에게 지원 가능한 한도를 제시하고 사전에 검증된 해결 방법으로만 기술 지원을 진행합니다. 기술 지원 과정에서 발생하는 데이터 손상, 데이터 손실, 복구 불가능한 정보의 삭제, 천재지변, 보안 취약점을 노린 네트워크 취약점 공격, 서비스 거부 공격, 등의 금전 및 재산 피해에 대해 아무런 책임이 없습니다. 일부 기술 지원은 당사가 이용자에게 별도 기술 지원 비용 청구할 수 있으며, 기술 지원 비용은 사전 협의된 비용과 다소 상이할 수 있습니다.\n' +
    '\n' +
    '기타 협약이나 제휴가 아닌 이상 당사는 제3자 혹은 이용자에게 기밀 정보를 공개하지 않습니다. 사내 직원과 그와 관련된 요원 및 인력은 서면으로 이 기밀을 유지하기로 합의하였습니다. 당사는 이 기밀 정보를 유지하기 위해 이 계약 기간에 따른 의무를 이행하며, 합의한 기밀 정보를 사용하는지 확인합니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    ' \n' +
    '\n' +
    ' \n' +
    '\n' +
    '\n' +
    ' \n' +
    '\n' +
    '제 14조 [ 서비스 중단 시 면책 사항 ]\n' +
    '\n' +
    ' \n' +
    '\n' +
    '당사는 이용자에 대한 책임 없이 언제든지 어떤 이유로든 서비스나 일부 기능을 중단할 수 있습니다. 이 항목은 서비스의 재고 부족을 의미하는 것이 아닙니다. 또한 당사는 이용자에게 안내할 책임이 있으며 이와 관련하여 환불을 요청하는 경우 당사가 제공하는 환불 정책을 참고하시기 바랍니다.\n' +
    '\n' +
    '다음과 같은 사유로 인하여 서비스 중단이 발생되는 경우, 당사가 통제 혹은 예방이 불가능하므로 이와 관련하여 당사의 별도의 통보가 없다 하더라도 당사의 귀책사유에 포함되지 않습니다.\n' +
    '\n' +
    '\n' +
    '1) 네트워크 장비의 예상하지 못한 장애 혹은 긴급 보수로 인하여 서비스 이용이 제42조의 배상 범위 시간 이내에서 일시적으로 중단되는 경우\n' +
    '\n' +
    '2) 서비스 이용 중인 서버의 고장이나 장애로 인한 유지 보수를 위하여 이용이 중단되는 경우\n' +
    '\n' +
    '3) 당사가 직접 소유 운영하지 아니하는 설비나 시설에서 발생한 장애로 인한 예측하지 못한 서비스의 중단이 발생되는 경우\n' +
    '\n' +
    '4) 전기 통신 사업법에 규정한 기간 통신 사업자의 네트워크 경로상 장애가 발생하는 경우\n' +
    '\n' +
    '5) 국가 비상사태, 지역 비상사태, 천재지변 등으로 정상적인 서비스를 제공할 수 없거나 이용에 지장이 있는 경우\n' +
    '\n' +
    '6) 이용자가 임의로 서비스를 중지하거나 서비스 분산 거부 공격 등의 네트워크 취약점 공격으로 이용자가 외부 네트워크로 접속할 수 없는 경우(단, 물리적 서버의 전원이 인가되지 않아 발생한 서비스 장애는 해당하지 않음.)\n' +
    '\n' +
    '7) 제15조의 제 항목에 해당하는 경우\n' +
    '\n' +
    ' \n' +
    '\n' +
    ' \n' +
    '\n' +
    ' \n' +
    '\n' +
    '\n' +
    ' \n' +
    '\n' +
    '제 15조 [ 네트워크 접속의 제한 ]\n' +
    '\n' +
    ' \n' +
    '\n' +
    '당사는 이용자의 서비스 이용 내용이 다음 각 호에 해당할 경우, 운영 중인 서버의 네트워크 접속을 중지하고 이용자에게 이의 해소를 요구할 수 있습니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '1) 제11조 규정에 의한 이용자의 의무를 위반한 경우\n' +
    '\n' +
    '2) 이용자의 서비스 이용요금 납입이 미납된 경우\n' +
    '\n' +
    '3) 이용자 서버가 허용 혹은 계약 체결한 네트워크 대역을 초과하여 사용하는 경우\n' +
    '\n' +
    '4) 이용자 서버의 과다 패킷 발생이나 브로드 캐스팅으로 인하여 서비스 중인 네트워크에 장애가 발생되는 경우\n' +
    '\n' +
    '5) 이용자 서버가 해킹되었거나, 바이러스에 감염된 것으로 의심되는 경우\n' +
    '\n' +
    '6) 이용자 서버가 관리자 부재로 인하여 적절한 보안 업데이트가 이루어지지 않고 있어 당사의 서비스 운영에 위험 요소로 판단되는 경우\n' +
    '\n' +
    '7) 이용자 서버가 과다한 전기를 사용하거나 발열로 인하여 당사의 서비스 운영에 지장이 발생될 것으로 예상되는 경우\n' +
    '\n' +
    '8) 과도한 국제 회선 사용으로 인하여 기간 통신 사업자로부터 이의 해소를 요구받은 경우\n' +
    '9) 정부 기관이 증거 자료 확보를 이유로 적절한 법적 절차를 거쳐 운영 서버에 대한 일시적인 서비스 중지를 요청한 경우\n' +
    '10) "정보통신망 이용 촉진 및 정보보호 등에 관한 법률"의 제반 규정 및 정보통신부 "스팸 메일 방지 가이드라인"에서 규정한 관련 사항을 준수하지 아니한 경우\n' +
    '\n' +
    '\n' +
    ' \n' +
    '\n' +
    '제 5장 서비스 이용계약의 변경/취소/해지\n' +
    '\n' +
    ' \n' +
    '\n' +
    '제 16조 [ 서비스 이용계약 내용의 변경 및 제한 ]\n' +
    '\n' +
    ' \n' +
    '\n' +
    '1. 이용자는 서비스 이용계약 내용 중 다음에 해당하는 변경 사유가 발생한 즉시 관련 절차에 따라 변경 신청해야 하며, 이와 관련한 정보 변경의 지연으로 인한 문제 발생 시 그 책임은 이용자에게 있습니다.\n' +
    '\n' +
    '1) 이용자 및 사실상 요금 납부의 책임을 지기로 한 자의 상호, 성명 또는 주소의 변경\n' +
    '\n' +
    '2) 계약 종류(상품의 종류, 회선의 종류, 접속 회선의 대역, 이용계약 기간, 이용목적)의 변경\n' +
    '\n' +
    ' \n' +
    '\n' +
    '2 계약된 서비스보다 초과하여 이용하고 있는 경우, 당사는 당사에 등록되어 있는 이용자의 연락처로 사실 통지 후 계약사항을 변경할 수 있습니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '3. 계약 기간 이전이라 하더라도 원가의 과도한 상승 요인이 발생할 경우 이용자의 동의를 얻어 이용료를 인상할 수 있습니다. 이는 당사의 귀책사유이므로 이용자가 변경된 이용료에 동의할 수 없을 경우, 이용자는 계약 기간과 관련한 위약 수수료를 지급하지 않고도 일방적으로 해지할 수 있습니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '4. 이용자가 당사가 청구한 서비스 이용 요금을 체납하고 있는 경우, 당사는 변경 신청을 제한할 수 있습니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '5. 설비의 여유가 없거나, 기술상 서비스 제공이 어렵거나 당사의 서비스 정책이 변경되는 경우에 당사는 변경 신청을 제한할 수 있습니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '6. 이용자는 사용하고자 하는 서비스를 선택한 후 당사가 제공한 가격만큼의 재화로 결제를 진행합니다. 단, 당사가 지정한 재화로만 결제할 수 있으며, 경우에 따라 당사는 이용자에게 현금영수증, 세금계산서 혹은 관련 증빙 서류를 발행합니다. 하지만, 관련 법률에 위촉되지 않는 이상 별도의 명세서나 청구서를 제공할 의무는 없습니다.\n' +
    '\n' +
    '7. 이용자는 당사에 서비스 약정 기간에 따른 서비스 이용요금을 선납하거나, 예치금 방식으로 미리 충전하고 해당 예치금에서 매시간 단위로 예치금이 차감되는 방식을 사용할 수 있습니다.\n' +
    '\n' +
    '7-1. 예치금 방식으로 서비스를 이용할 경우, 서비스 이용 시간에 따른 서비스 이용 대금을 계산하여, 매 시간마다 부과하고 이용자가 보유한 예치금에서 차감합니다. 이용자는 서비스를 해지하거나 삭제함에 따라 서비스 이용에 따른 예치금 차감을 중단할 수 있습니다.\n' +
    '\n' +
    ' \n' +
    '\n' +
    '8. 당사가 제공하는 서비스를 후불로 사용하는 과정에서 당사가 판단하기에 이용자의 신용도 및 지불능력 등을 확인해야할 경우 서비스 이용 도중에 선불을 요구할 수 있습니다. 이용자는 이 요구에 7영업일 내 지불을 완료하여야 하며, 당사가 이용자에게 신용 관련 정보를 요청하는 경우 이용자는 서비스를 이용하기 위해 관련 정보를 제공해야 합니다.\n' +
    '\n' +
    ' '


export const my_clause = '< 데이터크레딧 >(\'https://server24.kr\'이하 \'server24 - 서버24\')은(는) \n 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 \n 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 \n 다음과 같이 개인정보 처리방침을 수립·공개합니다.\n' +
    '\n' +
    '○ 이 개인정보처리방침은 2021년 11월 1부터 적용됩니다.\n' +
    '\n' +
    '\n' +
    '제1조(개인정보의 처리 목적)\n' +
    '\n' +
    '< 데이터크레딧 >(\'https://server24.kr\'이하 \'server24 - 서버24\')은(는) 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.\n' +
    '\n' +
    '1. 홈페이지 회원가입 및 관리\n' +
    '\n' +
    '회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 만14세 미만 아동의 개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.\n' +
    '\n' +
    '\n' +
    '2. 민원사무 처리\n' +
    '\n' +
    '민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.\n' +
    '\n' +
    '\n' +
    '3. 재화 또는 서비스 제공\n' +
    '\n' +
    '물품배송, 서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제·정산, 채권추심을 목적으로 개인정보를 처리합니다.\n' +
    '\n' +
    '\n' +
    '4. 마케팅 및 광고에의 활용\n' +
    '\n' +
    '신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.\n' +
    '\n' +
    '\n' +
    '5. 개인영상정보\n' +
    '\n' +
    '범죄의 예방 및 수사, 시설안전 및 화재예방 등을 목적으로 개인정보를 처리합니다.\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '제2조(개인정보의 처리 및 보유 기간)\n' +
    '\n' +
    '① < 데이터크레딧 >은(는) 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.\n' +
    '\n' +
    '② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.\n' +
    '\n' +
    '1.<홈페이지 회원가입 및 관리>\n' +
    '<홈페이지 회원가입 및 관리>와 관련한 개인정보는 수집.이용에 관한 동의일로부터<준영구>까지 위 이용목적을 위하여 보유.이용됩니다.\n' +
    '보유근거 : 홈페이지 회원가입 및 관리\n' +
    '관련법령 : 1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년\n' +
    '2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년\n' +
    '3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년\n' +
    '4) 계약 또는 청약철회 등에 관한 기록 : 5년\n' +
    '5) 표시/광고에 관한 기록 : 6개월\n' +
    '예외사유 :\n' +
    '2.<민원사무 처리>\n' +
    '<민원사무 처리>와 관련한 개인정보는 수집.이용에 관한 동의일로부터<준영구>까지 위 이용목적을 위하여 보유.이용됩니다.\n' +
    '보유근거 : 민원사무 처리\n' +
    '관련법령 : 1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년\n' +
    '2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년\n' +
    '3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년\n' +
    '4) 계약 또는 청약철회 등에 관한 기록 : 5년\n' +
    '5) 표시/광고에 관한 기록 : 6개월\n' +
    '1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년\n' +
    '2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년\n' +
    '3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년\n' +
    '4) 계약 또는 청약철회 등에 관한 기록 : 5년\n' +
    '5) 표시/광고에 관한 기록 : 6개월\n' +
    '예외사유 :\n' +
    '3.<재화 또는 서비스 제공>\n' +
    '<재화 또는 서비스 제공>와 관련한 개인정보는 수집.이용에 관한 동의일로부터<준영구>까지 위 이용목적을 위하여 보유.이용됩니다.\n' +
    '보유근거 : 재화 및 서비스 제공\n' +
    '관련법령 : 1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년\n' +
    '2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년\n' +
    '3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년\n' +
    '4) 계약 또는 청약철회 등에 관한 기록 : 5년\n' +
    '5) 표시/광고에 관한 기록 : 6개월\n' +
    '1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년\n' +
    '2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년\n' +
    '3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년\n' +
    '4) 계약 또는 청약철회 등에 관한 기록 : 5년\n' +
    '5) 표시/광고에 관한 기록 : 6개월\n' +
    '1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년\n' +
    '2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년\n' +
    '3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년\n' +
    '4) 계약 또는 청약철회 등에 관한 기록 : 5년\n' +
    '5) 표시/광고에 관한 기록 : 6개월\n' +
    '예외사유 :\n' +
    '\n' +
    '\n' +
    '제3조(개인정보의 제3자 제공)\n' +
    '\n' +
    '① < 데이터크레딧 >은(는) 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.\n' +
    '\n' +
    '② < 데이터크레딧 >은(는) 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.\n' +
    '\n' +
    '1. < >\n' +
    '개인정보를 제공받는 자 :\n' +
    '제공받는 자의 개인정보 이용목적 :\n' +
    '제공받는 자의 보유.이용기간:\n' +
    '\n' +
    '\n' +
    '제4조(개인정보처리 위탁)\n' +
    '\n' +
    '① < 데이터크레딧 >은(는) 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.\n' +
    '\n' +
    '1. < >\n' +
    '위탁받는 자 (수탁자) :\n' +
    '위탁하는 업무의 내용 :\n' +
    '위탁기간 :\n' +
    '② < 데이터크레딧 >은(는) 위탁계약 체결시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.\n' +
    '\n' +
    '③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.\n' +
    '\n' +
    '\n' +
    '\n' +
    '제5조(정보주체와 법정대리인의 권리·의무 및 그 행사방법)\n' +
    '\n' +
    '\n' +
    '\n' +
    '① 정보주체는 데이터크레딧에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.\n' +
    '\n' +
    '② 제1항에 따른 권리 행사는데이터크레딧에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 데이터크레딧은(는) 이에 대해 지체 없이 조치하겠습니다.\n' +
    '\n' +
    '③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.\n' +
    '\n' +
    '④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.\n' +
    '\n' +
    '⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.\n' +
    '\n' +
    '⑥ 데이터크레딧은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.\n' +
    '\n' +
    '\n' +
    '\n' +
    '제6조(처리하는 개인정보의 항목 작성)\n' +
    '\n' +
    '① < 데이터크레딧 >은(는) 다음의 개인정보 항목을 처리하고 있습니다.\n' +
    '\n' +
    '1< 홈페이지 회원가입 및 관리 >\n' +
    '필수항목 : 이메일, 휴대전화번호, 비밀번호, 로그인ID, 이름, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록\n' +
    '선택항목 : 회사전화번호, 직책, 부서, 회사명, 신용카드정보, 은행계좌정보\n' +
    '\n' +
    '\n' +
    '제7조(개인정보의 파기)\n' +
    '\n' +
    '\n' +
    '① < 데이터크레딧 > 은(는) 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.\n' +
    '\n' +
    '② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.\n' +
    '1. 법령 근거 :\n' +
    '2. 보존하는 개인정보 항목 : 계좌정보, 거래날짜\n' +
    '\n' +
    '③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.\n' +
    '1. 파기절차\n' +
    '< 데이터크레딧 > 은(는) 파기 사유가 발생한 개인정보를 선정하고, < 데이터크레딧 > 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.\n' +
    '\n' +
    '2. 파기방법\n' +
    '\n' +
    '전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.\n' +
    '\n' +
    '종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다\n' +
    '\n' +
    '\n' +
    '제8조(개인정보의 안전성 확보 조치)\n' +
    '\n' +
    '< 데이터크레딧 >은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.\n' +
    '\n' +
    '1. 정기적인 자체 감사 실시\n' +
    '개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.\n' +
    '\n' +
    '2. 개인정보 취급 직원의 최소화 및 교육\n' +
    '개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.\n' +
    '\n' +
    '3. 내부관리계획의 수립 및 시행\n' +
    '개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.\n' +
    '\n' +
    '4. 해킹 등에 대비한 기술적 대책\n' +
    '<데이터크레딧>(\'server24 - 서버24\')은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.\n' +
    '\n' +
    '5. 개인정보의 암호화\n' +
    '이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.\n' +
    '\n' +
    '6. 접속기록의 보관 및 위변조 방지\n' +
    '개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며,다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나, 고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고 있습니다.\n' +
    '또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.\n' +
    '\n' +
    '7. 개인정보에 대한 접근 제한\n' +
    '개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.\n' +
    '\n' +
    '8. 문서보안을 위한 잠금장치 사용\n' +
    '개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.\n' +
    '\n' +
    '9. 비인가자에 대한 출입 통제\n' +
    '개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '제9조(개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항)\n' +
    '\n' +
    '\n' +
    '\n' +
    '① 데이터크레딧 은(는) 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.\n' +
    '② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.\n' +
    '가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.\n' +
    '나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.\n' +
    '다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.\n' +
    '\n' +
    '제10조 (개인정보 보호책임자)\n' +
    '\n' +
    '① 데이터크레딧 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.\n' +
    '\n' +
    '▶ 개인정보 보호책임자\n' +
    '성명 :한찬희\n' +
    '직책 :대표\n' +
    '직급 :대표\n' +
    '연락처 :070-8885-0090, admin@server24.kr, 0504-092-7907\n' +
    '※ 개인정보 보호 담당부서로 연결됩니다.\n' +
    '\n' +
    '▶ 개인정보 보호 담당부서\n' +
    '부서명 :고객만족센터\n' +
    '담당자 :한찬희\n' +
    '연락처 :070-8885-0090, support@server24.kr, 0504-092-7907\n' +
    '② 정보주체께서는 데이터크레딧 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 데이터크레딧 은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.\n' +
    '\n' +
    '\n' +
    '\n' +
    '제11조(추가적인 이용·제공 판단기준)\n' +
    '\n' +
    '< 데이터크레딧 > 은(는) ｢개인정보 보호법｣ 제15조제3항 및 제17조제4항에 따라 ｢개인정보 보호법 시행령｣ 제14조의2에 따른 사항을 고려하여 정보주체의 동의 없이 개인정보를 추가적으로 이용·제공할 수 있습니다.\n' +
    '이에 따라 < 데이터크레딧 > 가(이) 정보주체의 동의 없이 추가적인 이용·제공을 하기 위해서 다음과 같은 사항을 고려하였습니다.\n' +
    '▶ 개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집 목적과 관련성이 있는지 여부\n' +
    '\n' +
    '▶ 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인 이용·제공에 대한 예측 가능성이 있는지 여부\n' +
    '\n' +
    '▶ 개인정보의 추가적인 이용·제공이 정보주체의 이익을 부당하게 침해하는지 여부\n' +
    '\n' +
    '▶ 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부\n' +
    '\n' +
    '※ 추가적인 이용·제공 시 고려사항에 대한 판단기준은 사업자/단체 스스로 자율적으로 판단하여 작성·공개함\n' +
    '\n' +
    '제12조(개인정보 열람청구)\n' +
    '정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.\n' +
    '< 데이터크레딧 >은(는) 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.\n' +
    '\n' +
    '▶ 개인정보 열람청구 접수·처리 부서\n' +
    '부서명 : 고객만족센터\n' +
    '담당자 : 한찬희\n' +
    '연락처 : 070-8885-0090, support@server24.kr, 0504-092-7907\n' +
    '\n' +
    '\n' +
    '제13조(권익침해 구제방법)\n' +
    '\n' +
    '\n' +
    '\n' +
    '정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.\n' +
    '\n' +
    '1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)\n' +
    '2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)\n' +
    '3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)\n' +
    '4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)\n' +
    '\n' +
    '「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.\n' +
    '\n' +
    '※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.\n' +
    '\n' +
    '제14조(영상정보처리기기 설치·운영)\n' +
    '① < 데이터크레딧 >은(는) 아래와 같이 영상정보처리기기를 설치·운영하고 있습니다.\n' +
    '\n' +
    '1.영상정보처리기기 설치근거·목적 : < 데이터크레딧 >의\n' +
    '\n' +
    '2.설치 대수, 설치 위치, 촬영 범위 :\n' +
    '설치대수 : 0 대\n' +
    '설치위치 : 데이터센터\n' +
    '촬영범위 : 데이터센터 실내외 전체\n' +
    '3.관리책임자, 담당부서 및 영상정보에 대한 접근권한자 : 해당 데이터센터 보안실\n' +
    '\n' +
    '4.영상정보 촬영시간, 보관기간, 보관장소, 처리방법\n' +
    '촬영시간 : 24 시간\n' +
    '보관기간 : 촬영시부터 준영구 년\n' +
    '보관장소 및 처리방법 : 데이터센터 내 보안실 및 관련 스토리지 서버\n' +
    '5.영상정보 확인 방법 및 장소 : 데이터센터 내 보안실\n' +
    '\n' +
    '6.정보주체의 영상정보 열람 등 요구에 대한 조치 : 개인영상정보 열람.존재확인 청구서로 신청하여야 하며, 정보주체 자신이 촬영된 경우 또는 명백히 정보주체의 생명.신체.재산 이익을 위해 필요한 경우에 한해 열람을 허용함\n' +
    '\n' +
    '7.영상정보 보호를 위한 기술적.관리적.물리적 조치 :\n' +
    '\n' +
    '\n' +
    '\n' +
    '제15조(개인정보 처리방침 변경)\n' +
    '\n' +
    '\n' +
    '① 이 개인정보처리방침은 2021년 11월 1부터 적용됩니다.\n' +
    '\n' +
    '② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.\n' +
    '\n' +
    '예시 ) - 20XX. X. X ~ 20XX. X. X 적용 (클릭)\n' +
    '\n' +
    '예시 ) - 20XX. X. X ~ 20XX. X. X 적용 (클릭)\n' +
    '\n' +
    '예시 ) - 20XX. X. X ~ 20XX. X. X 적용 (클릭)'
