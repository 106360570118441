import React, {Fragment} from "react";
import {Row, Col} from 'reactstrap';

const GameImgInfo = ({label, img, data, children}) => {

    return (
        <Row className='imgInfo_wrapper game_img_info'>
            <Col xs={3} className='imgInfo_img_wrapper '>
                <div className='imgInfo_img'>
                    <img src={img}/>
                </div>
            </Col>
            <Col xs={9} className='imgInfo_label'>
                <Row>
                    <Col xs={12}><label>{label}</label></Col>
                    <Col xs={12}>
                        <span>{data}</span>
                        {children}
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

GameImgInfo.defaultProps = {
    label: '',
    img: null,
    data: '',
}
export default GameImgInfo;
