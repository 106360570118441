import React, {Fragment, useState} from "react";
import {Col, Row} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import CharginMoreModal from "../CharginMoreModal";
import {useMediaQuery} from "react-responsive";
import TablePagination from "@mui/material/TablePagination";
import {getPointUsage} from "../action";
import {checkErrorResponse} from "../../../helpers/errorCheck";
import {changePerNum} from "../../../helpers/common";

const checkBillStatus = (status) => {
    switch (status) {
        case 'DONE':
            return '결제완료'
        case 'WAITING_FOR_DEPOSIT':
            return '입금 대기중'
        case 'CANCELED':
            return '결제취소'
        default:
            return ''
    }
}


const MobilePointUsageTable = () => {
    const {pointUsageRecord, pointUsageMetaData} = useSelector(state => state.bill);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(localStorage.getItem('pageNum') ? localStorage.getItem('pageNum') : 10);
    const dispatch = useDispatch();

    const handleChangePage = (event, newPage) => {
        let pageLink = '';
        if (page < newPage) pageLink = pointUsageMetaData.links.next
        else pageLink = pointUsageMetaData.links.previous
        getPointUsage(pageLink).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    setPage(newPage);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })

    };

    const handleChangeRowsPerPage = (event) => {
        getPointUsage(`point/usage?page=${0}&per_page=${event.target.value}`).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    const perNum = parseInt(event.target.value, 10);
                    changePerNum(perNum)
                    setRowsPerPage(perNum);
                    setPage(0);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })


    };

    return (
        <div className='mobile_table_wrapper mobile_point_usage_table'>
            {pointUsageRecord.map((payment, index) => {
                return (
                    <Fragment key={index}>
                        <Row className='mobile_item align-items-center'>
                            <Col className='p-0' xs={8}>
                                <span className='d-block fw-bold'>{`${payment.name}`}</span>
                                <span className={'fw-bold'}>{payment.subName}</span>

                            </Col>
                            <Col style={{textAlign: 'right'}} className='p-0' xs={4}>
                                <span className='d-block fw-bold'>{payment.ipCost + payment.ddosCost + payment.dayCost
                                + payment.bandwidthCost + payment.backupCost + payment.snapshotCost}P</span>
                                <span className={'small_font'}>{payment.type}</span>

                            </Col>
                            <Col style={{textAlign: 'left'}} xs={12} className='p-0 small_font'>
                                <span>{payment.time}</span>

                            </Col>
                            <Col style={{textAlign: 'left'}} xs={12} className='p-0 small_font'>
                                   <span>  {payment.more
                                       .split('\n')
                                       .map((line, index) => {
                                           return (
                                               <span key={index}>{line}<br/></span>
                                           )
                                       })}</span>
                            </Col>

                        </Row>
                    </Fragment>
                )
            })}
            <TablePagination
                style={{marginBottom: '2rem'}}
                labelRowsPerPage={''}
                rowsPerPageOptions={[20, 50, 75]}
                component="div"
                count={pointUsageMetaData.total_count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    )
}

export default MobilePointUsageTable;
