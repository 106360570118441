import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {Col, Row} from "reactstrap";
import {formatBytes, isIterableArray} from "../../../../utils/common";
import {getNumString} from "../../common/GameTypeImgItem";
import moment from "moment";
import {adminLogin, changeDirNameFileManager, changeFileNameFileManager, delDirFileManager, delFileFileManager, getDownloadFileUrl, getFileManagerDir} from "../../action";
import {checkErrorResponse} from "../../../../helpers/errorCheck";
import {useParams} from "react-router-dom";
import ConfirmModal from "../../../common/ConfirmModal";
import {notificationAlert} from "../../../../helpers/common";
import Swal from "sweetalert2";
import FileModifyModal from "./FileModifyModal";

const FileManagerItemBox = ({itemNo, route}) => {
    const dispatch = useDispatch();
    const {sessionId, fileManagerDirs} = useSelector(state => state.gameDeploy);
    let {id, no} = useParams();
    const [file, setFile] = useState({});
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    useEffect(() => {
        if (isIterableArray(fileManagerDirs)) {
            if (itemNo !== -1) {
                setFile(fileManagerDirs[itemNo]);
            }
        }
    }, [itemNo])

    const handleFileDel = () => {
        return new Promise((resolve, reject) => {
            if (file.IsDirectory) {
                delDirFileManager(sessionId, id, file.Filename).then(response => {
                    if (response) {
                        if (response.request.status === 200) {
                            let reloadRoute = route.filter(r => r !== '/');
                            getFileManagerDir(sessionId, id, reloadRoute.join('/')).then(getResponse => {
                                if (getResponse) {
                                    if (getResponse.request.status === 200) {
                                        if (getResponse.request.data.Status) {
                                            dispatch(getResponse);
                                            resolve(true);
                                        } else {
                                            notificationAlert('오류', '삭제에 실패하였습니다.');
                                        }
                                    }
                                }
                            }).catch(err => {
                                checkErrorResponse(err);
                            })
                        }

                    }
                }).catch(err => {
                    reject(err);
                    checkErrorResponse(err);
                })
            } else {
                delFileFileManager(sessionId, id, file.Filename).then(response => {
                    if (response) {
                        if (response.request.status === 200) {
                            let reloadRoute = route.filter(r => r !== '/');
                            getFileManagerDir(sessionId, id, reloadRoute.join('/')).then(response => {
                                if (response) {
                                    if (response.request.status === 200) {
                                        if (response.request.data.Status) {
                                            dispatch(response);
                                            resolve(true);
                                        } else {
                                            notificationAlert('오류', '삭제에 실패하였습니다.');
                                        }
                                    }
                                }
                            }).catch(err => {
                                reject(err);
                                checkErrorResponse(err);
                            })

                        }

                    }
                }).catch(err => {
                    reject(err);
                    checkErrorResponse(err);
                })
            }
        });
    }

    const handleChangeFileName = async () => {
        const {value: newFileName} = await Swal.fire({
            title: '변경하실 파일명을 입력하여 주세요.',
            input: 'text',
            inputLabel: '파일명',
            inputValue: file.Filename,
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return '파일명을 입력하여 주세요.'
                }
            }
        })
        if (newFileName) {
            if (file.IsDirectory) {
                changeDirNameFileManager(sessionId, id, file.Filename, newFileName).then(response => {
                    if (response) {
                        if (response.request.status === 200) {
                            let reloadRoute = route.filter(r => r !== '/');
                            getFileManagerDir(sessionId, id, reloadRoute.join('/')).then(response => {
                                if (response) {
                                    if (response.request.status === 200) {
                                        dispatch(response);
                                        notificationAlert('알림', '폴더명이 변경 되었습니다.');

                                    }
                                }
                            }).catch(err => {
                                checkErrorResponse(err);
                            })

                        }
                    }
                }).catch(err => {
                    checkErrorResponse(err);
                })
            } else {
                changeFileNameFileManager(sessionId, id, file.Filename, newFileName).then(response => {
                    if (response) {
                        if (response.request.status === 200) {
                            let reloadRoute = route.filter(r => r !== '/');
                            getFileManagerDir(sessionId, id, reloadRoute.join('/')).then(response => {
                                if (response) {
                                    if (response.request.status === 200) {
                                        dispatch(response);
                                        notificationAlert('알림', '파일명이 변경 되었습니다.');

                                    }
                                }
                            }).catch(err => {
                                checkErrorResponse(err);
                            })
                        }
                    }
                }).catch(err => {
                    checkErrorResponse(err);
                })
            }
        }
    }

    const handleDownLoadFile = () => {
        adminLogin().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    const adminSessionId = response.request.data.sessionID;
                    getDownloadFileUrl(no).then(response => {
                        if (response) {
                            if (response.request.status === 200) {
                                let reloadRoute = route.filter(r => r !== '/');
                                const url = `https://amp-test.server24.kr/fetch/${adminSessionId}/__VDS__${response.request.data.instanceName}/Minecraft/${reloadRoute.join('') + file.Filename}`;
                                window.open(url);
                            }
                        }
                    }).catch(err => {
                        checkErrorResponse(err);
                    })
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })

    }

    return (
        <div className='file_manager_item_box'>
            {Object.keys(file).length !== 0 &&
            <Fragment>
                <div className='file_box_title'>
                    <Row>
                        <Col xs={1}>
                            {file.IsDirectory ? <FolderIcon/> : <InsertDriveFileIcon/>}
                        </Col>
                        <Col xs={11}>
                            <span>
                            {file.Filename}
                                </span>
                        </Col>
                    </Row>
                </div>
                <div className='file_box_info'>
                    <Row className='file_box_item'>
                        <Col xs={6}>
                            <span>크기</span>
                        </Col>
                        <Col xs={6}>
                            <span>{formatBytes(file.SizeBytes)}</span>
                        </Col>
                    </Row>
                    <Row className='file_box_item'>
                        <Col xs={6}>
                            <span>생성일자</span>
                        </Col>
                        <Col xs={6}>
                <span>
            {moment(new Date(getNumString(file.Created))).format('YYYY년 MM월 DD일 HH:mm:ss')}
                </span>
                        </Col>
                    </Row>
                    <Row className='file_box_item'>
                        <Col xs={6}>
                            <span>수정일자</span>
                        </Col>
                        <Col xs={6}>
                <span>
            {moment(new Date(getNumString(file.Modified))).format('YYYY년 MM월 DD일 HH:mm:ss')}
                </span>
                        </Col>
                    </Row>
                    <Row className='file_box_item'>
                        <Col xs={6}>
                            <span>수정 가능 파일</span>
                        </Col>
                        <Col xs={6}>
                <span>
            {file.IsEditable ? '가능' : '불가능'}
                </span>
                        </Col>
                    </Row>
                    <Row className='file_box_item'>
                        <Col xs={6}>
                            <span>다운로드 가능 파일</span>
                        </Col>
                        <Col xs={6}>
                <span>
            {file.IsDownloadable ? '가능' : '불가능'}
                </span>
                        </Col>
                    </Row>

                </div>
                <div className='file_action_btn_wrapper'>
                    {file.IsDownloadable && <button onClick={handleDownLoadFile}>다운로드</button>}
                    {file.IsEditable && <button onClick={toggle}>수정</button>}
                    <button onClick={handleChangeFileName}>파일명 바꾸기</button>
                    <ConfirmModal
                        id={'del_file'}
                        fullWidth
                        color={'primary'}
                        variant={'contained'}
                        type={'delFileManager'}
                        handleEvent={handleFileDel}/>
                </div>
                <FileModifyModal fileName={file.Filename} modal={modal} toggle={toggle}/>
            </Fragment>
            }
        </div>
    )
}


export default FileManagerItemBox;
