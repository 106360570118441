import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PerformanceSetting from "./PerformanceSetting";
import GameServerSetting from "./GameServerSetting";
import GamePlaySetting from "./GamePlaySetting";
import JavaMemorySetting from "./JavaMemorySetting";
import {useEffect, useState} from "react";
import {initProfile} from "../../../account/action";
import {checkErrorResponse} from "../../../../helpers/errorCheck";
import {useDispatch, useSelector} from "react-redux";
import {getGameSettingConfigs} from "../../action";
import {useParams} from "react-router-dom";
import {gameSettingNodes} from "../../../../datas/game";
import {updateStatusList} from "../../../Product/action";
import {CircularProgress} from "@material-ui/core";

function TabPanel(props) {
    const {loader, children, value, index, ...other} = props;

    return (
        <div
            className='w-100'
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className='game_tab_box' sx={{p: 3}}>
                    {loader ?
                        <div className={'game_tab_change_loader'}>
                            <CircularProgress/>
                        </div>
                        :
                        <Typography>{children}</Typography>
                    }
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function GameManageTabs() {
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    const [loader, setLoader] = useState(false);
    let {id} = useParams();
    const {sessionId} = useSelector(state => state.gameDeploy);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        setLoader(true);
        getGameSettingConfigs(sessionId, id, gameSettingNodes[value]).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    setLoader(false);

                }
            }
        }).catch(err => {
            checkErrorResponse(err);
            setLoader(false);
        })
    }, [value])

    return (
        <Box sx={{flexDirection: 'column',flexGrow: 1, display: 'flex', minHeight: 500}} className={'game_manage_tab_wrapper'}>
            <Tabs
                className='game_setting_tab'
                orientation="horizontal"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="game manage tab"
                sx={{color: 'white', borderRight: 1, borderColor: 'divider'}}
            >
                <Tab label="Gameplay and Difficulty" {...a11yProps(0)} />
                <Tab label="Server Settings" {...a11yProps(1)} />
                <Tab label="Performance and Limits" {...a11yProps(2)} />
                <Tab label="Java and Memory" {...a11yProps(3)} />
            </Tabs>
            <TabPanel loader={loader} value={value} index={0}>
                <GamePlaySetting/>
            </TabPanel>
            <TabPanel loader={loader} value={value} index={1}>
                <GameServerSetting/>
            </TabPanel>
            <TabPanel loader={loader} value={value} index={2}>
                <PerformanceSetting/>
            </TabPanel>
            <TabPanel loader={loader} value={value} index={3}>
                <JavaMemorySetting/>
            </TabPanel>
        </Box>
    );
}
