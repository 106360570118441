import React from "react";
import {useDispatch, useSelector} from "react-redux";
import * as OSData from "../../../datas/OS";
import {setOS} from "../action";
import {Row, Col} from "reactstrap";
import {CheckTriangle} from "../../common/CheckTriangle";


const ThreeTwoBit = () => {
    const {OS} = useSelector((state) => state.deploy.vultr);
    const dispatch = useDispatch();
    const OSKind = OSData.OSKinds.filter(os => os.name === 'debian');
    const handleSETOS = (one, osInfo) => {
        if (one) {
            const osDetail = osInfo[0];
            dispatch(setOS(osDetail, 'os'))
        }
    }
    return (
        <Row className='sf_list_wrapper'>
            <Col xs={12} sm={6} lg={4} xl={3}
                 onClick={() => handleSETOS(true, OSData.OSThreeTwo)}
                 className={OSData.OSThreeTwo[0].id === OS.type.id ? 'sf_item select_item' : 'sf_item'}>
                <div className='sf_content'>
                    {OSData.OSThreeTwo[0].id === OS.type.id && <CheckTriangle height={40} width={40}/>}
                    <div className='sf_img'>
                        <img alt='32_bit_os_img' src={`https://s3.server24.kr/panel/img/os/${OSKind[0].display.replace(" ", "")}.png`}/>
                    </div>
                    <div className='sf_version'>
                        <span id='os_name'>{OSKind[0].display}</span>
                        <small>8 i386</small>
                    </div>
                </div>
            </Col>
        </Row>

    )
}

export default ThreeTwoBit;
