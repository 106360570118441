import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Fragment, useEffect, useState} from "react";
import {FormGroup, Input} from "reactstrap";
import searchIcon from "../../../assets/img/support/search.svg";
import {useDispatch, useSelector} from "react-redux";
import {cpanelRegions} from "../../../datas/region";
import {isIterableArray} from "../../../utils/common";
import {useHistory} from "react-router-dom";
import {getWebHostingInstance} from "../action";
import {webHostingProductRow} from "../../constant/TableRow";
import ListSkeleton from "../../common/ListSkeleton";
import {useMediaQuery} from "react-responsive";
import ProductMobileTable from "../ProductMobileTable";
import {checkErrorResponse} from "../../../helpers/errorCheck";
import NotExistTable from "../../common/NotExistTable";
import {Fab} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {LinearProgress, Tooltip} from "@material-ui/core";
import InstanceMore from "../InstanceMore";
import WebHostingInstanceMore from "./WebHostingInstanceMore";
import WebHostingMobileProductTable from "./WebHostingMobileProductTable";
import {changePerNum} from "../../../helpers/common";


function EnhancedTableHead() {
    return (
        <TableHead>
            <TableRow>
                {webHostingProductRow.map((headCell, index) => (
                    <TableCell
                        className={'webHosting_product_table_header'}
                        key={headCell.id}
                        align={headCell.align}
                        style={{padding: '16px!important'}}
                    >
                            <span style={headCell.label === 'OS' ? {marginRight: '0.4rem'} : {marginRight: 0}}>
                            {headCell.label}
                        </span>
                    </TableCell>
                ))}
                <TableCell>
                    {' '}
                </TableCell>
            </TableRow>
        </TableHead>
    );
}


const RegionImgItem = ({regionId}) => {
    const regionInfo = cpanelRegions.filter(region => region.cp_region_id === regionId);
    return (
        <div className='server_item'>
            <img alt={'country_img'} width={30} src={`https://s3.server24.kr/panel/img/country/${regionInfo[0].country.replace(" ", "")}.png`}/>
            <span style={{marginLeft: '0.4rem'}}>{regionInfo[0].cp_city}</span>
        </div>
    )
}
export default function WebHostingProductTable() {
    const history = useHistory();
    const dispatch = useDispatch();
    const {webHostingList} = useSelector(state => state.instance);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(localStorage.getItem('pageNum') ? localStorage.getItem('pageNum') : 10);
    const [servers, setServers] = useState([]);
    const [status, setStatus] = useState('All');
    const [skeletonLoad, setSkeletonLoad] = useState(false);
    const [searchTxt, setSearchTxt] = useState('');


    useEffect(() => {
        getWebHostingInstance().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    setSkeletonLoad(true);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, []);

    useEffect(() => {
        if (webHostingList) {
            setServers(webHostingList);
        }
    }, [webHostingList])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const perNum = parseInt(event.target.value, 10);
        changePerNum(perNum)
        setRowsPerPage(perNum);
        setPage(0);
    };
    const handleSearchServer = (input) => {
        setSearchTxt(input);
        const newInstanceList = webHostingList.filter((instance) =>
            instance.vi_label.toLowerCase().includes(input));
        setServers(newInstanceList);
    }
    const handleChange = (event) => {
        setStatus(event.target.value);
        switch (event.target.value) {
            case 'All':
                setServers([...webHostingList]);
                break;
            case 'Running':
                setServers(webHostingList.filter(instance => instance.vi_power_status === 'running'));
                break;
            case 'Stopped':
                setServers(webHostingList.filter(instance => instance.vi_power_status === 'stopped'));
                break;

            default :
                setServers([...webHostingList]);
                break;
        }
    };


    const isMobile = useMediaQuery({
        query: "(max-width:880px)"
    });
    return (
        <div className='product_content'>
            <Box sx={{width: '100%'}}>
                <Paper sx={{width: '100%', mb: 2, boxShadow: 'none'}}>
                    {isIterableArray(servers) &&
                    <div className='server_filter_wrapper'>
                        <FormGroup>
                            <Input type="select"
                                   onChange={handleChange}
                                   value={status}
                                   name="select"
                                   id="server_status_sel">
                                <option value={'All'}>전체</option>
                                <option value={'Running'}>Running</option>
                                <option value={'Stopped'}>Stopped</option>
                            </Input>
                        </FormGroup>
                        <div className="server_search_wrapper">
                            <input
                                onChange={event => handleSearchServer(event.target.value)}
                                style={{backgroundImage: `url(${searchIcon})`}}
                                type="text" className="form-control"
                                placeholder="Search..."/>
                            <div>
                                <Fab onClick={() => history.push('/deploy')} color="primary" aria-label="add">
                                    <AddIcon/>
                                </Fab>
                            </div>
                        </div>
                    </div>
                    }
                    <ListSkeleton loading={skeletonLoad}>
                        {isIterableArray(servers) ?
                            <Fragment>
                                {
                                    isMobile ?
                                        <WebHostingMobileProductTable serverList={servers}/>
                                        :
                                        <TableContainer style={{overflowX: 'unset'}}>
                                            <Table
                                                size={'medium'}
                                            >
                                                <EnhancedTableHead/>
                                                <TableBody>
                                                    {servers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .map((row, index) => {
                                                            const trafficPercent = (row.cb_used_byte / (row.cp_day_traffic * (1024 * 1024 * 1024))) * 100;
                                                            const storagePercent = (row.cu_usage_disk / (row.cp_disk * (1024 * 1024))) * 100;
                                                            const cpanelUrl = row.cp_region === 'kr' ? 'https://cpanel.server24.kr/' : 'https://cpanelkorea.com:2083/';
                                                            const usageDiskValue = (row.cu_usage_disk / (1000 * 1000 * 1000)).toFixed(2);
                                                            return (
                                                                <TableRow
                                                                    className='web_hosting_product_item'
                                                                    key={index}>
                                                                    <TableCell onClick={() => window.open(cpanelUrl)}>
                                                                        <div className='d-block fw-bold'>
                                                                            <span>{row.cu_main_domain}</span>
                                                                        </div>
                                                                        <small className='small_font'>{row.cu_user_name}</small>
                                                                    </TableCell>
                                                                    <TableCell onClick={() => window.open(cpanelUrl)}>
                                                                        <img style={{borderRadius: '50%'}} width={30} src={'https://s3.server24.kr/panel/img/os/Cpanel.png'} alt='web_hosting_os_img'/>
                                                                    </TableCell>
                                                                    <TableCell onClick={() => window.open(cpanelUrl)}>
                                                                        <RegionImgItem regionId={row.cp_region}/>
                                                                    </TableCell>
                                                                    <TableCell onClick={() => window.open(cpanelUrl)}>
                                                                        <span>{(row.cu_cost + row.cu_cost_bandwidth).toLocaleString()}P</span>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        onClick={() => window.open(cpanelUrl)}
                                                                        width={260}
                                                                        align="left">
                                                                        <Tooltip title={`${row.cb_used_byte.toFixed(2)}G 사용 / ${row.cp_day_traffic ? row.cp_day_traffic + 'G 제공' : '무제한'}`} placement="top">
                                                                            <div className='web_hosting_bar_wrapper'>
                                                                                <LinearProgress
                                                                                    id={'web_hosting_traffic_bar'}
                                                                                    className={trafficPercent < 50 ? 'save_traffic_bar' : trafficPercent < 90 ? ' warning_bar' : 'error_bar'}
                                                                                    color={'primary'}
                                                                                    variant="determinate"
                                                                                    value={trafficPercent >= 100 ? 100 : trafficPercent}/>
                                                                                {row.cp_day_traffic ?
                                                                                    <small>{trafficPercent.toFixed(2)}%</small>
                                                                                    :
                                                                                    <small>무제한</small>
                                                                                }
                                                                            </div>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        onClick={() => window.open(cpanelUrl)}
                                                                        width={260}
                                                                        align="left">
                                                                        <Tooltip title={`${usageDiskValue}G 사용 / ${row.cp_disk}G 제공`} placement="top">
                                                                            <div className='web_hosting_bar_wrapper'>
                                                                                <LinearProgress
                                                                                    id={'web_hosting_storage_bar'}
                                                                                    className={storagePercent < 50 ? 'save_save_storage_bar' : storagePercent < 90 ? ' warning_bar' : 'error_bar'}
                                                                                    color={'primary'}
                                                                                    variant="determinate"
                                                                                    value={storagePercent}/>
                                                                                <small>{storagePercent.toFixed(2)}%</small>
                                                                            </div>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                    <TableCell align="center" className='instance_more_btn p-0'>
                                                                        <div className='server_more_item'>
                                                                            <WebHostingInstanceMore payStatus={row.cu_del_status} no={row.cu_no}/>
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                    }
                                                </TableBody>

                                            </Table>
                                        </TableContainer>
                                }
                                <TablePagination
                                    labelRowsPerPage={'페이지당 목록 수'}
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    component="div"
                                    count={servers.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Fragment>
                            : <NotExistTable searchExist={searchTxt.length !== 0} type={'webHosting'}/>}
                    </ListSkeleton>
                </Paper>
            </Box>
        </div>
    );
}
