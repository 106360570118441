import React, {useState} from "react";
import Clause from "./Clause";
import RegisterForm from "./RegisterForm";

const Register = () => {

    const [step, setStep] = useState(1);
    const [check, setCheck] = useState([]);
    const [agreePhoneAd, setAgreePhoneAd] = useState(0);
    const [agreeEmailAd, setAgreeEmailAd] = useState(0);
    return (
        <>
            {
                step === 1 ?
                    <Clause setAgreeEmailAd={setAgreeEmailAd} setAgreePhoneAd={setAgreePhoneAd} check={check} setCheck={setCheck} setStep={setStep}/>
                    :
                    <RegisterForm agreeEmailAd={agreeEmailAd} agreePhoneAd={agreePhoneAd}/>
            }
        </>
    )
}

export default Register;
