import React, {useEffect} from "react";
import {getPoint} from "../bill/action";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {isLogin} from "../../helpers/common";
import {checkErrorResponse} from "../../helpers/errorCheck";
import {isGameMode} from "../../utils/common";


export const PointBox = () => {
    const {layoutMode} = useSelector(state => state.layout);
    return (
        <div className={isGameMode(layoutMode) ? 'dark_bg_color3 navbar_point_wrapper' : 'navbar_point_wrapper'}>
            <div className={isGameMode(layoutMode) ? 'dark_bg_color2 navbar_point_box' : 'navbar_point_box'}>
                <p>상호명 : 데이터크레딧</p>
                <p>대표이사 : 한찬희</p>
                <p>전화번호 : 070-8672-1035</p>
                <p>사업자등록번호 : 329-12-01903</p>
                <p>통신판매업 신고번호 : 2022-경기하남-0519</p>
                <p>주소 : 경기도 하남시 미사강변중앙로7번안길 25 하남미사유테크밸리 D동 605호</p>
                <p>이메일 : support@server24.kr</p>
                <p>Copyrights 2021 SERVER24 All Rights Reserved.</p>
                <div className='company_policy_wrapper' style={{marginTop: '1.5rem', textAlign: 'center'}}>
                    <p><span onClick={() => window.open('https://server24.kr/tos/')}>이용약관</span> | <span onClick={() => window.open('https://server24.kr/privacy/')}>개인정보 처리방침</span></p>
                </div>
            </div>
        </div>
    )
}
