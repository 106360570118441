import React, {useEffect, useState} from "react";
import {Row, Col} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {setPrice} from "../action";
import bandWidthIcon from '../../../assets/img/server_info/bandwidth.png';
import cpuIcon from '../../../assets/img/server_info/cpu-tower.png';
import ramIcon from '../../../assets/img/server_info/ram.png';
import storageIcon from '../../../assets/img/server_info/hdd.png';
import DeployTitle from "../DeployTitle";
import {isIterableArray} from "../../../utils/common";
import {Alert, AlertTitle} from "@mui/lab";

const PriceList = () => {
    const dispatch = useDispatch();
    const {planList, service, price} = useSelector(state => state.deploy.vultr);
    const [filterPlanList, setFilterPlanList] = useState([]);
    useEffect(() => {
        if (isIterableArray(planList))
            setFilterPlanList(planList.filter(plan => plan.vp_type === service.type));
    }, [planList]);


    const handleSetPrice = (plan) => {
        dispatch(setPrice(plan))
    }

    return (
        <div className='price_list_wrapper'>
            <DeployTitle title={'요금제를 선택해주세요'}/>
            {isIterableArray(filterPlanList) ?
                <Row className='price_list_row'>
                    {
                        filterPlanList.map((plan, index) => {
                            return (
                                <Col onClick={() => handleSetPrice(plan)} className='plan_item_wrapper' key={index} xs={12} sm={6} lg={4} xl={3}>
                                    <div className={price.type.vp_id === plan.vp_id ? 'plan_item select_plan' : 'plan_item'}>
                                        {/*<span id='common_price' style={{textDecoration: 'line-through'}}>{plan.vp_monthly_cost_kr.toLocaleString()}P/월</span>*/}
                                        <div style={{textAlign: 'center'}}>
                                            <h3 id='event_price'>{plan.vp_monthly_cost_event_kr.toLocaleString()}P/월</h3>
                                            <span id='event_hour_price'>{plan.vp_day_cost_event_kr.toLocaleString()}P/일</span>
                                        </div>
                                        <hr/>
                                        <div className='plan_server_detail'>
                                            <div className='plan_performance_item'>
                                                <img src={cpuIcon}/>
                                                <span><strong>{plan.vp_vcpu_count}</strong> CPU(Core)</span>
                                            </div>
                                            <div className='plan_performance_item'>
                                                <img src={ramIcon}/>
                                                <span><strong>{plan.vp_ram}MB</strong> Memory</span>
                                            </div>
                                            <div className='plan_performance_item'>
                                                <img src={bandWidthIcon}/>
                                                <span><strong>{plan.vp_bandwidth}GB</strong> Traffic <small>(매일 기본 제공)</small></span>
                                            </div>
                                            <div className='plan_performance_item'>
                                                <img src={bandWidthIcon}/>
                                                <span><strong>1GB 당 15P 차감</strong><small>(1일 기본 제공량 초과시)</small></span>
                                            </div>
                                            <div className='plan_performance_item'>
                                                <img src={bandWidthIcon}/>
                                                <span><strong>10GbE+ Shared </strong>Ethernet</span>
                                            </div>
                                            <div className='plan_performance_item'>
                                                <img src={storageIcon}/>
                                                <span id='plan_disk'><strong>{plan.vp_disk}GB</strong> 고성능 SSD</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }

                </Row>
                :
                <Alert severity={'warning'}>
                    <AlertTitle>경고</AlertTitle>
                    <span>죄송합니다, 현재 일시적으로 해당 지역에 재고가 부족합니다. 나중에 다시 시도해주세요.</span>
                </Alert>
            }
        </div>
    )
}


export default PriceList;
