import React, {Fragment, useState} from "react";
import GameSelectionSection from "./deploySection/GameSelectionSection";
import {useDispatch, useSelector} from "react-redux";
import MineSelectionPrice from "./minecraft/MineSelectionPrice";
import GameDeployFooter from "./deploySection/GameDeployFooter";
import MineServiceSection from "./minecraft/MineServiceSection";
import GameSelectionRegion from "./deploySection/GameSelectionRegion";
import {stopServer} from "../../manage/action";
import {updateStatusList} from "../../Product/action";
import {checkErrorResponse} from "../../../helpers/errorCheck";
import {gameDeploy, setGameDeployLoader} from "../action";
import Swal from "sweetalert2";
import {notificationAlert} from "../../../helpers/common";
import MineGameTypeSection from "./minecraft/MineGameTypeSection";
import GameDeployWarning from "./GameDeployWarning";
import MineDeployLoader from "./minecraft/MineDeployLoader";
import {MineEulaAgree} from "./minecraft/MineEulaAgree";


const GameDeploy = () => {
    const {gameType, regionType, priceType, mcType, deployQty} = useSelector(state => state.gameDeploy);
    const [domain, setDomain] = useState('');
    const [serverLabel, setServerLabel] = useState('');
    const [loader, setLoader] = useState(false);
    const [checkPolicy, setCheckPolicy] = useState(false);
    const [eulaCheck, setEulaCheck] = useState(false);
    const dispatch = useDispatch();

    const handleGameDeploy = () => {
        if (!checkPolicy) {
            notificationAlert('알림', '유의사항을 체크하신후 동의하여 주세요.');
            return;
        }
        if (!eulaCheck) {
            notificationAlert('알림', 'EULA 약관을 체크하신후 동의하여 주세요.');
            return;
        }
        dispatch(setGameDeployLoader(true));
        setLoader(true);
        if (gameType === '') {
            notificationAlert('알림', '게임을 선택하여 주세요.');
            setLoader(false);
            return;
        }

        if (domain === '') {
            notificationAlert('알림', '도메인을 입력하여 주세요.');
            setLoader(false);
            return;
        }
        if (serverLabel === '') {
            notificationAlert('알림', '서버 라벨을 입력하여 주세요.');
            setLoader(false);
            return;
        }
        const postData = {
            plan: priceType,
            domain,
            region: regionType,
            cnt: deployQty,
            instanceName: serverLabel,
            mcType
        }
        gameDeploy(postData).then(response => {
            if (response) {
                if (response.request.status === 201) {
                    dispatch(response);
                    setLoader(false);
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '게임 서버가 생성 되었습니다.',
                        showConfirmButton: false,
                        timer: 1500
                    }).then(r => {
                        if (r) {
                            setTimeout(() => {
                                window.location.href = '/';
                            }, 5000)

                        }

                    })
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
            setLoader(false);
            dispatch(setGameDeployLoader(false));
        })
    }

    return (
        <div>
            <div className='game_deploy_box'>
                <div className='game_deploy_wrapper'>
                    <div className='container-md'>
                        <GameDeployWarning setCheckPolicy={setCheckPolicy}/>
                        <GameSelectionSection/>
                        {gameType === 'MINECRAFT' && <MineEulaAgree setEulaCheck={setEulaCheck}/>}
                        {gameType !== '' && <GameSelectionRegion/>}
                        {gameType === 'MINECRAFT' && <MineGameTypeSection/>}
                        {gameType === 'MINECRAFT' && <MineSelectionPrice/>}
                        {gameType === 'MINECRAFT' && <MineServiceSection setServerLabel={setServerLabel} setDomain={setDomain}/>}
                    </div>
                </div>
                <GameDeployFooter checkPolicy={checkPolicy} loader={loader} handleGameDeploy={handleGameDeploy}/>
            </div>
        </div>
    )
}

export default GameDeploy;
