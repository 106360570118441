export const faqListLeft = [
    {
        index: '1',
        title: 'cPanel 웹 호스팅이 무엇인가요?',
        content: '기존의 웹 호스팅은 별도의 프로그램을 설치하거나 다소 어려운 방식으로 설치하고 업체 관리자에게 의존하는 방식으로 관리해왔습니다.\n' +
            '\n' +
            '하지만, cPanel 이라는 강력한 웹 사이트 관리 도구와 함께라면 친근한 한국어로 웹 사이트를 클릭하며 만들고 관리하며 별도의 이메일 서버를 구축하지 않아도 이메일을 보내고 받을 수 있는 이메일 기능도 지원됩니다.\n' +
            '\n' +
            '데이터베이스는 물론이고 다양한 PHP 버전까지 이미 구성되어 있고 매일 진행되는 백업과 보안 감사 기능 덕분에 더 안심하고 웹사이트를 운영할 수 있습니다.\n' +
            '\n' +
            '여기를 누르셔서 저희가 제공해드리는 cPanel 의 강력한 기능들을 확인해보실 수 있습니다.'
    }, {
        index: '2',
        title: '서버나 홈페이지 이전작업도 해주시나요?',
        content: '네, 가능합니다!\n' +
            '\n' +
            '기존에 사용하시는 서버와 홈페이지 규모와 이용하실 서비스에 따라서 무상 혹은 유상으로 이전 작업을 도와드리고 있습니다.\n' +
            '\n' +
            '보다 정확한 상담을 위해 티켓으로 문의주시면 감사하겠습니다.'
    }, {
        index: '3',
        title: '서비스 이용 중에 과금이 발생되지 않나요?',
        content: '네, 그렇습니다.\n' +
            '\n' +
            '과금 발생 가능성이 표시되어 있지 않거나 사전에 안내받지 못한 경우, 종량제 서비스를 이용하고 계시다면 이용 중 과금이 발생하지 않습니다.\n' +
            '\n' +
            '다만 고객님의 요청이나  필요에 의해 증설된 서버 성능이나 회선에 대한 비용에 대해서는 추가로 발생할 수 있으니 요금 체계가 어려우시다면 꼭 저희 상담사와 상담하시어 서비스 이용에 불편 없으시기 바랍니다.'
    }, {
        index: '4',
        title: '서비스를 무료로 체험해볼 수 있나요?',
        content: '네, 서비스를 무료로 체험하실 수 있습니다!\n' +
            '\n' +
            '서비스 무료 체험 기간 중 미풍양속을 해하는 행위 혹은 위법한 용도의 사용을 미연에 방지하기 위해 간단한 개인 신원 확인 절차 후 무료 체험 기회를 제공해드리고 있습니다.\n' +
            '\n' +
            '보다 정확한 상담을 위해 티켓으로 문의주시면 감사하겠습니다.'
    }
]
export const faqListRight = [
 {
        index: '5',
        title: 'AWS 및 GCP 관련 컨설팅을 받을 수 있나요?',
        content: '네, 그렇습니다.\n' +
            '서버24는 전문 엔지니어의 24시간 모니터링은 물론, 백본 안정성과 국내외 연동망 경험이 풍부하여 예기치 못한 장애 발생에도 서비스 다운 없도록 서비스를 안정적으로 오케스트레이션 해드립니다.\n' +
            '\n' +
            '아울러, 전문적인 기술요원들에 의한 체계적인 관리 서비스를 통해 여러분들께 보다 안정적이고 유연한 서비스를 제공해 드릴 수 있도록 최선을 다하고 있습니다.'
    }, {
        index: '6',
        title: '게임 서버 용도로 사용할 수 있나요?',
        content: '네, 게임 서버 용도로 사용하실 수 있습니다!\n' +
            '\n' +
            '마인크래프트, Steam 게임은 물론, 직접 개발하신 게임까지 다양한 게임 서비스들을 무리 없이 운영하실 수 있습니다.\n' +
            '\n' +
            '지원되는 게임여부를 확실히 확인하시고 싶으신가요?\n' +
            '\n' +
            '보다 정확한 상담을 위해 티켓으로 문의주시면 감사하겠습니다.'
    }, {
        index: '7',
        title: '서비스 신청은 어디에서 하나요?',
        content: '\n' +
            '왼쪽 메뉴의 "서비스 신청" 페이지에서 새로운 서비스를 신청하실 수 있습니다.\n' +
            '\n' +
            '사용방법에 어려우시다면 언제든 티켓으로 문의주시면 친절하게 안내해드리겠습니다.'
    }, {
        index: '8',
        title: '서비스 이용 중에 업그레이드 할 수 있나요?',
        content: '네, 서비스 사용 도중에 서버 부하량 증가 등으로 인해 계약 기간이 만료되지 않더라도 언제든지 서버 성능이나 서비스를 업그레이드하실 수 있습니다.\n' +
            '\n' +
            '업그레이드로 인해 추가로 발생하는 비용은 기존에 사용하신 서비스 요금을 일할 계산하여 차액만큼만 추가로 결제하시면 서비스 이용 중에도 업그레이드가 가능합니다.'
    }
]
