import React from "react";
import {Col} from "reactstrap";
import {deployWarningPolicy, paymentAgreement, serverDeployPolicyAgreementTxt} from "../../datas/policy";
import DeployTitle from "./DeployTitle";
import {Checkbox, FormControlLabel} from "@mui/material";


const ServicePolicyBox = ({checkPolicy, setCheckPolicy}) => {

    return (
        <div className='service_policy_box'>
            <DeployTitle title={'유의사항'}/>
            <div className='warning_deploy_wrapper'>
                {deployWarningPolicy.split('\n')
                    .map((line, index) => {
                        return (
                            <span key={index}>{line}<br/></span>
                        )
                    })}
            </div>
            <div className='deploy_agreement_wrapper'>
                <FormControlLabel className='bill_check_label' control={<Checkbox onChange={event => setCheckPolicy(event.target.checked)}/>} label={serverDeployPolicyAgreementTxt}/>
            </div>
        </div>
    )
}

export default ServicePolicyBox;
