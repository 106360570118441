import React, {useEffect, useState} from "react";
import SnapShotsTable from "../snapshot/SnapShotsTable";
import SnapShotModal from "../snapshot/SnapShotModal";
import {useDispatch} from "react-redux";
import {getSnapShot} from "../snapshot/action";
import {Fab} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const ManageSnapshots = () => {
    const [openModal, setOpenModal] = useState(false);

    const toggle = () => {
        setOpenModal(!openModal)
    }


    return (
        <div className='manage_snapshot_wrapper'>
            <div className='snapshots_header'>
                <h3>스냅샷 생성 및 복원</h3>
                <Fab onClick={toggle} color="primary" aria-label="add"> <AddIcon/> </Fab>
            </div>
            <SnapShotsTable type={'manage'} toggle={toggle} location='instance'/>
            <SnapShotModal location='instance' toggle={toggle} collapse={openModal}/>
        </div>
    )
}


export default ManageSnapshots;
