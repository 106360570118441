import React from "react";
import DeployTitle from "../../deploy/DeployTitle";
import {Checkbox, FormControlLabel} from "@mui/material";
import {serverDeployPolicyAgreementTxt} from "../../../datas/policy";


const GameDeployWarning = ({setCheckPolicy}) => {

    return (
        <div className='game_deploy_warning_wrapper'>
            <DeployTitle title={'유의사항'}/>
            <div className='warning_txt'>
                <span>- 하단에 표시된 총 비용에 해당하는 포인트가 서비스 비용으로 즉시 차감됩니다.</span>
                <span>- 서비스 신청 후 1개월간 사용하실 수 있으며, 1개월은 30일로 계산됩니다.</span>
                <span>- 서비스를 이용하시면서 언제든지 상위 요금제로 업그레이드 하실 수 있습니다. </span>
            </div>
            <div className='deploy_agreement_wrapper'>
                <FormControlLabel className='deploy_agreement_label' control={<Checkbox onChange={event => setCheckPolicy(event.target.checked)}/>} label={serverDeployPolicyAgreementTxt}/>
            </div>
        </div>
    )
}


export default GameDeployWarning;
