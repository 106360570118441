import React, {useEffect, useState} from "react";
import loadImg from '../../../../assets/img/game/minecraft/loadingMine.gif';

const MineDeployLoader = () => {
    const [messageStep, setMessageStep] = useState(0);
    const message = ['리스폰 지역의 잔디를 깎는중..', '돌 계단의 흙먼지를 청소하는 중..', '주민 마을을 건설하는 중..'];

    useEffect(() => {
        const countdown = setInterval(() => {
            if (messageStep === 2) {
                setMessageStep(0);
            } else {
                setMessageStep(messageStep + 1);
            }
        }, 7000);
        return () => clearInterval(countdown);

    }, [messageStep]);


    return (
        <div className='mine_deploy_loader_wrapper'>
            <div className='loader_content'>
                <img src={loadImg} alt={'mine_load_gif'}/>
                <span>{message[messageStep]}</span>
            </div>
        </div>
    )
}

export default MineDeployLoader;
