import React, {Fragment, useState} from "react";
import ConfirmPopover from "../../common/ConfirmPopover";
import {RestoreBackupTxt} from "../../constant/Popover";
import {Button} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {restoreBackup} from "../action";
import {updateAllStatusList} from "../../Product/action";
import {checkErrorResponse} from "../../../helpers/errorCheck";
import ConfirmModal from "../../common/ConfirmModal";


const RestoreButton = ({backupId}) => {
    const dispatch = useDispatch();
    const {instanceInfo} = useSelector(state => state.manage);

    const handleRestoreInstance = () => {
        return new Promise((resolve, reject) => {
            const post = {
                backup_id: backupId
            }
            restoreBackup(instanceInfo.vi_id, post).then(response => {
                if (response) {
                    if (response.request.status === 202) {
                        dispatch(response);
                        dispatch(updateAllStatusList('active', 'stopped', 'locked', instanceInfo.vi_id));
                        resolve(true);
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
                reject(err);
            })
        })
    }


    return (
        <Fragment>
            <ConfirmModal
                onlyIcon={true}
                className='p-2'
                variant={'outlined'}
                color={'primary'}
                type={'restoreBackup'}
                handleEvent={handleRestoreInstance}/>
            {/*<Button*/}
            {/*    aria-describedby='restore_backup'*/}
            {/*    className='p-2'*/}
            {/*    variant={'outlined'}*/}
            {/*    color={'primary'}*/}
            {/*    onClick={event => setPopoverOpen(event.currentTarget)}>*/}
            {/*    복원*/}
            {/*</Button>*/}
            {/*<ConfirmPopover*/}
            {/*    anchorEl={popoverOpen}*/}
            {/*    setAnchorEl={setPopoverOpen}*/}
            {/*    type={'reinstall'}*/}
            {/*    loader={loader}*/}
            {/*    title={'백업을 복원 하시겠습니까?'}*/}
            {/*    btnTxt={'백업 복원'}*/}
            {/*    checkLabel={'네, 백업을 복원하겠습니다.'}*/}
            {/*    nodeId={'restore_backup'}*/}
            {/*    text={RestoreBackupTxt}*/}
            {/*    event={handleRestoreInstance}/>*/}
        </Fragment>
    )
}

export default RestoreButton;
