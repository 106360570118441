import React from "react";
import DeployTitle from "../../../deploy/DeployTitle";
import {Checkbox, FormControlLabel} from "@mui/material";
import {mineEulaAgreementTxt} from "../../../../datas/policy";


export const MineEulaAgree = ({setEulaCheck}) => {

    return (
        <div className='game_deploy_warning_wrapper'>
            <DeployTitle title={'Minecraft Server EULA'}/>
            <div className='warning_txt'>
                <span>- Minecraft 서버를 생성/실행하기 전에 Mojang EULA에 동의해야 합니다.</span>
                <span>- <strong>동의를 체크한경우 연결된 계약의 조건을 읽고 동의함을 나타냅니다.</strong></span>
                <span>- Related Link: <a href={'https://account.mojang.com/documents/minecraft_eula'} target={'_blank'}>Minecraft End User Licence Agreement</a></span>
            </div>
            <div className='deploy_agreement_wrapper'>
                <FormControlLabel className='deploy_agreement_label' control={<Checkbox onChange={event => setEulaCheck(event.target.checked)}/>} label={mineEulaAgreementTxt}/>
            </div>
        </div>
    )
}
