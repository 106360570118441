import React, {Fragment, useEffect, useState} from 'react';
import {
    Col, Collapse,
    Form,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';
import classNames from "classnames";
import {useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import LoaderButton from "../common/LoaderButton";
import {notificationAlert, sendMsg} from "../../helpers/common";
import {changePhone, phonePointEvent} from "./action";
import Button from '@mui/material/Button';
import sha256 from 'sha256';
import {checkErrorResponse} from "../../helpers/errorCheck";
import smartPhoneIcon from '../../assets/img/account/smartphone.svg';
import {toHHMMSS} from "../../utils/common";


const ChangePhoneModal = ({isNew, phoneModal, phoneModalOpen}) => {
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const [randomCode, setRandomCode] = useState();
    const [inputCode, setInputCode] = useState();
    const [okDisabled, setOkDisabled] = useState(true);
    const [phoneNum, setPhoneNum] = useState('');
    const [loader, setLoader] = useState(false);
    const [timerSec, setTimerSec] = useState(-1);
    const [phoneLoader, setPhoneLoader] = useState(false);
    const {register, handleSubmit, errors} = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        defaultValues: {}
    });


    useEffect(() => {
        if (timerSec === 0) {
            notificationAlert('오류', '시간이 초과되었습니다. 다시 시도해 주세요.')
            phoneModalOpen(false);
        }
        const timeOut = setTimeout(() => {
            if (timerSec > 0)
                setTimerSec(timerSec - 1)
        }, 1000);

        return () => clearTimeout(timeOut)
    }, [timerSec]);

    const handleChangePhone = () => {
        setPhoneLoader(true);
        changePhone({phone: phoneNum}).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    if (!isNew) {
                        phonePointEvent().then(eventResponse => {
                            if (eventResponse) {
                                if (eventResponse.request.status === 200) {
                                    dispatch(response);
                                    setPhoneLoader(false);
                                    phoneModalOpen(false);
                                    notificationAlert('알림', '이벤트 포인트가 지급되었습니다.');
                                }
                            }
                        }).catch(err => {
                            setPhoneLoader(false);
                            phoneModalOpen(false);
                            checkErrorResponse(err);
                        })
                    } else {
                        setPhoneLoader(false);
                        phoneModalOpen(false);
                    }

                }
            }
        }).catch(err => {
            setPhoneLoader(false);
            phoneModalOpen(false);
            checkErrorResponse(err);
        })
    }

    const handlePhone = (data, e) => {
        e.preventDefault();
        setLoader(true);
        setPhoneNum(data.phone);
        sendMsg(data.phone).then(response => {
            if (response.status === 200) {
                setLoader(false);
                setDisabled(true);
                setRandomCode(response.data.certNum);
                setTimerSec(180);
                notificationAlert('알림', "문자메세지를 확인해주세요.")

            }
        }).catch(err => {
            setLoader(false);
            if (err.response.status === 409) {
                setDisabled(false);
                setTimerSec(-1);
            }
            checkErrorResponse(err);
        })


    }

    const checkPhone = () => {
        const enCryptoCode = sha256(inputCode + process.env.REACT_APP_PHONE_KEY);
        if (enCryptoCode === randomCode) {
            setOkDisabled(false);
            setDisabled(false);
            notificationAlert('알림', '인증번호가 등록되었습니다.');
        } else {
            notificationAlert('오류', '잘못된 인증번호 입니다.');
        }
    }

    useEffect(() => {
        setDisabled(false);
        setOkDisabled(true);
        setRandomCode(null);
        setInputCode(null);
        setTimerSec(-1);
        setPhoneNum('');
    }, [phoneModal])

    // useEffect(() => {
    //     if (disabled) {
    //         const countdown = setInterval(() => {
    //             if (seconds > 0) {
    //                 setSeconds(seconds - 1);
    //             }
    //             if (seconds === 0) {
    //                 if (minutes === 0) {
    //                     clearInterval(countdown);
    //                     notificationAlert('오류', '시간이 초과되었습니다. 다시 시도해 주세요.')
    //                     phoneModalOpen(false);
    //                 } else {
    //                     setMinutes(minutes - 1);
    //                     setSeconds(59);
    //                 }
    //             }
    //         }, 1000);
    //         return () => clearInterval(countdown);
    //     }
    //
    // }, [minutes, seconds]);


    return (
        <Modal className='new_phone_modal' centered isOpen={phoneModal} toggle={() => phoneModalOpen(!phoneModal)}>
            <ModalHeader>
                <Row className='phone_modal_title'>
                    <Col xs={2} className='p-0'>
                        <img src={smartPhoneIcon} alt={'smartPhone_img'}/>
                    </Col>
                    <Col xs={10} className='p-0'>
                        {!isNew ?
                            <Fragment>
                                <h4>
                                    휴대전화 번호 인증
                                </h4>
                                <p className='small_font mt-2'>휴대전화 번호 최초 인증시 10,000포인트 즉시 지급!</p>
                            </Fragment>
                            :
                            <h4>
                                휴대전화 번호 수정
                            </h4>
                        }
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody>
                <div className='phone_modal_auth_wrapper'>
                    <Row className='phone_modal_auth'>
                        <Col xl={12} lg={12}>
                            <Row className='align-items-center'>
                                <Col xs={3} className='p-0'>
                                    <span>휴대전화 번호</span>
                                </Col>
                                <Col xs={9} className='p-0'>
                                    <Form id='new_phone_form' onSubmit={handleSubmit(handlePhone)}>
                                        <Row>
                                            <Col xs={8} className='p-0'>
                                                <FormGroup style={{marginTop: 0}} className="new_phone_input float-left d-inline-block">
                                                    <Input
                                                        id="phone"
                                                        name="phone"
                                                        type="tel"
                                                        maxLength="11"
                                                        placeholder="휴대전화 번호를 입력해주세요."
                                                        innerRef={register({
                                                            required: '휴대전화 번호를 입력해주세요.',
                                                            minLength: {
                                                                value: 11,
                                                                message: '휴대전화 번호 형식을 확인해주세요'
                                                            }
                                                        })}
                                                        readOnly={disabled}
                                                        disabled={disabled}
                                                        className={classNames({'border-danger': errors.phone})}
                                                    />
                                                    {errors.phone && <span style={{fontSize: '0.8rem'}} className="text-danger">{errors.phone.message}</span>}
                                                </FormGroup>
                                            </Col>
                                            <Col xs={4} className={'p-0'}>
                                                <LoaderButton
                                                    disabled={disabled || !okDisabled}
                                                    className="send_msg_btn ml-3"
                                                    color="primary"
                                                    variant={'outlined'}
                                                    type="submit"
                                                    form='new_phone_form'
                                                    loader={loader}
                                                >인증번호 전송
                                                </LoaderButton>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                    <Row style={{marginTop: '1.3rem'}} className='phone_modal_auth align-items-center'>
                        <Col xs={3} className='p-0'>
                            <span>인증번호</span>
                        </Col>
                        <Col xs={9} className='p-0'>
                            <Row>
                                <Col xs={8} className='p-0'>
                                    <FormGroup className="w-100 float-left d-inline-block position-relative">
                                        <Input
                                            disabled={!disabled}
                                            id="phoneCheck"
                                            name="phoneCheck"
                                            type={'text'}
                                            maxLength="5"
                                            placeholder="인증번호"
                                            value={inputCode}
                                            onChange={({target}) => setInputCode(target.value)}
                                            className={classNames({'border-danger': errors.phoneCheck})}
                                        />
                                        {disabled && <span id={'phone_auth_count'}>  {toHHMMSS(timerSec)}</span>}
                                        {errors.phoneCheck && <span className="text-danger fs--1">{errors.phoneCheck.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col xs={4} className={'p-0'}>
                                    <Button
                                        disabled={!disabled}
                                        style={{marginLeft: '1rem'}}
                                        color="primary"
                                        variant={'contained'}
                                        type="submit"
                                        onClick={() => checkPhone()}>
                                        확인
                                    </Button>
                                </Col>
                            </Row>


                        </Col>
                    </Row>
                </div>

            </ModalBody>
            <ModalFooter>
                <Button
                    style={{marginRight: '1rem'}}
                    color="primary"
                    variant={'outlined'}
                    onClick={() => phoneModalOpen(!phoneModal)}>
                    취소
                </Button>
                <LoaderButton
                    loader={phoneLoader}
                    color="primary"
                    variant={'contained'}
                    onClick={() => handleChangePhone()}
                    disabled={okDisabled}
                >확인
                </LoaderButton>
            </ModalFooter>
        </Modal>
    );
}

export default ChangePhoneModal;
