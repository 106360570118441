import React, {useState} from "react";
import {Col, Row} from "reactstrap";
import {Button, Checkbox, FormControlLabel} from "@mui/material";
import {loadTossPayments} from "@tosspayments/sdk";
import orderId from 'order-id';
import {billData} from "../../datas/BillData";
import accountIcon from '../../assets/img/bill/account.png';
import creditCardIcon from '../../assets/img/bill/credit-card.png';
import tossIcon from '../../assets/img/bill/tossLogo.png';
import giftCardIcon from '../../assets/img/bill/gift-card.png';
import transferAccountIcon from '../../assets/img/bill/transferAccount.png';
import gameGiftCardIcon from '../../assets/img/bill/gameGift.png';
import {paymentAgreement, paymentPolicy} from "../../datas/policy";
import {notificationAlert} from "../../helpers/common";
import {useSelector} from "react-redux";
import {isGameMode} from "../../utils/common";

const newOrderId = orderId(process.env.REACT_APP_ORDER_ID_KEY);

const payTypeRow = [
    {
        label: '신용카드',
        key: '카드',
        type: 'card',
        icon: creditCardIcon
    },
    {
        label: '토스 간편결제',
        key: '토스결제',
        type: 'card',
        icon: tossIcon
    },
    {
        label: '가상계좌',
        key: '가상계좌',
        type: 'vr-account',
        icon: accountIcon
    },
    {
        label: '계좌이체',
        key: '계좌이체',
        type: 'transfer-account',
        icon: transferAccountIcon
    },
    // {
    //     label: '문화상품권',
    //     key: '문화상품권',
    //     type: 'gift',
    //     icon: giftCardIcon
    // },
    // {
    //     label: '게임문화상품권',
    //     key: '게임문화상품권',
    //     type: 'gift',
    //     icon: gameGiftCardIcon
    // },

]


const ChargingPoint = () => {
    const {name, email} = useSelector(state => state.bill.billInfo.account);
    const {layoutMode} = useSelector(state => state.layout);
    const [point, setPoint] = useState(0);
    const [pointPrice, setPointPrice] = useState(0);
    const [payType, setPayType] = useState(null);
    const [payName, setPayName] = useState(null);
    const [agreementPolicy, setAgreementPolicy] = useState(false);
    const handlePayType = (type) => {
        setPayType(type);
        const payName = payTypeRow.filter(pay => pay.key === type);
        setPayName(payName[0].type);
    }
    const handleChargingPoint = async () => {
        if (payType) {
            if (point === 0) {
                notificationAlert('알림', '충전금액을 선택해주세요.')
                return;
            }

            const clientKey = process.env.REACT_APP_TOSS_CLIENT_KEY;
            const order_id = newOrderId.generate();
            let requestPayType = payType;
            if (payType === '자동카드') requestPayType = '카드';
            const tossPayments = await loadTossPayments(clientKey);
            await tossPayments.requestPayment(requestPayType, {
                amount: point,
                orderId: order_id,
                orderName: `${pointPrice} 포인트`,
                customerName: name,
                customerEmail: email,
                maxCardInstallmentPlan: 3,
                successUrl: window.location.origin + `/payment/${payName}/success/${point}`,
                failUrl: window.location.origin + `/payment/${payName}/fail/${point}`,
            }).catch(function (error) {

            })


        } else {
            notificationAlert('오류', '결제 수단을 선택해주세요.')
        }
    }


    return (
        <div className={isGameMode(layoutMode) ? 'charging_point_wrapper dark_bg_color1' : 'charging_point_wrapper'}>
            <Row className='charging_point_price'>
                <Col xs={12} lg={2} className='point_price_label'>
                    <label>충전 금액</label>
                </Col>
                <Col xs={12} lg={10} className='point_price_wrapper'>
                    <Row className='w-100 m-0'>
                        {billData.billPrice.map((price, index) => {
                            return (
                                <Col className={isGameMode(layoutMode) ? 'bill_point_type dark_type' : 'bill_point_type'} key={index} xs={6} lg={3}>
                                    <div onClick={() => {
                                        setPointPrice(price.point);
                                        setPoint(price.price);
                                    }} className={point === price.price ? 'point_price_item select_price' : 'point_price_item'}>
                                        <div className='top_point'><h3 className='d-inline'>{price.point.toLocaleString()} P</h3></div>
                                        <div className='bottom_price'><span>{price.price.toLocaleString()}원</span></div>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Col>
            </Row>
            <div className='charging_point_type'>
                <Row>
                    <Col xs={12} lg={2}>
                        <label>결제 수단</label>
                    </Col>
                    <Col xs={12} lg={10} className='p-0'>
                        <Row className='w-100 m-0'>
                            {payTypeRow.map((type, index) => {
                                return (
                                    <Col className='bill_pay_type' key={index} xs={6} lg={3}>
                                        <div onClick={() => handlePayType(type.key)} className={payType === type.key ? 'charging_point_type_item select_type' : 'charging_point_type_item'}>
                                            <div className='type_img'>
                                                <img className={type.key !== '토스결제' ? 'gray_img' : ''} src={type.icon}/>
                                            </div>
                                            <div className='type_label'><span>{type.label}</span></div>
                                        </div>
                                    </Col>
                                )

                            })}
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className='payment_policy_wrapper'>
                <Row>
                    <Col xs={12} lg={2}>
                        <label>포인트 정책 동의 및<br/> 결제 전 주의사항</label>
                    </Col>
                    <Col className='p-0' xs={12} lg={10}>
                        <div className='policy_txt_wrapper'>
                            <div className='policy_txt'>
                                {paymentPolicy.split('\n')
                                    .map((line, index) => {
                                        return (
                                            <p key={index}>{line}<br/></p>
                                        )
                                    })}
                            </div>
                        </div>
                        <FormControlLabel className='bill_check_label' control={<Checkbox onChange={event => setAgreementPolicy(event.target.checked)}/>} label={paymentAgreement}/>
                    </Col>

                </Row>
            </div>

            <div className='charging_point_btn'>
                <Row>
                    <Col className='p-0' xs={12} lg={2}/>
                    <Col className='p-0' xs={12} lg={10}>
                        <Button
                            disabled={!agreementPolicy}
                            onClick={handleChargingPoint}
                            variant={'contained'}
                            color={'primary'}>
                            결제하기
                        </Button>
                    </Col>
                </Row>

            </div>
        </div>
    )
}

export default ChargingPoint;
