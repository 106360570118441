import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/index.scss';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'moment/locale/ko'
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

