import React, {useEffect, useState} from "react";
import NavBar from "../nav/NavBar";
import ProductTable from "./ProductTable";
import {useDispatch, useSelector} from "react-redux";
import {initProfile, sessionExpired} from "../account/action";
import UserWelcome from "./UserWelcome";
import {TabContext, TabPanel} from "@mui/lab";
import Box from "@mui/material/Box";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import WebHostingProductTable from "./webHosting/WebHostingProductTable";
import ProductOverviewModal from "./overview/ProductOverview";
import {Modal, ModalBody} from "reactstrap";
import {Button} from "@mui/material";


const ServiceEndModal = () => {

    const [modal, setModal] = useState(true);

    return (
        <Modal centered isOpen={modal} toggle={() => setModal(false)} className={'product_overview_modal'}>
            <ModalBody>
                <div className='product_overview_wrapper'>
                    <p style={{padding: '2rem'}}>
                        <span style={{fontSize: '2rem'}}>
                        <strong>[서비스 종료 안내]</strong>
                            </span>
                            <br/>
                        <br/>
                        안녕하세요, 서버24를 이용해주셔서 감사합니다.<br/>
                        <br/>
                        아쉽게도, 서버24 서비스는 종료 예정입니다. 이에 따라 2024년 1월 31일을 기준으로 모든 서비스가 종료될 예정입니다.<br/>
                        <br/>
                        종료로 인해 불편을 끼쳐드려 죄송합니다. 서비스 종료로 인한 모든 데이터 및 기록은 영구적으로 소멸될 예정이오니, 필요한 정보는 미리 백업해주시기 바랍니다.<br/>
                        <br/>
                        서비스 종료일을 이후로는 아무런 데이터 및 서버 / 스냅샷 등 모든 서비스가 보안 및 개인정보 보호를 위해 소멸되오니 미리 백업 및 채널톡을 통해 저희측에게 문의해 주시기
                        바랍니다.
                        서비스 종료일 이후로는 당사측에 아무런 책임이 없으므로 이점 유의 부탁드리겠습니다.<br/>
                        <br/>
                        고객님께서 보유 중인 포인트에 대해서는 채널톡을 통해 문의주시면, 전액 환불 도움을 드리겠습니다. 우측 하단의 채널톡을 이용하여 문의해주시면 신속하게
                        처리해드리겠습니다.<br/>
                        <br/>
                        다시 한번 서버24를 이용해주셔서 감사드리며, 더 나은 서비스로 보답하는 미래를 기대합니다. 감사합니다.<br/>
                        <br/>
                        <span style={{fontSize: '1.4rem',color: 'red'}}>
                        <strong>서비스 종료일 : 2024년 1월 31일</strong>
                            </span><br/>
                        <br/>
                        <span style={{fontSize: '1.4rem',color: '#0a77d5'}}>
                        <strong>문의사항 : 우측하단 채널톡을 통해 이용바랍니다.</strong>
                            </span><br/>
                        <br/>
                        <strong> [서버24 운영팀 드림]</strong> <br/>
                    </p>
                    <Button
                        style={{padding: "0.5rem 0"}}
                        onClick={() => setModal(false)}
                        variant={'contained'}
                        color={'primary'}
                        fullWidth>
                        닫기</Button>
                </div>
            </ModalBody>
        </Modal>

    )
}


const Product = () => {
    const [value, setValue] = useState('1');
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 0);

        const sessionTimer = window.setTimeout(() => {
            dispatch(sessionExpired());
        }, 3300000);

        return () => {
            window.clearTimeout(sessionTimer)
        }
    }, []);


    const handleChange = (event, newValue) => {
        if (newValue === "3") {
            console.log(newValue)
            window.open('https://vps.easyhost.kr:4083')
        } else {
            setValue(newValue);
        }
    };
    return (
        <NavBar>
            <ServiceEndModal/>

            <div className='position-relative'>
                <div className='product_wrapper container-md'>
                    <UserWelcome/>
                    <div className='product_top'>
                        <h2>서버목록</h2>
                    </div>
                    <TabContext value={value}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <TabList onChange={handleChange} aria-label="">
                                <Tab label="서버 호스팅" value="1"/>
                                <Tab label="웹 호스팅" value="2"/>


                            </TabList>
                        </Box>
                        <TabPanel className='tab_panel p-0' value="1"><ProductTable toggle={toggle}/></TabPanel>
                        <TabPanel className='tab_panel p-0' value="2"><WebHostingProductTable
                            toggle={toggle}/></TabPanel>
                    </TabContext>

                </div>
            </div>
            <ProductOverviewModal modal={modal} toggle={toggle}/>
        </NavBar>
    )
}


export default Product
