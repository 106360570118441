export const minecraftSettingDesc = {
    "Force users to use the default game mode when they connect regardless of their state when they left": "유저가 다시 접속할 때 마다 게임모드를 기본으로 설정(변경)합니다",
    "The default game mode for players on the server": "서버 플레이어에게 기본적으로 설정할 게임모드를 지정합니다",
    "How difficult the game is for players in survival mode": "생존모드에서 사용할 난이도를 지정합니다",
    "What permissions 'opped' players have.": "op 권한을 가지고 있는 플레이어가 사용할 수 있는 권한의 한계를 지정합니다",
    "What permissions functions have when executed by in-game actions": "게임 내 기능을 실행할 때 어떤 권한을 가지고 있는지를 지정합니다",
    "Spigot only - the radius around the spawn point that is protected.": "Spigot 전용 - 리스폰 지역으로부터 보호할 주변 반경을 지정합니다",
    "Permanently ban players upon death.": "하드코어 모드 - 플레이어가 사망하면 자동으로 Ban(접속차단)되도록 설정합니다",
    "Whether or not monsters (such as zombies and skeletons) spawn in-game.": "몬스터(좀비 및 스켈레톤 등)가 게임 내에서 생성되는지 여부를 설정합니다",
    "Whether or not friendly animals (such as cows and pigs) spawn in-game.": "플레이어를 공격하지 않는 우호적인 동물(소, 돼지 등)이 게임 내에서 스폰되는지 여부를 설정합니다",
    "Whether or not friendly mobs (such as villagers) spawn in-game.": "플레이어를 공격하지 않는우호적인 몹(예: 주민)이 게임 내에서 생성되는지 여부를 설정합니다",
    "Control whether or not players may fight each other.": "(PVP) 플레이어가 서로 싸울 수 있는지 여부를 설정합니다",
    "Whether or not to allow players to use fly-hacks in survival mode.": "플레이어가 생존 모드에서 플라이를 사용할 수 있도록 허용할지 여부를 지정합니다",
    "Whether or not command blocks are permitted in-game.": "게임 내에서 커맨드 블록(Command Block)이 허용되는지 여부를 지정합니다",
    "How many minutes a player may stand idle/away before being kicked. (0 to disable)": "플레이어가 자리비움으로 인해 자동 Kick 될 때까지의 시간을 지정합니다 (기본값, 0은 비활성화)",
    "Controls who can access the server.": "화이트리스트를 활성화하여, 사전에 추가된 사용자만 접속할 수 있도록 설정합니다",
    "The maximum number of milliseconds a single tick may take before the server watchdog stops the server.": "서버 워치독이 서버를 중지하기 전에 단일 틱이 걸릴 수 있는 최대 시간(밀리초)입니다.",
    "The highest number of blocks above the ground that players may place blocks. (Removed in 1.17 and newer)": "플레이어 지을 수 있는 블록의 최대 높이를 지정합니다 (1.17 버전부터 제거됨)",
    "Which version of Java the server should be started with for server types that rely on Java": "사용하시는 버킷 등이 Java 버전에 민감할 경우 Java 버전을 다르게 지정하실 수 있습니다",
    "The maximum number of players on the server": "서버에 동시 접속할 수 있는 최대 플레이어 수를 지정합니다",
    "Automatically add official Minecraft server jars to the approved hash list.": "승인된 해시 목록에 공식 Minecraft 서버 Jar 파일 및 해시를 자동으로 추가합니다",
    "Headline to show in the server list.": "서버 목록에 표시할 헤드라인 메시지를 설정합니다",
    "": "",
    "When using the 'specific version' release stream, which version of Minecraft to use. Official server only.": "'특정 버전' 릴리스 스트림을 사용할 때 사용할 Minecraft 버전을 지정합니다. 공식(Official) 서버에 해당하는 옵션입니다",
    "When using the 'specific version' release stream, which version of the Spigot/CraftBukkit server to compile and install.": "'특정 버전' 릴리스 스트림을 사용할 때 컴파일하고 설치할 Spigot/CraftBukkit 서버 버전을 지정합니다",
    "Which specific version of the ForgeMod server to download. Consider [supporting the developers](https://www.patreon.com/LexManos) on Patreon!": "다운로드할 ForgeMod 서버의 특정 버전을 지정합니다",
    "When using the 'specific version' release stream, which version of the Sponge server to download.": "'특정 버전' 릴리스 스트림을 사용할 때 다운로드할 Sponge 서버의 버전을 지정합니다",
    "When using the 'specific version' release stream, which version of the Paper server to download.": "'특정 버전' 릴리스 스트림을 사용할 때 다운로드할 Paper 서버 버전을 지정합니다",
    "When using the 'specific version' release stream, which version of the Purpur server to download.": "'특정 버전' 릴리스 스트림을 사용할 때 다운로드할 Purpur 서버 버전을 지정합니다",
    "Which jar file to use. [Autoselect] picks the default for the given server type.": "사용할 jar 파일. [자동 선택]은 주어진 서버 유형에 대한 기본값을 선택합니다.",
    "Extra arguments to append at the end of those normally given to the Minecraft server, useful for doing forced spigot/paper upgrades. Don't use this for Java/GC arguments.": "Minecraft 서버에 일반적으로 제공되는 인수 끝에 추가할 추가 인수로, 강제 마개/종이 업그레이드를 수행하는 데 유용합니다. Java/GC 인수에는 이것을 사용하지 마십시오",
    "Tries to prevent players who are using proxies/VPNs from joining": "프록시나 VPN 으로 접속하는 플레이어를 차단하거나 자동으로 내보내도록 설정합니다",
    "Seed value to use for the world generator.": "월드 생성 시에 사용할 시드(Seed) 값을 지정합니다",
    "The type of terrain the server should generate.": "서버가 생성해야 하는 지형 유형을 설정합니다",
    "How far ahead clients can request chunk data. Higher values may degrade performance.": "클라이언트가 청크 데이터를 요청할 수 있는 최대 거리를 지정합니다. 값이 너무 높으면 성능이 저하될 수 있습니다",
    "How far away (in chunks) entities and blocks are updated. Higher values use more resources": "얼마나 멀리(청크 단위로) 있는 엔티티와 블록들이 업데이트되는지를 설정합니다. 값이 높을수록 더 많은 리소스를 사용합니다",
    "If enabled, the list of online playeres will not be included in server status requests": "이 설정을 활성화하면 온라인 플레이어 목록이 서버 상태 요청에 포함되지 않습니다",
    "Which world should the Minecraft server be started with.": "Minecraft 서버에서 기본적으로 사용하는 월드(World) 이름을 지정합니다",
    "Controls how the world generator will build the world. Requires the 'Flat' world type to be set.": "월드를 생성할 때 구축하는 방법을 지정합니다. 'Flat'와 같은 세계 유형을 설정할 수 있습니다",
    "Whether or not structures such as villages or mineshafts will be created": "서버에서 마을이나 갱도(광산) 등의 구조물의 생성 여부를 지정합니다",
    "Whether or not 'The Nether' can be accessed via portals.": "포털을 통해 'The Nether'에 액세스할 수 있는지 여부를 지정합니다",
    "If enabled, this requires the client have the specified resource pack before they connect.": "이 설정을 활성화하면 서버에 접속하기 전에 접속자가 지정된 리소스팩이 있는지 검사합니다",
    "The message shown to the user if they do not have the required resource pack when they are prompted for it. JSON formatted.": "서버에서 지정한 리소스팩이 없는 경우, 접속자에게 메시지로 표시할 내용을 JSON 형태로 설정할 수 있습니다 메시지입니다",
    "URL of a resource pack .zip file that clients should download and use.": "플레이어가 서버에 접속할 때 미리 다운로드하여 적용해야하는 리소스팩 .zip 파일의 URL 주소를 설정합니다",
    "A SHA1 Hash of the resource pack to be used in order to verify its integrity.": "무결성을 확인하기 위해 사용할 리소스 팩의 SHA1 해시를 지정할 수 있습니다",
    "Automatically restart the Minecraft server if the server (or a plugin) produces a 'severe' message": "서버(또는 플러그인)가 '심각한'(severe) 메시지를 출력하면 Minecraft 서버를 자동으로 다시 시작합니다"
}

export const minecraftSettingTitle = {
    "Force Game Mode": "게임 모드 강제설정",
    "Game Mode": "게임 모드",
    "Difficulty": "게임 난이도",
    "Op permission level": "Op 권한 수준",
    "Function permission level": "기능 권한 수준",
    "Spawn Protection Radius": "리스폰 보호 반경",
    "Hardcore Mode": "하드코어 모드",
    "Allow Monsters": "몬스터 스폰 설정",
    "Allow Animals": "동물 스폰 설정",
    "Allow NPCs": "NPC 스폰 설정",
    "Allow Player v.s. Player combat (PvP)": "PvP 여부 설정",
    "Allow Flight": "Fly 허용 여부",
    "Allow Command Blocks": "Command Blocks 허용 여부",
    "Player idle timeout minutes": "자리비움 설정",
    "Use Whitelist": "화이트리스트 사용 여부",
    "Max tick time": "최대 틱(tick) 타임",
    "Max build height": "최대 건설 가능한 높이",
    "Java Version": "Java 버전 지정",
    "Max Players": "최대 동시접속자",
    "Auto-add official to whitelist": "화이트리스트에 공식 자동 추가",
    "MOTD": "MOTD 설정",
    "Server Type": "서버 종류",
    "Release Stream": "릴리즈 스트림",
    "Official Version": "공식 버전",
    "Spigot/CraftBukkit Version": "Spigot/CraftBukkit 버전",
    "Forge Version": "Forge 버전",
    "Sponge Version": "Sponge 버전",
    "Paper Version": "Paper 버전",
    "Purpur Version": "Purpur 버전",
    "Server JAR": "Server JAR 경로",
    "Additional server arguments": "서버 추가 인수(Arguments)",
    "Disallow proxied connections": "Proxy/VPN 접속자 차단",
    "World Seed": "Seed 설정",
    "World Type": "월드 종류",
    "View Distance": "시야(Distance) 설정",
    "Simulation Distance": "시뮬레이션 거리",
    "Hide Online Players": "온라인 플레이어 숨기기",
    "Level Name": "레벨 이름",
    "Maximum World Size": "최대 세계 크기",
    "World Generator Settings": "월드 생성 설정",
    "Generate Structures": "구조 생성 설정",
    "Enable 'The Nether'": "The Nether 활성화 여부",
    "Require Resource Pack": "리소스팩 필수 여부",
    "Resource Pack Prompt": "리소스팩 프롬프트",
    "Resource Pack URL": "리소스팩 URL",
    "Resource Pack Hash": "리소스팩 Hash",
    "Restart on errors": "오류 발생 시 자동 재시작"
}
