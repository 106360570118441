export const ipv4Row = [
    {
        name: 'Address',
        minWidth: 250,
        id: 'vi4_ip',
        type: 'string'
    },
    {
        name: 'Netmask',
        minWidth: 50,
        id: 'vi4_netmask',
        type: 'string'
    },
    {
        name: 'Gateway',
        minWidth: 50,
        id: 'vi4_gateway',
        type: 'string'
    },
    {
        name: 'Reverse DNS',
        minWidth: 50,
        id: 'vi4_reverse',
        type: 'string'
    }
];
export const ipv6Row = [
    {
        name: 'Address',
        minWidth: 250,
        id: 'vi_v6_main_ip',
        type: 'string'
    },
    {
        name: 'Network',
        minWidth: 50,
        id: 'vi_v6_network',
        type: 'string'
    },
    {
        name: 'Netmask',
        minWidth: 50,
        id: 'vi_v6_network_size',
        type: 'string'
    },
    // {
    //     name: 'Default Gateway',
    //     minWidth: 50,
    //     id: 'vi_reverse_dns',
    //     type: 'string'
    // }
];

export const backUpRow = [
    {
        name: 'Name',
        minWidth: 250,
        id: 'vb_description',
        type: 'string'
    },
    {
        name: 'OS',
        minWidth: 50,
        id: 'vi_os_id',
        type: 'img'
    },
    {
        name: 'Size',
        minWidth: 50,
        id: 'vb_size',
        type: 'string'
    },
    {
        name: 'Date',
        minWidth: 50,
        id: 'vb_date_created',
        type: 'string'
    },

    {
        name: 'Status',
        minWidth: 50,
        id: 'vb_status',
        type: 'string'
    }
]

export const instanceBackUpRow = [
    {
        name: 'Name',
        minWidth: 250,
        id: 'vb_description',
        type: 'string'
    },
    {
        name: 'Size',
        minWidth: 50,
        id: 'vb_size',
        type: 'string'
    },
    {
        name: 'Date',
        minWidth: 50,
        id: 'vb_date_created',
        type: 'string'
    },
    {
        name: 'Status',
        minWidth: 50,
        id: 'vb_status',
        type: 'string'
    }
]

export const webHostingProductRow = [
    {
        id: 'cu_main_domain',
        numeric: false,
        disablePadding: true,
        label: 'SERVER NAME',
        align: 'left'
    },
    {
        id: 'cpanel',
        numeric: false,
        disablePadding: true,
        label: 'OS',
        align: 'left'
    },
    {
        id: 'cp_region',
        numeric: false,
        disablePadding: true,
        label: 'LOCATION',
        align: 'left'
    },
    {
        id: 'cu_cost',
        numeric: false,
        disablePadding: true,
        label: 'CHARGES',
        align: 'left'
    },
    {
        id: 'cb_used_byte',
        numeric: false,
        disablePadding: true,
        label: 'TRAFFIC',
        align: 'center'
    },
    {
        id: 'cu_usage_disk',
        numeric: false,
        disablePadding: true,
        label: 'STORAGE',
        align: 'center'
    },
]

export const productRow = [
    {
        id: 'vi_label',
        numeric: false,
        disablePadding: false,
        label: 'SERVER NAME',
        align: 'left'
    },
    {
        id: 'vi_os_id',
        numeric: false,
        disablePadding: false,
        label: 'OS',
        align: 'right'
    },
    {
        id: 'Location',
        numeric: true,
        disablePadding: false,
        label: 'LOCATION',
        align: 'right'
    },
    {
        id: 'Charges',
        numeric: true,
        disablePadding: false,
        label: 'CHARGES',
        align: 'right'
    },
    {
        id: 'vi_power_status',
        numeric: true,
        disablePadding: false,
        label: 'STATUS',
        align: 'right'
    },
];

export const snapShotListRow = [
    {
        name: 'Name',
        minWidth: 250,
        id: 'vs_description',
        type: 'string'
    },
    {
        name: 'OS',
        minWidth: 50,
        id: 'vs_os_id',
        type: 'img'
    },
    {
        name: 'Size',
        minWidth: 50,
        id: 'vs_size',
        type: 'string'
    },
    {
        name: 'Date',
        minWidth: 50,
        id: 'vs_date_created',
        type: 'string'
    },

    {
        name: 'Status',
        minWidth: 50,
        id: 'vs_status',
        type: 'string'
    }
]
