import React from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import ListSkeleton from "../common/ListSkeleton";
import {getPointUsageType} from "./funtions";
import {useMediaQuery} from "react-responsive";
import MobilePointUsageTable from "./mobile/MobilePointUsageTable";
import {getPointUsage} from "./action";
import {checkErrorResponse} from "../../helpers/errorCheck";
import {isGameMode, isIterableArray} from "../../utils/common";
import {changePerNum} from "../../helpers/common";


const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'white',
        color: '#8297AA',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#F4F8FA',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function PointUsageTable({loader}) {
    const dispatch = useDispatch();
    const {pointUsageRecord, pointUsageMetaData} = useSelector(state => state.bill);
    const {layoutMode} = useSelector(state => state.layout);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(localStorage.getItem('pageNum') ? localStorage.getItem('pageNum') : 10);
    const isMobile = useMediaQuery({
        query: "(max-width:880px)"
    });
    const handleChangePage = (event, newPage) => {
        let pageLink = '';
        if (page < newPage) pageLink = pointUsageMetaData.links.next
        else pageLink = pointUsageMetaData.links.previous
        getPointUsage(pageLink).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    setPage(newPage);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })

    };

    const handleChangeRowsPerPage = (event) => {
        getPointUsage(`point/usage?page=${0}&per_page=${event.target.value}`).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    const perNum = parseInt(event.target.value, 10);
                    changePerNum(perNum)
                    setRowsPerPage(perNum);
                    setPage(0);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })


    };

    return (
        <Fragment>
            <ListSkeleton height={350} type={'rectangular'} itemNum={1} colNum={12} loading={loader}>
                {isIterableArray(pointUsageRecord) ?
                    <Fragment>
                        {isMobile ?
                            <MobilePointUsageTable/>
                            :
                            <Fragment>
                                <Table className={isGameMode(layoutMode) ? 'dark_table_mode' : ''} sx={{minWidth: 700}} aria-label="bill table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">분류</StyledTableCell>
                                            <StyledTableCell align="left">서비스 항목</StyledTableCell>
                                            <StyledTableCell align="right">차감 포인트</StyledTableCell>
                                            <StyledTableCell align="center">차감일</StyledTableCell>
                                            <StyledTableCell align="center">비고</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {pointUsageRecord.map((payment, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="center">{payment.type}</StyledTableCell>
                                                <StyledTableCell align="left">
                                                    <span className='d-block'>{`${payment.name}`}</span>
                                                    <span>{payment.subName}</span>


                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {(payment.ipCost + payment.ddosCost + payment.dayCost
                                                        + payment.bandwidthCost + payment.backupCost + payment.snapshotCost + payment.gameCost).toLocaleString()}P
                                                </StyledTableCell>
                                                <StyledTableCell align="center">{payment.time}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {payment.more
                                                        .split('\n')
                                                        .map((line, index) => {
                                                            return (
                                                                <span key={index}>{line}<br/></span>
                                                            )
                                                        })}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    className={isGameMode(layoutMode) ? 'dark_table_pagination' : ''}
                                    labelRowsPerPage={'페이지당 목록 수'}
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    component="div"
                                    count={pointUsageMetaData.total_count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Fragment>
                        }
                    </Fragment>
                    :
                    <div className='not_exist_txt_wrapper'>
                        <span>포인트 사용내역이 존재하지 않습니다.</span>
                    </div>
                }
            </ListSkeleton>
        </Fragment>
    );
}
