import React, {Fragment, useState} from "react";
import {Col, Row} from "reactstrap";
import {Collapse} from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ScriptDetail from "./ScriptDetail";

const MobileScriptTable = ({scriptList}) => {
    const [open, setOpen] = useState(-1);
    const handleOpen = (index) => {
        if (open === index) setOpen(-1);
        else setOpen(index);
    }

    return (
        <div className='mobile_table_wrapper'>
            {scriptList.map((script, index) => {
                return (
                    <Fragment key={index}>
                        <Row onClick={() => handleOpen(index)} className='mobile_item align-items-center'>
                            <Col className='p-0' xs={8}>
                                <span id='script_name' className='d-block fw-bold'>{script.vsc_name}</span>
                                <small>{script.vsc_id}</small>
                            </Col>
                            <Col style={{textAlign : 'right'}} className='p-0 fw-bold' xs={2}>
                                <span id='script_type'>{script.vsc_type === 'boot' && 'Boot'}</span>
                            </Col>
                            <Col style={{textAlign : 'right'}} xs={2} className='p-0'>
                                <ArrowForwardIosIcon className={open === index ? 'open_script mobile_script_more_btn' : 'mobile_script_more_btn'}/>
                            </Col>
                        </Row>
                        <Collapse in={open === index} timeout="auto" unmountOnExit>
                            <ScriptDetail info={script}/>
                        </Collapse>
                    </Fragment>
                )
            })}
        </div>
    )
}

export default MobileScriptTable;
