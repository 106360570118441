import React, {Fragment, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {useDispatch, useSelector} from "react-redux";
import {Ipv4DeleteTxt} from "../../constant/Popover";
import ConfirmPopover from "../../common/ConfirmPopover";
import {delInstanceIpv4, updateReverseDns} from "../action";
import {Chip} from "@mui/material";
import ViewTxtInput from "../../common/ViewTxtInput";
import {checkErrorResponse} from "../../../helpers/errorCheck";
import ConfirmModal from "../../common/ConfirmModal";


const Ipv4Item = ({dispatch, instanceId, ipv4IP, over, setOver, index}) => {


    const handleDelIpv4 = () => {
        return new Promise((resolve, reject) => {
            delInstanceIpv4(instanceId, ipv4IP).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        dispatch(response);
                        resolve(true);
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
                reject(err);
            })
        })
    }


    return (
        <Fragment>
            <ConfirmModal
                onlyIcon={true}
                className='remove_ipv4_btn'
                type={'deleteIpv4'}
                handleEvent={handleDelIpv4}/>
            {/*<button*/}
            {/*    className='remove_ipv4_btn'*/}
            {/*    aria-describedby='del_ipv4'*/}
            {/*    onClick={event => setPopoverOpen(event.currentTarget)}*/}
            {/*    onMouseEnter={() => setOver(index)}*/}
            {/*    onMouseLeave={() => setOver(-1)}>*/}
            {/*    <DeleteIcon color={over === index ? 'error' : 'disabled'}/>*/}
            {/*</button>*/}
            {/*<ConfirmPopover*/}
            {/*    popLocation={'right'}*/}
            {/*    anchorEl={popoverOpen}*/}
            {/*    setAnchorEl={setPopoverOpen}*/}
            {/*    type={'restart'}*/}
            {/*    loader={loader}*/}
            {/*    title={'IP 주소를 제거하시겠습니까? '}*/}
            {/*    btnTxt={'IP 삭제'}*/}
            {/*    checkLabel={'네, IP 주소를 삭제하겠습니다.'}*/}
            {/*    nodeId={'del_ipv4'}*/}
            {/*    text={Ipv4DeleteTxt}*/}
            {/*    event={() => handleDelIpv4(ipv4IP)}/>*/}
        </Fragment>
    )
}


const Ipv4Body = ({dataRow, idS}) => {
    const dispatch = useDispatch();
    const {instanceInfo} = useSelector(state => state.manage);
    const [over, setOver] = useState(-1);
    const handleSetReverseDns = (rDNS, ip) => {
        return new Promise(((resolve, reject) => {
            const post = {
                reverse: rDNS,
                ip: ip
            }
            updateReverseDns(instanceInfo.vi_id, post).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        dispatch(response)
                        resolve(true);
                    }
                }
            }).catch(err => {
                if (err) {
                    reject(err);
                }
            })
        }))
    }

    return (
        <tbody>
        {
            dataRow.map((data, index) => {
                const isMain = data.vi4_type === 'main_ip';
                return (
                    <Fragment key={index}>
                        <tr className='txt_table_tr'>

                            {idS.map((id, index) => {
                                if (id === 'vi4_ip') {
                                    return (
                                        <td key={index}>
                                            {isMain ?
                                                <Fragment>
                                                    <span>{data[id]}</span>
                                                    <Chip
                                                        style={{marginLeft: '1rem',  color: '#20A144', backgroundColor: '#E7FBF0'}}
                                                        label="Main IP"
                                                    />
                                                </Fragment>
                                                :
                                                <span>{data[id]}</span>
                                            }

                                        </td>
                                    )
                                }
                                if (id === 'vi4_reverse') {
                                    return (
                                        <td key={index}>
                                            <ViewTxtInput data={data.vi4_ip} clickEvent={handleSetReverseDns} inputValue={data[id]}>
                                                <span>{data[id]}</span>
                                            </ViewTxtInput>
                                        </td>
                                    )
                                }

                                return (
                                    <td key={index}>
                                        <span>{data[id]}</span>
                                    </td>
                                )

                            })}

                            <td>
                                {!isMain &&
                                <Ipv4Item
                                    over={over}
                                    setOver={setOver}
                                    index={index}
                                    dispatch={dispatch}
                                    instanceId={instanceInfo.vi_id}
                                    ipv4IP={data.vi4_ip}/>
                                }
                            </td>

                        </tr>
                    </Fragment>)
            })
        }
        </tbody>
    )
}

export default Ipv4Body;
