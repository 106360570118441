import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import {RouteIf} from "../hoc/RouteIf";
import Login from "../components/login/Login";
import DashboardLayout from "./DashboardLayout";
import Register from "../components/register/Register";
import FindId from "../components/find/FindId";
import FindPwd from "../components/find/FindPwd";
import {isLogin} from "../helpers/common";
import PasswordReset from "../components/find/PasswordReset";
import {useDispatch, useSelector} from "react-redux";
import {getCookie, isGameMode, showConsoleArt} from "../utils/common";
import {io} from "socket.io-client";
import {updateAllStatusList, updateInstanceMainIp} from "../components/Product/action";
import Product from "../components/Product/Product";
import {useLocalStorage} from "../hoc/useLocalStorage";
import {changeLayoutMode} from "./action";
import GameDashboard from "../components/game/dashboard/GameDashboard";
import {ThemeProvider} from "@mui/material";
import {createTheme} from "@material-ui/core";
import CentosPanel from "../components/panel/CentosPanel";


const Layout = () => {
    const dispatch = useDispatch();
    const [isUser, setIsUser] = useState(isLogin());
    const [layout, setLayout] = useLocalStorage('server24_mode', 'origin');
    const {layoutMode} = useSelector(state => state.layout);

    useEffect(() => {
        showConsoleArt();
        dispatch(changeLayoutMode(layout));
        const isUser = isLogin();
        if (isUser) {
            setIsUser(isUser);
            try {
                const useEmail = getCookie('last_login_username');
                const ENDPOINT = `https://${process.env.REACT_APP_API_SERVER}`;
                const socket = io(ENDPOINT);
                socket.on("statusChange", data => {

                    dispatch(updateAllStatusList(data.vi_status, data.vi_power_status, data.vi_server_status, data.vi_id));
                    dispatch(updateInstanceMainIp(data.vi_id, data.vi_main_ip));
                });

                socket.on("connect", () => {
                    socket.emit('login', {
                        email: useEmail,
                        socketId: socket.id
                    })
                });

                socket.on("disconnect", () => {

                });
            } catch (e) {

            }


        }
    }, [])

    useEffect(() => {

        if (layoutMode) {
            if (layoutMode === '')
                dispatch(changeLayoutMode(layout));
            else {
                setLayout(layoutMode);

            }
        }
    }, [layoutMode])


    return (
        <Router>
            <Switch>

                <RouteIf exact path="/" option={null} component={isUser ? isGameMode(layoutMode) ? GameDashboard : Product : Login}/>
                {/*<RouteIf path="/login" option={null} component={Login}/>*/}
                <RouteIf path="/register" option={null} component={Register}/>
                {/*<RouteIf exact path="/centosPanel" option={true} component={CentosPanel}/>*/}
                <RouteIf path="/find/id" option={null} component={FindId}/>
                <RouteIf path="/find/pwd" option={null} component={FindPwd}/>
                <RouteIf path="/resetPwd/:token" option={null} component={PasswordReset}/>
                <RouteIf component={DashboardLayout}/>
            </Switch>
        </Router>
    )

}


export default Layout;
