import React, {useRef, useState} from "react";
import {useSelector} from "react-redux";
import {isIterableArray} from "../../../../utils/common";
import ConsoleList from "./ConsoleList";
import {sendConsoleCommand} from "../../action";
import {checkErrorResponse} from "../../../../helpers/errorCheck";


const GameManageConsole = ({instanceId}) => {
    const {consoleList, sessionId} = useSelector(state => state.gameDeploy);
    const [command, setCommand] = useState('');
    const commandInputRef = useRef(null);
    const handleSendCommand = (e) => {
        if (e.key === 'Enter') {
            sendConsoleCommand(sessionId, instanceId, command).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        commandInputRef.current.value = '';
                    }

                }
            }).catch(err => {
                checkErrorResponse(err);
            })
        }
    }

    return (
        <div className='game_manage_console'>
            <div className='console_wrapper'>
                <div className='console_list'>
                    {isIterableArray(consoleList) && <ConsoleList/>}
                </div>

                <div className='console_writer'>
                    <input ref={commandInputRef} onKeyPress={handleSendCommand} onChange={event => setCommand(event.target.value)} type={'text'}/>
                </div>
            </div>

        </div>
    )
}

export default GameManageConsole;
