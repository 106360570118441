export const ToastMsg = {
    NotActiveBackupMsg: '요청에 따라 자동 백업 기능이 비활성화되었으며, 활성화 후 비활성화된 서비스에 대해 자동으로 활성화 할 수 없습니다.\n' +
        '활성화가 필요하신 경우 티켓으로 문의주시면 현재 서버에 대한 자동 백업 기능을 다시 활성화해드리겠습니다.',

    WarningInactiveBackupMsg: '비활성화 하시면 기존 백업이 수 일 내 삭제되며, 다시 활성화하실 수 없습니다.',

    NeverActiveBackupMsg: '자동 백업이 비활성화되었으며 고객 포털에서 이 서버에 대해 다시 활성화 할 수 없습니다. \n' +
        '실수로 비활성화된 경우 기술지원팀 문의하여 이 서버에 대한 DDoS 보호를 다시 활성화하십시오. ',

    ActiveDdosMsg: '디도스 방어 서비스를 활성화할 경우 월 100,000 포인트가 차감됩니다. 활성화 후 실제 적용까지는 최대 5분이 소요될 수 있습니다.\n' +
        '보다 효과적인 방어를 위해 서버 내 DNS 및 네트워크 설정을 자동(DHCP)으로 유지하시기 바랍니다.',

    EnabledDdosMsg: '현재 이 서버에 대한 10G 디도스 방어 서비스가 활성화되어 있습니다.',

    WarningDdosMsg: '디도스 방어 서비스를 비활성화 하실 경우 다시 활성화 할 수 없습니다.',

    NeverActiveDdosMsg: '요청에 따라 디도스 방어 서비스가 비활성화되었으며, 활성화 후 비활성화된 서비스에 대해 자동으로 활성화 할 수 없습니다. \n' +
        '활성화가 필요하신 경우 티켓으로 문의주시면 현재 서버에 대한 디도스 방어 서비스를 다시 활성화해드리겠습니다.',

    NotActiveIpv6Msg: '현재 이 IPv6 주소를 사용할 수 있지만, 서버 내부에서 별도의 설정이 필요할 수 있습니다.',

    ChangeHostNameMsg: '이 기능을 통해 호스트네임을 변경하실 경우, 서버의 모든 데이터가 삭제되고 재설치됩니다.',

    ChangePlanMsg: '요금제를 업그레이드하실 경우, 즉시 서버의 전원이 재시작되고 해당 요금제의 성능이 반영됩니다.\n' +
        '또한 요금제 업그레이드 이후에는 하위 요금제로의 다운그레이드가 불가능합니다.\n' +
        '부가서비스 요금은 별도입니다.',

    MountIsoMsg: 'ISO 연결과 해제를 위해, 서버 전원 재시작이 요구될 수 있습니다.',

    UnmountIsoMsg: 'ISO 해제를 위해, 서버 전원 재시작이 필요합니다.',

    AuthenticationBusinessMsg: '기업 인증이 완료되었습니다.',

    ChangeGamePlanMsg: '- 요금제를 업그레이드하실 경우, 즉시 서버의 전원이 재시작되고 해당 요금제의 성능이 반영됩니다.\n' +
        '- 또한 요금제 업그레이드 이후에는 하위 요금제로의 다운그레이드가 불가능합니다.\n' +
        '- 데이터에 분실이 발생할수 있으므로 백업을 권장합니다. 데이터 분실시 책임은 고객 본인에게 있습니다.'
}


