import React from "react";
import * as OSData from '../../../datas/OS';
import {groupBy} from "../../../helpers/common";
import {useDispatch, useSelector} from "react-redux";
import {setOS} from "../action";
import SelectVersion from "./SelectVersion";
import {Row, Col} from "reactstrap";
import {CheckTriangle} from "../../common/CheckTriangle";
import {Tooltip} from "@mui/material";

const SixFourBit = () => {
    const {OS} = useSelector((state) => state.deploy.vultr);
    const dispatch = useDispatch();
    const OSGroup = groupBy(OSData.OSSixFour, 'family');
    const handleSETOS = (one, osInfo) => {
        if (one) {
            const osDetail = osInfo[0];
            dispatch(setOS(osDetail, 'os'))
        }
    }
    return (
        <Row className='sf_list_wrapper'>

            {OSData.OSKinds.map((os, index) => {
                    const osInfo = OSGroup[os.name];
                    let isSelect = osInfo.some(item => item.id === OS.type.id);
                    if (os.name === 'windows') {
                        isSelect = osInfo.some(item => item.id === OS.type.vs_id);
                    }
                    const oneVersion = osInfo.length === 1;
                    return (
                        <Col key={index} xs={12} sm={6} lg={4} xl={3}
                             onClick={() => handleSETOS(oneVersion, osInfo)}
                             className={isSelect ? 'sf_item select_item' : 'sf_item'}>
                            {os.name === 'windows' ?
                                <Tooltip placement={'top'} title={"서비스 신청 후, 콘솔 보기 기능을 통해 윈도우 암호를 설정하셔야 원격 접속이 가능합니다."}>
                                    <div className='sf_content'>
                                        {isSelect && <CheckTriangle height={40} width={40}/>}
                                        <div className='sf_img'>
                                            <img
                                                src={`https://s3.server24.kr/panel/img/os/${os.display.replace(" ", "")}.png`}/>
                                        </div>
                                        <div className='sf_version'>
                                            <span id='os_name'>{os.display}</span>
                                            {oneVersion ?
                                                <small>8 x64</small>
                                                :
                                                <SelectVersion osInfo={osInfo}/>
                                            }
                                        </div>
                                    </div>
                                </Tooltip>
                                :
                                <div className='sf_content'>
                                    {isSelect && <CheckTriangle height={40} width={40}/>}
                                    <div className='sf_img'>
                                        <img
                                            src={`https://s3.server24.kr/panel/img/os/${os.display.replace(" ", "")}.png`}/>
                                    </div>
                                    <div className='sf_version'>
                                        <span id='os_name'>{os.display}</span>
                                        {oneVersion ?
                                            <small>8 x64</small>
                                            :
                                            <SelectVersion osInfo={osInfo}/>
                                        }
                                    </div>
                                </div>
                            }


                        </Col>
                    )
                }
            )}
        </Row>
    )
}

export default SixFourBit;
