import React, {Fragment, useEffect, useState} from "react";
import ImgInfo from "../common/ImgInfo";
import {useDispatch, useSelector} from "react-redux";
import {Row, Col} from 'reactstrap';
import cpuIcon from '../../assets/img/server_info/cpu-tower.png';
import ramIcon from '../../assets/img/server_info/ram.png';
import storageIcon from '../../assets/img/server_info/hdd.png';
import osIcon from '../../assets/img/server_info/operating-system.png';
import userIcon from '../../assets/img/server_info/user.png';
import ipIcon from '../../assets/img/server_info/ip.png';
import locationIcon from '../../assets/img/server_info/placeholder.png';
import bandWidthIcon from '../../assets/img/server_info/bandwidth.png';
import passwordIcon from '../../assets/img/server_info/key.png';
import {getRegionInfo} from "../../utils/common";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {Tooltip} from "@mui/material";
import BandWidthLineChart from "./BandWidthLineChart";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {getBandWidth} from "./action";
import ListSkeleton from "../common/ListSkeleton";
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {checkErrorResponse} from "../../helpers/errorCheck";

const ManageDashBoard = () => {

    const dispatch = useDispatch();
    const {instanceInfo} = useSelector(state => state.manage);
    const [hidePwd, setHidePwd] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    useEffect(() => {
        getBandWidth(instanceInfo.vi_id).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    setSkeletonLoader(true);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [instanceInfo])


    return (
        <Fragment>
            <ListSkeleton height={350} type={'rectangular'} itemNum={1} colNum={12} loading={skeletonLoader}>
                <BandWidthLineChart/>
            </ListSkeleton>
            <ListSkeleton height={350} type={'rectangular'} itemNum={1} colNum={12} loading={skeletonLoader}>
                <div className='manage_dashboard_wrapper'>
                    <div className='dashboard_server_info'>
                        <h3>서버 정보</h3>
                        <div className='server_info_content'>
                            <Row>
                                <Col xs={12} md={4} className='server_info_item'>
                                    <ImgInfo img={cpuIcon} label={'CPU'} data={`${instanceInfo.vi_vcpu_count} vCore`}/>
                                    <ImgInfo img={ramIcon} label={'RAM'} data={`${instanceInfo.vi_ram} MB`}/>
                                    <ImgInfo img={storageIcon} label={'Storage'} data={`${instanceInfo.vi_disk} GB`}/>
                                    <ImgInfo img={bandWidthIcon} label={'Bandwidth'} data={`${instanceInfo.vp_bandwidth} GB (매일 제공량)`}/>
                                </Col>
                                <Col xs={12} md={4} className='server_info_item'>
                                    <ImgInfo img={locationIcon} subData={getRegionInfo(instanceInfo.vi_region)} label={'Location'} data={`${instanceInfo.vi_vcpu_count} vCore`}/>
                                    <ImgInfo img={ipIcon} label={'IP Address'} data={instanceInfo.vi_main_ip}>
                                        <CopyToClipboard text={instanceInfo.vi_main_ip}>
                                            <Tooltip title='IP 복사' placement='top'>
                                                <ContentCopyIcon className='hide_pwd'/>
                                            </Tooltip>
                                        </CopyToClipboard>
                                    </ImgInfo>
                                    <ImgInfo img={userIcon} label={'Username'} data={`root`}/>
                                    <ImgInfo img={passwordIcon} label={'Password'} data={!hidePwd ? '*******' :
                                        instanceInfo.vi_default_password === 'Unknown. Password was set via snapshot restore.'
                                            ? '스냅샷으로 복원되었거나 일부 운영체제는 시스템에서 확인할 수 없습니다.' : instanceInfo.vi_default_password}>
                                        <Fragment>
                                            {hidePwd ?
                                                <Tooltip title="비밀번호 숨기기" placement="top">
                                                    <VisibilityOffIcon className='hide_pwd' onClick={() => setHidePwd(false)}/>
                                                </Tooltip>
                                                :
                                                <Tooltip title="비밀번호 보기" placement="top">
                                                    <RemoveRedEyeIcon className='hide_pwd' onClick={() => setHidePwd(true)}/>
                                                </Tooltip>
                                            }

                                            <CopyToClipboard text={instanceInfo.vi_default_password}>
                                                <Tooltip title='비밀번호 복사' placement='top'>
                                                    <ContentCopyIcon className='hide_pwd'/>
                                                </Tooltip>
                                            </CopyToClipboard>

                                        </Fragment>
                                    </ImgInfo>
                                </Col>
                                <Col xs={12} md={4} className='server_info_item'>
                                    <ImgInfo img={osIcon} label={'OS'} data={instanceInfo.vi_os}/>
                                </Col>

                            </Row>
                        </div>
                    </div>
                </div>
            </ListSkeleton>
        </Fragment>
    )
}


export default ManageDashBoard;
