import {logoutRequest} from "../components/login/function";
import {notificationAlert} from "./common";
import Swal from "sweetalert2";


export const gameCheckErrorResponse = (error) => {
    if (error) {
        switch (error.result.Reason) {
            case 'A file with this name already exists.': {
                notificationAlert('오류', '이미 해당 경로에 동일한 이름에 파일이 존재합니다.');
                break;
            }
            default: {
                notificationAlert('오류', '문제가 발생하였습니다. 잠시후 다시 시도해주세요.');
                break;
            }
        }
    }
}


export const checkErrorResponse = (error) => {
    if (error) {
        if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
                if (error.response.data.message === '세션이 만료되었습니다.' || error.response.data.message === '비활성화된 계정입니다. 고객센터를 통해 비활성화를 풀어주세요.') {
                    logoutRequest().then(response => {
                        if (response) {
                            if (response.status === 200) {
                                localStorage.removeItem('auth_login');
                                Swal.fire({
                                    allowOutsideClick: () => window.location.href = '/',
                                    title: '오류',
                                    text: error.response.data.message,
                                    confirmButtonText: '닫기'
                                }).then(result => {
                                    if (result.isConfirmed) {
                                        window.location.href = '/';
                                    }
                                })
                            }
                        }

                    }).catch(err => {
                        if (err) {
                            if (err.response) {
                                notificationAlert('오류', err.response.data.message)
                            }
                        }

                    })
                    return;
                }
            }
            if (error.response.status === 404 && error.response.data.message === '관리자에 의해 접속이 제한된 서버입니다.') {
                Swal.fire({
                    allowOutsideClick: () => window.location.href = '/',
                    title: '오류',
                    text: error.response.data.message,
                    confirmButtonText: '닫기'
                }).then(result => {
                    if (result.isConfirmed) {
                        window.location.href = '/';
                    }
                })
                return;
            }
            if (error.response.status === 404 && error.response.data.message === '탈퇴한 유저입니다.') {
                logoutRequest().then(response => {
                    if (response) {
                        if (response.status === 200) {
                            localStorage.removeItem('auth_login');
                            window.location.href = '/';
                        }
                    }

                }).catch(err => {
                    checkErrorResponse(err);

                })
                return;
            }
            if (error.response.status === 404 && error.response.data.message === '존재하지 않는 인스턴스입니다.') {
                window.history.back();
                notificationAlert('오류', error.response.data.message);
                return;
            }
            if (error.response.status === 400 && error.response.data.message === '서버를 키는 중입니다. 잠시 후 다시 접속해주세요.') {
                window.history.back();
                notificationAlert('오류', error.response.data.message);
                return;
            }
            notificationAlert('오류', error.response.data.message);
        }
    }
}
