import * as types from './actionTypes';
import {_GAME_DEPLOY, _GET_GAME_INSTANCE_INFO, _GET_GAME_PLAN_LIST} from "./actionTypes";
import {isIterableArray} from "../../utils/common";


let initialState = {
    gameType: '',
    priceType: '',
    gameServerList: [],
    regionType: 'icn',
    mcType: '',
    deployQty: 1,
    deployLoader: false,
    upgradePlans: [],
    currentPlan: {},
    sessionId: '',
    gameInstanceDetail: {},
    gameInstanceInfo: {
        Status: {},
        Messages: [],
        ConsoleEntries: [],
        Tasks: []
    },
    gameInstanceActive: false,
    consoleList: [],
    fileManagerDirs: [],
    gameSettingNodes: [],
    gameBackups: []
}


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case types._SET_GAME_TYPE: {
            switch (action.gameType) {
                case 'MINECRAFT': {
                    return {
                        ...state,
                        gameType: action.gameType,
                        priceType: 'GRASS-10P-1024M',
                        mcType: 'Java',
                    }
                }
                default:
                    return {
                        ...state
                    }
            }

        }

        case types._SET_GAME_PRICE: {
            return {
                ...state,
                priceType: action.priceType
            }
        }

        case types._SET_GAME_REGION: {
            return {
                ...state,
                regionType: action.regionType
            }
        }

        case types._SET_MC_TYPE: {
            return {
                ...state,
                mcType: action.mcType
            }
        }

        case types._SET_GAME_DEPLOY_LOADER: {
            return {
                ...state,
                deployLoader: action.loader
            }
        }

        case types._SET_DEPLOY_QTY: {
            const newQty = action.qty ? state.deployQty + 1 : state.deployQty - 1;
            if (newQty < 1)
                return {
                    ...state
                }
            else
                return {
                    ...state,
                    deployQty: newQty
                }
        }

        case types._GET_GAME_SERVERS: {
            return {
                ...state,
                gameServerList: action.request.data.mcs
            }
        }

        case types._GAME_DEPLOY: {
            return {
                ...state
            }
        }

        case types._GET_GAME_PLAN_LIST: {
            return {
                ...state,
                currentPlan: action.request.data.currentPlan,
                upgradePlans: action.request.data.upgradePlans
            }
        }

        case types._SET_SESSION_ID: {

            return {
                ...state,
                gameInstanceDetail: action.request.data.instance,
                sessionId: action.request.data.sessionId
            }
        }

        case types._GET_GAME_INSTANCE_INFO: {
            if (isIterableArray(action.request.data.ConsoleEntries)) {
                const newConsoleList = [...state.consoleList, ...action.request.data.ConsoleEntries]
                return {
                    ...state,
                    gameInstanceInfo: action.request.data,
                    consoleList: newConsoleList
                }
            } else {
                return {
                    ...state,
                    gameInstanceInfo: action.request.data,
                }
            }
        }

        case types._SET_GAME_SERVER_ACTIVE : {
            return {
                ...state,
                gameInstanceActive: action.active
            }
        }


        case types._GET_FILE_MANAGER_DIR: {
            return {
                ...state,
                fileManagerDirs: action.request.data.result
            }
        }

        case types._GET_SETTING_NODES: {

            return {
                ...state,
                gameSettingNodes: action.request.data.result
            }
        }

        case types._SET_GAME_NODE: {
            const nodeIndex = state.gameSettingNodes.findIndex(instance => instance.Node === action.node);
            if (nodeIndex !== -1) {
                state.gameSettingNodes[nodeIndex].CurrentValue = action.value;
            }
            return {
                ...state,
                gameSettingNodes: [...state.gameSettingNodes]
            }
        }

        case types._GET_GAME_BACKUPS : {
            return {
                ...state,
                gameBackups: action.request.data.result
            }
        }

        case types._DELETE_GAME_BACKUP : {
            const newBackups = state.gameBackups.filter(backup => backup.Id !== action.backupId);

            return {
                ...state,
                gameBackups: [...newBackups]
            }
        }

        case types._SET_GAME_LABEL: {
            state.gameInstanceDetail.label = action.label;
            const newDetail = state.gameInstanceDetail;
            return {
                ...state,
                gameInstanceDetail: {...newDetail}
            }
        }

        case types._EXTEND_GAME_EXPIRE_DATE: {
            state.gameInstanceDetail.expire_date = action.request.data.expireDate;
            const newDetail = state.gameInstanceDetail;
            return {
                ...state,
                gameInstanceDetail: {...newDetail}
            }
        }

        case types._SET_EULA_AGREE: {

            state.gameInstanceDetail.eula = 1;
            const newDetail = state.gameInstanceDetail;

            return {
                ...state,
                gameInstanceDetail: {...newDetail}
            }
        }

        default:
            return state;
    }

}
