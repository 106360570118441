import React from "react";
import DeployTitle from "../../../deploy/DeployTitle";
import {Col, Row} from "reactstrap";
import {gameList} from "../../../../datas/game";
import {useDispatch, useSelector} from "react-redux";
import {setDeployGameType} from "../../action";
import {notificationAlert} from "../../../../helpers/common";


const GameSelectionSection = () => {
    const {gameType} = useSelector(state => state.gameDeploy);
    const dispatch = useDispatch();

    const handleSelectGame = (gameTitle) => {
        if (gameTitle !== 'MINECRAFT') {
            notificationAlert('알림', '현재 준비중인 서비스입니다.');
            return;
        }
        dispatch(setDeployGameType(gameTitle))
    }
    return (
        <div className='game_selection_wrapper'>
            <DeployTitle title={'게임 선택'}/>
            <div className='game_selection'>
                <Row>
                    {gameList.map((game, index) => {
                        return (
                            <Col key={index} xs={12} lg={3}>
                                <div
                                    onClick={() => handleSelectGame(game.title)}
                                    className='game_select_item'
                                    style={{backgroundImage: `url(${game.img})`}}>
                                    <div className={gameType === game.title ? 'filter_bg del_filter' : 'filter_bg'}>
                                        <h4>{game.title}</h4>
                                        {game.title !== 'MINECRAFT' && <span className='coming_soon_txt'>COMING SOON</span>}
                                    </div>
                                </div>
                            </Col>
                        )
                    })}

                </Row>
            </div>
        </div>
    )
}


export default GameSelectionSection;
