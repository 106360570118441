import React, {useState} from "react";
import GradientBack from "../common/GradientBack";
import {Form, Button} from "reactstrap";
import userIcon from "../../assets/img/login/user.svg";
import phoneIcon from "../../assets/img/find/phone.svg";
import MyInput from "../common/MyInput";


const FindId = () => {

    const [name, setName] = useState('');
    const [phoneNum, setPhoneNum] = useState('');

    const handleSubmit = e => {
        e.preventDefault();


    };

    return (
        <GradientBack boxSize={450}>
            <div className='find_form_wrapper'>
                <h2>아이디 찾기</h2>
                <p>회원정보에 등록한 휴대전화 번호와 입력한
                    휴대전화 번호가 일치해야 인증번호를 받을 수 있습니다.</p>
                <Form onSubmit={handleSubmit}>
                    <MyInput
                        icon={userIcon}
                        label={'이름'}
                        placeholder={'이름을 입력해주세요.'}
                        value={name}
                        onChange={({target}) => setName(target.value)}
                        type="text"
                    />
                    <MyInput label={'휴대전화 번호*'}
                             icon={phoneIcon}
                             value={phoneNum}
                             type='tel'
                             onChange={({target}) => setPhoneNum(target.value)}
                             placeholder={'휴대전화 번호를 입력해주세요.'}/>

                    <div className='findId_btn'>
                        <Button type='submit'>인증번호 전송</Button>
                    </div>
                </Form>
            </div>
        </GradientBack>
    )
}

export default FindId;
