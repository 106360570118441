import React from "react";
import {Skeleton} from "@mui/lab";
import {Row, Col} from 'reactstrap';

const SkeletonItem = ({num, top, type, colNum, height}) => {
    const itemList = [];
    const marginTop = top ? top : 0;
    for (let i = 0; i < num; i++) {
        itemList.push(
            <Col style={{marginTop: `${marginTop}rem`}} key={i} className='p-0' xs={colNum}>
                <Skeleton
                    animation="wave"
                    variant={type}
                    sx={{width: '100%'}}
                    height={height}/>
            </Col>)
    }
    return itemList;
}

const ListSkeleton = ({loading, top, type, children, colNum, itemNum, height}) => {

    return (
        <>
            {loading ?
                children
                :
                <Row className='list_skeleton_wrapper m-0'>
                    <SkeletonItem colNum={colNum} top={top} type={type} height={height} num={itemNum}/>
                </Row>
            }

        </>
    )
}

ListSkeleton.defaultProps = {
    height: 100,
    colNum: 12,
    itemNum: 6,
    type: 'text'
}

export default ListSkeleton;
