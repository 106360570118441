import React from "react";
import javaEdition from '../../../../assets/img/game/minecraft/type/MinejavaEdition.jpeg';
import bedRockEdtion from '../../../../assets/img/game/minecraft/type/MinecraftBedrock.jpeg';
import DeployTitle from "../../../deploy/DeployTitle";
import {Col, Row} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {setDeployMcType} from "../../action";

const MineGameTypeSection = () => {
    const dispatch = useDispatch();
    const {mcType} = useSelector(state => state.gameDeploy);
    const handleSetMcType = (type) => {
        dispatch(setDeployMcType(type));
    }

    return (
        <div className='mine_game_type_wrapper'>
            <DeployTitle title={'게임 종류'}/>
            <div>
                <Row className='game_type_row'>
                    <Col xs={6} lg={4} style={{padding: '2rem'}}>
                        <Row onClick={() => handleSetMcType('Java')}
                             className={mcType === 'Java' ? 'game_type_item select_type' : 'game_type_item'}>
                            <Col xs={12}>
                                <div
                                    style={{backgroundImage: `url(${javaEdition})`}}
                                    className='game_type_img'>
                                    <div className='game_type_txt'>
                                        <h4>자바 에디션</h4>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={6} lg={4} style={{padding: '2rem'}}>
                        <Row onClick={() => handleSetMcType('Bedrock')}
                             className={mcType === 'Bedrock' ? 'game_type_item select_type' : 'game_type_item'}>
                            <Col xs={12}>
                                <div
                                    style={{backgroundImage: `url(${bedRockEdtion})`}}
                                    className='game_type_img'>
                                    <div className='game_type_txt'>
                                        <h4>베드락 에디션</h4>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}


export default MineGameTypeSection;
