import React, {Fragment, useEffect, useRef, useState} from "react";
import {useParams} from 'react-router-dom';
import {Button, TextareaAutosize} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import UserTicket from "./UserTicket";
import {addTicketComment, closeTicket, getTicket, uploadFile} from "../action";
import {useDispatch, useSelector} from "react-redux";
import {isGameMode, isGameModeTxt, isIterableArray, timeToString} from "../../../utils/common";
import InputLabel from "@mui/material/InputLabel";
import {checkErrorResponse} from "../../../helpers/errorCheck";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import {getTicketStatus, notificationAlert} from "../../../helpers/common";
import ListSkeleton from "../../common/ListSkeleton";
import {useMediaQuery} from "react-responsive";
import {Col, Row} from "reactstrap";
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import {CircularProgress} from "@material-ui/core";


const TicketDetail = () => {
    const {status, subject} = useSelector((state) => state.ticket.ticketInfo);
    const {ticketComments, ticketUsers} = useSelector((state) => state.ticket);
    const fileInputRef = useRef(null);
    const contentInputRef = useRef(null);
    const dispatch = useDispatch();
    let {id} = useParams();
    const {layoutMode} = useSelector(state => state.layout);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState(0);
    const [ticketFile, setTicketFile] = useState(null);
    const [lastMsgUser, setLastMsgUser] = useState({});
    const [ticketStatus, setTicketStatus] = useState({});
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [commentLoader, setCommentLoader] = useState(false);
    const isMobile = useMediaQuery({
        query: "(max-width:880px)"
    });


    const sendTicket = (ticketId, post) => {
        addTicketComment(ticketId, post).then(response => {
            if (response.request.status === 200) {
                dispatch(response);
                contentInputRef.current.value = '';
                fileInputRef.current.value = '';
                setLoading(false);
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }

    function handleSendTicketComment() {
        setLoading(true);
        let post = {
            "content": message,
            "author_id": userId,
        }
        if (ticketFile) {
            uploadFile(ticketFile).then(token => {
                if (token) {
                    post.upload = token;
                    sendTicket(id, post);
                }

            }).catch(err => {
                setLoading(false);
                if (err === 'volume Error') {
                    fileInputRef.current.value = '';
                } else {
                    checkErrorResponse(err);
                }
            })
        } else {
            sendTicket(id, post);
        }

    }

    useEffect(() => {
        setLoading(false)
        getTicket(id).then(response => {
            if (response.request.status === 200) {
                dispatch(response);
                const userId = response.request.data.users.filter(user => !user.agent);
                setUserId(userId[0].id);
                setSkeletonLoader(true);
            }
        }).catch(err => {
            checkErrorResponse(err);
        })

        let bot = setInterval(() => {
            setCommentLoader(true);
            getTicket(id).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        dispatch(response);
                        const userId = response.request.data.users.filter(user => !user.agent);
                        setUserId(userId[0].id);
                        setCommentLoader(false);
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
            })
        }, 60000);

        return () => clearInterval(bot);

    }, []);


    useEffect(() => {
        if (isIterableArray(ticketComments)) {
            const author_id = ticketComments[0].author_id;
            const user = ticketUsers.filter(user => user.id === author_id);
            if (isIterableArray(user)) {
                setLastMsgUser(user[0])
            }

        }
    }, [ticketComments, ticketUsers])
    useEffect(() => {
        if (status) {
            setTicketStatus(getTicketStatus(status));
        }
    }, [status])
    const handleCloseTicket = () => {
        closeTicket(id).then(response => {
            if (response.request.status === 200) {
                dispatch(response);
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }
    const handleUploadFile = (e) => {
        if (e.target.files) {
            setTicketFile(e.target.files);
        }
    }

    const handleUpdateComment = () => {
        setCommentLoader(true);
        getTicket(id).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    const userId = response.request.data.users.filter(user => !user.agent);
                    setUserId(userId[0].id);
                    setCommentLoader(false);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }

    return (
        <Fragment>
            <div className={isGameMode(layoutMode) ? 'ticket_detail_top_info dark_ticket_info' : 'ticket_detail_top_info'}>
                <div className='container-md info_wrapper'>
                    {!isMobile &&
                    <div className='ticket_info_txt'>
                        <span>티켓 정보</span>
                    </div>}
                    {Object.keys(ticketStatus).length !== 0 &&
                    <div className='ticket_info_status' style={{color: ticketStatus.color}}>
                        {ticketStatus.icon}
                        <span>{ticketStatus.txt}</span>
                    </div>
                    }
                    {isIterableArray(ticketComments) && <div className='ticket_info_time' style={{color: 'rgba(0,0,0,0.59)'}}>
                        <AccessTimeIcon fontSize={'small'}/><span>{timeToString(ticketComments[0].created_at)}</span>
                    </div>}
                    <div className='ticket_last_msg' style={{color: '#0593FF'}}>
                        <ChatBubbleOutlineIcon fontSize={'small'}/><span>{lastMsgUser.name}</span>

                    </div>
                </div>
            </div>
            <div className='ticket_detail_wrapper container-md'>
                <div className='ticket_detail_top'>
                    <div className='ticket_detail_info'>
                        <h4 className={isGameModeTxt(layoutMode)}>{subject}</h4>
                    </div>

                    <div className='ticket_detail_btn'>
                        <ListSkeleton height={36} type={'rectangular'} colNum={12} itemNum={1} loading={skeletonLoader}>
                            {(status !== 'solved' && status !== 'closed') &&
                            <Button variant={"contained"} onClick={handleCloseTicket} startIcon={<CloseIcon/>}>
                                티켓 종료(문제가 해결됨)
                            </Button>
                            }
                            <Button className='comment_loader_btn'
                                    style={{marginLeft: '1rem'}}
                                    variant={'contained'}
                                    onClick={handleUpdateComment}
                                    color={'primary'}>
                                {commentLoader ?
                                    <CircularProgress size={'small'} className='comment_loader_icon'/>
                                    :
                                    <ReplayOutlinedIcon/>
                                }

                            </Button>
                        </ListSkeleton>
                    </div>

                </div>
                <div className={isGameMode(layoutMode) ? 'ticket_detail_content dark_ticket_detail' : 'ticket_detail_content'}>
                    <div className='ticket_detail_box'>
                        <div className='ticket_detail_writer_wrapper'>
                            <ListSkeleton height={330} type={'rectangular'} colNum={12} itemNum={1} loading={skeletonLoader}>
                                <div className='ticket_detail_writer'>
                                    <TextareaAutosize
                                        ref={contentInputRef}
                                        className='ticket_detail_input'
                                        aria-label="maximum height"
                                        placeholder="내용을 입력하세요."
                                        onChange={event => setMessage(event.target.value)}
                                        style={{width: '100%', minHeight: '200px', maxHeight: '700px'}}
                                    />
                                    <div className='ticket_detail_file'>
                                        <InputLabel>첨부 가능한 파일 용량(12MB)</InputLabel>
                                        <input
                                            ref={fileInputRef}
                                            type="file"
                                            accept="image/* , .zip,.rar,.7zip, .pdf, .hwp, .mov"
                                            onChange={(e) => handleUploadFile(e)}
                                        />
                                    </div>
                                    <LoadingButton
                                        disabled={message.length === 0}
                                        onClick={handleSendTicketComment}
                                        endIcon={<SendIcon/>}
                                        loading={loading}
                                        loadingPosition="end"
                                        variant="contained"
                                    >
                                        문의내용 전송
                                    </LoadingButton>
                                </div>
                            </ListSkeleton>
                        </div>
                        <ListSkeleton height={250} type={'rectangular'} top={1} itemNum={3} colNum={12} loading={skeletonLoader}>
                            {isIterableArray(ticketComments) && [...ticketComments].map((comment, index) => {
                                return (<Fragment key={index}>
                                        <UserTicket comment={comment}/>
                                        {index === 0 &&
                                        <Row style={{padding: '1.5rem 0'}}>
                                            <Col xs={4} lg={5}>
                                                <hr/>
                                            </Col>
                                            <Col xs={4} lg={2} className='text-center'>
                                                <span className='fw-bold' style={{fontSize: '0.8rem', color: '#8697A8'}}>New Message</span>
                                            </Col>
                                            <Col xs={4} lg={5}>
                                                <hr/>
                                            </Col>
                                        </Row>}
                                    </Fragment>
                                )

                            })}
                        </ListSkeleton>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}


export default TicketDetail;
