import React from "react";
import NavBar from "../../nav/NavBar";
import GameMainBanner from "./GameMainBanner";
import GameServerTable from "./GameServerTable";
import {useMediaQuery} from "react-responsive";
import GameMobileDashboard from "./GameMobileDashboard";


const GameDashboard = () => {
    const isMobile = useMediaQuery({
        query: "(max-width:880px)"
    });


    return (
        <NavBar>
            <div className='container-md' style={{padding: '3rem 0'}}>
                <GameMainBanner/>
                {isMobile ?
                    <GameMobileDashboard/>
                    :
                    <GameServerTable/>
                }

            </div>
        </NavBar>
    )
}

export default GameDashboard;
