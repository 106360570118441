import React from "react";
import {CircularProgressWithLabel} from "./CircleProgress";
import {useSelector} from "react-redux";


const GameCpuStatus = () => {

    const {gameInstanceInfo, gameInstanceActive, sessionId} = useSelector(state => state.gameDeploy);

    return (
        <div>
            <div className='game_status_title'>
                <span>CPU 사용량</span>
            </div>
            <div className='game_status_wrapper'>
                <CircularProgressWithLabel value={gameInstanceInfo.Status.Metrics['CPU Usage'].RawValue} type={'cpu'}>
                    {`${gameInstanceInfo.Status.Metrics['CPU Usage'].RawValue}%`}
                </CircularProgressWithLabel>
                <div className='game_status_value'>
                    <span>{`${gameInstanceInfo.Status.Metrics['CPU Usage'].RawValue} %`}</span>
                </div>
            </div>
        </div>
    )
}


export default GameCpuStatus;
