import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {deleteServer, reinstallServer, restartServer, stopServer} from "../manage/action";
import {detailDelInstance, updateAllStatusList, updateStatusList} from "./action";
import {useDispatch} from "react-redux";
import Swal from "sweetalert2";
import {checkErrorResponse} from "../../helpers/errorCheck";
import ConfirmModal from "../common/ConfirmModal";

export default function InstanceMore({id, serverStatus,payStatus}) {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);


    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleServerStop = () => {
        return new Promise((resolve, reject) => {
            const post = {
                instance_ids: [id]
            }
            stopServer(post).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        resolve(true);
                        dispatch(updateStatusList(post.instance_ids, 'stopped'));
                    }
                }
            }).catch(err => {
                reject(err);
                checkErrorResponse(err);
            })
        })
    };

    const handleServerRestart = () => {
        return new Promise((resolve, reject) => {
            const post = {
                instance_ids: [id]
            }
            restartServer(post).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        dispatch(updateStatusList(post.instance_ids, 'running'));
                        resolve(true);
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
                reject(err);
            })
        })
    };

    const handleServerDelete = () => {
        return new Promise((resolve, reject) => {
            deleteServer(id).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        resolve(true);
                        dispatch(detailDelInstance(id));
                    }
                }
            }).catch(err => {
                reject(err);
                checkErrorResponse(err);
            })
        })
    }
    const handleServerReinstall = () => {
        return new Promise((resolve, reject) => {
            dispatch(updateAllStatusList('pending', 'stopped', 'none', id));
            reinstallServer(id).then(response => {
                if (response) {
                    if (response.request.status === 202) {
                        resolve(true);
                        dispatch(updateAllStatusList('active', 'running', 'ok', id));
                    }
                }
            }).catch(err => {
                reject(err);
                checkErrorResponse(err);
            })
        })
    }


    return (
        <div style={{padding: '0.6rem 0'}}>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls="long-menu"
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="instance_more_wrapper"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                <MenuItem
                    onClick={handleClose}
                    disabled={serverStatus === 'locked' || payStatus === 'tDeleted'}
                    className='more_menu_item p-0'>
                    <ConfirmModal
                        type={'restart'}
                        handleEvent={handleServerRestart}/>
                </MenuItem>
                <MenuItem
                    onClick={handleClose}
                    disabled={serverStatus === 'locked' || payStatus === 'tDeleted'}
                    className='more_menu_item p-0'>
                    <ConfirmModal
                        type={'stop'}
                        handleEvent={handleServerStop}/>
                </MenuItem>
                <MenuItem
                    onClick={handleClose}
                    disabled={serverStatus === 'locked' || payStatus === 'tDeleted'}
                    className='more_menu_item p-0'>
                    <ConfirmModal
                        type={'reinstall'}
                        handleEvent={handleServerReinstall}/>
                </MenuItem>
                <MenuItem
                    onClick={handleClose}
                    disabled={serverStatus === 'locked'}
                    className='more_menu_item p-0'>
                    <ConfirmModal
                        type={'delete'}
                        handleEvent={handleServerDelete}/>
                </MenuItem>
            </Menu>
        </div>
    );
}
