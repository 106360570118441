import React, {Fragment} from "react";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from "@mui/material/TableRow";

const NotExist = ({type, text, height}) => {


    return (
        <Fragment>
            {type === 'list' ?
                <div style={{minHeight: `${height}px`}} className='not_exist_wrapper'>
                    <div className='text_wrapper'>
                        <span>{text}</span>
                    </div>
                </div>
                :
                <TableBody style={{minHeight: `${height}px`}} className='not_exist_wrapper'>
                    <TableRow className='text_wrapper'>
                        <TableCell>{text}</TableCell>
                    </TableRow>
                </TableBody>
            }
        </Fragment>
    )
}

NotExist.defaultProps = {
    text: '',
    height: '300'
}
export default NotExist;
