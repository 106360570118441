import * as types from './actionTypes';


let initialState = {
    scriptList: [],

}


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types._GET_SCRIPTS: {
            return {
                ...state,
                scriptList: action.request.data.scripts
            }
        }

        case types._ADD_SCRIPTS: {
            const newScript = action.request.data.script;
            return {
                ...state,
                scriptList: [...state.scriptList, newScript]
            }
        }

        case types._UPDATE_SCRIPTS: {
            const scriptIndex = state.scriptList.findIndex(script => script.vsc_id === action.id);
            state.scriptList[scriptIndex].vsc_name = action.post.name;
            state.scriptList[scriptIndex].vsc_type = action.post.type;
            state.scriptList[scriptIndex].vsc_script = action.post.script;
            return {
                ...state,
                scriptList: [...state.scriptList]
            }
        }

        case types._DEL_SCRIPTS: {
            const newScriptList = state.scriptList.filter(script => script.vsc_id !== action.id);
            return {
                ...state,
                scriptList: [...newScriptList]
            }
        }

        default:
            return state;
    }

}
