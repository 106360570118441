import React, {useState, useEffect} from "react";
import TicketTable from "./TicketTable";
import TicketFaq from "./TicketFaq";
import TicketModal from "./TicketModal";
import {initTicketList} from "./action";
import {useDispatch, useSelector} from "react-redux";
import {checkErrorResponse} from "../../helpers/errorCheck";
import {isGameModeTxt} from "../../utils/common";

const Ticket = () => {
    const dispatch = useDispatch();
    const ticketList = useSelector((state) => state.ticket.ticketList);
    const {layoutMode} = useSelector(state => state.layout);
    const [openModal, setOpenModal] = useState(false);
    const [listLoading , setListLoading] = useState(false);
    const toggle = () => {
        setOpenModal(!openModal)
    }
    useEffect(() => {
        initTicketList().then(result => {
            if (result.request.status === 200) {
                dispatch(result);
                setListLoading(true);
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [])
    return (
        <div className='container-md ticket_wrapper'>
            <div className='ticket_top'>
                <h2 className={isGameModeTxt(layoutMode)}>고객문의 {`(${ticketList.length})`}</h2>
            </div>
            <TicketTable toggle={toggle} loading={listLoading} ticketList={ticketList}/>
            <TicketFaq/>
            <TicketModal toggle={toggle} collapse={openModal}/>
        </div>
    )
}


export default Ticket;
