import React, {Fragment, useEffect, useState} from "react";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import {Spinner} from "reactstrap";


const InstanceStatus = ({vi_server_status, vi_power_status, vi_status}) => {
    const [statusType, setStatusType] = useState(null);

    useEffect(() => {
        if (vi_server_status === 'none' && vi_status === 'pending') setStatusType('install');
        else if (vi_server_status === 'locked' && vi_power_status === 'stopped') setStatusType('restoring');
        else if (vi_server_status === 'locked' && vi_power_status === 'running') setStatusType('snapshot');
        else if (vi_status === 'active' && vi_power_status === 'running') setStatusType('running');
        else if (vi_status === 'active' && vi_power_status === 'stopped') setStatusType('stopped');

    }, [vi_status, vi_server_status, vi_power_status])
    return (
        <div className='instance_status_show_wrapper'>
            {statusType === 'install' && <span className='server_pending'>
                <Spinner size={'sm'}
                         aria-hidden="true"
                         as="span"
                         animation="border"
                         color='warning'
                         variant="warning">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                {' '}Installing
            </span>}
            {statusType === 'restoring' && <span className='server_pending'>
                <Spinner size={'sm'}
                         aria-hidden="true"
                         as="span"
                         animation="border"
                         color='warning'
                         variant="warning">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                {' '}Restoring
            </span>}
            {statusType === 'snapshot' && <span className='server_pending'>
                <Spinner size={'sm'}
                         aria-hidden="true"
                         as="span"
                         animation="border"
                         color='warning'
                         variant="warning">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                {' '}Snapshot
            </span>}
            {statusType === 'running' && <span className='server_run'><PlayCircleIcon/>{' '}Running</span>}
            {statusType === 'stopped' && <span className='server_stop'><StopCircleIcon/>{' '}Stopped</span>}
        </div>
    )
}

export default InstanceStatus;

