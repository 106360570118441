import React from "react";


const CardAvatar = ({img, width, height, backgroundColor}) => {

    return (
        <div style={{width, height, backgroundColor}}
             className='card_avatar_wrapper'>
            <img src={img} alt={'card_avatar'}/>
        </div>
    )
}

export default CardAvatar
