import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useDispatch} from "react-redux";
import {checkErrorResponse} from "../../../helpers/errorCheck";
import ConfirmModal from "../../common/ConfirmModal";
import {deleteWebHostingInstance} from "../action";
import ChangePlanModal from "./ChangePlanModal";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {Button} from "@mui/material";
import {useState} from "react";

export default function WebHostingInstanceMore({no, payStatus}) {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);


    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleWebHostingDelete = () => {
        return new Promise((resolve, reject) => {
            deleteWebHostingInstance(no).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        dispatch(response);
                        resolve(true);
                    }
                }
            }).catch(err => {
                reject(err);
                checkErrorResponse(err);
            })
        })
    };


    return (
        <div style={{padding: '0.6rem 0'}}>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls="long-menu"
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="instance_more_wrapper"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                <MenuItem
                    onClick={handleClose}
                    disabled={payStatus === 'tDeleted'}
                    className='more_menu_item p-0'>
                    <Button onClick={toggle}><AttachMoneyIcon/> 요금제 변경</Button>
                </MenuItem>
                <MenuItem
                    onClick={handleClose}
                    className='more_menu_item p-0'>
                    <ConfirmModal
                        type={'webHostingDel'}
                        handleEvent={handleWebHostingDelete}/>
                </MenuItem>
            </Menu>
            <ChangePlanModal cuNo={no} modal={modal} toggle={toggle}/>
        </div>
    );
}
