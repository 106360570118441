import React, {useEffect, useState} from "react";
import {Col, Row} from "reactstrap";
import LoaderButton from "../../../common/LoaderButton";
import {useDispatch, useSelector} from "react-redux";
import {minecraftPriceList} from "../../../../datas/game";
import {isIterableArray} from "../../../../utils/common";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {setDeployGameQty} from "../../action";
import {notificationAlert} from "../../../../helpers/common";
import moment from "moment";

const GameDeployFooter = ({handleGameDeploy, loader, checkPolicy}) => {
    const dispatch = useDispatch();
    const {priceType, deployQty} = useSelector(state => state.gameDeploy);
    const [totalAmount, setTotalAmount] = useState(0);

    useEffect(() => {
        const priceData = minecraftPriceList.filter(price => price.id === priceType);
        if (isIterableArray(priceData))
            setTotalAmount(priceData[0].monthly_const);
        else
            setTotalAmount(0);
    }, [priceType]);

    const handleDeployQty = (qty) => {
        dispatch(setDeployGameQty(qty));
    }
    return (
        <div className='deploy_footer'>
            <Row className='last_step_footer container-md p-0'>
                <Col xs={12}>
                    <div className='last_price_wrapper'>
                        <div className='summary_price_wrapper'>
                            <h4 className='fw-bold'>총 비용</h4>
                            <div id='total_price'>
                                <div className='deploy_month_price'>
                                    <h3>{(totalAmount * deployQty).toLocaleString()}P</h3><small>/{deployQty}개월</small>
                                </div>
                            </div>
                        </div>
                        <div className='deploy_term_wrapper'>
                            <small>서비스 이용기간:</small>
                            <div>
                                <small style={{margin: 0}}>{moment().format('YYYY년 MM월 DD일')}</small>
                                <small style={{margin: '0 5px'}}> ~ </small>
                                <small> {moment().add(30 * deployQty, 'days').format('YYYY년 MM월 DD일')}</small>
                            </div>
                        </div>
                        <div className='server_deploy_btn_wrapper'>
                            <div className='game_deploy_qty_wrapper'>
                                <button onClick={() => handleDeployQty(false)} className='qty_btn'><RemoveIcon/></button>
                                <button className='qty_num'><span>{deployQty}</span></button>
                                <button onClick={() => handleDeployQty(true)} className='qty_btn'><AddIcon/></button>
                            </div>
                            <LoaderButton
                                disabled={!checkPolicy}
                                style={{minWidth: '226px'}}
                                loader={loader}
                                id={'deploy_next_btn'}
                                onClick={handleGameDeploy}>
                                서비스 신청
                            </LoaderButton>
                        </div>
                    </div>
                </Col>
            </Row>


        </div>
    )
}
export default GameDeployFooter;
