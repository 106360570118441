import React, {Fragment, useEffect, useState} from "react";
import {regions} from '../../../datas/region';
import {useDispatch, useSelector} from "react-redux";
import {getPlan, setPrice, setRegion} from "../action";
import {Row, Col} from 'reactstrap';
import {CheckTriangle} from "../../common/CheckTriangle";
import DeployTitle from "../DeployTitle";
import {isIterableArray} from "../../../utils/common";
import {notificationAlert} from "../../../helpers/common";
import {checkRegion} from "../function";
import {checkErrorResponse} from "../../../helpers/errorCheck";
import {CircularProgress} from "@material-ui/core";

const RegionList = () => {
    const [loader, setLoader] = useState('');
    const {region, service} = useSelector((state) => state.deploy.vultr);
    const dispatch = useDispatch();
    const handleGetPlan = (response, vrId) => {
        const planList = response.data.planList.filter(plan => plan.vp_type === service.type);
        if (isIterableArray(planList)) {
            dispatch(setRegion(vrId));
            dispatch(setPrice(planList[0]));
            setLoader('');
        } else {
            notificationAlert('오류', '죄송합니다, 현재 일시적으로 해당 지역에 재고가 부족합니다. 나중에 다시 시도해주세요.');
            setLoader('');

        }
    }
    const handleRegion = (id) => {
        if (region.type.vr_id === id) {
            notificationAlert('알림', '이미 선택하신 리전입니다.');
            return;
        }
        setLoader(id);
        checkRegion(id).then(response => {
            if (response) {
                if (response.status === 200) {
                    dispatch(getPlan(response.data.planList));
                    if (isIterableArray(response.data.planList)) {
                        handleGetPlan(response, id);
                    } else {
                        notificationAlert('오류', '죄송합니다, 현재 일시적으로 해당 지역에 재고가 부족합니다. 나중에 다시 시도해주세요.');
                        setLoader('');
                    }
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
            setLoader('');
        })
    }


    return (
        <Row className='deploy_region_list'>
            <DeployTitle title={'서비스 지역'}/>
            {regions.map(item => {
                return (
                    <Col xs={6} lg={3} key={item.vr_id}
                         onClick={() => handleRegion(item.vr_id)}
                         className={item.vr_no === region.type.vr_no ? 'server_region select_region' : 'server_region'}>
                        <div className={loader === item.vr_id ? 'deploy_region_item justify-content-center' : 'deploy_region_item'}>
                            {loader === item.vr_id ?
                                <CircularProgress color={'primary'} id={'deploy_region_item_loader'}/>
                                :
                                <Fragment>
                                    {item.vr_no === region.type.vr_no && <CheckTriangle height={40} width={40}/>}
                                    <div className='region_img'>
                                        <img src={`https://s3.server24.kr/panel/img/country/${(item.country).replace(" ", "")}.png`}/>
                                    </div>
                                    <div className='region_country'>
                                        <span>{item.vr_city}</span>
                                        <small>{item.country}</small>
                                    </div>
                                </Fragment>
                            }
                        </div>


                    </Col>
                )
            })}
        </Row>
    )
}

export default RegionList;
