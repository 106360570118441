import React from 'react';
import {Spinner} from 'reactstrap';
import {Button} from '@mui/material';
import PropTypes from "prop-types";

const LoaderButton = ({id, isFull, form,disabled, size, variant, onClick, className, loader, children, color, type, ...rest}) => (
    <>
        {loader ?
            <Button fullWidth={!!isFull} id={id} size={size} variant={variant} disabled className={className}>
                <Spinner size={'sm'}
                         aria-hidden="true"
                         as="span"
                         animation="border"
                         variant="primary">
                    <span className="visually-hidden">{children}</span>
                </Spinner>
            </Button>
            :
            type === 'submit' ?
                <Button id={id} disabled={disabled} fullWidth={!!isFull} size={size} {...rest} variant={variant} form={form} color={color} type={type} className={className}>
                    {children}
                </Button>
                :
                <Button id={id} disabled={disabled} fullWidth={!!isFull} size={size} {...rest} variant={variant} color={color} type={type} className={className} onClick={onClick}>
                    {children}
                </Button>
        }

    </>
);

LoaderButton.propTypes = {
    children : PropTypes.element.isRequired
};
LoaderButton.defaultProps = {
    disabled : false
}
export default LoaderButton;
