import React, {useEffect, useState} from "react";
import ChargingPoint from "./ChargingPoint";
import Box from "@mui/material/Box";
import {TabContext, TabPanel} from "@mui/lab";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import BillInfo from "./BillInfo";
import {useLocation} from "react-router-dom";
import {PointBox} from "../nav/PointBox";
import {useDispatch, useSelector} from "react-redux";
import {getBillInfo, getPoint} from "./action";
import {checkErrorResponse} from "../../helpers/errorCheck";
import PointUsage from "./PointUsage";
import {isGameMode, isGameModeTxt} from "../../utils/common";

const Billing = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [value, setValue] = useState('1');
    const {totalPoint} = useSelector(state => state.bill);
    const {layoutMode} = useSelector(state => state.layout);
    const [loader, setLoader] = useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        if (location.state) {
            setValue(location.state.tabNum);
        }
        getBillInfo().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    setLoader(true);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [])

    return (
        <div className='billing_wrapper container-md'>
            <div className='billing_title'>
                <h2 className={isGameModeTxt(layoutMode)}>결제 및 비용</h2>
                <div className='holding_point_wrapper'>
                    <h3>총 보유 포인트</h3>
                    <h2>{parseInt(totalPoint).toLocaleString()}P</h2>
                </div>
            </div>
            <Box sx={{width: '100%', typography: 'body1'}}>
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleChange} aria-label="">
                            <Tab className={isGameModeTxt(layoutMode)} label="결제정보" value="1"/>
                            <Tab className={isGameModeTxt(layoutMode)} label="포인트 충전" value="2"/>
                            <Tab className={isGameModeTxt(layoutMode)} label="포인트 사용 내역" value="3"/>

                        </TabList>
                    </Box>
                    <TabPanel className='tab_panel p-0' value="1"><BillInfo loader={loader}/></TabPanel>
                    <TabPanel className='tab_panel p-0' value="2"><ChargingPoint/></TabPanel>
                    <TabPanel className='tab_panel p-0' value="3"><PointUsage/></TabPanel>
                </TabContext>
            </Box>
        </div>
    )
}

export default Billing;
