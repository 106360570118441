import React, {Fragment, useState} from "react";
import {Table} from "reactstrap";
import {allOsList} from "../../datas/OS";
import DeleteIcon from '@mui/icons-material/Delete';
import {isIterableArray} from "../../utils/common";
import NotExist from "./NotExist";
import {getfileSize} from "../../utils/size";
import CreateIcon from "@mui/icons-material/Create";
import {Collapse} from "@mui/material";
import SnapshotDetail from "../snapshot/SnapShotDetail";
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ConfirmPopover from "./ConfirmPopover";
import {DelBackupTxt} from "../constant/Popover";
import {delBackup} from "../snapshot/action";
import {useDispatch} from "react-redux";
import {checkErrorResponse} from "../../helpers/errorCheck";
import {LoaderImg} from "./LoaderImg";
import ConfirmModal from "./ConfirmModal";


const BackupButton = ({setDelNum, index, delNum, id}) => {
    const dispatch = useDispatch();
    const [popoverOpen, setPopoverOpen] = useState(null);
    const [loader, setLoader] = useState(false);

    const handleDelBackup = () => {
        return new Promise((resolve, reject) => {
            delBackup(id).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        dispatch(response);
                        resolve(true);
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
                reject(err);
            })
        })
    }

    return (
        <td>
            <ConfirmModal
                onlyIcon={true}
                className={'my_table_trash_btn'}
                type={'deleteBackup'}
                handleEvent={handleDelBackup}/>
            {/*<button*/}
            {/*    aria-describedby='del_backup_server'*/}
            {/*    className='my_table_trash_btn'*/}
            {/*    onClick={event => setPopoverOpen(event.currentTarget)}*/}
            {/*    onMouseEnter={() => setDelNum(index)}*/}
            {/*    onMouseLeave={() => setDelNum(-1)}>*/}
            {/*    <DeleteIcon color={delNum === index ? 'error' : 'disabled'}/>*/}
            {/*</button>*/}
            {/*<ConfirmPopover*/}
            {/*    anchorEl={popoverOpen}*/}
            {/*    setAnchorEl={setPopoverOpen}*/}
            {/*    type={'restart'}*/}
            {/*    title={'백업을 삭제하시겠습니까 ?'}*/}
            {/*    loader={loader}*/}
            {/*    btnTxt={'백업 삭제'}*/}
            {/*    nodeId={'del_backup_server'}*/}
            {/*    text={DelBackupTxt}*/}
            {/*    event={handleDelBackup}/>*/}
        </td>
    )
}


const MyImgTable = ({topRow, dataRow, detailType, location}) => {

    const idS = topRow.map(row => row.id);
    const [mdNum, setMdNum] = useState(-1);
    const [delNum, setDelNum] = useState(-1);
    const [open, setOpen] = useState(-1);
    const handleOpen = (index) => {
        if (open === index) setOpen(-1);
        else setOpen(index);
    }

    return (
        <div className='my_table_wrapper'>
            <Table>
                <thead>
                <tr>
                    {topRow.map((item, index) => <th key={index} style={{minWidth: `${item.minWidth}px`}}>{item.name}</th>)}
                    <th>{' '}</th>
                </tr>
                </thead>
                <tbody>
                {
                    dataRow.map((data, index) => {
                            return (
                                <Fragment key={index}>
                                    <tr className='my_img_table_tr' onClick={() => handleOpen(index)}>
                                        {idS.map((id, index) => {
                                            if (id === 'vs_os_id' || id === 'vi_os_id') {
                                                const imgSource = allOsList.filter(os => parseInt(os.id) === data[id]);
                                                if (isIterableArray(imgSource)){
                                                    if (imgSource[0].imgName === 'Iso'){
                                                        return (
                                                            <td key={index}>
                                                                <img id='snapShotOSImg' src={`https://s3.server24.kr/panel/img/os/${imgSource[0].imgName}.svg`}/>
                                                            </td>
                                                        )
                                                    }else {
                                                        return (
                                                            <td key={index}>
                                                                <img id='snapShotOSImg' src={`https://s3.server24.kr/panel/img/os/${imgSource[0].imgName}.png`}/>
                                                            </td>
                                                        )
                                                    }
                                                }

                                            } else if (id === 'vs_size' || id === 'vb_size') {
                                                return (
                                                    <td key={index}>
                                                        <span>{getfileSize(data[id])}</span>
                                                    </td>
                                                )
                                            } else if (id === 'vs_status' || id === 'vb_status') {
                                                return (
                                                    data[id] === 'complete' ?
                                                        <td key={index} className='status_success'>
                                                            <div className='status_wrapper'>
                                                                <div id='status_circle'/>
                                                                <span>사용가능</span>
                                                            </div>
                                                        </td>

                                                        : <td key={index} className='status_pending'>
                                                            <div className='status_wrapper'>
                                                                <div id='status_circle'/>
                                                                <span>처리중</span>
                                                            </div>
                                                        </td>
                                                )
                                            } else {
                                                return (
                                                    <td key={index}>{id === 'vs_description' ?
                                                        <div id='snapShotName'>
                                                            <span>{data[id]}</span>
                                                            <small>{data.vs_id}</small>
                                                        </div>
                                                        :
                                                        <div id='snapShotName'>
                                                            <span>{data[id]}</span>
                                                            {id === 'vb_description' && <small>{data.vb_id}</small>}
                                                        </div>
                                                    }
                                                    </td>
                                                )
                                            }
                                        })}
                                        {detailType === 'backup' ?
                                            <BackupButton id={data.vb_id} index={index} delNum={delNum} setDelNum={setDelNum}/>
                                            :
                                            <td>


                                                <button className='my_table_trash_btn' onMouseEnter={() => setMdNum(index)}
                                                        onMouseLeave={() => setMdNum(-1)}>
                                                    <CreateIcon color={mdNum === index ? 'primary' : 'disabled'}/>
                                                </button>
                                                <button className='my_table_trash_btn' onMouseEnter={() => setDelNum(index)}
                                                        onMouseLeave={() => setDelNum(-1)}>
                                                    {location === 'instance' ?
                                                        <SettingsBackupRestoreIcon color={delNum === index ? 'primary' : 'disabled'}/>
                                                        :
                                                        <DeleteIcon color={delNum === index ? 'error' : 'disabled'}/>
                                                    }

                                                </button>
                                            </td>
                                        }
                                    </tr>
                                    <tr>
                                        <td style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                                            <Collapse in={open === index} timeout="auto" unmountOnExit>
                                                {detailType === 'snapShot' && <SnapshotDetail snapshotStatus={data.vs_status} location={location} info={data}/>}
                                            </Collapse>
                                        </td>
                                    </tr>
                                </Fragment>)
                        }
                    )
                }
                </tbody>


            </Table>
        </div>
    )
}

MyImgTable.defaultProps = {
    topRow: [],
    dataRow: []
}

export default MyImgTable;
