import React, {useState, useEffect, Fragment} from "react";
import MyImgTable from "../common/MyImgTable";
import {useDispatch, useSelector} from "react-redux";
import {snapShotListRow} from "../constant/TableRow";
import ListSkeleton from "../common/ListSkeleton";
import {getSnapShot} from "./action";
import SnapShotMobileTable from "./SnapShotMobileTable";
import {useMediaQuery} from "react-responsive";
import {checkErrorResponse} from "../../helpers/errorCheck";
import {isIterableArray} from "../../utils/common";
import NotExistTable from "../common/NotExistTable";
import NotManageExistTable from "../manage/NotManageExistTable";

const SnapShotsTable = ({location, type, toggle}) => {
    const dispatch = useDispatch();
    const {snapShotList} = useSelector(state => state.snapShot);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const isMobile = useMediaQuery({
        query: "(max-width:880px)"
    });
    useEffect(() => {
        getSnapShot().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    setSkeletonLoader(true);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [])


    return (
        <div className='snapshots_table_wrapper'>
            <ListSkeleton loading={skeletonLoader}>
                {isIterableArray(snapShotList) ?
                    <Fragment>
                        {isMobile ?
                            <SnapShotMobileTable type={'snapShot'} snapShotList={snapShotList}/>
                            :
                            <MyImgTable type='스냅샷이' location={location} detailType='snapShot' topRow={snapShotListRow} dataRow={snapShotList}/>

                        }
                    </Fragment>
                    :
                    <Fragment>
                        {type === 'manage' ?
                            <NotManageExistTable txt={'스냅샷이 존재하지 않습니다.'} btnTxt={'스냅샷 생성'} toggle={toggle}/>
                            :
                            <NotExistTable searchExist={false} clickEvent={toggle} type={'snapShot'}/>
                        }
                    </Fragment>
                }

            </ListSkeleton>
        </div>
    )
}
export default SnapShotsTable;
