import React, {useState} from "react";
import GameBackupTable from "./GameBackupTable";


const GameServerBackup = () => {


    return (
        <div className='game_server_backup'>
            <GameBackupTable/>
        </div>
    )
}

export default GameServerBackup;
