import React from "react";
import Chart from "react-apexcharts";
import {useSelector} from "react-redux";
import moment from "moment";

const TIME_RANGE_IN_MILLISECONDS = 30 * 1000;
const ADDING_DATA_INTERVAL_IN_MILLISECONDS = 2000;
const ADDING_DATA_RATIO = 0.8;


export const GameStatusGraph = ({type}) => {


    const {gameInstanceInfo} = useSelector(state => state.gameDeploy);
    const nameList = [type];
    const defaultDataList = nameList.map(name => ({
        name: name,
        data: []
    }));
    const [dataList, setDataList] = React.useState(defaultDataList);


    const options = {
        colors: [function ({value, seriesIndex, w}) {
            if (type === 'CPU Usage') {
                return '#4d99ff'
            } else if (type === 'Memory Usage') {
                return '#62AC57'
            } else {
                return '#e5c7ca'
            }
        }],
        chart: {
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            },
            animations: {
                easing: "linear",
                dynamicAnimation: {
                    speed: 500
                }
            }
        },
        tooltip: {
            enabled: false,
            style: {
                colors: 'black',
                fontSize: '12px',
            },
            x: {
                // format: "yyyy/MM/dd HH:mm:ss"
            }
        },
        xaxis: {
            labels: {
                style: {
                    colors: 'white'
                },
                datetimeUTC: false,
            },
            type: "datetime",
            range: TIME_RANGE_IN_MILLISECONDS
        },
        yaxis: {
            labels: {
                formatter: (val) => {
                    if (type === 'CPU Usage') {
                        return `${val.toFixed(0)}%`
                    } else if (type === 'Memory Usage') {
                        return `${val.toFixed(0)}MB`
                    } else {
                        return `${val.toFixed(0)}명`
                    }
                },
                style: {
                    colors: 'white'
                }
            },
            // title: {text: "Value"}
        }
    };

    const cpuOptions = {
        colors: [function ({value, seriesIndex, w}) {
            if (type === 'CPU Usage') {
                return '#4d99ff'
            } else if (type === 'Memory Usage') {
                return '#62AC57'
            } else {
                return '#e5c7ca'
            }
        }],
        chart: {
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            },
            animations: {
                easing: "linear",
                dynamicAnimation: {
                    speed: 500
                }
            }
        },
        tooltip: {
            enabled: false,
            style: {
                colors: 'black',
                fontSize: '12px',
            },
            x: {
                // format: "yyyy/MM/dd HH:mm:ss"
            }
        },
        xaxis: {
            labels: {
                style: {
                    colors: 'white'
                },
                datetimeUTC: false,
            },
            type: "datetime",
            range: TIME_RANGE_IN_MILLISECONDS
        },
        yaxis: {
            min:0,
            max: 100,
            labels: {
                formatter: (val) => {

                    if (val === Infinity || val > 300) {
                        return `0%`
                    } else {
                        return `${val.toFixed(0)}%`
                    }
                },
                style: {
                    colors: 'white'
                }
            },
            // title: {text: "Value"}
        }
    };

    React.useEffect(() => {

        const addDataRandomly = data => {
            if (Math.random() < 1 - ADDING_DATA_RATIO) {
                return data;
            }

            return [
                ...data,
                {
                    x: moment().toDate(),
                    y: gameInstanceInfo.Status.Metrics[type].RawValue
                }
            ];
        };
        const interval = setInterval(() => {
            setDataList(
                dataList.map(val => {
                    return {
                        name: val.name,
                        data: addDataRandomly(val.data)
                    };
                })
            );
        }, ADDING_DATA_INTERVAL_IN_MILLISECONDS);

        return () => clearInterval(interval);
    });


    return <Chart type="line" options={type === 'CPU Usage' ? cpuOptions : options} series={dataList}/>;
};
