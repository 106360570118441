import React, {useState, useEffect} from "react";
import MyTxtTable from "../../common/MyTxtTable";
import {useDispatch, useSelector} from "react-redux";
import ToastBox from "../../common/ToastBox";
import {addInstanceIpv4, getInstanceIpv4, updateInstanceDetail} from "../action";
import {ipv4Row, ipv6Row} from "../../constant/TableRow";
import ListSkeleton from "../../common/ListSkeleton";
import {ToastMsg} from "../../constant/ToastMsg";
import {useMediaQuery} from "react-responsive";
import MobileIpv4Table from "./mobileSetting/MobileIpv4Table";
import MobileIpv6Table from "./mobileSetting/MobileIpv6Table";
import {checkErrorResponse} from "../../../helpers/errorCheck";
import ConfirmModal from "../../common/ConfirmModal";

const SettingIP = () => {
    const dispatch = useDispatch();
    const {instanceInfo, featureInfo, ipv4List} = useSelector(state => state.manage);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    useEffect(() => {
        getInstanceIpv4(instanceInfo.vi_id).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    setSkeletonLoader(true);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [])

    const isMobile = useMediaQuery({
        query: "(max-width:880px)"
    });

    const handleIpv4Assign = () => {
        return new Promise((resolve, reject) => {
            const post = {
                reboot: true
            }
            addInstanceIpv4(instanceInfo.vi_id, post).then(response => {
                if (response) {
                    if (response.request.status === 201) {
                        dispatch(response);
                        resolve(true);
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
                reject(err);
            })
        })
    }

    const handleIpv6Assign = () => {
        return new Promise((resolve, reject) => {
            const post = {
                enable_ipv6: true
            }
            updateInstanceDetail(instanceInfo.vi_id, post, 'enable_ipv6').then(response => {
                if (response) {
                    if (response.request.status === 202) {
                        dispatch(response);
                        resolve(true);
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
                reject(err);
            })
        })
    }

    return (
        <div className='setting_ip_wrapper'>
            <div className='ip_v4'>
                <div className='ip_v4_title'>
                    <h4>Public IPv4 Network</h4>
                    <ConfirmModal
                        color='primary'
                        variant={'contained'}
                        type={'addIpv4'}
                        handleEvent={handleIpv4Assign}/>
                    {/*<Button*/}
                    {/*    aria-describedby='add_ipv4'*/}
                    {/*    onClick={event => setIpv4PopoverOpen(event.currentTarget)}*/}
                    {/*    color='primary'*/}
                    {/*    variant={'contained'}>*/}
                    {/*    <ControlPointIcon/>IPv4 주소 추가*/}
                    {/*</Button>*/}
                    {/*<ConfirmPopover*/}
                    {/*    popLocation={'right'}*/}
                    {/*    anchorEl={ipv4PopoverOpen}*/}
                    {/*    setAnchorEl={setIpv4PopoverOpen}*/}
                    {/*    type={'restart'}*/}
                    {/*    loader={loader}*/}
                    {/*    title={'IPv4 IP 주소를 추가하시겠습니까??'}*/}
                    {/*    btnTxt={'IP 주소 추가'}*/}
                    {/*    checkLabel={'네, 추가하겠습니다.'}*/}
                    {/*    nodeId={'add_ipv4'}*/}
                    {/*    text={Ipv4AssignTxt}*/}
                    {/*    event={handleIpv4Assign}/>*/}
                </div>
                <ListSkeleton itemNum={2} loading={skeletonLoader}>
                    {isMobile ?
                        <MobileIpv4Table ipList={ipv4List}/>
                        :
                        <MyTxtTable detailType='ipv4' dataRow={ipv4List} topRow={ipv4Row} type={'IPv4가'}/>
                    }

                </ListSkeleton>
            </div>
            {featureInfo.includes('ipv6') ?
                <div className='ip_v6'>
                    <h4>Public IPv6 Network</h4>
                    <ListSkeleton itemNum={1} loading={skeletonLoader}>
                        {isMobile ?
                            <MobileIpv6Table ipv6={instanceInfo}/>
                            :
                            <MyTxtTable detailType='ipv6' dataRow={[instanceInfo]} topRow={ipv6Row} type={'IPv6가'}/>
                        }
                    </ListSkeleton>
                </div>
                :
                <div className='not_ip_v6'>
                    <ToastBox type={'info'} title={'알림'} text={ToastMsg.NotActiveIpv6Msg}/>
                    <ConfirmModal
                        color={'primary'}
                        variant={'contained'}
                        type={'enableIpv6'}
                        handleEvent={handleIpv6Assign}/>
                    {/*<Button*/}
                    {/*    onClick={event => setPopoverOpen(event.currentTarget)}*/}
                    {/*    aria-describedby='ipv6_assign'*/}
                    {/*    color={'primary'}*/}
                    {/*    variant={'contained'}>*/}
                    {/*    IPv6 네트워크 할당*/}
                    {/*</Button>*/}
                    {/*<ConfirmPopover*/}
                    {/*    popLocation={'right'}*/}
                    {/*    anchorEl={popoverOpen}*/}
                    {/*    setAnchorEl={setPopoverOpen}*/}
                    {/*    type={'restart'}*/}
                    {/*    loader={loader}*/}
                    {/*    title={'IPv6 네트워크를 할당하시겠습니까??'}*/}
                    {/*    btnTxt={'네트워크 할당'}*/}
                    {/*    checkLabel={'네, 할당하겠습니다.'}*/}
                    {/*    nodeId={'ipv6_assign'}*/}
                    {/*    text={Ipv6AssignTxt}*/}
                    {/*    event={handleIpv6Assign}/>*/}
                </div>
            }

        </div>
    )
}


export default SettingIP;
