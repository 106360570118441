import * as types from './actionTypes';
import axios from "axios";

export const getInstance = async () => {
    const getInstanceRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._GET_INSTANCE,
        request: getInstanceRequest
    }
}

export const getWebHostingInstance = async () => {
    const getWebHostingInstanceRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/cpanel`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._GET_WEB_HOSTING_INSTANCE,
        request: getWebHostingInstanceRequest
    }
}

export const getUserTotalBandWidth = async () => {
    const getUserTotalBandWidthRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/servermv/bandwidth`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._GET_USER_TOTAL_BANDWIDTH,
        request: getUserTotalBandWidthRequest
    }
}

export const getUserName = async () => {
    const getUserNameRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/user/event`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: getUserNameRequest
    }
}

export const addInstance = async (post) => {
    const addInstanceRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._ADD_INSTANCE,
        request: addInstanceRequest
    }
}

export const updateInstance = async (post, id) => {
    const updateInstanceRequest = await axios.patch(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/${id}`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._UPDATE_INSTANCE,
        request: updateInstanceRequest,
        id: id,
        post: post
    }
}

export const deleteInstance = async (id) => {
    const deleteInstanceRequest = await axios.delete(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/${id}`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._DEL_INSTANCE,
        request: deleteInstanceRequest,
        id: id
    }
}

export const detailDelInstance = (id) => {
    return {
        type: types._DEL_INSTANCE,
        id: id
    }
}

export const updateInstanceMainIp = (id, ip) => {
    return {
        type: types._UPDATE_MAIN_IP,
        id: id,
        mainIp: ip
    }
}

export const updateAllStatusList = (status, power_status, server_status, id) => {
    return {
        type: types._UPDAET_ALL_STATUS,
        status: status,
        power_status: power_status,
        server_status: server_status,
        id: id
    }
}

export const updateStatusList = (ids, status) => {
    return {
        type: types._UPDATE_STATUS_LIST,
        ids: ids,
        status: status
    }
}

export const checkPassword = async (post) => {
    return await axios.post(`https://${process.env.REACT_APP_API_SERVER}/auth/confirm/password`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });
}

export const addWebHostingInstance = async (regionId, post) => {
    const addInstanceRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/cpanel/${regionId}/account/user`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        // type: types._ADD_INSTANCE,
        request: addInstanceRequest
    }
}

export const deleteWebHostingInstance = async (no) => {
    const deleteWebHostingInstanceRequest = await axios.delete(`https://${process.env.REACT_APP_API_SERVER}/cpanel/account/user/${no}`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._DEL_WEB_HOSTING_INSTANCE,
        request: deleteWebHostingInstanceRequest,
        no
    }
}

export const getWebHostingPlan = async (no) => {
    const getWebHostingPlanRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/cpanel/account/user/${no}/availability`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._GET_WEB_HOSTING_PLAN,
        request: getWebHostingPlanRequest
    }
}

export const updateWebHostingPlan = async (no, planId) => {
    const updateWebHostingPlanRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/cpanel/account/user/${no}/upgrade`, {plan: planId}, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        no,
        planId,
        type: types._UPDATE_WEB_HOSTING_PLAN,
        request: updateWebHostingPlanRequest
    }
}
