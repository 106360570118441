import React from "react";
import {Provider} from 'react-redux';
import store from './store';
import {BrowserRouter as Router} from 'react-router-dom';
import Layout from "./layouts/Layout";
import axios from "axios";
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import { createTheme } from '@mui/material/styles';
import {ThemeProvider} from "@emotion/react";

axios.defaults.withCredentials = true;


function App() {

    const theme = createTheme({
        palette: {
            primary: {
                light: '#4D99FF',
                main: '#4D99FF',
                dark: '#4D99FF',
                contrastText: '#fff',
            },
        },
    });



    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPCHA_KEY}>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <Router>
                        <Layout/>
                    </Router>
                </ThemeProvider>
            </Provider>
        </GoogleReCaptchaProvider>
    );
}

export default App;
