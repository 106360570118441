import React, {useEffect} from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Profile from "./Profile";
import {useDispatch, useSelector} from "react-redux";
import {initProfile} from "./action";
import AuthBusiness from "./AuthBusiness";
import ActivityHistory from "./ActivityHistory";
import {checkErrorResponse} from "../../helpers/errorCheck";
import {isGameMode, isGameModeTxt} from "../../utils/common";

const Account = () => {
    const dispatch = useDispatch();
    const {layoutMode} = useSelector(state => state.layout);
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        initProfile().then(response => {
            const request = response.request;
            if (request.status === 200) {
                dispatch(response);
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [])

    return (
        <div className={isGameMode(layoutMode) ? 'container-md account_wrapper dark_mode_account' : 'container-md account_wrapper'}>
            <div className='account_top'>
                <h2 className={isGameModeTxt(layoutMode)}>마이페이지</h2>
            </div>
            <div className='account_content'>
                <Box sx={{width: '100%', typography: 'body1'}}>
                    <TabContext value={value}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <TabList className='account_tab_wrapper' onChange={handleChange} aria-label="profile_tab">
                                <Tab label="프로필" value="1"/>
                                <Tab label="활동 기록" value="2"/>
                                {/*<Tab label="Item Three" value="3"/>*/}
                            </TabList>
                        </Box>
                        <TabPanel className='p-0' value="1"><Profile/></TabPanel>
                        <TabPanel className='p-0' value="2"><ActivityHistory/></TabPanel>
                        {/*<TabPanel value="3">Item Three</TabPanel>*/}
                    </TabContext>
                </Box>

            </div>

        </div>
    )
}

export default Account;
