import React from "react";
import {useSelector} from "react-redux";
import {Col, Row} from "reactstrap";
import moment from "moment";
import {getNumString} from "../../common/GameTypeImgItem";




const ConsoleList = () => {
    const {consoleList} = useSelector(state => state.gameDeploy);
    return (
        <div className='console_list_wrapper'>
            <Row>
                {consoleList.map((consoleInfo, index) => {
                    return (
                        <Col key={index} xs={12}>
                            <Row>
                                <Col xs={3}>
                                    <span style={{color: 'orange'}}>{
                                        moment(new Date(getNumString(consoleInfo.Timestamp))).format('YYYY년 MM월 DD일 HH:mm:ss')
                                    }</span>
                                </Col>
                                <Col xs={9}>
                                    <div className='console_content'>
                                        <span style={{color: '#4d99ff'}}>{consoleInfo.Source}</span>
                                        <p>{consoleInfo.Contents}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    )
                })}
            </Row>
        </div>
    )
}

export default ConsoleList;
