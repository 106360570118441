import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import productImg from '../assets/img/common/cloud-server.svg';
import backupImg from '../assets/img/common/notBackup.svg';
import snapShotImg from '../assets/img/common/notSnapShot.svg';
import scriptImg from '../assets/img/common/notScript.svg';
import ticketImg from '../assets/img/common/manager.svg';
import webHostingImg from '../assets/img/common/notWebHosting.svg';
import gameServerImg from '../assets/img/common/notExistGameServer.svg';
import CameraIcon from '@mui/icons-material/Camera';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import MoneyOffCsredOutlinedIcon from '@mui/icons-material/MoneyOffCsredOutlined';
import DoNotTouchOutlinedIcon from '@mui/icons-material/DoNotTouchOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';

export const notExistData = {
    product: {
        img: productImg,
        title: '반갑습니다!',
        description: '아래 버튼을 눌러 첫 번째 서버를 생성해보세요!',
        btnTxt: '여기를 눌러 첫 번째 서버 생성하기',
        btnLink: '/deploy',
        features: [
            {
                title: '수 분내로 생성되는 완전 자동화 시스템',
                description: '복잡한 계약이나 약정 없이, 언제든 필요에 따라 서버를 생성하고 삭제하세요',
                icon: <DnsOutlinedIcon fontSize={'large'}/>,
            },
            {
                title: 'Administrator / root 권한 기본 제공',
                description: '성능과 보안 그리고 비용 모두가 우수한 고가용성 고성능 인프라를 만나보세요',
                icon: <SettingsOutlinedIcon fontSize={'large'}/>,
            },
            {
                title: '모든 리전에서 TCP/UDP 10G DDoS 방어 지원',
                description: '대한민국을 포함한 전세계 19개국에 즉시 서버를 배포해보세요',
                icon: <SecurityOutlinedIcon fontSize={'large'}/>,
            }
        ]
    },
    snapShot: {
        img: snapShotImg,
        title: '앗, 아직 생성된 스냅샷이 없네요!',
        description: '스냅샷은 생성된 서버에서 언제든 무중단으로 생성하실 수 있습니다!',
        btnTxt: '스냅샷 생성하기',
        btnLink: '/',
        features: [
            {
                title: '즉시 복제와 복원 가능한 자유로운 스냅샷 시스템',
                description: '원하는 지점에 생성하고, 복원하고, 새로운 서버로 복제할 수 있습니다',
                icon: <CameraAltOutlinedIcon fontSize={'large'}/>,
            },
            {
                title: '스냅샷을 백업 용도로 사용해보세요!',
                description: '작업 전 스냅샷을 찍어두시면 장애시 신속한 복구가 가능합니다',
                icon: <CameraIcon fontSize={'large'}/>,
            },
        ]
    },
    backup: {
        img: backupImg,
        title: '앗, 아직 저장된 백업이 없네요!',
        description: '백업은 지정하신 스케줄에 맞게 자동으로 무중단 백업되며, 별도 비용 없이 서버를 즉시 특정 지점으로 복원할 수 있습니다',
        btnTxt: '여기를 눌러 서버를 선택하고 자동 백업을 설정해보세요',
        btnLink: '/',
        features: [
            {
                title: '사용자화 가능한 자동 백업 스케줄',
                description: '서버 마다 다른 백업 스케줄을 지정할 수 있습니다',
                icon: <CalendarTodayOutlinedIcon fontSize={'large'}/>,
            },
            {
                title: '안전하게 보관되는 백업본',
                description: '백업된 파일은 별도의 내결함성 저장소에 저장되며 무중단으로 진행됩니다',
                icon: <DnsOutlinedIcon fontSize={'large'}/>,
            },
            {
                title: '비용효율적인 자동 백업 시스템',
                description: '백업 서비스 신청 시, 대상 서버 요금제의 10~30% 비용이 추가로 부과됩니다',
                icon: <BackupOutlinedIcon fontSize={'large'}/>,
            }
        ]
    },
    script: {
        img: scriptImg,
        title: '앗, 아직 생성한 스크립트가 없네요!',
        description: '서버 생성 후 반복되는 초기 작업을 스크립트로 작성할 수 있습니다',
        btnTxt: '첫 번째 스크립트 생성하기',
        btnLink: '/',
        features: [
            {
                title: '지금 바로 사용해보세요',
                description: '작성된 스크립트는 신규 서버 신청 시 사용하실 수 있습니다',
                icon: <DescriptionOutlinedIcon fontSize={'large'}/>,
            },
            {
                title: '완전 무제한 무료',
                description: '스크립트는 갯수 상관 없이 무제한 무료 기능입니다',
                icon: <MoneyOffCsredOutlinedIcon fontSize={'large'}/>,
            },
            {
                title: '반복 작업은 이제 안녕',
                description: '스크립트를 미리 작성해두시면, 반복 작업을 최소화하실 수 있습니다',
                icon: <DoNotTouchOutlinedIcon fontSize={'large'}/>,
            }
        ]
    },
    ticket: {
        img: ticketImg,
        title: '도움이 필요하신가요?',
        description: '서비스 이용중 문제가 발생하셨다면, 언제든 티켓으로 문의주시면 전문 상담사가 친절하고 정확하게 문제를 해결해드리겠습니다!',
        btnTxt: '첫 번째 문의사항 접수하기',
        btnLink: '/',
        features: [
            {
                title: '24/7 연중무휴 온라인 기술지원',
                description: '문제가 있으시다면 언제든 티켓으로 문의주세요, 곧 전문 상담사가 고객님을 도와드릴 것입니다',
                icon: <QuizOutlinedIcon fontSize={'large'}/>,
            },
        ]
    },
    webHosting: {
        img: webHostingImg,
        title: '반갑습니다!',
        description: '아래 버튼을 눌러 첫 번째 웹 호스팅 서버를 생성해보세요!',
        btnTxt: '여기를 눌러 첫 번째 서버 생성하기',
        btnLink: '/deploy',
        features: [
            {
                title: '수 분내로 생성되는 완전 자동화 시스템',
                description: '복잡한 계약이나 약정 없이, 언제든 필요에 따라 서버를 생성하고 삭제하세요',
                icon: <DnsOutlinedIcon fontSize={'large'}/>,
            },
            {
                title: 'Administrator / root 권한 기본 제공',
                description: '성능과 보안 그리고 비용 모두가 우수한 고가용성 고성능 인프라를 만나보세요',
                icon: <SettingsOutlinedIcon fontSize={'large'}/>,
            },
            {
                title: '일부 리전에서 TCP/UDP 20G+ DDos 방어 지원',
                description: '추가 비용 없이 웹 사이트를 타겟으로 한 대용량 디도스 공격을 효과적으로 방어하세요',
                icon: <SecurityOutlinedIcon fontSize={'large'}/>,
            }
        ]
    },
    gameServer: {
        img: gameServerImg,
        title: '반갑습니다!',
        description: '아래 버튼을 눌러 첫 번째 게임서버를 생성해보세요!',
        btnTxt: '여기를 눌러 첫 번째 게임서버 생성하기',
        btnLink: '/deploy',
        features: [
            {
                title: '추가비용 없이 DDoS 공격 무제한 방어',
                description: '최대 80Tbps+ 이상의 네트워크로 신종 취약점 공격과 대용량 디도스 공격을 추가 비용 없이 효과적으로 방어합니다',
                icon: <DnsOutlinedIcon fontSize={'large'}/>,
            },
            {
                title: '원격 접속이 필요 없는 혁신적인 서버 관리 방식',
                description: '복잡한 원격 접속이나 설정 없이도 웹 페이지로 서버를 손쉽게 관리할 수 있습니다',
                icon: <SettingsOutlinedIcon fontSize={'large'}/>,
            },
            {
                title: '5.0GHz 이상의 초고속 CPU와 SSD 무제한 저장공간 제공',
                description: '고성능 인프라와 NVMe SSD 저장공간을 가용량 내 무제한으로 제공합니다',
                icon: <SecurityOutlinedIcon fontSize={'large'}/>,
            }
        ]
    },
}
