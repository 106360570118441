import React, {Fragment} from "react";
import {Col, Row} from "reactstrap";
import {defaultZendeskIpFieldId} from "../../constant/DefaultValue";
import {isIterableArray, timeToString} from "../../../utils/common";
import {getTicketStatus} from "../../../helpers/common";
import Chip from "@mui/material/Chip";
import {useHistory} from "react-router-dom";


const MobileTicketTable = ({tickets, page, rowsPerPage}) => {
    const history = useHistory();

    return (
        <div className='mobile_table_wrapper'>
            {tickets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ticket, index) => {
                const ipv4Label = ticket.fields.filter(ip => ip.id === defaultZendeskIpFieldId);
                const ticketStatus = getTicketStatus(ticket.status);
                return (
                    <Fragment key={index}>
                        <Row onClick={() => history.push(`/support/view/${ticket.id}`)} className='mobile_item align-items-center'>
                            <Col className='p-0' xs={8}>
                                <span className='fw-bold d-block'>{ticket.subject}</span>
                                {isIterableArray(ipv4Label) && <span className={'small_font'}>{ipv4Label[0].value}</span>}
                            </Col>
                            <Col style={{textAlign: 'right'}} className='p-0' xs={4}>
                                <Chip className='status_chip'
                                      size={'medium'}
                                      label={ticketStatus.txt}
                                      style={{
                                          fontWeight: 'bold',
                                          color: ticketStatus.listColor,
                                          backgroundColor: ticketStatus.backgroundColor
                                      }}/>
                            </Col>
                            <Col style={{textAlign: 'left'}} xs={8} className='p-0'>
                                <span className='small_font'>{timeToString(ticket.updated_at)}</span>
                            </Col>

                        </Row>
                    </Fragment>
                )
            })}
        </div>
    )
}

export default MobileTicketTable;
