import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import MyImgTable from "../common/MyImgTable";
import {backUpRow} from "../constant/TableRow";
import {getBackup} from "./action";
import ListSkeleton from "../common/ListSkeleton";
import {useMediaQuery} from "react-responsive";
import SnapShotMobileTable from "./SnapShotMobileTable";
import {checkErrorResponse} from "../../helpers/errorCheck";
import {isIterableArray} from "../../utils/common";
import NotExistTable from "../common/NotExistTable";

const BackupsTable = () => {
    const dispatch = useDispatch();
    const {backupList} = useSelector(state => state.snapShot);
    const [skeletonLoader, setSkeletonLoader] = useState(false);

    const isMobile = useMediaQuery({
        query: "(max-width:880px)"
    });

    useEffect(() => {
        getBackup().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    setSkeletonLoader(true);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [])


    return (
        <div className='backups_table_wrapper'>
            <ListSkeleton loading={skeletonLoader}>
                {isIterableArray(backupList) ?
                    <Fragment>
                        {isMobile ?
                            <SnapShotMobileTable type={'allBackup'} snapShotList={backupList}/>
                            :
                            <MyImgTable detailType='backup' type='백업이' dataRow={backupList} topRow={backUpRow}/>
                        }
                    </Fragment>
                    :
                    <NotExistTable searchExist={false} type={'backup'}/>
                }
            </ListSkeleton>
        </div>
    )
}
export default BackupsTable;
