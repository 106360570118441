import React, {Fragment, useState} from "react";
import {Table} from "reactstrap";
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import {isIterableArray} from "../../utils/common";
import NotExist from "./NotExist";
import {Button, Collapse} from "@mui/material";
import ScriptDetail from "../script/ScriptDetail";
import Ipv4Body from "../manage/setting/Ipv4Body";
import RestoreButton from "../manage/backup/RestoreButton";
import {getfileSize} from "../../utils/size";


const MyTxtTable = ({topRow, dataRow, detailType}) => {

    const idS = topRow.map(row => row.id);
    const [mdNum, setMdNum] = useState(-1);
    const [delNum, setDelNum] = useState(-1);
    const [open, setOpen] = useState(-1);

    const handleOpen = (index) => {
        if (detailType === 'script') {
            if (open === index) setOpen(-1);
            else setOpen(index);
        }
    }


    return (
        <div className='my_table_wrapper'>
            <Table>
                <thead>
                <tr>
                    {topRow.map((item, index) => <th key={index} style={{minWidth: `${item.minWidth}px`}}>{item.name}</th>)}
                    <th>{' '}</th>
                </tr>
                </thead>
                {detailType === 'ipv4' ?
                    <Ipv4Body dataRow={dataRow} idS={idS}/>
                    :
                    <tbody>
                    {
                        dataRow.map((data, index) => {
                            return (
                                <Fragment key={index}>
                                    <tr className='txt_table_tr' onClick={() => handleOpen(index)}>
                                        {idS.map((id, index) => {
                                            if (id === 'vb_status') {
                                                return (
                                                    data[id] === 'complete' ?
                                                        <td key={index} className='status_success'>
                                                            <div className='status_wrapper'>
                                                                <div id='status_circle'/>
                                                                <span>사용가능</span>
                                                            </div>
                                                        </td>

                                                        : <td key={index} className='status_pending'>
                                                            <div className='status_wrapper'>
                                                                <div id='status_circle'/>
                                                                <span>처리중</span>
                                                            </div>
                                                        </td>
                                                )
                                            } else {
                                                return (
                                                    <td key={index}>

                                                        {id === 'vsc_date_modified' && data[id] === null ?
                                                            <span>{data['vsc_date_created']}</span>
                                                            :
                                                            (id === 'vsc_type') && (data[id] === 'boot') ?
                                                                <span>Boot</span> :
                                                                id === 'vb_size' ? <span>{getfileSize(data[id])}</span> :
                                                                    <span>{data[id]}</span>
                                                        }

                                                    </td>
                                                )
                                            }
                                        })}

                                        <td>
                                            {((detailType !== 'ipv6') && (detailType !== 'backup')) &&
                                            <Fragment>
                                                <button
                                                    className='txt_table_btn'
                                                    onMouseEnter={() => setMdNum(index)}
                                                    onMouseLeave={() => setMdNum(-1)}>
                                                    <CreateIcon color={mdNum === index ? 'primary' : 'disabled'}/>
                                                </button>

                                                <button
                                                    className='txt_table_btn'
                                                    onMouseEnter={() => setDelNum(index)}
                                                    onMouseLeave={() => setDelNum(-1)}>
                                                    <DeleteIcon color={delNum === index ? 'error' : 'disabled'}/>
                                                </button>
                                            </Fragment>
                                            }
                                            {detailType === 'backup' &&
                                            <RestoreButton backupId={data.vb_id}/>
                                            }
                                        </td>

                                    </tr>
                                    {detailType === 'script' &&
                                    <tr>
                                        <td style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                                            <Collapse in={open === index} timeout="auto" unmountOnExit>
                                                <ScriptDetail info={data}/>
                                            </Collapse>
                                        </td>
                                    </tr>
                                    }
                                </Fragment>)
                        })
                    }
                    </tbody>
                }
            </Table>
        </div>
    )
}

MyTxtTable.defaultProps = {
    topRow: [],
    dataRow: [],
    type: ''
}

export default MyTxtTable;
