import * as types from './actionTypes';
import {_UPDATE_WEB_HOSTING_PLAN} from "./actionTypes";
import {isIterableArray} from "../../utils/common";


let initialState = {
    instanceList: [],
    webHostingList: [],
    webHostingPlanList: [],
    currentPlan: {},
    totalBandwidth: []
}


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types._GET_INSTANCE: {
            return {
                ...state,
                instanceList: action.request.data.instances
            }
        }

        case types._GET_WEB_HOSTING_INSTANCE: {
            const instances = action.request.data.cpanels ? action.request.data.cpanels : []
            return {
                ...state,
                webHostingList: instances
            }
        }

        case types._GET_USER_TOTAL_BANDWIDTH: {
            return {
                ...state,
                totalBandwidth: action.request.data.bandwidth
            }
        }

        case types._ADD_INSTANCE: {
            const newInstance = action.request.data.instance;
            return {
                ...state,
                instanceList: [...state.instanceList, newInstance]
            }
        }

        case types._UPDATE_INSTANCE: {
            const scriptIndex = state.instanceList.findIndex(instance => instance.vi_id === action.id);

            state.scriptList[scriptIndex].vsc_name = action.post.name;
            state.scriptList[scriptIndex].vsc_type = action.post.type;
            state.scriptList[scriptIndex].vsc_script = action.post.script;
            return {
                ...state,
                instanceList: [...state.scriptList]
            }
        }

        case types._DEL_INSTANCE: {
            const newInstanceList = state.instanceList.filter(instance => instance.vi_id !== action.id);
            return {
                ...state,
                instanceList: [...newInstanceList]
            }
        }

        case types._UPDAET_ALL_STATUS: {
            const instanceIndex = state.instanceList.findIndex(instance => instance.vi_id === action.id);
            if (instanceIndex !== -1) {
                state.instanceList[instanceIndex].vi_power_status = action.power_status;
                state.instanceList[instanceIndex].vi_server_status = action.server_status;
                state.instanceList[instanceIndex].vi_status = action.status;
            }
            return {
                ...state,
                instanceList: [...state.instanceList]
            }
        }

        case types._UPDATE_MAIN_IP: {
            const instanceIndex = state.instanceList.findIndex(instance => instance.vi_id === action.id);
            if (instanceIndex !== -1) {
                state.instanceList[instanceIndex].vi_main_ip = action.mainIp;

            }
            return {
                ...state,
                instanceList: [...state.instanceList]
            }
        }

        case types._UPDATE_STATUS_LIST: {
            const ids = action.ids;
            for (const id of ids) {
                const instanceIndex = state.instanceList.findIndex(instance => instance.vi_id === id);
                state.instanceList[instanceIndex].vi_power_status = action.status;
            }
            return {
                ...state
            }
        }

        case types._DEL_WEB_HOSTING_INSTANCE: {
            const newWebHostingList = state.webHostingList.filter(instance => instance.cu_no !== action.no);
            return {
                ...state,
                webHostingList: [...newWebHostingList]
            }
        }

        case types._GET_WEB_HOSTING_PLAN: {
            return {
                ...state,
                webHostingPlanList: action.request.data.upgradePlans,
                currentPlan: action.request.data.currentPlan
            }
        }

        case types._UPDATE_WEB_HOSTING_PLAN: {
            const instanceIndex = state.webHostingList.findIndex(instance => instance.cu_no === action.no);
            const planItem = state.webHostingPlanList.filter(plan => plan.cp_id === action.planId);
            if ((instanceIndex !== -1) && isIterableArray(planItem))
                Object.assign(state.webHostingList[instanceIndex], planItem[0]);
            return {
                ...state,

            }

        }

        default:
            return state;
    }

}
