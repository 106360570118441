export const paymentPolicy = '전자상거래 등에서의 상품정보제공 고시\n' +
    '\n' +
    '1. 제작자 또는 공급사\n' +
    '- 상호 : 데이터크레딧\n' +
    '- 대표자 : 한찬희\n' +
    '- 주소 : 경기도 하남시 미사강변중앙로7번안길 25 하남미사유테크밸리 D동 605호\n' +
    '\n' +
    '2. 이용조건 및 이용기간\n' +
    '- 서버24 서비스는 웹/앱 내에서 동일하게 사용 가능합니다.\n' +
    '- 서버24에서 제공하는 서비스는 현금을 포인트로 충전하여 이용할 수 있습니다.\n' +
    '- 서버24에서 현금으로 충전한 1포인트는 한화 1원의 가치를 가지고 있습니다.\n' +
    '- 서버24에서 현금으로 충전한 포인트는 충전일로부터 3개월(90일) 이후에는 환불하실 수 없습니다.\n' +
    '- 서버24에서 현금 및 이벤트를 포함한 포인트는 충전 혹은 지급일로부터 3년(1095일)까지 사용하실 수 있습니다.\n' +
    '- 서버24에서는 이용자가 선택한 요금제를 1일마다 분할하여 계산한 다음 보유한 포인트에서 1일마다 차감하고 있습니다.\n' +
    '\n' +
    '3. 상품 제공 방식\n' +
    '- 사이버 머니(포인트)\n' +
    '\n' +
    '4. 최소 시스템 사양 및 필수 소프트웨어\n' +
    '- 크롬 기반 웹 브라우저 이용 권장\n' +
    '\n' +
    '5. 청약철회 또는 계약의 해지 및 해지에 따른 효과\n' +
    '- 환급(환불)신청은 실제 충전한 유료 포인트에 대해서만 가능합니다.(이벤트 혹은 마케팅으로 지급된 포인트는 환불되지 않습니다.)\n' +
    '- 환급(환불)신청 시, 신청금액의 최대 30%가 환급 수수료로 공제되고 나머지 포인트가 현금으로 환급됩니다.(회원가입 시 동의하신 이용약관 및 환불정책을 참고해주시기 바랍니다.)\n' +
    '- 캐시 충전일로부터 7일(168시간) 이내이고 충전한 포인트나 기존의 포인트를 일체 사용하시지 않은 경우, 환급 수수료 없이 전액을 승인취소(환불)해드립니다.\n' +
    '\n' +
    '6. 소비자상담 관련 문의방법\n' +
    '- 서버24 온라인 티켓 문의(고객센터)\n' +
    '- 서버24 이메일 상담 주소 : support@server24.kr'

export const paymentAgreement = '상품 가격 및 유효기간을 확인하였으며, 계약 관련 고지 사항과 포인트 정책 및 결제 진행 등에 동의합니다.';

export const serverDeployPolicyAgreementTxt = '유의사항을 모두 숙지하였으며, 위 사항에 대해 모두 동의합니다.';

export const mineEulaAgreementTxt = '위 사항을 모두 숙지하였으며, 위 사항에 대해 모두 동의합니다.'

export const deployWarningPolicy = '- 서비스 비용은 일할계산하여 삭제하실 때 까지 보유 포인트에서 매일 차감됩니다.\n' +
    '- 서비스는 신청 후 1분 내 준비되며, 스냅샷에서 복원하실 경우 최대 120분이 소요됩니다.\n ' +
    '- 서비스 신청 후 당일 서버 비용에 대한 포인트가 즉시 차감됩니다.\n ' +
    '- 서비스 생성 후 생성시간으로부터 1시간 이후부터 서버 삭제가 가능합니다.\n ' +
    '- 서비스 이용 중 자사 네트워크 가용량을 초과하거나 장애를 유발할 수 있는 규모의 공격으로 인해 기존 이용자의 정상적인 서비스 이용에 악영향을 줄 것으로 판단되는 경우 일시적으로 널라우팅 될 수 있습니다.'
