import axios from "axios";

export const findPwdRequest = async (post) => {
    return await axios.post(`https://${process.env.REACT_APP_API_SERVER}/auth/account/password`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    })
}

export const resetPwdRequest = async (post, token) => {
    return await axios.patch(`https://${process.env.REACT_APP_API_SERVER}/auth/account/password`, post, {
        headers: {
            "Content-Type": 'application/json',
            "singleusetoken": token
        }
    })
}

