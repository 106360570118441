import React, {useEffect, useState} from "react";
import {Col, Row} from 'reactstrap';
import {notExistData} from "../../datas/notExistData";
import {Button} from "@mui/material";
import {useHistory} from "react-router-dom";
import {isGameModeTxt} from "../../utils/common";
import {useSelector} from "react-redux";

const NotExistTable = ({type, clickEvent, searchExist}) => {
    const history = useHistory();
    const [data, setData] = useState({features: []});
    const {layoutMode} = useSelector(state => state.layout);

    useEffect(() => {
        if (type) {
            setData(notExistData[type]);
        }
    }, [type])

    return (
        <div className='not_exist_table_wrapper'>
            {searchExist ?
                <div className='not_search_exist_wrapper'>
                    <span>검색결과가 존재하지 않습니다.</span>
                </div>
                :
                <Row>
                    <Col md={6}>
                        <div className='not_exist_table_main_txt'>
                            <h4 className={isGameModeTxt(layoutMode)}>{data.title}</h4>
                            {type !== 'webHosting' &&
                            <p>{data.description}</p>
                            }
                            {type !== 'webHosting' &&
                            <Button onClick={clickEvent ? clickEvent : () => history.push(data.btnLink)}>{data.btnTxt}</Button>}
                        </div>
                        <div className='not_exist_table_advantage'>
                            {data.features.map((feature, index) => {
                                return (
                                    <Row key={index} style={{marginTop: '1.5rem'}}>
                                        <Col className='text_left_align icon_wrapper' xs={1}>{feature.icon}</Col>
                                        <Col className='text_left_align description_wrapper' xs={11}>
                                            <span>{feature.title}</span>
                                            <span>{feature.description}</span>
                                        </Col>
                                    </Row>
                                )
                            })}
                        </div>
                    </Col>
                    <Col className='not_exist_img_wrapper' md={6}>
                        <img src={data.img}/>
                    </Col>
                </Row>
            }
        </div>
    )
}

export default NotExistTable;

NotExistTable.defaultProps = {
    type: 'product',
    clickEvent: null,
    searchExist: false
}
