import React, {useEffect, useState} from "react";
import {FormGroup, Input, Label} from "reactstrap";
import TextField from "@mui/material/TextField";
import LoaderButton from "../common/LoaderButton";
import ToastBox from "../common/ToastBox";
import {authenticationBusiness, getAuthBusiness} from "./action";
import {useDispatch, useSelector} from "react-redux";
import {ToastMsg} from "../constant/ToastMsg";
import {DetailDescription} from "../constant/DetailDescription";
import {checkErrorResponse} from "../../helpers/errorCheck";


const AuthBusiness = () => {
    const [bName, setBName] = useState('');
    const [bNo, setBNo] = useState('');
    const [pName, setPName] = useState('');
    const [startDay, setStartDay] = useState('');
    const [loader, setLoader] = useState(false);
    const [isBusiness, setIsBusiness] = useState(false);
    const dispatch = useDispatch();
    const {companyName} = useSelector(state => state.account.businessInfo);
    useEffect(() => {
        getAuthBusiness().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [])

    useEffect(() => {
        if (companyName) setIsBusiness(true);
    }, [companyName]);

    const handleAuthBusiness = () => {
        setLoader(true);
        const post = {
            "b_no": bNo,
            "start_dt": startDay,
            "p_nm": pName,
            "p_nm2": "",
            "b_nm": bName
        }
        authenticationBusiness(post, bName).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    setLoader(false);
                }
            }
        }).catch(err => {
            setLoader(false);
            checkErrorResponse(err);
        })
    }

    return (
        <div className='auth_business_wrapper'>
            <h4>기업 인증</h4>
            {isBusiness ?
                <ToastBox className='auth_business_toast' type={'info'} title={'인증'} text={ToastMsg.AuthenticationBusinessMsg}/>
                :
                <div className='auth_business_form'>
                    <div className='auth_business_form_item'>
                        <FormGroup>
                            <Label>상호명</Label>
                            <Input
                                placeholder={'상호명을 입력해주세요.'}
                                onChange={event => setBName(event.target.value)}
                                id="business_title"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>사업자등록번호</Label>
                            <Input
                                placeholder={'- 를 제외한 번호를 입력해주세요.'}
                                onChange={event => setBNo(event.target.value)}
                                id="business_number"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>대표자명</Label>
                            <Input
                                placeholder={'대표자명을 입력해주세요.'}
                                onChange={event => setPName(event.target.value)}
                                id="business_name"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>개업일자 예)20210705</Label>
                            <Input
                                placeholder={'개업일자를 입력해주세요.'}
                                onChange={event => setStartDay(event.target.value)}
                                id="business_name"
                            />
                        </FormGroup>
                        <LoaderButton className='auth_business_btn' isFull={true} onClick={handleAuthBusiness} loader={loader} variant={'contained'} color={'primary'}>
                            인증
                        </LoaderButton>
                    </div>
                </div>
            }
            <div className='auth_business_description'>
                <div className='business_txt'>
                    {DetailDescription.account.authBusiness.split('\n')
                        .map((line, index) => {
                            return (
                                <span key={index}>{line}<br/></span>
                            )
                        })}
                </div>
            </div>
        </div>
    )

}


export default AuthBusiness
