import React, {Fragment, useEffect, useState} from "react";
import MyTxtTable from "../../common/MyTxtTable";
import {instanceBackUpRow} from "../../constant/TableRow";
import {useDispatch, useSelector} from "react-redux";
import {Col, Row} from "reactstrap";
import ToastBox from "../../common/ToastBox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {notificationAlert, oneDayTime, oneMonthDay, weekString} from "../../../helpers/common";
import {getInstanceBackupAll, updateBackupSchedule, updateInstanceDetail} from "../action";
import LoaderButton from "../../common/LoaderButton";
import ListSkeleton from "../../common/ListSkeleton";
import {ToastMsg} from "../../constant/ToastMsg";
import {useMediaQuery} from "react-responsive";
import SnapShotMobileTable from "../../snapshot/SnapShotMobileTable";
import {checkErrorResponse} from "../../../helpers/errorCheck";
import ConfirmModal from "../../common/ConfirmModal";
import {isIterableArray} from "../../../utils/common";
import NotManageExistTable from "../NotManageExistTable";
import {makeStyles} from "@material-ui/core/styles";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";


const ManageBackupDetail = () => {

    const dispatch = useDispatch();
    const {instanceInfo, backupList, backupSchedule, nextBackupSchedule} = useSelector(state => state.manage);
    const [scheduleType, setScheduleType] = useState('daily');
    const [scheduleHour, setScheduleHour] = useState(0);
    const [scheduleDow, setScheduleDow] = useState(1);
    const [scheduleDom, setScheduleDom] = useState(1);
    const [loader, setLoader] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const useStyles = makeStyles(() => ({

        menuPaper: {
            maxHeight: 400
        }
    }));
    const classes = useStyles();
    const isMobile = useMediaQuery({
        query: "(max-width:880px)"
    });

    useEffect(() => {
        getInstanceBackupAll(instanceInfo.vi_id).then(response => {
            if (response) {
                dispatch(response);
                setSkeletonLoader(true);
            }
        }).catch(err => {
            checkErrorResponse(err);
        })

    }, []);

    useEffect(() => {
        if (backupSchedule) {
            setScheduleType(backupSchedule.vbs_type);
            setScheduleHour(backupSchedule.vbs_hour);
            if (backupSchedule.vbs_type === 'weekly')
                setScheduleDow(backupSchedule.vbs_dow);
            if (backupSchedule.vbs_type === 'monthly')
                setScheduleDom(backupSchedule.vbs_dom);
        }
    }, [backupSchedule])
    const handleInactiveBackup = () => {
        return new Promise((resolve, reject) => {
            const post = {
                backups: 'disabled'
            }
            updateInstanceDetail(instanceInfo.vi_id, post, 'backups').then(response => {
                if (response) {
                    if (response.request.status === 202) {
                        dispatch(response);
                        resolve(true);
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
                reject(err);
            })
        });
    }
    const handleBackupSchedule = () => {
        setLoader(true);
        let post = {
            type: scheduleType,
            hour: scheduleHour
        };
        if (scheduleType === 'weekly') {
            post.dow = scheduleDow;
        }
        if (scheduleType === 'monthly') {
            post.dom = scheduleDom;
        }
        updateBackupSchedule(instanceInfo.vi_id, post).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    setLoader(false);
                    notificationAlert('알림', '자동 백업 스케줄이 성공적으로 변경되었습니다.');
                }
            }
        }).catch(err => {
            setLoader(false);
            checkErrorResponse(err);
        })
    }


    return (
        <div className='manage_backup_detail'>
            <Row>
                <Col className='manage_backup_schedule' xs={12} lg={6}>
                    <div className='manage_box'>
                        <h4>자동 백업 스케줄 설정</h4>
                        <span id={'next_backup_time'}>다음 백업 예정 : {nextBackupSchedule}</span>
                        <div className='schedule_box'>
                            <FormControl fullWidth>
                                <InputLabel id="schedule_type_label">백업 스케쥴 주기</InputLabel>
                                <Select
                                    MenuProps={{classes: {paper: classes.menuPaper}}}
                                    fullWidth
                                    labelId="schedule_type_label"
                                    value={scheduleType}
                                    label="ScheduleType"
                                    onChange={event => setScheduleType(event.target.value)}
                                >
                                    <MenuItem className='select_list_item' value={'daily'}>매일</MenuItem>
                                    <MenuItem className='select_list_item' value={'daily_alt_odd'}>격일(홀수)</MenuItem>
                                    <MenuItem className='select_list_item' value={'daily_alt_even'}>격일(짝수)</MenuItem>
                                    <MenuItem className='select_list_item' value={'weekly'}>매주</MenuItem>
                                    <MenuItem className='select_list_item' value={'monthly'}>매월</MenuItem>
                                </Select>
                            </FormControl>
                            <div className='schedule_time'>
                                {(scheduleType === 'daily' || scheduleType === 'daily_alt_odd' || scheduleType === 'daily_alt_even') &&
                                <div className='schedule_select'>
                                    <FormControl fullWidth>
                                        <InputLabel id="schedule_time_label">백업 스케쥴 시간</InputLabel>
                                        <Select
                                            MenuProps={{classes: {paper: classes.menuPaper}}}
                                            fullWidth
                                            labelId="schedule_time_label"
                                            value={scheduleHour}
                                            label="ScheduleTime"
                                            onChange={event => setScheduleHour(event.target.value)}>
                                            {oneDayTime().map((time, index) => <MenuItem key={index} className='select_list_item' value={time}>{
                                                time / 10 < 1 ? `0${time}` : time
                                            }:00 KST(UTC+9)</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </div>
                                }
                                {(scheduleType === 'weekly' || scheduleType === 'monthly') &&
                                <Row className='schedule_select' noGutters>
                                    <Col xs={6}>
                                        {scheduleType === 'weekly' ?
                                            <FormControl fullWidth>
                                                <InputLabel id="schedule_time_week">백업 스케쥴 요일</InputLabel>
                                                <Select
                                                    MenuProps={{classes: {paper: classes.menuPaper}}}
                                                    fullWidth
                                                    labelId="schedule_time_week"
                                                    value={scheduleDow}
                                                    label="ScheduleWeek"
                                                    onChange={event => setScheduleDow(event.target.value)}
                                                >
                                                    {weekString().map((week, index) => <MenuItem key={index} className='select_list_item' value={index + 1}>{week}요일</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                            :
                                            <FormControl fullWidth>
                                                <InputLabel id="schedule_time_monthly">백업 스케쥴 날짜</InputLabel>
                                                <Select
                                                    MenuProps={{classes: {paper: classes.menuPaper}}}
                                                    fullWidth
                                                    labelId="schedule_time_monthly"
                                                    value={scheduleDom}
                                                    label="ScheduleWeek"
                                                    onChange={event => setScheduleDom(event.target.value)}
                                                >
                                                    {oneMonthDay().map((week, index) => <MenuItem key={index} className='select_list_item' value={index + 1}>{week}일</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        }

                                    </Col>
                                    <Col xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="schedule_time_times">백업 스케쥴 시간</InputLabel>
                                            <Select
                                                MenuProps={{classes: {paper: classes.menuPaper}}}
                                                fullWidth
                                                labelId="schedule_time_times"
                                                value={scheduleHour}
                                                label="ScheduleTime"
                                                onChange={event => setScheduleHour(event.target.value)}>
                                                {oneDayTime().map((time, index) => <MenuItem key={index} className='select_list_item' value={time}>{
                                                    time / 10 < 1 ? `0${time}` : time
                                                }:00 KST</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Col>

                                </Row>
                                }
                            </div>
                            <div className='backup_schedule_btn schedule_select'>
                                <LoaderButton
                                    loader={loader}
                                    onClick={handleBackupSchedule}
                                    isFull={true}
                                    variant={'contained'}
                                    color={'primary'}>
                                    백업 스케줄 변경
                                </LoaderButton>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className='manage_backup_active' xs={12} lg={6}>

                    <div className='manage_box'>
                        <h4>자동 백업 비활성화</h4>
                        <ToastBox type={'warning'} title={'경고'} text={ToastMsg.WarningInactiveBackupMsg}/>
                        <ConfirmModal
                            className={'manage_backup_active_btn'}
                            fullWidth
                            color={'error'}
                            variant={'contained'}
                            type={'disableBackup'}
                            handleEvent={handleInactiveBackup}/>
                    </div>
                </Col>
            </Row>
            <div className='manage_backup_history'>
                <h4>자동 백업 기록</h4>
                <ListSkeleton loading={skeletonLoader}>
                    {isIterableArray(backupList) ?
                        <Fragment>
                            {isMobile ?
                                <SnapShotMobileTable type={'backup'} snapShotList={backupList}/>
                                :
                                <MyTxtTable detailType='backup' dataRow={backupList} topRow={instanceBackUpRow}/>
                            }
                        </Fragment>
                        :
                        <NotManageExistTable isBackup={true} btnTxt={nextBackupSchedule} txt={'백업 기록이 존재하지 않습니다.'}/>}
                </ListSkeleton>
            </div>

        </div>
    )


}

export default ManageBackupDetail
