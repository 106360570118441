import React from "react";
import main_sec_img from '../../../assets/img/game/dashboard/panelGameMain.png';
import gameMainLogoImg from '../../../assets/img/nav/game_main_logo.png';

const GameMainBanner = () => {

    return (
        <div className='game_main_banner_wrapper'>
            <div className='dashboard_main_section'
                 style={{backgroundImage: `url(${main_sec_img})`}}>
                <div className='black_back'>
                    <div className='container-md'>
                        <div className='minecraft_main_section_txt'>
                            <div style={{padding: '2rem 0'}}>
                                <img alt={'banner_logo'} src={gameMainLogoImg}/>
                            </div>
                            <h1>마인크래프트 서버 호스팅 오픈</h1>
                            <button onClick={() => window.location.href = '/'}>PLAY NOW</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default GameMainBanner;
