import React from "react";
import Switch from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {checkErrorResponse} from "../../../../helpers/errorCheck";
import {useDispatch, useSelector} from "react-redux";
import {setGameSettingConfigs} from "../../action";
import {notificationAlert} from "../../../../helpers/common";
import {useMediaQuery} from "react-responsive";
import {makeStyles} from "@material-ui/core/styles";
import {isIterableArray} from "../../../../utils/common";

export const ValTypeForm = ({node, type, defaultValue, EnumValues, tag}) => {
    const dispatch = useDispatch();
    const {sessionId, gameInstanceDetail} = useSelector(state => state.gameDeploy);
    const useStyles = makeStyles(() => ({

        menuPaper: {
            maxHeight: 400
        }
    }));
    const classes = useStyles();
    const isMobile = useMediaQuery({
        query: "(max-width:880px)"
    });

    const handleSetGameConfig = (value) => {

        let configs = {};
        configs[node] = value;
        setGameSettingConfigs(sessionId, gameInstanceDetail.InstanceID, configs, node, value).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    notificationAlert('알림', '설정이 변경되었습니다.');
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }

    switch (type) {
        case 'Int32': {
            return (
                <input className='basic_setting_input' onBlur={event => handleSetGameConfig(event.target.value)} type={'number'} defaultValue={defaultValue}/>
            )
        }
        case 'Boolean': {
            return (
                <Switch
                    inputProps={{'aria-label': 'controlled'}}
                    onChange={event => handleSetGameConfig(event.target.checked)}
                    checked={defaultValue}/>
            )
        }
        case 'Enum': {
            let selectDefaultValue = defaultValue;
            if (defaultValue === "") {
                if (Object.keys(EnumValues).length !== 0) {
                    selectDefaultValue = EnumValues[Object.keys(EnumValues)[0]];
                }

            }

            return (
                <FormControl fullWidth>
                    <InputLabel id={`game_setting_${tag}`}>{tag}</InputLabel>
                    <Select
                        MenuProps={{classes: {paper: classes.menuPaper}}}
                        className='game_setting_select'
                        labelId={`game_setting_${tag}`}
                        onChange={event => handleSetGameConfig(event.target.value)}
                        label={tag}
                        value={typeof defaultValue === 'number' ? JSON.stringify(selectDefaultValue) : selectDefaultValue}
                        style={{color: 'white'}}
                        // onChange={event => setOS(event.target.value)}
                    >
                        {Object.keys(EnumValues).map(function (key, index) {
                            return (
                                <MenuItem key={index} className='select_list_item' value={key}>{EnumValues[key]}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            )
        }
        case 'String': {
            return (
                <input className='basic_setting_input' onBlur={event => handleSetGameConfig(event.target.value)} type={'text'} defaultValue={defaultValue}/>
            )
        }

        case 'Combo': {
            if (tag === "level-type") {
                return (
                    <FormControl fullWidth>
                        <InputLabel id={`game_setting_${tag}`}>{tag}</InputLabel>
                        <Select
                            className='game_setting_select'
                            labelId={`game_setting_${tag}`}
                            label={tag}
                            value={typeof defaultValue === 'number' ? JSON.stringify(defaultValue) : defaultValue}
                            style={{color: 'white'}}
                            // onChange={event => setOS(event.target.value)}
                        >
                            {Object.keys(EnumValues).map(function (key, index) {
                                return (
                                    <MenuItem key={index} className='select_list_item' value={key}>{EnumValues[key]}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                )
            } else if (tag === "level-name") {
                return (
                    <input className='basic_setting_input' onBlur={event => handleSetGameConfig(event.target.value)} type={'text'} defaultValue={defaultValue}/>
                )
            } else {
                return (
                    <input className='basic_setting_input' onBlur={event => handleSetGameConfig(event.target.value)} type={'text'} defaultValue={defaultValue}/>
                )
            }

        }
        default: {
            break;
        }

    }
}

ValTypeForm.defaultProps = {
    type: '',
    defaultValue: '',
    EnumValues: {},
    tag: '',
    node: ''
}
