import React, {Fragment, useEffect, useState} from "react";
import {Row, Col} from "reactstrap";
import eventBanner from '../../assets/img/event/event_banner.png';
import {checkErrorResponse} from "../../helpers/errorCheck";
import {getNotice} from "./action";
import {useDispatch, useSelector} from "react-redux";
import {Chip} from "@mui/material";
import {changePerNum, getDateString, getNoticeType} from "../../helpers/common";
import TablePagination from "@mui/material/TablePagination";
import {isGameMode} from "../../utils/common";

const Notice = () => {
    const dispatch = useDispatch();
    const {noticeList} = useSelector(state => state.notice);
    const {layoutMode} = useSelector(state => state.layout);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(localStorage.getItem('pageNum') ? localStorage.getItem('pageNum') : 10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const perNum = parseInt(event.target.value, 10);
        changePerNum(perNum)
        setRowsPerPage(perNum);
        setPage(0);
    };


    useEffect(() => {
        getNotice().then(response => {
            const request = response.request;
            if (request.status === 200) {
                dispatch(response);
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [])


    return (
        <div className='notice_wrapper '>
            <div className='notice_event_banner'>
                <div className='notice_event_img '>
                    <img src={eventBanner}/>
                </div>
                <div className='notice_event_description container-md'>
                    <h3>오픈 기념 이벤트</h3>
                    <div style={{marginTop: '1rem'}}>
                        <p>지금 휴대폰번호를 인증하시면, 서비스를 체험하실 수 있는 1만원 상당의 포인트를 무료로 드립니다!</p>
                    </div>
                </div>
            </div>

            <div className={isGameMode(layoutMode) ? 'notice_content_wrapper dark_bg_color1' : 'notice_content_wrapper'}>
                <Row className='notice_list_wrapper'>
                    {noticeList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((notice, index) => {
                        const noticeType = getNoticeType('product')
                        return (
                            <Row onClick={() => window.open(notice.url, '_blank')} key={index} className='notice_list_item_wrapper'>
                                <Col className='p-0' key={index} xs={12} lg={8} style={{marginTop: '3rem'}}>
                                    <Row className='notice_list_item'>
                                        <Col xs={12}>
                                            <Chip className='notice_item_chip'
                                                  size='small'
                                                  style={{
                                                      fontSize: '0.4rem',
                                                      fontWeight: 'bold',
                                                      color: noticeType.listColor,
                                                      backgroundColor: noticeType.backgroundColor
                                                  }}
                                                  label={noticeType.txt}/>
                                        </Col>
                                        <Col className='notice_item' xs={12}>
                                            <div className='notice_item_content'>
                                                <h4>{notice.title}</h4>

                                            </div>
                                        </Col>
                                        <Col className='p-0' xs={12}>
                                            <small id='notice_date'>{getDateString(notice.create_time)}</small>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} lg={4} style={{marginTop: '3rem'}}>
                                    <div className='notice_type_img'>
                                        <img src={`https://s3.server24.kr/panel/img/notice/${notice.img}`}/>
                                    </div>

                                </Col>
                            </Row>
                        )
                    })}
                </Row>
                <div className={'notice_list_footer'}>
                    <TablePagination
                        labelRowsPerPage={'페이지당 목록 수'}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={noticeList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </div>
        </div>
    )
}


export default Notice;
