import React, {Fragment, useEffect, useState} from "react";
import {getUpgradeList, updateInstancePlan} from "../action";
import {checkErrorResponse} from "../../../helpers/errorCheck";
import ToastBox from "../../common/ToastBox";
import {ToastMsg} from "../../constant/ToastMsg";
import {convertPlanTxt, isIterableArray} from "../../../utils/common";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ConfirmModal from "../../common/ConfirmModal";
import {useDispatch, useSelector} from "react-redux";
import {Col, Row} from "reactstrap";
import {Slider} from "@material-ui/core";
import upgradeArrow from "../../../assets/img/product/upgradeArrow.svg";
import {Alert, AlertTitle} from "@mui/lab";
import {Button} from "@mui/material";

const ChangePlan = ({instanceInfo}) => {
    const dispatch = useDispatch();
    const {upgradeList, currentPlan} = useSelector(state => state.manage);
    const [planStep, setPlanStep] = useState(0);
    const handleChange = (event, newValue) => {
        if (typeof newValue === 'number') {
            setPlanStep(newValue);
        }
    };
    useEffect(() => {
        getUpgradeList(instanceInfo.vi_id).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [])


    const handleChangePlan = () => {
        return new Promise((resolve, reject) => {
            const instanceId = instanceInfo.vi_id;
            const post = {
                plan: upgradeList[planStep].vp_id
            }
            updateInstancePlan(instanceId, post).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        //TODO 전제 인스턴스 리스트랑 매니지 상태 값 변경 시켜주기
                        dispatch(response);
                        resolve(true);
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
                reject(err);
            })
        })
    }

    return (
        <div className={'web_hosting_plan_modal'}>
            <div className='change_plan_wrapper'>
                <div className='change_plan_title'>
                    <h3>요금제 변경</h3>
                </div>
                {isIterableArray(upgradeList) ?
                    <Fragment>
                        <ToastBox type={'warning'} title={'경고'} text={ToastMsg.ChangePlanMsg}/>
                        <div className='use_plan_txt py-2'>
                            <Row>
                                <Col xs={7}>
                                    <div className='use_plan_now'>
                                        <span>{`현재 요금 : ${(currentPlan.vp_monthly_cost_event_kr).toLocaleString()} P/월`}</span>
                                        <small>{`(${(currentPlan.vp_day_cost_event_kr).toLocaleString()} P/일)`}</small>
                                    </div>
                                    <div className='use_plan_upgrade'>
                                        <span>{`업그레이드 요금 : ${(upgradeList[planStep].vp_monthly_cost_event_kr).toLocaleString()} P/월`}</span>
                                        <small>{`(${(upgradeList[planStep].vp_day_cost_event_kr).toLocaleString()} P/일)`}</small>
                                    </div>
                                </Col>
                                <Col xs={5}>
                                    <div className='use_plan_compare'>
                                        <h4>{`+${(upgradeList[planStep].vp_monthly_cost_event_kr)
                                        - (currentPlan.vp_monthly_cost_event_kr)} P/월`}</h4>
                                        <span>{`+${(upgradeList[planStep].vp_day_cost_event_kr)
                                        - (currentPlan.vp_day_cost_event_kr)} P/일`}</span>
                                    </div>
                                </Col>

                            </Row>


                        </div>
                        <div style={{marginTop: '1.5rem'}}>
                            <Fragment>
                                <Slider
                                    color={'secondary'}
                                    aria-label="PlanInfo"
                                    defaultValue={0}
                                    valueLabelDisplay="auto"
                                    onChange={handleChange}
                                    step={1}
                                    marks
                                    min={0}
                                    max={upgradeList.length - 1}
                                />
                                <div className='plan_info_wrapper'>
                                    <span>요금제 정보</span>
                                    <Row className='plan_info_box'>
                                        <Col xs={5} className='now_plan_info'>
                                            <Row>
                                                <Col xs={6} className='plan_key_list fw-bold'>
                                                    <span>CPU : </span>
                                                    <span>DISK : </span>
                                                    <span>MEMORY : </span>
                                                    <span>TRAFFIC : </span>

                                                </Col>
                                                <Col xs={6} className='plan_key_list' style={{textAlign: 'right'}}>
                                                    <span>{currentPlan.vp_vcpu_count} CPU</span>
                                                    <span>{currentPlan.vp_disk} GB</span>
                                                    <span>{currentPlan.vp_ram} MB</span>
                                                    <span>{currentPlan.vp_bandwidth} GB <br/> <small>(매일 기본 제공량)</small></span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={2} className='text-center'>
                                            <img className='w-75' src={upgradeArrow} alt={'upgrade_arrow'}/>
                                        </Col>
                                        <Col xs={5} className='upgrade_plan_info'>
                                            <Row>
                                                <Col xs={6} className='plan_key_list fw-bold'>
                                                    <span>CPU : </span>
                                                    <span>DISK : </span>
                                                    <span>MEMORY : </span>
                                                    <span>TRAFFIC : </span>
                                                </Col>
                                                <Col xs={6} className='plan_key_list' style={{textAlign: 'right'}}>
                                                    <span>{upgradeList[planStep].vp_vcpu_count} CPU</span>
                                                    <span>{upgradeList[planStep].vp_disk} GB</span>
                                                    <span>{upgradeList[planStep].vp_ram} MB</span>
                                                    <span>{upgradeList[planStep].vp_bandwidth} GB <br/>  <small>(매일 기본 제공량)</small></span>
                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>
                                </div>
                            </Fragment>
                            <ConfirmModal
                                fullWidth
                                color={'primary'}
                                variant={'contained'}
                                type={'webHostingChangePlan'}
                                handleEvent={handleChangePlan}/>
                        </div>
                    </Fragment>
                    :
                    <Fragment>
                        <Alert severity="info">
                            <AlertTitle>알림</AlertTitle>
                            <span>더 이상 업그레이드 가능한 요금제가 존재하지 않습니다.</span>
                        </Alert>
                    </Fragment>
                }
            </div>
        </div>
    )
}

export default ChangePlan;
