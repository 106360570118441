import * as types from './actionTypes';


let initialState = {
    snapShotList: [],
    backupList: []
}


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types._GET_SNAPSHOT: {
            return {
                ...state,
                snapShotList: action.request.data.snapshots
            }
        }

        case types._ADD_SNAPSHOT: {
            const newSnapshot = action.request.data.snapshot;
            return {
                ...state,
                snapShotList: [...state.snapShotList, newSnapshot]
            }
        }

        case types._UPDATE_SNAPSHOT: {
            const snapShotIndex = state.snapShotList.findIndex(snapshot => snapshot.vs_id === action.id);
            state.snapShotList[snapShotIndex].vs_description = action.post.description;

            return {
                ...state,
                snapShotList: [...state.snapShotList]
            }
        }



        case types._DEL_SNAPSHOT: {
            const newSnapShotList = state.snapShotList.filter(snapshot => snapshot.vs_id !== action.id);
            return {
                ...state,
                snapShotList: [...newSnapShotList]
            }
        }

        case types._GET_BACKUP: {
            return {
                ...state,
                backupList: action.request.data.backups
            }
        }

        case types._DEL_BACKUP: {
            const newBackupList = state.backupList.filter(backup => backup.vb_id !== action.id);
            return {
                ...state,
                backupList: [...newBackupList]
            }
        }

        default:
            return state;
    }

}
