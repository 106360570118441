import React, {useState} from "react";
import {DateRangePicker, LocalizationProvider} from "@mui/lab";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {Button, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import HistoryTable from "./HistoryTable";
import {useSelector} from "react-redux";
import {isGameModeTxt} from "../../utils/common";

const ActivityHistory = () => {
    const {layoutMode} = useSelector(state => state.layout);
    return (
        <div className='activity_history_wrapper'>
            <h4 className={isGameModeTxt(layoutMode)}>활동 기록</h4>
            <HistoryTable/>
        </div>
    )
}


export default ActivityHistory;
