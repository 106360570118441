import {allOsList} from "../../datas/OS";
import * as React from "react";
import {Tooltip} from "@material-ui/core";


const OsImgItem = ({osId}) => {
    const osInfo = allOsList.filter(os => parseInt(os.id) === osId);
    let osImgName;
    if (osInfo[0].imgName === 'Snapshot' || osInfo[0].imgName === 'Backup' || osInfo[0].imgName === 'Iso') {
        osImgName = `${osInfo[0].imgName}.svg`;
    } else {
        osImgName = `${osInfo[0].imgName}.png`;

    }
    return (
        <div className='server_item server_os_item'>
            <Tooltip title={osInfo[0].name} placement="top">
                <img src={`https://s3.server24.kr/panel/img/os/${osImgName}`} alt={'os_img'}/>
            </Tooltip>
        </div>
    )
};

export default OsImgItem;
