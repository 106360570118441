import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import PaymentIcon from "@mui/icons-material/Payment";
import StorageIcon from '@mui/icons-material/Storage';
import {useHistory, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {isGameMode} from "../../utils/common";
import GridViewIcon from '@mui/icons-material/GridView';
export default function MobileDrawer({toggleDrawer, state}) {
    const location = useLocation();
    const history = useHistory();
    const {layoutMode, layoutLoader} = useSelector(state => state.layout);
    const {deployLoader} = useSelector(state => state.gameDeploy);
    const userInfo = useSelector(state => state.account.userInfo)
    const list = (anchor) => (
        <Box
            sx={{marginTop: '64px', width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250}}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List className='nav_list_wrapper'>
                <ListItem
                    className={(location.pathname === '/' || location.pathname.includes('manage')) ? 'visit_page' : ''}
                    onClick={() => history.push('/')} button>
                    <ListItemIcon>
                        <DashboardOutlinedIcon
                            color={(location.pathname === '/' || location.pathname.includes('manage')) ? 'primary' : ''}/>
                    </ListItemIcon>
                    <ListItemText primary={'대시보드'}/>
                </ListItem>
                <ListItem className={location.pathname === '/deploy' ? 'visit_page' : ''}
                          onClick={() => history.push('/deploy')} button>
                    <ListItemIcon>
                        <StorageIcon color={location.pathname === '/deploy' ? 'primary' : ''}/>
                    </ListItemIcon>
                    {isGameMode(layoutMode) ?
                        <ListItemText primary={'게임서비스 신청'}/>
                        :
                        <ListItemText primary={'서비스 신청'}/>
                    }
                </ListItem>
                {/*{((userInfo.email === process.env.REACT_APP_SHOW_CONSOLE_ONE) ||*/}
                {/*        (userInfo.email === process.env.REACT_APP_SHOW_CONSOLE_TWO) ||*/}
                {/*        (userInfo.email === process.env.REACT_APP_SHOW_CONSOLE_THREE) ||*/}
                {/*        (userInfo.email === process.env.REACT_APP_SHOW_CONSOLE_FOUR)) &&*/}
                {/*<ListItem className={location.pathname.includes('/centosPanel') ? 'visit_page' : ''}*/}
                {/*          onClick={() => window.open('/centosPanel')} button>*/}
                {/*    <ListItemIcon>*/}
                {/*        <GridViewIcon color={location.pathname.includes('/centosPanel') ? 'primary' : ''}/>*/}
                {/*    </ListItemIcon>*/}
                {/*    <ListItemText primary={'센토스 패널'}/>*/}
                {/*</ListItem>*/}
                {/*}*/}
                <ListItem className={location.pathname.includes('/support') ? 'visit_page' : ''}
                          onClick={() => history.push('/support')} button>
                    <ListItemIcon>
                        <MailOutlineRoundedIcon color={location.pathname.includes('/support') ? 'primary' : ''}/>
                    </ListItemIcon>
                    <ListItemText primary={'고객지원'}/>
                </ListItem>
                {!isGameMode(layoutMode) &&
                    <ListItem className={location.pathname === '/snapshot' ? 'visit_page' : ''}
                              onClick={() => history.push('/snapshot')} button>
                        <ListItemIcon>
                            <CameraAltOutlinedIcon color={location.pathname === '/snapshot' ? 'primary' : ''}/>
                        </ListItemIcon>
                        <ListItemText primary={'스냅샷'}/>
                    </ListItem>
                }
                {!isGameMode(layoutMode) &&
                    <ListItem className={location.pathname === '/script' ? 'visit_page' : ''}
                              onClick={() => history.push('/script')} button>
                        <ListItemIcon>
                            <DescriptionOutlinedIcon color={location.pathname.includes('/script') ? 'primary' : ''}/>
                        </ListItemIcon>
                        <ListItemText primary={'스크립트'}/>
                    </ListItem>
                }
                <ListItem className={location.pathname === '/billing' ? 'visit_page' : ''}
                          onClick={() => history.push('/billing')} button>
                    <ListItemIcon>
                        <PaymentIcon color={location.pathname.includes('/billing') ? 'primary' : ''}/>
                    </ListItemIcon>
                    <ListItemText primary={'결제 및 비용'}/>
                </ListItem>
                {/*<ListItem className={location.pathname === '/handBook' ? 'visit_page' : ''}*/}
                {/*          onClick={() => window.open('https://datacredit.notion.site/SERVER24-KR-f16ad353b6b849bdbe057dea86bd8dfd')}*/}
                {/*          button>*/}
                {/*    <ListItemIcon>*/}
                {/*        <MenuBookOutlinedIcon color={location.pathname.includes('/handBook') ? 'primary' : ''}/>*/}
                {/*    </ListItemIcon>*/}
                {/*    <ListItemText primary={'사용설명서'}/>*/}
                {/*</ListItem>*/}
            </List>
        </Box>
    );

    return (
        <Drawer
            className={isGameMode(layoutMode) ? 'dark_mobile_nav_bar' : ''}
            anchor={'left'}
            open={state['left']}
            onClose={toggleDrawer('left', false)}
        >
            {list('left')}
        </Drawer>
    );
}
