import React, {Fragment, useState} from "react";
import ServiceList from "./ServiceList";
import {useDispatch, useSelector} from "react-redux";
import RegionList from "./region/RegionList";
import LoaderButton from "../common/LoaderButton";
import OSList from "./OSList";
import PriceList from "./price/PriceList";
import SettingList from "./setting/SettingList";
import {addInstance, addWebHostingInstance} from "../Product/action";
import {useHistory} from "react-router-dom";
import {defaultServerHostname, defaultServerLabel} from "../constant/DefaultValue";
import {Row, Col} from 'reactstrap';
import Swal from 'sweetalert2'
import {checkErrorResponse} from "../../helpers/errorCheck";
import ServerTypeList from "./ServerTypeList";
import WebHostingPriceList from "./price/WebHostingPriceList";
import WebHostingRegionList from "./region/WebHostingRegionList";
import WebHostingSettingList from "./setting/WebHostingSettingList";
import WebTotalPrice from "./webHosting/WebTotalPrice";
import {notificationAlert} from "../../helpers/common";
import ServicePolicyBox from "./ServicePolicyBox";


const Deploy = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {region, OS, price, setting} = useSelector((state) => state.deploy.vultr);
    const {server, cpanel} = useSelector((state) => state.deploy);
    const [loader, setLoader] = useState(false);
    const [label, setLabel] = useState(null);
    const [hostName, setHostName] = useState(null);
    const [webDomain, setWebDomain] = useState('');
    const [webUserId, setWebUserId] = useState('');
    const [webUserPwd, setWebUserPwd] = useState('');
    const [checkPolicy, setCheckPolicy] = useState(false);
    const handleMakeDeploy = () => {
        if (!checkPolicy) {
            notificationAlert('알림', '유의사항을 체크하신후 동의하여 주세요.');
            return;
        }
        if (OS.osType === '') {
            notificationAlert('알림', '운영체제를 필수로 선택하여 주세요.');
            return;
        }
        if (!label) {
            notificationAlert('알림', '서버 식별자(Label)을 필수로 입력해 주세요.');
            return;
        }
        if (OS.type.vs_type === 'windows' && price.type.vp_disk < 80) {
            notificationAlert('알림', '윈도우 운영체제에 경우 최소 80기가 이상의 저장공간을 포함한 요금제가 필요합니다.');
            return;
        }
        setLoader(true);
        let post = {
            "hostname": defaultServerHostname,
            "label": label,
            "region": region.type.vr_id,
            "plan": price.type.vp_id,
            "backups": setting.type.additional.autoBackup ? "enabled" : "disabled",
            "enable_ipv6": setting.type.additional.ipv6,
            "ddos_protection": setting.type.additional.ddos,
        }
        switch (OS.osType) {
            case 'os': {
                post.os_id = OS.type.id;
                break;
            }
            case 'iso' : {
                post.iso_id = OS.type.id;
                break;
            }
            case 'snapshot' : {
                post.snapshot_id = OS.type.vs_id;
                break;
            }
            case 'backup' : {
                post.snapshot_id = OS.type.vb_id;
                break;
            }

            default :
                break;
        }
        if (setting.type.scripts.vsc_id !== '') post.script_id = setting.type.scripts.vsc_id;
        if (hostName) post.hostname = hostName;
        addInstance(post).then(response => {
            if (response) {
                if (response.request.status === 201) {
                    setLoader(false);
                    dispatch(response);
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '인스턴스가 생성 되었습니다.',
                        showConfirmButton: false,
                        timer: 1500
                    }).then(r => history.push('/'))

                }
            }
        }).catch(err => {
            setLoader(false);
            checkErrorResponse(err);

        })
    }

    const handleMakeWebHosting = () => {
        if (!checkPolicy) {
            notificationAlert('알림', '유의사항을 체크하신후 동의하여 주세요.');
            return;
        }
        if (webDomain === '') return notificationAlert('알림', '도메인을 필수로 입력하여 주세요.');
        if (webUserId === '') return notificationAlert('알림', 'cpanel 계정 아이디를 필수로 입력하여 주세요.');
        if (webUserPwd === '') return notificationAlert('알림', 'cpanel 계정 비밀번호를 필수로 입력하여 주세요.');
        setLoader(true);
        const post = {
            domain: webDomain,
            username: webUserId,
            password: webUserPwd,
            plan: cpanel.price.type.cp_id
        }
        addWebHostingInstance(cpanel.region.type, post).then(response => {
            if (response) {
                if (response.request.status === 201) {
                    setLoader(false);
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '인스턴스가 생성 되었습니다.',
                        showConfirmButton: false,
                        timer: 1500
                    }).then(r => history.push('/'))
                }
            }
        }).catch(err => {
            setLoader(false);
            checkErrorResponse(err);
        })
    }


    return (
        <Fragment>
            <div className='deploy_wrapper content_init container-md'>
                <div className='deploy_header'>
                    <h3>서비스 신청</h3>
                </div>
                <div className='deploy_content'>
                    <ServicePolicyBox setCheckPolicy={setCheckPolicy} checkPolicy={checkPolicy}/>
                    <ServerTypeList/>
                    {server.type === 'server' && <ServiceList/>}
                    {server.type === 'server' ? <RegionList/> : <WebHostingRegionList/>}
                    {server.type === 'server' && <OSList/>}
                    {server.type === 'server' ? <PriceList/> : <WebHostingPriceList/>}
                    {server.type === 'server' ?
                        <SettingList
                            setLabel={setLabel}
                            setHostName={setHostName}/>
                        :
                        <WebHostingSettingList
                            setDomain={setWebDomain}
                            setId={setWebUserId}
                            setPwd={setWebUserPwd}
                        />}

                </div>
            </div>
            <div className='deploy_footer'>
                <Row className='last_step_footer container-md p-0'>
                    <Col xs={12}>
                        <div className='last_price_wrapper'>
                            <div className='summary_price_wrapper'>
                                <h4 className='fw-bold'>총 비용</h4>
                                {server.type === 'server' ?
                                    <div id='total_price'>
                                        <div className='deploy_month_price'>
                                            <h3>{(price.monthPrice + setting.type.price.month.ddos + setting.type.price.month.autoBackup).toLocaleString()}P</h3><small>/월</small>
                                        </div>
                                        <div className='deploy_month_price'>
                                            <span>({(price.dayPrice + setting.type.price.day.ddos + setting.type.price.day.autoBackup).toLocaleString()}P</span><small>/일</small>)
                                        </div>
                                    </div>
                                    :
                                    <WebTotalPrice/>
                                }

                            </div>
                            <div className='server_deploy_btn_wrapper'>
                                <LoaderButton
                                    disabled={!checkPolicy}
                                    loader={loader}
                                    id={'deploy_next_btn'}
                                    onClick={server.type === 'server' ? handleMakeDeploy : handleMakeWebHosting}>
                                    서비스 신청
                                </LoaderButton>
                            </div>
                        </div>
                    </Col>
                </Row>


            </div>
        </Fragment>
    )
}

export default Deploy;
