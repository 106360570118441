import React from "react";
import {Row, Col} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {setCpanelPrice} from "../action";
import bandWidthIcon from '../../../assets/img/server_info/bandwidth.png';
import cpuIcon from '../../../assets/img/server_info/cpu-tower.png';
import ramIcon from '../../../assets/img/server_info/ram.png';
import storageIcon from '../../../assets/img/server_info/hdd.png';
import ddosIcon from '../../../assets/img/server_info/shield.png';
import DeployTitle from "../DeployTitle";

const WebHostingPriceList = () => {
    const dispatch = useDispatch();
    const {planList, price} = useSelector(state => state.deploy.cpanel);

    const handleSetPrice = (plan) => {
        dispatch(setCpanelPrice(plan))
    }
    return (
        <div className='price_list_wrapper'>
            <DeployTitle title={'요금제를 선택해주세요'}/>
            <Row className='price_list_row'>
                {

                    planList.map((plan, index) => {
                        return (
                            <Col onClick={() => handleSetPrice(plan)} className=' plan_item_wrapper' key={index} xs={12} sm={6} lg={4} xl={3}>
                                <div className={price.type.cp_id === plan.cp_id ? 'plan_item select_plan' : 'plan_item'}>
                                    <div style={{textAlign: 'center'}}>
                                        <h3 id='event_price'>
                                            {plan.cp_monthly_cost !== 0 ? `${plan.cp_monthly_cost.toLocaleString()}P/월` : '무료'}
                                        </h3>
                                        <span id='event_hour_price'>
                                            {plan.cp_day_cost !== 0 ? `${plan.cp_day_cost.toLocaleString()}P/day` : '무료 체험 이벤트'}
                                        </span>
                                    </div>
                                    <hr/>
                                    <div className='plan_server_detail'>
                                        <div className='plan_performance_item'>
                                            <img src={cpuIcon}/>
                                            <span><strong>{plan.cp_cpu}</strong> CPU</span>
                                        </div>
                                        <div className='plan_performance_item'>
                                            <img src={ramIcon}/>
                                            <span><strong>{plan.cp_memory}MB</strong> Memory</span>
                                        </div>
                                        <div className='plan_performance_item'>
                                            <img src={bandWidthIcon}/>
                                            <span><strong>{plan.cp_day_traffic ? `${plan.cp_day_traffic}GB` : '무제한'}</strong> Traffic <small>(매일 기본 제공)</small></span>
                                        </div>
                                        <div className='plan_performance_item'>
                                            <img src={bandWidthIcon}/>
                                            <span><strong>1GB 당 1000P 차감</strong><small>(기본 제공량 초과시)</small></span>
                                        </div>

                                        <div className='plan_performance_item'>
                                            <img src={bandWidthIcon}/>
                                            <span><strong>10G+ Fiber </strong>Ethernet</span>
                                        </div>
                                        <div className='plan_performance_item'>
                                            <img src={storageIcon}/>
                                            <span id='plan_disk'><strong>{plan.cp_disk}GB</strong> SSD</span>
                                        </div>
                                        {plan.cp_ddos ?
                                            <div className='plan_performance_item'>
                                                <img src={ddosIcon}/>
                                                <span id='plan_disk'><strong>{plan.cp_ddos}G</strong> DDoS</span>
                                            </div>
                                            :
                                            <div className='plan_performance_item'/>
                                        }
                                    </div>
                                </div>
                            </Col>
                        )
                    })
                }

            </Row>
        </div>
    )
}


export default WebHostingPriceList;
