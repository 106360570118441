import React from "react";


const DeployTitle = ({title}) => {

    return (
        <div className='deploy_title'>
            <span>{title}</span>
        </div>
    )
}


DeployTitle.defaultProps = {
    title: ''
}


export default DeployTitle;
