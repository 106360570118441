import React, {useState} from "react";
import {faqListLeft, faqListRight} from '../../datas/faq';
import {Button, UncontrolledCollapse} from 'reactstrap';
import faqArrow from '../../assets/img/landing/faq_arrow.svg';
import {isGameMode, isGameModeTxt} from "../../utils/common";
import {useSelector} from "react-redux";

const TicketFaq = () => {
    const {layoutMode} = useSelector(state => state.layout);
    const [toggleFaq, setToggleFaq] = useState([]);
    const faqHandler = (index) => {
        if (toggleFaq.indexOf(index) !== -1) {
            setToggleFaq(toggleFaq.filter(item => item !== index))
        } else {
            setToggleFaq([...toggleFaq, index]);
        }

    }
    return (
        <div className='faq_item_wrapper container'>
            <div className='faq_title'>
                <h4 className={isGameModeTxt(layoutMode)}>자주 묻는 질문</h4>
            </div>
            <div className={isGameMode(layoutMode) ? 'faq_list dark_mode_faq' : 'faq_list'}>
                <div className='faq_wrap'>
                    {faqListLeft.map((item, index) => {
                        return (
                            <div className='faq_list_item' key={index}>
                                <Button onClick={() => faqHandler(index)} id={`product_toggler_1_${index}`}>
                                    <div className='faq_item'>
                                        <span>{item.title}</span>
                                        <img className={toggleFaq.includes(index) ? 'rotate_arrow' : ''} src={faqArrow}/>
                                    </div>

                                </Button>
                                <UncontrolledCollapse toggler={`product_toggler_1_${index}`}>
                                    <div className='toggle_wrapper'>
                                        <div className='toggle_content_wrapper'>
                                            <p>
                                                {item.content
                                                    .split('\n')
                                                    .map((line, index) => {
                                                        return (
                                                            <span key={index}>{line}<br/></span>
                                                        )
                                                    })}
                                            </p>
                                        </div>
                                    </div>
                                </UncontrolledCollapse>
                            </div>
                        )
                    })}
                </div>
                <div className='faq_wrap'>
                    {faqListRight.map((item, index) => {
                        return (
                            <div className='faq_list_item' key={index}>
                                <Button onClick={() => faqHandler(index + 5)} id={`product_toggler_1_${index + 5}`}>
                                    <div className='faq_item'>
                                        <span>{item.title}</span>
                                        <img className={toggleFaq.includes(index + 5) ? 'rotate_arrow' : ''} src={faqArrow}/>
                                    </div>

                                </Button>
                                <UncontrolledCollapse toggler={`product_toggler_1_${index + 5}`}>
                                    <div className='toggle_wrapper'>
                                        <div className='toggle_content_wrapper'>
                                            <p>
                                                {item.content
                                                    .split('\n')
                                                    .map((line, index) => {
                                                        return (
                                                            <span key={index}>{line}<br/></span>
                                                        )
                                                    })}
                                            </p>
                                        </div>
                                    </div>
                                </UncontrolledCollapse>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default TicketFaq;
