import React from "react";
import {Col, Input, Label, Row} from "reactstrap";
import DeployTitle from "../DeployTitle";
import {Tooltip} from "@mui/material";
import {DetailDescription} from "../../constant/DetailDescription";
import HelpIcon from "@mui/icons-material/Help";
import {useForm} from "react-hook-form";
import classNames from "classnames";


const WebHostingSettingList = ({setId, setPwd, setDomain}) => {

    const {register, errors} = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    return (
        <Row className={'setting_list_wrapper'}>
            <DeployTitle title={'사용하실 도메인과 계정을 입력해주세요'}/>
            <Col xs={12} lg={6}>
                <Row className='setting_list_host_wrapper'>
                    <Col xs={12}>
                        <div>
                            <Label>도메인</Label>
                            <span>*</span>
                            <Tooltip title={DetailDescription.manage.cPanelDomain} placement="top">
                                <HelpIcon fontSize={'small'} color={'disabled'}/>
                            </Tooltip>
                        </div>
                        <Input
                            className={classNames({'setting_list_host border-danger': errors.setting_cpanel_domain})}
                            type={'text'}
                            placeholder={'server24.kr'}
                            onChange={event => setDomain(event.target.value)}
                            name='setting_cpanel_domain'
                            innerRef={register({
                                required: '도메인은 반드시 입력되어야 합니다',
                                pattern: {
                                    value: /^[a-z|0-9|.]+$/,
                                    message: '특수문자를 제외한  영문 소문자, 숫자를 포함하여 입력하여 주세요.'
                                }
                            })}
                            id="setting_label"/>
                        {errors.setting_cpanel_domain && <span style={{fontSize: '0.875rem'}} className="text-danger">{errors.setting_cpanel_domain.message}</span>}
                    </Col>
                    <Col xs={12}>
                        {/*//TODO 호스트 네임 잘못된 문자 입력시 예외처리 해주기*/}
                        <div>
                            <Label>cPanel 계정 아이디</Label>
                            <Tooltip title={DetailDescription.manage.cPanelId}
                                     placement="top">
                                <HelpIcon fontSize={'small'} color={'disabled'}/>
                            </Tooltip>
                        </div>
                        <Input
                            className={classNames({'setting_list_host border-danger': errors.setting_cpanel_id})}
                            type={'text'}
                            maxLength={16}
                            placeholder={'생성하실 계정 아이디를 입력해주세요.'}
                            onChange={event => setId(event.target.value)}
                            name='setting_cpanel_id'
                            innerRef={register({
                                required: '계정 아이디는 반드시 입력되어야 합니다',
                                pattern: {
                                    value: /^[a-z|A-Z|0-9|]+$/,
                                    message: '특수문자를 제외한 16자 이하 영문 대소문자, 숫자를 포함하여 입력하여 주세요.'
                                }
                            })}
                            id="setting_hostName"/>
                        {errors.setting_cpanel_id && <span style={{fontSize: '0.875rem'}} className="text-danger">{errors.setting_cpanel_id.message}</span>}
                    </Col>
                    <Col xs={12}>
                        <div>
                            <Label>cPanel 계정 비밀번호</Label>
                            <Tooltip title={DetailDescription.manage.cPanelPwd}
                                     placement="top">
                                <HelpIcon fontSize={'small'} color={'disabled'}/>
                            </Tooltip>
                        </div>
                        <Input
                            // className='setting_list_host'
                            type={'password'}
                            placeholder={'생성하실 계정 비밀번호를 입력해주세요.'}
                            onChange={event => setPwd(event.target.value)}
                            name='setting_cpanel_pwd'
                            autoComplete='new-password'
                            innerRef={register({
                                required: '계정 비밀번호는 반드시 입력되어야 합니다',
                                pattern: {
                                    value: /^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{10,20}$/,
                                    message: '10자 이상 20자 이하 영문 대소문자, 최소 1개의 숫자 및 특수 문자를 포함하여 입력하여 주세요.'
                                }
                            })}
                            errors={errors}
                            className={classNames({'setting_list_host border-danger': errors.setting_cpanel_pwd})}
                            id="setting_hostName"/>
                        {errors.setting_cpanel_pwd && <span style={{fontSize: '0.875rem'}} className="text-danger">{errors.setting_cpanel_pwd.message}</span>}
                    </Col>
                </Row>
            </Col>
        </Row>
    )

}

export default WebHostingSettingList;
