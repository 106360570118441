import React, {Fragment, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {isIterableArray} from "../../../../utils/common";
import {getSftpUser} from "../../action";
import {checkErrorResponse} from "../../../../helpers/errorCheck";
import {decrypt, encrypt} from "../../../../helpers/common";
import {Tooltip} from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {CopyToClipboard} from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const FileManagerSftpModal = ({modal, toggle}) => {
    const {gameInstanceDetail} = useSelector(state => state.gameDeploy);
    const [port, setPort] = useState('');
    const [user, setUser] = useState({});
    const [hidePwd, setHidePwd] = useState(false);

    useEffect(() => {
        getSftpUser().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    const {id, password} = response.request.data.user;
                    setUser({
                        id: id,
                        password: decrypt(password)
                    });
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [])

    useEffect(() => {
        if (isIterableArray(gameInstanceDetail.ApplicationEndpoints)) {
            const portUrl = gameInstanceDetail.ApplicationEndpoints[gameInstanceDetail.ApplicationEndpoints.length - 1].Endpoint.split(':');
            setPort(portUrl[portUrl.length - 1]);
        }
    }, [gameInstanceDetail])


    return (
        <Modal centered isOpen={modal} toggle={toggle} className={'file_manager_sftp_modal'}>
            <ModalHeader>
                <span>SFTP 연결</span>
            </ModalHeader>
            <ModalBody>
                <div className='sftp_info_wrapper'>
                    <Row className='m-0'>
                        <Col className='p-0' xs={3}>
                            <div className='sftp_access_row'>
                                <span>주소</span>
                                <span>호스트</span>
                                <span>Port</span>
                                <span>ID</span>
                                <span>Password</span>
                            </div>
                        </Col>
                        <Col className='p-0' xs={9}>
                            <div className='sftp_access_row'>
                                <span>{`sftp://${user.id}@amp-test.server24.kr:${port}`}</span>
                                <span>{process.env.REACT_APP_GAME_CONSOLE}</span>
                                <span>{port}</span>
                                <span>{user.id}</span>
                                <div className='sftp_pwd_wrapper'>
                                    <span>
                                        {!hidePwd ? '*******' :
                                            user.password}
                                    </span>
                                    {hidePwd ?
                                        <Tooltip title="비밀번호 숨기기" placement="top">
                                            <VisibilityOffIcon className='hide_pwd' onClick={() => setHidePwd(false)}/>
                                        </Tooltip>
                                        :
                                        <Tooltip title="비밀번호 보기" placement="top">
                                            <RemoveRedEyeIcon className='hide_pwd' onClick={() => setHidePwd(true)}/>
                                        </Tooltip>
                                    }

                                    <CopyToClipboard text={user.password}>
                                        <Tooltip title='비밀번호 복사' placement='top'>
                                            <ContentCopyIcon className='hide_pwd'/>
                                        </Tooltip>
                                    </CopyToClipboard>

                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className='sftp_description'>
                        <span>-SFTP 접속을 통해 직접 파일을 옮기실수 있습니다.</span>
                        <span>-위에 나와있는 아이디/비밀번호를 통해 접속 가능합니다.</span>
                    </div>
                    <div className='close_btn_wrapper'>
                        <button onClick={toggle}>닫기</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default FileManagerSftpModal;
