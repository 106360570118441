export const publicIso = [
    {
        "id": "9d8f68f1-b8a0-4e8a-bb08-b05597aaf780",
        "description": "3.4.2 build 372",
        "name": "Trinity Rescue Kit",
        "md5sum": "4909e1961ba27752b7aa8eba23ea7b5d"
    },
    {
        "id": "0f8278e7-b313-454c-b7a5-1a86aa929ea8",
        "description": "8.6 i386 netinstall",
        "name": "Debian 8",
        "md5sum": "5f274e02640720502ee55c3501b889ae"
    },
    {
        "id": "952f21f0-d529-4da7-b4e3-145e032dec1f",
        "description": "7 x86_64 Minimal",
        "name": "CentOS 7",
        "md5sum": "bb782a81d55b9271edb2b42da77400b5"
    },
    {
        "id": "c8e34fb0-f6dd-4050-9bae-fcc3d5b4f394",
        "description": "x86_64",
        "name": "Core OS",
        "md5sum": "c855070581552141998bb71c2f58de71"
    },
    {
        "id": "2aa648be-6e75-4763-83b2-62351276b33a",
        "description": "1.0.0 amd64",
        "name": "Devuan Jessie 1.0.0",
        "md5sum": "325f2e20e6ec0f7936e440fbca7c6342"
    },
    {
        "id": "22eae7ce-55d0-405d-916f-cd1830cf4bc1",
        "description": "1.0.0 i386",
        "name": "Devuan Jessie 1.0.0",
        "md5sum": "2d5f932128b44d7c4321ac3811c66415"
    },
    {
        "id": "5995531e-0b86-475a-aff0-79eb4014809d",
        "description": "9.0 x86_64",
        "name": "Debian 9",
        "md5sum": "b894f1e8ebf3065a488b6c01a742cf4e"
    },
    {
        "id": "c4abc46c-5237-4252-9552-564c43b71ed9",
        "description": "9.0 i386",
        "name": "Debian 9",
        "md5sum": "8d7c8a2d8a41bce1c52534f6871ccefc"
    },
    {
        "id": "8ac1d216-3808-49d9-a0b3-28e3e6e8be56",
        "description": "1.3 x86_64",
        "name": "Rancher OS",
        "md5sum": "1ee0a4abb95e1684ca1aa7c66218398d"
    },
    {
        "id": "0eaece5e-6e59-4ef8-8b84-28f4d4c11fb0",
        "description": "18.04 x86_64",
        "name": "Ubuntu 18.04",
        "md5sum": "e35f45caf1d26ed5b1217d67f6ee86e8"
    },
    {
        "id": "339de83c-ef93-4ffd-9f70-282dab141347",
        "description": "11.2 RELEASE x86-64",
        "name": "FreeBSD 11.2",
        "md5sum": "8444cb840f2be11a2caca8d3231ea560"
    },
    {
        "id": "9a4eff6f-ef11-4d3f-a243-1b65dad25a84",
        "description": "11.2 RELEASE i386",
        "name": "FreeBSD 11.2",
        "md5sum": "085c690f9cee3fb4bf849951955866b0"
    },
    {
        "id": "a8e59e82-8078-4907-8f44-6ed888d3d915",
        "description": "2.0.0 x86-64",
        "name": "Devuan ASCII 2.0.0",
        "md5sum": "7f1911bfd43fbab409cbed70f831a057"
    },
    {
        "id": "aeb17399-de42-46cd-9c9f-f7c73acc3d19",
        "description": "2.0.0 i386",
        "name": "Devuan ASCII 2.0.0",
        "md5sum": "47e279a3d2656b74ce851b0c6ef99501"
    },
    {
        "id": "ce93abeb-8555-41c0-9ac8-579dd60e830c",
        "description": "15.0 x86_64",
        "name": "openSUSE Leap 15.0",
        "md5sum": "5d4d4c83e678b6715652a9cf5ff3c8a3"
    },
    {
        "id": "648ad5b0-baa9-46c1-b178-6e756f732378",
        "description": "FreeNAS 11.1",
        "name": "FreeNAS 11.1",
        "md5sum": "633d6444cad903c707983b54e04fc053"
    },
    {
        "id": "29e155aa-8cf5-4004-a957-8c71837e0784",
        "description": "amd64",
        "name": "OpenBSD 6.4",
        "md5sum": "adc97d48a1b2dfa2ee87af2bd4fe18c7"
    },
    {
        "id": "fa4d8b75-3efa-4253-8484-e5e87099cd41",
        "description": "i386",
        "name": "OpenBSD 6.4",
        "md5sum": "118bd3ffb7e379564f69f4e5b386b7e9"
    },
    {
        "id": "c238099c-0dec-4410-ad1f-6e2eb6986f55",
        "description": "1.4.2",
        "name": "Rancher OS",
        "md5sum": "e407b51b165dcc1fc3df5e91d1ec6d0f"
    },
    {
        "id": "4a69aee7-9fa3-4c36-b800-174400f37e9a",
        "description": "12.0-RELEASE amd64",
        "name": "FreeBSD 12.0",
        "md5sum": "9d3d2933390400918736fcdf46825ee3"
    },
    {
        "id": "0d845df6-74b9-4680-b420-714acc15eb79",
        "description": "12.0-RELEASE i386",
        "name": "FreeBSD 12.0",
        "md5sum": "8cb01151dfd8009041b57389e9fa402a"
    },
    {
        "id": "c019f72c-f865-4e99-afab-744a7c5089b6",
        "description": "1.5 x86_64",
        "name": "Rancher OS",
        "md5sum": "25c610c1150d1dd2c0388d310f0dff84"
    },
    {
        "id": "5a2a0e6c-e383-402c-8761-9e591abe52a1",
        "description": "1.2 x86_64",
        "name": "VyOS 1.2",
        "md5sum": "02ca4dad640d490cfeb0c677c3ee1021"
    },
    {
        "id": "9ceba6a3-9049-462d-a10e-7b9f98f938cc",
        "description": "6.0.1",
        "name": "SystemRescueCD",
        "md5sum": "4e35cda9a03012ae9520a26b9e96d613"
    },
    {
        "id": "e5c23c48-08e4-4e46-9895-cb8faf2a934e",
        "description": "CentOS-8-x86_64-1905-dvd1.iso",
        "name": "CentOS 8 x86_64",
        "md5sum": "e15fb0e51726de0a4128448b9bd63e7b"
    },
    {
        "id": "b8d450f5-c6f5-4e7f-b63f-fc24c501d85e",
        "description": "Debian 10.3 x86_64",
        "name": "Debian 10.3",
        "md5sum": "5956434be4b81e6376151b64ef9b1596"
    },
    {
        "id": "673f3943-c844-4e28-99f4-eef7d97a42b1",
        "description": "3.12.0 x86_64",
        "name": "Alpine Linux",
        "md5sum": "2dd1f7617b4c2d1808b075c493d6f6a1"
    },
    {
        "id": "7fe2232c-408a-426c-b6ef-58149faa7912",
        "description": "SNG7-FPBX-64bit-2008-1",
        "name": "FreePBX",
        "md5sum": "9769ee35aee420b245ac01bc19aa9483"
    },
    {
        "id": "985a68c7-061e-4668-93c2-5e898920d224",
        "description": "6.1.8",
        "name": "SystemRescueCD",
        "md5sum": "86cd52f7bae39c183879fdaca1f850c0"
    },
    {
        "id": "0426d2d2-dbfd-4b9c-a2fc-bf8b6e66324a",
        "description": "20.7",
        "name": "OPNsense",
        "md5sum": "f1747b60212b2abbf02d02ee2643bced"
    },
    {
        "id": "5404055c-6ef0-43db-bc1a-255152eb133f",
        "description": "20.09 ",
        "name": "NixOS 20.09",
        "md5sum": "dedb27ec407485fc870db7cf215dce96"
    },
    {
        "id": "e7ba8be4-0418-44cd-aafc-3f0d1abfed21",
        "description": "SNG7-FPBX-64bit-2011-5",
        "name": "FreePBX",
        "md5sum": "b87322f8f6ff4ad9fadc2b0442b904fb"
    },
    {
        "id": "ea3c4a0e-47b9-4981-8b54-5da0cb37ca63",
        "description": "121 x86",
        "name": "Finnix",
        "md5sum": "ade8602da8fc272f47e007b03add4ad1"
    },
    {
        "id": "20eceb4f-7b28-466e-aaf4-cff60385c30d",
        "description": "2021.06.01 x86_64",
        "name": "Arch Linux",
        "md5sum": "1bf76d864651cc6454ab273fd3d2226a"
    },
    {
        "id": "647973f8-cb16-438c-abb0-cc8b2777e3dd",
        "description": "8 x64",
        "name": "vzlinux",
        "md5sum": "1e2e251f2dbf075dbf02204330de1026"
    },
    {
        "id": "b72c1cc4-b87f-447b-a219-829259c1aa52",
        "description": "8 x64",
        "name": "Rocky",
        "md5sum": "c1cce83eb6a3b880cd176e2d3614c00b"
    },
    {
        "id": "9b368793-ec40-4e08-822d-3b821ff18ac8",
        "description": "8 x64",
        "name": "AlmaLinux",
        "md5sum": "f2219d421bf6afa444b35667af6c44aa"
    }
]
