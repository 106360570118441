import * as types from './actionTypes';
import {maskingPhone} from "../../utils/common";

let initialState = {
    userInfo: {
        email: '',
        name: '',
        phone: null,
        agreePhone: false,
        agreeEmail: false
    },
    sessionInfo: {
        expired: false,
        alreadyExpired: false
    },
    businessInfo: {
        companyName: null
    },
    userActivityLog: [],
    userLogMetaData: {total_count: 0}
}


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types._INIT_PROFILE: {

            return {
                ...state,
                userInfo: action.request.data.data
            };
        }

        case types._GET_USER_LOG : {
            return {
                ...state,
                userActivityLog: action.request.data.logs,
                userLogMetaData: action.request.data.metadata
            }
        }

        case types._CHANGE_PHONE: {
            state.userInfo.phone = maskingPhone(action.phone)
            return {
                ...state,
                userInfo: {...state.userInfo}

            }
        }

        case types._SESSION_EXPIRED: {
            state.sessionInfo.expired = true;
            return {
                ...state,
                sessionInfo: {...state.sessionInfo}
            }
        }

        case types._SESSION_LOGOUT: {
            state.sessionInfo.alreadyExpired = true;
            return {
                ...state,
                sessionInfo: {...state.sessionInfo}
            }
        }

        case types._SESSION_EXTENSION: {
            state.sessionInfo.expired = false;
            return {
                ...state,
                sessionInfo: {...state.sessionInfo}
            }
        }

        case types._GET_AUTH_BUSINESS: {
            return {
                ...state,
                businessInfo: {companyName: action.request.data.companyName}
            }
        }

        case types._AUTHENTICATION_BUSINESS: {
            return {
                ...state,
                businessInfo: {companyName: action.companyName}
            }
        }

        case types._EMAIL_MARKETING_AGREE: {
            state.userInfo.agreeEmail = action.agree !== 0;
            return {
                ...state
            }
        }

        case types._SMS_MARKETING_AGREE: {
            state.userInfo.agreePhone = action.agree !== 0;
            return {
                ...state
            }
        }

        default:
            return state;
    }

}
