import React, {useEffect, useState} from "react";
import {isIterableArray} from "../../../../utils/common";
import {Col, Row} from "reactstrap";
import {ValTypeForm} from "./ValTypeForm";
import {useSelector} from "react-redux";
import {Button} from "@mui/material";
import {checkErrorResponse} from "../../../../helpers/errorCheck";
import {gameSettingAction} from "../../action";
import {notificationAlert} from "../../../../helpers/common";
import {MinecraftVersionNode} from "../../common/GameTypeImgItem";
import {minecraftSettingDesc, minecraftSettingTitle} from "../../../../datas/TranslatorTxt";

const versionNode = [
    "MinecraftModule.MinecraftSettings.SpecificVersion",
    "MinecraftModule.MinecraftSettings.SpecificSpigotVersion",
    "MinecraftModule.MinecraftSettings.SpecificForgeVersion",
    "MinecraftModule.MinecraftSettings.SpecificSpongeVersion",
    "MinecraftModule.MinecraftSettings.SpecificPaperVersion",
    "MinecraftModule.MinecraftSettings.SpecificPurpurVersion",]

const GameServerSetting = () => {
    const {gameSettingNodes, sessionId, gameInstanceDetail} = useSelector(state => state.gameDeploy);
    const [serverType, setServerType] = useState('0');
    const handleSettingAction = (module, method, argument) => {
        gameSettingAction(sessionId, gameInstanceDetail.InstanceID, module, method, argument).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    if (response.request.data.result.Status) {
                        notificationAlert('알림', '업데이트 요청에 성공하였습니다.');
                    } else {
                        notificationAlert('알림', '업데이트 요청에 실패하였습니다.');
                    }
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }

    useEffect(() => {
        if (isIterableArray(gameSettingNodes)) {
            const serverTypeNode = gameSettingNodes.filter(node => node.Node === 'MinecraftModule.MinecraftSettings.ServerType');
            if (isIterableArray(serverTypeNode)) {
                setServerType(serverTypeNode[0].CurrentValue);
            }
        }
    }, [gameSettingNodes])

    return (
        <div className='setting_panel_wrapper'>
            {isIterableArray(gameSettingNodes) &&
            <Row>
                {gameSettingNodes.map((settingNode, index) => {
                        if (versionNode.some(node => node === settingNode.Node)) {
                            if (MinecraftVersionNode(parseInt(serverType), settingNode.Node)) {
                                return (
                                    <Col key={index} xs={4}>
                                        <div className='setting_panel_item'>
                                            <div className={'setting_description'}>
                                                <span>{minecraftSettingTitle[settingNode.Name]}</span>
                                                <p>{minecraftSettingDesc[settingNode.Description]}</p>
                                            </div>
                                            <div className='setting_form'>
                                                <ValTypeForm
                                                    node={settingNode.Node}
                                                    tag={settingNode.Tag}
                                                    type={settingNode.ValType}
                                                    defaultValue={settingNode.CurrentValue}
                                                    EnumValues={settingNode.EnumValues}/>
                                            </div>
                                            {isIterableArray(settingNode.Actions) &&
                                            <div className='game_setting_action'>
                                                <Button onClick={() => handleSettingAction(settingNode.Actions[0].Module, settingNode.Actions[0].Method, settingNode.Actions[0].Argument)} color={'primary'} variant={'contained'}>
                                                    {settingNode.Actions[0].Caption}
                                                </Button>
                                            </div>}
                                        </div>
                                    </Col>
                                )
                            }
                        } else {
                            return (
                                <Col key={index} xs={4}>
                                    <div className='setting_panel_item'>
                                        <div className={'setting_description'}>
                                            <span>{minecraftSettingTitle[settingNode.Name]}</span>
                                            <p>{minecraftSettingDesc[settingNode.Description]}</p>
                                        </div>
                                        <div className='setting_form'>
                                            <ValTypeForm
                                                node={settingNode.Node}
                                                tag={settingNode.Tag}
                                                type={settingNode.ValType}
                                                defaultValue={settingNode.CurrentValue}
                                                EnumValues={settingNode.EnumValues}/>
                                        </div>
                                        {isIterableArray(settingNode.Actions) &&
                                        <div className='game_setting_action'>
                                            <Button onClick={() => handleSettingAction(settingNode.Actions[0].Module, settingNode.Actions[0].Method, settingNode.Actions[0].Argument)} color={'primary'} variant={'contained'}>
                                                {settingNode.Actions[0].Caption}
                                            </Button>
                                        </div>}
                                    </div>
                                </Col>
                            )
                        }
                    }
                )}

            </Row>
            }
        </div>
    )
}

export default GameServerSetting;
