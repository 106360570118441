import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {getUserTotalBandWidth} from "../action";
import {checkErrorResponse} from "../../../helpers/errorCheck";
import TotalBandwidthChart from "./TotalBandwidthChart";
import { Modal, ModalBody} from "reactstrap";
import {Button} from "@mui/material";
import {formatBytes} from "../../../utils/common";



const ProductOverviewModal = ({modal, toggle}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        getUserTotalBandWidth().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response)
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [])
    return (
        <Modal centered isOpen={modal} toggle={toggle} className={'product_overview_modal'}>
            <ModalBody>
                <div className='product_overview_wrapper'>
                    <TotalBandwidthChart/>
                    <Button
                        style={{padding : "0.5rem 0"}}
                        onClick={toggle}
                        variant={'contained'}
                        color={'primary'}
                        fullWidth>
                        닫기</Button>
                </div>
            </ModalBody>
        </Modal>

    )
}


export default ProductOverviewModal



