import React from "react";
import {Row} from "reactstrap";
import {Col} from "react-bootstrap";
import DeployTitle from "./DeployTitle";
import cPanelLogo from '../../assets/img/deploy/cPanelLogo.png';
import serverHostingLogo from '../../assets/img/deploy/serverHostingLogo.svg';
import gameLogo from '../../assets/img/deploy/joystick.svg';
import {Alert, AlertTitle} from "@mui/lab";
import {useDispatch, useSelector} from "react-redux";
import {setServer, setService} from "./action";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {changeLayoutLoader, changeLayoutMode} from "../../layouts/action";
import {useHistory} from "react-router-dom";
import {notificationAlert} from "../../helpers/common";

const ServerTypeList = () => {
    const history = useHistory();
    const serverInfo = {
        server: {
            title: '서버 호스팅(10G DDoS 방어, 무중단 스냅샷/자동백업 생성 및 복원 지원)',
            description: '전 세계에 즉시 배포되는 저렴하고 안정적인 무약정 고성능 서버입니다. 언제든 상위 요금제로 업그레이드가 가능하며, 필요가 없어진 서버를 삭제하여 비용을 줄일 수 있습니다.'
        },
        webServer: {
            title: 'cPanel 웹 호스팅(SSH, Crontab, 웹메일, 무료백업 및 복원 지원)',
            description: 'cPanel은 기존의 웹 호스팅보다 사용하기 매우 쉽고 빠르며, 몇 번의 클릭만으로 웹사이트, 파일, MySQL, 데이터 추적, 통계, 보안까지 신경 써야 하는 관리 기능을 모두 지원합니다.'
        }
    }

    const {server} = useSelector((state) => state.deploy);
    const dispatch = useDispatch();
    const handleService = (type) => {
        dispatch(setServer(type));
    }

    const handleDeployModeChange = () => {
        notificationAlert('알림','서비스 준비중 입니다.');
        // dispatch(changeLayoutLoader(true));
        // setTimeout(() => {
        //     dispatch(changeLayoutMode('game'));
        //     dispatch(changeLayoutLoader(false));
        //
        // }, 2000);
    }


    return (
        <div className='server_type_list_wrapper'>
            <DeployTitle title={'신청하실 서비스를 선택해주세요'}/>
            <Row>
                <Col xs={12} sm={6} lg={3} className='text-center' style={{cursor: 'pointer'}}>
                    <div onClick={() => handleService('server')} className={server.type === 'server' ? 'server_type_item select_item' : 'server_type_item'}>
                        {server.type === 'server' && <CheckCircleIcon/>}
                        <div>
                            <img id={'server_hosting_logo'} src={serverHostingLogo} alt={'server_hosting_logo'}/>
                        </div>
                    </div>
                    <div className='server_type_name'>
                        <span>서버 호스팅</span>
                    </div>
                </Col>
                {/*<Col xs={12} sm={6} lg={3} className='text-center' style={{cursor: 'pointer'}}>*/}
                {/*    <div onClick={() => handleService('webServer')} className={server.type === 'webServer' ? 'server_type_item select_item' : 'server_type_item'}>*/}
                {/*        {server.type === 'webServer' && <CheckCircleIcon/>}*/}
                {/*        <div>*/}
                {/*            <img id={'server_cPanel_logo'} src={cPanelLogo} alt={'server_cPanel_logo'}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className='server_type_name'>*/}
                {/*        <span>웹 호스팅</span>*/}
                {/*    </div>*/}
                {/*</Col>*/}
                {/*<Col xs={12} sm={6} lg={3} className='text-center' style={{cursor: 'pointer'}}>*/}
                {/*    <div onClick={handleDeployModeChange} className={'server_type_item'}>*/}
                {/*        <div>*/}
                {/*            <img id={'server_game_logo'} src={gameLogo} alt={'server_game_logo'}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className='server_type_name'>*/}
                {/*        <span>게임서버 호스팅</span>*/}
                {/*    </div>*/}
                {/*</Col>*/}
                <Col xs={12}>
                    <div className='select_service_description' style={{marginTop: '1rem'}}>
                        {server.type.length !== 0 ?
                            <Alert severity="info">
                                <AlertTitle>{serverInfo[server.type].title}</AlertTitle>
                                {serverInfo[server.type].description}
                            </Alert>
                            :
                            <Alert severity="info">
                                <AlertTitle>서비스를 선택하여 주세요.</AlertTitle>

                            </Alert>
                        }
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ServerTypeList;
