import * as React from 'react';
import PropTypes from 'prop-types';
import {alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import {visuallyHidden} from '@mui/utils';
import InstanceMore from "./InstanceMore";
import {Fragment, useEffect, useState} from "react";
import {FormGroup, Input} from "reactstrap";
import searchIcon from "../../assets/img/support/search.svg";
import {useDispatch, useSelector} from "react-redux";
import OsImgItem from "../common/OsImgItem";
import {regions} from "../../datas/region";
import {isIterableArray} from "../../utils/common";
import {useHistory} from "react-router-dom";
import {restartServer, stopServer} from "../manage/action";
import {getInstance, updateStatusList} from "./action";
import InstanceStatus from "./InstanceStatus";
import {productRow} from "../constant/TableRow";
import ListSkeleton from "../common/ListSkeleton";
import {useMediaQuery} from "react-responsive";
import ProductMobileTable from "./ProductMobileTable";
import {checkErrorResponse} from "../../helpers/errorCheck";
import ConfirmModal from "../common/ConfirmModal";
import {changePerNum, notificationAlert} from "../../helpers/common";
import NotExistTable from "../common/NotExistTable";
import {Button, Fab} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ProductOverview from "./overview/ProductOverview";
import ProductOverviewModal from "./overview/ProductOverview";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell style={{padding: 0}}>
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {productRow.map((headCell) => {

                        return (
                            <TableCell
                                key={headCell.id}
                                align={headCell.align}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                            <span style={headCell.label === 'OS' ? {marginRight: '0.4rem'} : {marginRight: 0}}>
                            {headCell.label}
                        </span>
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        )
                    }
                )}
                <TableCell>

                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const dispatch = useDispatch();
    const {numSelected, selected} = props;
    const [loader, setLoader] = useState(false);

    const handleServerStop = () => {
        setLoader(true);
        const post = {
            instance_ids: selected
        }
        stopServer(post).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    setLoader(false);
                    dispatch(updateStatusList(selected, 'stopped'));
                }
            }
        }).catch(err => {
            setLoader(false);
            checkErrorResponse(err);
        })
    };

    const handleServerRestart = () => {
        setLoader(true);
        const post = {
            instance_ids: selected
        }
        restartServer(post).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    setLoader(false);
                    dispatch(updateStatusList(selected, 'running'));
                }
            }
        }).catch(err => {
            setLoader(false);
            checkErrorResponse(err);
        })
    };


    return (
        <Toolbar
            className={numSelected > 0 ? 'product_table_toolbar show_toolbar' : 'product_table_toolbar hide_toolbar'}
            sx={{
                pl: {sm: 2},
                pr: {xs: 1, sm: 1},
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}>
            <Typography
                className={numSelected > 0 ? 'product_table_server_num' : 'hide_toolbar'}
                sx={{flex: '1 1 60%'}}
                color="inherit"
                variant="subtitle1"
                component="div">
                {numSelected} 개 선택
            </Typography>

            {numSelected > 0 && (
                <div className={numSelected > 0 ? 'product_table_btn' : 'hide_toolbar'}>
                    <div>
                        <ConfirmModal
                            type={'restart'}
                            handleEvent={handleServerRestart}/>
                    </div>
                    <div>
                        <ConfirmModal
                            type={'stop'}
                            handleEvent={handleServerStop}/>
                    </div>
                </div>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};


const RegionImgItem = ({regionId}) => {
    const regionInfo = regions.filter(region => region.vr_id === regionId);
    return (
        <div className='server_item'>
            <img src={`https://s3.server24.kr/panel/img/country/${regionInfo[0].country.replace(" ", "")}.png`}/>
            <span>{regionInfo[0].vr_city}</span>
        </div>
    )
}
export default function ProductTable({toggle}) {
    const history = useHistory();
    const dispatch = useDispatch();
    const instanceList = useSelector(state => state.instance.instanceList);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('vi_label');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(localStorage.getItem('pageNum') ? localStorage.getItem('pageNum') : 10);
    const [servers, setServers] = useState([]);
    const [status, setStatus] = useState('All');
    const [skeletonLoad, setSkeletonLoad] = useState(false);
    const [searchTxt, setSearchTxt] = useState('');
    useEffect(() => {

        getInstance().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    setSkeletonLoad(true);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, []);

    useEffect(() => {
        if (instanceList) {
            setServers(instanceList);
        }
    }, [instanceList])


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = servers.map((n) => n.vi_id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const perNum = parseInt(event.target.value, 10);
        changePerNum(perNum)
        setRowsPerPage(perNum);
        setPage(0);
    };
    const handleSearchServer = (input) => {
        setSearchTxt(input);
        const newInstanceList = instanceList.filter((instance) =>
            instance.vi_label.toLowerCase().includes(input));
        setServers(newInstanceList);
    }
    const handleChange = (event) => {
        setStatus(event.target.value);
        switch (event.target.value) {
            case 'All':
                setServers([...instanceList]);
                break;
            case 'Running':
                setServers(instanceList.filter(instance => instance.vi_power_status === 'running'));
                break;
            case 'Stopped':
                setServers(instanceList.filter(instance => instance.vi_power_status === 'stopped'));
                break;

            default :
                setServers([...instanceList]);
                break;
        }
    };
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const moveLocation = (id, server_status) => {
        if (server_status === 'none') notificationAlert('알림', '현재 인스톨 진행중 입니다.')
        else history.push(`/manage/${id}`)
    }
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - servers.length) : 0;
    const isMobile = useMediaQuery({
        query: "(max-width:880px)"
    });
    return (
        <div className='product_content'>
            <Box sx={{width: '100%'}}>
                <Paper sx={{width: '100%', mb: 2, boxShadow: 'none'}}>
                    <div className='server_filter_wrapper'>
                        <div className='flex-row d-flex align-items-center'>
                            <FormGroup>
                                <Input type="select"
                                       onChange={handleChange}
                                       value={status}
                                       name="select"
                                       id="server_status_sel">
                                    <option value={'All'}>전체</option>
                                    <option value={'Running'}>Running</option>
                                    <option value={'Stopped'}>Stopped</option>
                                </Input>
                            </FormGroup>
                            <Button
                                onClick={toggle}
                                id={'show_traffic_btn'}
                                color={'primary'}
                                variant={'outlined'}>트래픽 총 사용량 보기</Button>
                        </div>
                        <div className="server_search_wrapper">
                            <input
                                onChange={event => handleSearchServer(event.target.value)}
                                style={{backgroundImage: `url(${searchIcon})`}}
                                type="text" className="form-control"
                                placeholder="Search..."/>
                            <div>
                                <Fab onClick={() => window.location.href = '/deploy'} color="primary" aria-label="add">
                                    <AddIcon/>
                                </Fab>
                            </div>
                        </div>
                        <div>

                        </div>
                    </div>
                    <EnhancedTableToolbar selected={selected} numSelected={selected.length}/>
                    <ListSkeleton loading={skeletonLoad}>
                        {isIterableArray(servers) ?
                            <Fragment>
                                {
                                    isMobile ?
                                        <ProductMobileTable serverList={servers} moveLocation={moveLocation}/>
                                        :
                                        <TableContainer style={{overflowX: 'unset'}}>
                                            <Table
                                                size={'medium'}
                                            >
                                                <EnhancedTableHead
                                                    numSelected={selected.length}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onSelectAllClick={handleSelectAllClick}
                                                    onRequestSort={handleRequestSort}
                                                    rowCount={servers.length}
                                                />
                                                <TableBody>
                                                    {stableSort(servers, getComparator(order, orderBy))
                                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .map((row, index) => {
                                                            const isItemSelected = isSelected(row.vi_id);
                                                            const labelId = `enhanced-table-checkbox-${index}`;
                                                            return (
                                                                <TableRow
                                                                    className='server_table_row'
                                                                    hover
                                                                    role="checkbox"
                                                                    aria-checked={isItemSelected}
                                                                    tabIndex={-1}
                                                                    key={index}
                                                                    selected={isItemSelected}
                                                                >
                                                                    <TableCell padding="checkbox">
                                                                        <div className='server_check_item'>
                                                                            <Checkbox
                                                                                color="primary"
                                                                                onClick={(event) => handleClick(event, row.vi_id)}
                                                                                checked={isItemSelected}
                                                                                inputProps={{
                                                                                    'aria-labelledby': labelId,
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        id={labelId}
                                                                        scope="row"
                                                                        style={{cursor: 'pointer'}}
                                                                        onClick={() => moveLocation(row.vi_id, row.vi_server_status)}
                                                                        padding="none"
                                                                    >
                                                                        <div className='server_item server_label_item'>
                                                         <span className='fw-bold'>
                                                               {row.vi_label === '' ? 'Instance Name' : row.vi_label}
                                                        </span>
                                                                            <small>{`${row.vp_ram}MB `} {row.sub_title} - {row.vi_main_ip}</small>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        onClick={() => moveLocation(row.vi_id, row.vi_server_status)}
                                                                        align="right">
                                                                        <OsImgItem osId={row.vi_os_id}/>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        onClick={() => moveLocation(row.vi_id, row.vi_server_status)}
                                                                        align="right">
                                                                        <div className='server_item region_item'>
                                                                            <RegionImgItem regionId={row.vi_region}/>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        onClick={() => moveLocation(row.vi_id, row.vi_server_status)}
                                                                        align="right">
                                                                        <div className='server_item'>
                                                                            <span>{(row.vi_cost + row.vi_cost_backup + row.vi_cost_ddos).toLocaleString()} P</span></div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        onClick={() => moveLocation(row.vi_id, row.vi_server_status)}
                                                                        align="right">
                                                                        <div className='server_item'>
                                                                            <InstanceStatus
                                                                                vi_power_status={row.vi_power_status}
                                                                                vi_server_status={row.vi_server_status}
                                                                                vi_status={row.vi_status}/>

                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell align="right" className='instance_more_btn'>
                                                                        {row.vi_server_status !== 'none' &&
                                                                        <div className='server_more_item'>
                                                                            <InstanceMore payStatus={row.vi_del_status} serverStatus={row.vi_server_status} id={row.vi_id}/>
                                                                        </div>
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}


                                                </TableBody>

                                            </Table>
                                        </TableContainer>
                                }
                                <TablePagination
                                    labelRowsPerPage={'페이지당 목록 수'}
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    component="div"
                                    count={servers.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Fragment>
                            : <NotExistTable searchExist={searchTxt.length !== 0} type={'product'}/>}
                    </ListSkeleton>
                </Paper>
            </Box>
        </div>
    );
}
