export const cpanelRegions = [
    // {
    //     "cp_region_id": 'kr',
    //     "cp_city": "Seoul",
    //     "country": "South Korea",
    // },
    {
        "cp_region_id": 'us',
        "cp_city": "North America - West",
        "country": "United States",
    }
]

export const gameRegions = [
    {
        "vr_no": 7,
        "vr_id": "icn",
        "vr_city": "Seoul",
        "vr_country": "KR",
        "continent": "Asia",
        "vr_options_ddos": true,
        "vr_options_block": false,
        "country": "South Korea",
        "vr_img": "https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/84cb4123-971b-42a7-90d5-4a2ac7596900/country"
    },
    {
        "vr_no": 12,
        "vr_id": "nrt",
        "vr_city": "Tokyo",
        "vr_country": "JP",
        "continent": "Asia",
        "vr_options_ddos": true,
        "vr_options_block": false,
        "country": "Japan",
        "vr_img": "https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/96a8ea2f-077e-43e6-d398-cdf383071200/country"
    },
]


export const regions = [
    {
        "vr_no": 7,
        "vr_id": "icn",
        "vr_city": "Seoul",
        "vr_country": "KR",
        "continent": "Asia",
        "vr_options_ddos": true,
        "vr_options_block": false,
        "country": "South Korea",
        "vr_img": "https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/84cb4123-971b-42a7-90d5-4a2ac7596900/country"
    },
    {
        "vr_no": 12,
        "vr_id": "nrt",
        "vr_city": "Tokyo",
        "vr_country": "JP",
        "continent": "Asia",
        "vr_options_ddos": true,
        "vr_options_block": false,
        "country": "Japan",
        "vr_img": "https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/96a8ea2f-077e-43e6-d398-cdf383071200/country"
    },
    {
        "vr_no": 15,
        "vr_id": "sgp",
        "vr_city": "Singapore",
        "vr_country": "SG",
        "continent": "Asia",
        "vr_options_ddos": true,
        "vr_options_block": false,
        "country": "Singapore",
        "vr_img": "https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/8ef0fb04-888e-4bc3-42ec-8f029f457300/country"
    },
    {
        "vr_no": 14,
        "vr_id": "sea",
        "vr_city": "Seattle",
        "vr_country": "US",
        "continent": "North America",
        "vr_options_ddos": true,
        "vr_options_block": false,
        "country": "United States",
        "vr_img": "https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/31ba64cf-79dd-41fc-9abf-f9e61cac3f00/country"
    },
    {
        "vr_no": 8,
        "vr_id": "lax",
        "vr_city": "Los Angeles",
        "vr_country": "US",
        "continent": "North America",
        "vr_options_ddos": true,
        "vr_options_block": true,
        "country": "United States",
        "vr_img": "https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/31ba64cf-79dd-41fc-9abf-f9e61cac3f00/country"
    },
    {
        "vr_no": 5,
        "vr_id": "ewr",
        "vr_city": "New Jersey",
        "vr_country": "US",
        "continent": "North America",
        "vr_options_ddos": true,
        "vr_options_block": true,
        "country": "United States",
        "vr_img": "https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/31ba64cf-79dd-41fc-9abf-f9e61cac3f00/country"
    },
    {
        "vr_no": 2,
        "vr_id": "atl",
        "vr_city": "Atlanta",
        "vr_country": "US",
        "continent": "North America",
        "vr_options_ddos": true,
        "vr_options_block": false,
        "country": "United States",
        "vr_img": "https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/31ba64cf-79dd-41fc-9abf-f9e61cac3f00/country"
    },
    {
        "vr_no": 16,
        "vr_id": "sjc",
        "vr_city": "Silicon Valley",
        "vr_country": "US",
        "continent": "North America",
        "vr_options_ddos": true,
        "vr_options_block": false,
        "country": "United States",
        "vr_img": "https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/31ba64cf-79dd-41fc-9abf-f9e61cac3f00/country"
    },
    {
        "vr_no": 11,
        "vr_id": "mia",
        "vr_city": "Miami",
        "vr_country": "US",
        "continent": "North America",
        "vr_options_ddos": true,
        "vr_options_block": false,
        "country": "United States",
        "vr_img": "https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/31ba64cf-79dd-41fc-9abf-f9e61cac3f00/country"
    },
    {
        "vr_no": 4,
        "vr_id": "dfw",
        "vr_city": "Dallas",
        "vr_country": "US",
        "continent": "North America",
        "vr_options_ddos": true,
        "vr_options_block": false,
        "country": "United States",
        "vr_img": "https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/31ba64cf-79dd-41fc-9abf-f9e61cac3f00/country"
    },
    {
        "vr_no": 13,
        "vr_id": "ord",
        "vr_city": "Chicago",
        "vr_country": "US",
        "continent": "North America",
        "vr_options_ddos": true,
        "vr_options_block": false,
        "country": "United States",
        "vr_img": "https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/31ba64cf-79dd-41fc-9abf-f9e61cac3f00/country"
    },
    {
        "vr_no": 1,
        "vr_id": "ams",
        "vr_city": "Amsterdam",
        "vr_country": "NL",
        "continent": "Europe",
        "vr_options_ddos": true,
        "vr_options_block": false,
        "country": "Netherlands",
        "vr_img": "https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/87fbc333-5a7b-4452-3ef1-e4098bdf8a00/country"
    },
    {
        "vr_no": 3,
        "vr_id": "cdg",
        "vr_city": "Paris",
        "vr_country": "FR",
        "continent": "Europe",
        "vr_options_ddos": true,
        "vr_options_block": false,
        "country": "France",
        "vr_img": "https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/6b73912c-56f7-47ba-e0fb-20fd0ab10f00/country"
    },
    {
        "vr_no": 6,
        "vr_id": "fra",
        "vr_city": "Frankfurt",
        "vr_country": "DE",
        "continent": "Europe",
        "vr_options_ddos": true,
        "vr_options_block": false,
        "country": "Germany",
        "vr_img": "https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/a6ada47a-f511-4a13-cc8f-86f4cdf8f900/country"
    },
    {
        "vr_no": 9,
        "vr_id": "lhr",
        "vr_city": "London",
        "vr_country": "GB",
        "continent": "Europe",
        "vr_options_ddos": true,
        "vr_options_block": false,
        "country": "United Kingdom",
        "vr_img": "https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/31ba64cf-79dd-41fc-9abf-f9e61cac3f00/country"
    },
    {
        "vr_no": 10,
        "vr_id": "mex",
        "vr_city": "Mexico City",
        "vr_country": "MX",
        "continent": "North America",
        "vr_options_ddos": true,
        "vr_options_block": false,
        "country": "Mexico",
        "vr_img": "https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/2885db5a-8efd-485e-843e-c64f82d76500/country"
    },
    {
        "vr_no": 17,
        "vr_id": "sto",
        "vr_city": "Stockholm",
        "vr_country": "SE",
        "continent": "Europe",
        "vr_options_ddos": true,
        "vr_options_block": false,
        "country": "Sweden",
        "vr_img": "https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/74feb476-2f52-4d9e-c2e5-145d4b91ba00/country"
    },
    {
        "vr_no": 18,
        "vr_id": "syd",
        "vr_city": "Sydney",
        "vr_country": "AU",
        "continent": "Australia",
        "vr_options_ddos": true,
        "vr_options_block": false,
        "country": "Australia",
        "vr_img": "https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/827d2a89-af5f-49ca-857f-298ef7115500/country"
    },
    {
        "vr_no": 19,
        "vr_id": "yto",
        "vr_city": "Toronto",
        "vr_country": "CA",
        "continent": "North America",
        "vr_options_ddos": true,
        "vr_options_block": false,
        "country": "Canada",
        "vr_img": "https://imagedelivery.net/WnET-qkUxCrTUVaWac5Ibw/69649999-a3d3-4dca-753c-acde926ad800/country"
    }
]
