import React, {useEffect, useState} from 'react';
import {Card, CardBody, Col, Row, CustomInput} from 'reactstrap';
import {Line} from 'react-chartjs-2';
import {ago28Days, rgbaColor, themeColors} from "../../helpers/common";
import {convertToGB, formatBytes, isIterableArray} from "../../utils/common";
import {useSelector} from "react-redux";
import moment from "moment";

const getBound = (type, bandWidthList) => {
    return new Promise(resolve => {
        const lastMonthDay = moment().endOf("month").date();
        if (isIterableArray(bandWidthList)) {
            const BoundList = [];
            for (let i = 1; i <= lastMonthDay; i++) {
                let boundData = bandWidthList.filter(item => item.vb_date === moment().set({date: i}).format("YYYY-MM-DD"))
                if (boundData.length !== 0) {
                    if (type === 'in') BoundList.push(parseInt(boundData[0].vb_incoming_bytes));
                    else BoundList.push(parseInt(boundData[0].vb_outgoing_bytes));

                } else {
                    BoundList.push(0);
                }
            }
            resolve(BoundList);
        } else {
            const emptyBandWidth = [];
            for (let i = 1; i <= lastMonthDay; i++) {
                emptyBandWidth.push(0);
            }
            resolve(emptyBandWidth);
        }
    })
}


const BandWidthLineChart = () => {
    const {bandWidthList} = useSelector(state => state.manage);
    const [inBound, setInBound] = useState([]);
    const [outBound, setOutBound] = useState([]);
    useEffect(() => {
        const makeBandWidth = async () => {
            const inBoundMap = await getBound('in', bandWidthList);
            const outBoundMap = await getBound('out', bandWidthList);
            return {
                inBoundMap,
                outBoundMap
            }

        }
        makeBandWidth().then(res => {
            setInBound(res.inBoundMap);
            setOutBound(res.outBoundMap);
        });

    }, [bandWidthList])
    const label = ago28Days();

    const config = {
        data(canvas) {
            const ctx = canvas.getContext('2d');
            const gradientFill = ctx.createLinearGradient(0, 0, 0, 250);
            gradientFill.addColorStop(0, 'rgba(255, 255, 255, 0.3)');
            gradientFill.addColorStop(1, 'rgba(255, 255, 255, 0)');

            return {
                labels: label,
                datasets: [
                    {
                        label: 'Inbound',
                        borderWidth: 2,
                        data: [...inBound],
                        borderColor: rgbaColor(themeColors.primary, 0.8),
                        backgroundColor: gradientFill
                    },
                    {
                        label: 'Outbound',
                        borderWidth: 2,
                        data: [...outBound],
                        borderColor: rgbaColor(themeColors.light, 0.8),
                        backgroundColor: gradientFill
                    }
                ]
            };
        },
        options: {
            legend: {display: false},
            tooltips: {
                mode: 'x-axis',
                xPadding: 20,
                yPadding: 10,
                displayColors: false,
                callbacks: {
                    label: tooltipItem => `${label[tooltipItem.index]} - ${formatBytes(tooltipItem.value)}`,
                    title: () => null
                }
            },
            hover: {mode: 'label'},
            scales: {
                xAxes: [
                    {
                        scaleLabel: {
                            show: true,
                            labelString: 'Month'
                        },
                        ticks: {
                            fontColor: rgbaColor('#fff', 0.7),
                            fontStyle: 800
                        },
                        gridLines: {
                            color: rgbaColor('#fff', 0.1),
                            zeroLineColor: rgbaColor('#fff', 0.1),
                            lineWidth: 1
                        }
                    },

                ],
                yAxes: [
                    {
                        display: false,
                        gridLines: {
                            color: rgbaColor('#fff', 1)
                        }
                    }
                ]
            }
        }
    };

    return (
        <Card className="mb-3" style={{marginTop: "1vh"}}>
            <CardBody className="rounded-soft bg_main">
                <Row className="text-white align-items-center no-gutters">
                    <Col className='monthly_traffic_usage_title'>
                        <h3>월간 트래픽 사용량</h3>
                        <span style={{color: '#0069ff'}} className="fs-5 mb-0 d-block">{`Inbound ${formatBytes(inBound.reduce((sum, num) => sum + num, 0))}`}</span>
                        <span className="fs-5 text-white mb-0 d-block">{`Outbound ${formatBytes(outBound.reduce((sum, num) => sum + num, 0))}`}</span>
                    </Col>

                </Row>
                <Line data={config.data} options={config.options} width={1618} height={375}/>
            </CardBody>
        </Card>
    );
};

export default BandWidthLineChart;
