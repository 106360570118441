import React, {Fragment} from "react";
import {Row, Col} from 'reactstrap';

const ImgInfo = ({label, img, data, subData, children}) => {

    return (
        <Row className='imgInfo_wrapper'>
            <Col xs={3} className='imgInfo_img_wrapper'>
                <div className='imgInfo_img'>
                    <img src={img}/>
                </div>
            </Col>
            <Col xs={9} className='imgInfo_label'>
                <Row>
                    <Col xs={12}><label>{label}</label></Col>
                    <Col xs={12}>
                        {subData ? <Fragment> <img className='imgInfo_sub_img' src={subData.vr_img}/>
                                <span>{subData.vr_city} </span></Fragment> :
                            <span>{data}</span>
                        }
                        {children}
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

ImgInfo.defaultProps = {
    label: '',
    img: null,
    data: '',
    subImg: null
}
export default ImgInfo;
