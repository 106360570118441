import React from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Fragment} from "react";
import {useSelector} from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import ListSkeleton from "../common/ListSkeleton";
import CharginMoreModal from "./CharginMoreModal";
import {useMediaQuery} from "react-responsive";
import MobileBillTable from "./mobile/MobileBillTable";
import {isGameMode, isIterableArray} from "../../utils/common";
import {changePerNum} from "../../helpers/common";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'white',
        color: '#8297AA',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#F4F8FA',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const checkBillStatus = (status) => {
    switch (status) {
        case 'DONE':
            return '결제완료'
        case 'WAITING_FOR_DEPOSIT':
            return '입금 대기중'
        case 'CANCELED':
            return '결제취소'
        default:
            return ''
    }
}

export default function ChargeTable({loader}) {
    const {paymentRecord} = useSelector(state => state.bill.billInfo);
    const {layoutMode} = useSelector(state => state.layout);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(localStorage.getItem('pageNum') ? localStorage.getItem('pageNum') : 10);
    const isMobile = useMediaQuery({
        query: "(max-width:880px)"
    });
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const perNum = parseInt(event.target.value, 10);
        changePerNum(perNum)
        setRowsPerPage(perNum);
        setPage(0);
    };

    return (
        <Fragment>
            <ListSkeleton height={350} type={'rectangular'} itemNum={1} colNum={12} loading={loader}>
                {isIterableArray(paymentRecord) ?
                    <Fragment>
                        {isMobile ?
                            <MobileBillTable/>
                            :
                            <Fragment>
                                <Table className={isGameMode(layoutMode) ? 'dark_table_mode' : ''} sx={{minWidth: 700}} aria-label="bill table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="left">상품명</StyledTableCell>
                                            <StyledTableCell align="left">결제방식</StyledTableCell>
                                            <StyledTableCell align="right">결제금액</StyledTableCell>
                                            <StyledTableCell align="center">결제일</StyledTableCell>
                                            <StyledTableCell align="center">승인여부</StyledTableCell>
                                            <StyledTableCell align="center">{' '}</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {paymentRecord.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((payment, index) => (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell align="left">{`${((payment.totalAmount * 10) / 11).toLocaleString()}포인트`}</StyledTableCell>
                                                    <StyledTableCell align="left">{payment.method}</StyledTableCell>
                                                    <StyledTableCell align="right">{
                                                        (payment.method === '가상계좌' && payment.status === 'WAITING_FOR_DEPOSIT') ?
                                                            0 :
                                                            (parseInt(payment.totalAmount)).toLocaleString()
                                                    }원</StyledTableCell>
                                                    <StyledTableCell align="center">{payment.approvedAt}</StyledTableCell>
                                                    <StyledTableCell align="center">{checkBillStatus(payment.status)}</StyledTableCell>
                                                    <StyledTableCell align="center"><CharginMoreModal payment={payment}/></StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    className={isGameMode(layoutMode) ? 'dark_table_pagination' : ''}
                                    labelRowsPerPage={'페이지당 목록 수'}
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    component="div"
                                    count={paymentRecord.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Fragment>
                        }
                    </Fragment>
                    :
                    <div className='not_exist_txt_wrapper'>
                        <span>결제내역이 존재하지 않습니다.</span>
                    </div>
                }
            </ListSkeleton>
        </Fragment>
    );
}
