import React from "react";
import DeployTitle from "../../../deploy/DeployTitle";
import {Row, Col} from "reactstrap";
import {minecraftPriceList} from "../../../../datas/game";
import {useDispatch, useSelector} from "react-redux";
import {setDeployGamePrice} from "../../action";


const MineSelectionPrice = () => {
    const dispatch = useDispatch();
    const {priceType} = useSelector(state => state.gameDeploy);

    const handleSelectPrice = (priceType) => {
        dispatch(setDeployGamePrice(priceType))
    }


    return (
        <div className='minecraft_select_price_wrapper'>
            <DeployTitle title={'서버 요금제 선택'}/>
            <div className='minecraft_price_selection'>
                <Row>
                    {minecraftPriceList.map((price, index) => {
                        return (
                            <Col key={index} xs={12} lg={3}>
                                <div
                                    onClick={() => handleSelectPrice(price.id)}
                                    className={priceType === price.id ? 'mine_price_item select' : 'mine_price_item'}>
                                    <img className='mine_price_block' width={82} src={price.priceImg} alt={'price_block'}/>
                                    <div className='price_info'>
                                        <h4>{price.title}</h4>
                                        <div className='monthly_price'>
                                            <h3>{price.monthly_const.toLocaleString()}P</h3>
                                            <small>/월</small>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className='price_performance'>
                                        <span>최대 {price.slots}명 동시 접속가능</span>
                                        <span><strong>{price.ram}</strong>MB RAM</span>
                                    </div>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </div>
        </div>
    )
}


export default MineSelectionPrice;
