import React, {Fragment, useEffect, useState} from "react";
import {gameRegions, regions} from '../../../../datas/region';
import {useDispatch, useSelector} from "react-redux";
import {Row, Col} from 'reactstrap';
import {CircularProgress} from "@material-ui/core";
import DeployTitle from "../../../deploy/DeployTitle";
import {CheckTriangle} from "../../../common/CheckTriangle";
import {setDeployGameRegion} from "../../action";
import {Alert, AlertTitle} from "@mui/lab";

const GameSelectionRegion = () => {
    const [loader, setLoader] = useState('');
    const {regionType} = useSelector((state) => state.gameDeploy);
    const dispatch = useDispatch();

    const handleRegion = (id) => {
        dispatch(setDeployGameRegion(id));
    }


    return (
        <Row className='deploy_region_list game_deploy_region_wrapper'>
            <DeployTitle title={'서비스 지역'}/>
            {gameRegions.map(item => {
                return (
                    <Col xs={6} lg={3} key={item.vr_id}
                         onClick={() => handleRegion(item.vr_id)}
                         className={item.vr_id === regionType ? 'server_region select_region' : 'server_region'}>
                        <div className={loader === item.vr_id ? 'deploy_region_item justify-content-center' : 'deploy_region_item'}>
                            {loader === item.vr_id ?
                                <CircularProgress color={'primary'} id={'deploy_region_item_loader'}/>
                                :
                                <Fragment>
                                    {item.vr_id === regionType && <CheckTriangle height={40} width={40}/>}
                                    <div className='region_img'>
                                        <img src={`https://s3.server24.kr/panel/img/country/${(item.country).replace(" ", "")}.png`}/>
                                    </div>
                                    <div className='region_country'>
                                        <span>{item.vr_city}</span>
                                        <small>{item.country}</small>
                                    </div>
                                </Fragment>
                            }
                        </div>


                    </Col>
                )
            })}
            <Col xs={12}>
                <div className='game_deploy_region_alert'>
                    {regionType === '' ?
                        <Alert severity="info">
                            <AlertTitle>서비스 지역을 선택해주세요.</AlertTitle>
                            먼저, 서비스 지역을 선택해주세요.
                        </Alert>
                        :
                        <div>
                            {regionType === 'icn' ?
                                <Alert severity="info">
                                    <AlertTitle>대한민국(서울) 리전</AlertTitle>
                                    - 서버는 고성능 CPU와 ECC DDR4 메모리로 제공됩니다.<br/>
                                    - 고성능 NVMe SSD 저장소 공간을 추가 비용 없이 물리적 가용공간 내 무제한으로 제공해드립니다.
                                </Alert>
                                :
                                <Alert severity="info">
                                    <AlertTitle>일본(도쿄) 리전</AlertTitle>
                                    - 서버는 5.0GHz 내외의 고성능 CPU(PassMark 17128점 이상)와 ECC DDR4 메모리로 제공됩니다.<br/>
                                    - 최대 80Tbps+ 이상의 공격을 유연하게 방어하는 CDN77 네트워크를 사용합니다.<br/>
                                    - 네트워크 가속 기술을 통해 한-일간 지연시간(Ping)이 평균 20~40ms 내외로 매우 우수합니다.<br/>
                                    - 고성능 NVMe SSD 저장소 공간을 추가 비용 없이 물리적 가용공간 내 무제한으로 제공해드립니다.
                                </Alert>
                            }
                        </div>
                    }
                </div>
            </Col>
        </Row>
    )
}

export default GameSelectionRegion;
