import axios from "axios";
import * as types from "./actionTypes";
import {_CHARGING_POINT, _GET_BILL_INFO} from "./actionTypes";

export const paymentRequest = async (payType, paymentKey, post) => {
    const paymentApiRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/payment/approval/${payType}/${paymentKey}`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._PAYMENT_REQUEST,
        request: paymentApiRequest,
        data: post
    }
}

export const getPoint = async () => {
    const getPointRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/user/point`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });
    return {
        type: types._GET_POINT,
        request: getPointRequest,
    }
}

export const getVrAccountInfo = async (payNo) => {
    const getVrAccountInfoRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/payment/${payNo}/vr-account`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });
    return {
        request: getVrAccountInfoRequest,
    }
}

export const chargingPointRequest = (point) => {

    return {
        type: types._CHARGING_POINT,
        point: point,
    }
}

export const getBillInfo = async () => {
    const getBillInfoRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/user/point/info`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });
    return {
        type: types._GET_BILL_INFO,
        request: getBillInfoRequest,
    }
}

export const getPointUsage = async (link) => {
    const getPointUsageRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/user/${link}`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });
    return {
        type: types._GET_POINT_USAGE,
        request: getPointUsageRequest,
    }
}
