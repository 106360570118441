import * as types from './actionTypes';



let initialState = {
    noticeList: [],

}


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types._GET_NOTICE: {
            return {
                ...state,
                noticeList: action.request.data.notices
            }
        }

        case types._GET_NAV_NOTICE: {
            return {
                ...state,
                navNotice: action.request.data.notices
            }
        }

        default:
            return state;
    }

}
