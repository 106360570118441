import React from "react";
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';


const TypeIcon = ({type}) => {
    switch (type) {
        case 'info' : {
            return <InfoIcon fontSize={'large'}/>
        }
        case 'success' : {
            return <CheckCircleIcon fontSize={'large'}/>
        }
        case 'warning' : {
            return <WarningIcon fontSize={'large'}/>
        }
        case 'error': {
            return <ErrorIcon fontSize={'large'}/>
        }
        default:
            return <InfoIcon fontSize={'large'}/>
    }
}

const ToastBox = ({type, text, title ,className}) => {


    return (
        <div className={`toast_box_wrapper ${type} ${className}`}>
            <div className='toast_icon'>
                <TypeIcon type={type}/>
            </div>
            <div className='toast_txt'>
                <span className='toast_title'>{title}</span>
                {text.split('\n')
                    .map((line, index) => {
                        return (
                            <p key={index}>{line}<br/></p>
                        )
                    })}
            </div>
        </div>
    )
}

export default ToastBox;
