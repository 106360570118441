import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Switch} from "react-router-dom";
import NavBar from "../components/nav/NavBar";
import Ticket from "../components/ticket/Ticket";
import TicketDetail from "../components/ticket/detail/TicketDetail";
import {RouteIf} from "../hoc/RouteIf";
import Account from "../components/account/Account";
import Deploy from "../components/deploy/Deploy";
import SnapShotList from "../components/snapshot/SnapShotList";
import ScriptList from "../components/script/ScriptList";
import Manage from "../components/manage/Manage";
import {useDispatch, useSelector} from "react-redux";
import {sessionExpired} from "../components/account/action";
import Billing from "../components/bill/Billing";
import BillRequest from "../components/bill/BillRequest";
import {isLogin} from "../helpers/common";
import {getPoint} from "../components/bill/action";
import {checkErrorResponse} from "../helpers/errorCheck";
import Notice from "../components/notice/Notice";
import {isGameMode} from "../utils/common";
import GameDeploy from "../components/game/deploy/GameDeploy";
import GameManage from "../components/game/manage/GameManage";
import CentosPanel from "../components/panel/CentosPanel";

const DashboardLayout = ({location, setValue}) => {
    const {layoutMode} = useSelector(state => state.layout);
    const dispatch = useDispatch();
    useEffect(() => {
        getPoint().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response)
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
        if (isLogin()) {
            const sessionTimer = window.setTimeout(() => {
                dispatch(sessionExpired());
            }, 3300000);

            return () => {
                window.clearTimeout(sessionTimer)
            }
        }
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);

    }, [location.pathname]);

    return (
        <>
            <NavBar>
                {isGameMode(layoutMode) ?
                    <Switch>
                        <RouteIf exact path="/support" option={true} component={Ticket}/>
                        <RouteIf path="/support/view/:id" option={true} component={TicketDetail}/>
                        <RouteIf exact path="/account" option={true} component={Account}/>
                        <RouteIf exact path="/deploy" option={true} component={GameDeploy}/>
                        <RouteIf exact path="/billing" option={true} component={Billing}/>
                        <RouteIf exact path="/notice" option={true} component={Notice}/>
                        <RouteIf path="/gameManage/:no/:id" option={true} component={GameManage}/>
                        <RouteIf path="/payment/:payType/:status/:point" option={true} component={BillRequest}/>
                    </Switch>
                :
                    <Switch>
                        <RouteIf exact path="/support" option={true} component={Ticket}/>
                        <RouteIf path="/support/view/:id" option={true} component={TicketDetail}/>
                        <RouteIf exact path="/account" option={true} component={Account}/>
                        <RouteIf exact path="/deploy" option={true} component={Deploy}/>
                        <RouteIf exact path="/snapshot" option={true} component={SnapShotList}/>
                        <RouteIf exact path="/script" option={true} component={ScriptList}/>
                        <RouteIf exact path="/billing" option={true} component={Billing}/>
                        <RouteIf exact path="/notice" option={true} component={Notice}/>
                        <RouteIf path="/manage/:id" option={true} component={Manage}/>
                        <RouteIf path="/payment/:payType/:status/:point" option={true} component={BillRequest}/>
                    </Switch>
                }

                {/*<Footer />*/}
            </NavBar>
        </>
    );
};

DashboardLayout.propTypes = {location: PropTypes.object.isRequired};

export default DashboardLayout;
