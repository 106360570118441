import * as types from './actionTypes';
import axios from "axios";
import {_GET_NAV_NOTICE} from "./actionTypes";


export const getNotice = async () => {
    const getNoticeRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/notice/?offset=0&limit=100&order=desc`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._GET_NOTICE,
        request: getNoticeRequest
    }
}

export const getNavNotice = async () => {
    const getNavNoticeRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/notice/?offset=0&limit=1&order=desc`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._GET_NAV_NOTICE,
        request: getNavNoticeRequest
    }
}
