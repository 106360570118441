import React, {useEffect, useState} from "react";
import {TabContext, TabPanel} from "@mui/lab";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import ManageDashBoard from "./ManageDashBoard";
import Box from "@mui/material/Box";
import ManageSnapshots from "./ManageSnapshots";
import {useDispatch, useSelector} from "react-redux";
import {deleteServer, getInstanceDetail, infoStatusReinstall, reinstallServer, restartServer, stopServer, updateInstanceDetail} from "./action";
import {useHistory, useParams} from "react-router-dom";
import {getOsBigImg, isGameMode} from "../../utils/common";
import {Row, Col} from 'reactstrap';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {detailDelInstance, updateAllStatusList} from "../Product/action";
import {Button} from "@mui/material";
import ViewTxtInput from "../common/ViewTxtInput";
import ManageSetting from "./setting/ManageSetting";
import ManageBackup from "./backup/ManageBackup";
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import ListSkeleton from "../common/ListSkeleton";
import {checkErrorResponse} from "../../helpers/errorCheck";
import ConfirmModal from "../common/ConfirmModal";


const Manage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    let {id} = useParams();
    const {instanceInfo} = useSelector(state => state.manage);
    const {layoutMode} = useSelector(state => state.layout);
    const [value, setValue] = useState('1');
    const [anchorEl, setAnchorEl] = useState(null);
    const [headerSkeleton, setHeaderSkeleton] = useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleServerStop = () => {
        return new Promise((resolve, reject) => {
            const post = {
                instance_ids: [id]
            }
            stopServer(post).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        dispatch(response);
                        resolve(true);
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
                reject(err);
            })
        })
    };

    const handleServerRestart = () => {
        return new Promise((resolve, reject) => {
            const post = {
                instance_ids: [id]
            }
            restartServer(post).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        dispatch(response);
                        resolve(true);
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
                reject(err);
            })
        })
    };

    const handleServerDelete = () => {
        return new Promise((resolve, reject) => {
            deleteServer(id).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        dispatch(response);
                        dispatch(detailDelInstance(id));
                        resolve(true);
                        history.push('/');
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
                reject(err);
            })
        })
    }
    const handleServerReinstall = () => {
        return new Promise((resolve, reject) => {
            dispatch(infoStatusReinstall(id, 'stopped'));
            reinstallServer(id).then(response => {
                if (response) {
                    if (response.request.status === 202) {
                        dispatch(updateAllStatusList('pending', 'stopped', 'none', id));
                        dispatch(infoStatusReinstall(id, 'running'));
                        resolve(true);
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
                reject(err);
            })
        })
    }

    const handleSetLabel = (text) => {
        return new Promise(((resolve, reject) => {
            const post = {
                label: text
            }
            updateInstanceDetail(id, post, 'label').then(response => {
                if (response) {
                    if (response.request.status === 202) {
                        dispatch(response)
                        resolve(true);
                    }
                }
            }).catch(err => {
                if (err) {
                    reject(err);
                }
            })
        }))
    }

    useEffect(() => {
        if (!isGameMode(layoutMode)) {
            getInstanceDetail(id).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        dispatch(response);
                        setHeaderSkeleton(true);
                    }
                }
            }).catch(err => {

                setHeaderSkeleton(true);
                checkErrorResponse(err);
            })
        }
    }, [])

    return (
        <div className='manage_wrapper container-md'>
            <ListSkeleton height={200} type={'rectangular'} itemNum={1} colNum={12} loading={headerSkeleton}>
                <div className='manage_header'>
                    <div className='server_info'>
                        <Row>
                            <Col className='server_info_title' xs={12}>
                                <div className='manage_os_title'>
                                    <img src={`https://s3.server24.kr/panel/img/os/${getOsBigImg(instanceInfo.vi_os_id)}`}/>
                                    <div className='manage_label'>
                                        <ViewTxtInput clickEvent={handleSetLabel} inputValue={instanceInfo.vi_label}>
                                            <h2>
                                                {instanceInfo.vi_label}
                                            </h2>
                                        </ViewTxtInput>
                                        {instanceInfo.vi_power_status === 'running' ?
                                            <small className='d-flex align-items-center run_status'>
                                                <div id='status_circle_run'/>
                                                {instanceInfo.vi_power_status}</small>
                                            :
                                            <small className='d-flex align-items-center stop_status'>
                                                <div id='status_circle_run'/>
                                                {instanceInfo.vi_power_status}</small>
                                        }


                                    </div>
                                </div>
                                <div className='manage_os_control'>
                                    <div>
                                        <ConfirmModal
                                            btnDisabled={instanceInfo.vi_server_status === 'locked' || instanceInfo.vi_del_status === 'tDeleted'}
                                            type={'stop'}
                                            handleEvent={handleServerStop}/>

                                    </div>
                                    <div>
                                        <ConfirmModal
                                            btnDisabled={instanceInfo.vi_server_status === 'locked' || instanceInfo.vi_del_status === 'tDeleted'}
                                            type={'restart'}
                                            handleEvent={handleServerRestart}/>
                                    </div>
                                    <div className='manage_os_more_btn'>
                                        <button aria-controls="manage-control"
                                                onClick={handleMenu}>
                                            <MoreHorizIcon/>
                                        </button>
                                        <Menu
                                            id="manage-control"
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                        >
                                            <MenuItem
                                                onClick={handleClose}
                                                disabled={instanceInfo.vi_server_status === 'locked' || instanceInfo.vi_del_status === 'tDeleted'}
                                                className='manage_menu_item p-0'>
                                                <Button
                                                    className='p-2'
                                                    onClick={() => window.open(instanceInfo.vi_kvm, 'console', 'location=no,status=no,scrollbars=yes')}>
                                                    <DesktopWindowsIcon/>콘솔 보기
                                                </Button>
                                            </MenuItem>

                                            <MenuItem
                                                onClick={handleClose}
                                                disabled={instanceInfo.vi_server_status === 'locked' || instanceInfo.vi_del_status === 'tDeleted'}
                                                className='manage_menu_item p-0'>
                                                <ConfirmModal
                                                    type={'reinstall'}
                                                    handleEvent={handleServerReinstall}/>
                                            </MenuItem>
                                            <MenuItem
                                                onClick={handleClose}
                                                disabled={instanceInfo.vi_server_status === 'locked'}
                                                className='manage_menu_item p-0'>
                                                <ConfirmModal
                                                    type={'delete'}
                                                    handleEvent={handleServerDelete}/>
                                            </MenuItem>

                                        </Menu>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </ListSkeleton>
            <Box sx={{width: '100%', typography: 'body1'}}>
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: 'white'}}>
                        <TabList onChange={handleChange} aria-label="">
                            <Tab label="DashBoard" value="1"/>
                            <Tab label="Snapshots" value="2"/>
                            <Tab disabled={instanceInfo.vi_server_status === 'locked'} label="Backups" value="3"/>
                            <Tab disabled={instanceInfo.vi_server_status === 'locked'} label="Settings" value="4"/>

                        </TabList>
                    </Box>
                    <TabPanel className='tab_panel p-0' value="1"><ManageDashBoard/></TabPanel>
                    <TabPanel className='tab_panel p-0' value="2"><ManageSnapshots/></TabPanel>
                    <TabPanel className='tab_panel p-0' value="3"><ManageBackup/></TabPanel>
                    <TabPanel className='tab_panel p-0' value="4"><ManageSetting/></TabPanel>
                </TabContext>
            </Box>
        </div>
    )
}

export default Manage;
