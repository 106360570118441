import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {setOS} from "../action";
import {useDispatch} from "react-redux";

export default function SelectVersion({osInfo}) {
    const dispatch = useDispatch();
    const options = osInfo.map(item => item.name);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        const osDetail = osInfo[index];

        if(osDetail.family === "windows"){
            dispatch(setOS({vs_id : osDetail.id, vs_type : 'windows'}, 'snapshot'))
        }else {
            dispatch(setOS(osDetail, 'os'))
        }


    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className='w-100'>
            <List
                component="nav"
                aria-label="version settings"
            >
                <ListItem
                    style={{padding: 0}}
                    button
                    id="lock-button"
                    aria-haspopup="listbox"
                    aria-controls="lock-menu"
                    aria-label="version setting"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickListItem}
                >
                    <ListItemText
                        className='version_txt'
                        primary="버전 선택"
                        secondary={options[selectedIndex]}
                    />
                </ListItem>
            </List>
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                {options.map((option, index) => (
                    <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
