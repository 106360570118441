import React, {useState, useRef, useEffect} from "react";
import {Modal, ModalBody, ModalFooter} from "reactstrap";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import {addTicket, getTicketServerList, uploadFile} from "./action";
import {useDispatch, useSelector} from "react-redux";
import {Spinner} from "react-bootstrap";
import {checkErrorResponse} from "../../helpers/errorCheck";
import {getInstance} from "../Product/action";
import ListSkeleton from "../common/ListSkeleton";
import {notificationAlert} from "../../helpers/common";

const TicketModal = ({collapse, toggle}) => {
    const dispatch = useDispatch();
    const ticketFileInput = useRef(null);
    const [category, setCategory] = useState('기술문의');
    const [server, setServer] = useState({});
    const [subject, setSubject] = useState('');
    const [selectedFile, setSelectedFile] = useState(false);
    const [message, setMessage] = useState('');
    const [ticketFile, setTicketFile] = useState(null);
    const [loader, setLoader] = useState(false);
    const [serverList, setServerList] = useState([]);
    useEffect(() => {
        setLoader(false);
        getTicketServerList().then(response => {
            if (response) {
                setServerList(response);
                setLoader(true);

            }
        }).catch(err => {
            checkErrorResponse(err);
        })

        return () => setServerList([])
    }, []);

    const sendTicket = (post) => {
        addTicket(post).then(result => {
            if (result.request.status === 201) {
                setSelectedFile(false);
                dispatch(result);
                toggle();
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }
    const handleMakeTicket = () => {
        if (subject.length === 0) {
            notificationAlert('오류', '제목을 입력해주세요.');
            return;
        }
        if (message.length === 0) {
            notificationAlert('오류', '문의사항을 입력해주세요.');
            return;
        }
        setSelectedFile(true);
        let post = {
            title: subject,
            content: message,
            id: server.vi_id ? server.vi_id : '',
            ip: server.vi_main_ip ? server.vi_main_ip : '',
            gameId: server.gm_id ? server.gm_id : '',
            cpanelDomain: server.cu_main_domain ? server.cu_main_domain : ''
        }
        if (ticketFile) {
            uploadFile(ticketFile).then(token => {
                if (token) {
                    post.upload = token;
                    sendTicket(post);
                }

            }).catch(err => {
                setSelectedFile(false);
                if (err === 'volume Error') {
                    ticketFileInput.current.value = '';
                } else {
                    checkErrorResponse(err);
                }
            })
        } else {
            sendTicket(post);
        }

    }

    const handleUploadFile = (e) => {
        if (e.target.files) {
            setTicketFile(e.target.files);
        }
    }
    return (
        <Modal className='ticket_add_modal_wrapper' backdrop={'static'} centered aria-labelledby="contained-modal-title-vcenter" isOpen={collapse} toggle={toggle}>
            <ModalBody>
                <div className='ticket_modal_title'>
                    <h4>티켓 생성하기</h4>
                </div>
                <ListSkeleton height={60} type={'rectangular'} itemNum={1} colNum={12} loading={loader}>
                    <Box className='ticket_modal_input' sx={{minWidth: 120}}>
                        <FormControl fullWidth>
                            <InputLabel id="ticket_category_label">Category</InputLabel>
                            <Select
                                labelId="ticket_category_label"
                                id="ticket_category"
                                value={category}
                                label="Category"
                                onChange={event => setCategory(event.target.value)}
                            >
                                <MenuItem className='select_list_item' value={'기술문의'}>기술 문의</MenuItem>
                                {/*<MenuItem className='select_list_item' value={1}>영업 문의</MenuItem>*/}
                            </Select>
                        </FormControl>
                    </Box>
                </ListSkeleton>
                <ListSkeleton height={60} type={'rectangular'} itemNum={1} colNum={12} loading={loader}>
                    <Box className='ticket_modal_input' sx={{minWidth: 120}}>
                        <FormControl fullWidth>
                            <InputLabel id="ticket_server_label">Server</InputLabel>
                            <Select
                                labelId="ticket_server_label"
                                id="ticket_server"
                                value={server}
                                label="Server"
                                renderValue={(selected) => {
                                    if (Object.keys(selected).length === 0) {
                                        return <em>서버를 선택하여 주세요.</em>;
                                    } else {
                                        if (selected.gm_no) {
                                            return (
                                                `${selected.gm_domain} - ${selected.gmp_memory}MB (게임서버)`
                                            )
                                        } else if (selected.cu_no) {
                                            return (
                                                `${selected.cu_main_domain} - ${selected.cp_memory}MB (웹호스팅)`
                                            )
                                        } else {
                                            return (
                                                `${selected.vi_label} - ${selected.vi_main_ip} (일반서버)`
                                            )
                                        }
                                    }

                                }}
                                onChange={event => setServer(event.target.value)}
                            >
                                <MenuItem disabled value="">
                                    <em>서버목록</em>
                                </MenuItem>
                                {serverList.map((instance, index) => {
                                    if (instance.gm_no) {
                                        return (
                                            <MenuItem key={index} className='select_list_item' value={instance}>{`${instance.gm_domain} - ${instance.gmp_memory}MB (게임서버)`}</MenuItem>
                                        )
                                    } else if (instance.cu_no) {
                                        return (
                                            <MenuItem key={index} className='select_list_item' value={instance}>{`${instance.cu_main_domain} - ${instance.cp_memory}MB (웹호스팅)`}</MenuItem>
                                        )
                                    } else {
                                        return (
                                            <MenuItem key={index} className='select_list_item' value={instance}>{`${instance.vi_label} - ${instance.vi_main_ip} (일반서버)`}</MenuItem>
                                        )
                                    }

                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </ListSkeleton>
                <ListSkeleton height={60} type={'rectangular'} itemNum={1} colNum={12} loading={loader}>
                    <TextField className='ticket_modal_input'
                               onChange={event => setSubject(event.target.value)}
                               fullWidth id="ticket_subject" label="Subject" variant="outlined"/>
                </ListSkeleton>
                <ListSkeleton height={200} type={'rectangular'} itemNum={1} colNum={12} loading={loader}>
                    <div style={{marginTop: '1rem'}}>
                        <label style={{marginLeft: '0.3rem', fontSize: '0.8rem'}}>문의내용</label>
                        <TextareaAutosize
                            className=' ticket_modal_txtArea'
                            aria-label="maximum height"
                            placeholder="문의사항을 입력하세요."
                            onChange={event => setMessage(event.target.value)}
                            style={{width: '100%', minHeight: '200px', maxHeight: '600px'}}
                        />
                    </div>
                </ListSkeleton>
                <ListSkeleton height={80} type={'rectangular'} itemNum={1} colNum={12} loading={loader}>
                    <div className='ticket_modal_file'>
                        <InputLabel>첨부 가능한 파일 용량(12MB)</InputLabel>
                        <input
                            ref={ticketFileInput}
                            type="file"
                            accept="image/* , .zip,.rar,.7zip, .pdf, .hwp, .mov"
                            onChange={(e) => handleUploadFile(e)}
                        />
                    </div>
                </ListSkeleton>
            </ModalBody>
            <ListSkeleton height={60} type={'rectangular'} itemNum={1} colNum={12} loading={loader}>
                <ModalFooter className='ticket_modal_footer'>
                    <Button onClick={toggle} variant="outlined">취소</Button>

                    {selectedFile ? <Button disabled variant="contained">
                            <Spinner animation="border" variant="primary"/></Button>
                        :
                        <Button onClick={() => handleMakeTicket()} variant="contained">생성하기</Button>
                    }


                </ModalFooter>
            </ListSkeleton>
        </Modal>
    )
}


export default TicketModal;
