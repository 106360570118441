export const _SET_GAME_TYPE = '_SET_GAME_TYPE';

export const _SET_GAME_PRICE = '_SET_GAME_PRICE';

export const _GET_GAME_SERVERS = '_GET_GAME_SERVERS';

export const _SET_GAME_REGION = '_SET_GAME_REGION';

export const _GAME_DEPLOY = '_GAME_DEPLOY';

export const _SET_MC_TYPE = '_SET_MC_TYPE';

export const _SET_DEPLOY_QTY = '_SET_DEPLOY_QTY';

export const _SET_GAME_DEPLOY_LOADER = '_SET_GAME_DEPLOY_LOADER';

export const _GET_GAME_PLAN_LIST = '_GET_GAME_PLAN_LIST';

export const _SET_SESSION_ID = '_SET_SESSION_ID'

export const _GET_GAME_INSTANCE_INFO = '_GET_GAME_INSTANCE_INFO';

export const _SET_GAME_SERVER_ACTIVE = '_SET_GAME_SERVER_ACTIVE';

export const _GET_FILE_MANAGER_DIR = '_GET_FILE_MANAGER_DIR';

export const _GET_SETTING_NODES = '_GET_SETTING_NODES';

export const _GET_GAME_BACKUPS = '_GET_GAME_BACKUPS';

export const _SET_GAME_NODE = '_SET_GAME_NODE';

export const _DELETE_GAME_BACKUP = '_DELETE_GAME_BACKUP';

export const _SET_GAME_LABEL = '_SET_GAME_LABEL';

export const _EXTEND_GAME_EXPIRE_DATE = '_EXTEND_GAME_EXPIRE_DATE';

export const _SET_EULA_AGREE = '_SET_EULA_AGREE';
