import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getBackup, getSnapShot} from "../../snapshot/action";
import {isIterableArray} from "../../../utils/common";
import OSListItem from "./OSListItem";
import NotExist from "../../common/NotExist";
import ListSkeleton from "../../common/ListSkeleton";
import {checkErrorResponse} from "../../../helpers/errorCheck";


const Backup = () => {
    const dispatch = useDispatch();
    const {backupList} = useSelector(state => state.snapShot);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    useEffect(() => {
        getBackup().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response)
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [])
    useEffect(() => {
        if (backupList) {
            setSkeletonLoader(true);
        }
    }, [backupList]);

    return (
        <div className='snapshot_wrapper'>
            <ListSkeleton colNum={3} itemNum={8} height={120} loading={skeletonLoader}>
                {isIterableArray(backupList) ?
                    <OSListItem atr={'vb_id'}
                                osData={backupList}
                                loader={false}
                                img={'https://s3.server24.kr/panel/img/os/Backup.svg'}
                                name='vb_description'
                                date='vb_date_created'
                                osType='backup'
                    />
                    :
                    <NotExist type={'list'} height={400} text={'백업이 존재하지 않습니다.'}/>
                }
            </ListSkeleton>
        </div>
    )
}

export default Backup;
