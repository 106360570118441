import React, {Fragment, useEffect, useState} from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {useDispatch, useSelector} from "react-redux";
import {getPointUsage} from "../bill/action";
import {checkErrorResponse} from "../../helpers/errorCheck";
import {getUserActivityLog} from "./action";
import Chip from "@mui/material/Chip";
import {changePerNum, getUserLogStatus} from "../../helpers/common";
import ListSkeleton from "../common/ListSkeleton";
import {isGameMode, isIterableArray} from "../../utils/common";

const columns = [
    {id: 'ul_time', label: '날짜', minWidth: 100},
    {id: 'ul_more', label: '', minWidth: 50},
    {
        id: 'ul_message',
        label: '',
        minWidth: 170,
        align: 'left',
    },
];

const LogTypeChip = ({type}) => {
    let logStatus = {};
    switch (type) {
        case '작업': {
            logStatus = getUserLogStatus('work');
            break;
        }
        case '생성': {
            logStatus = getUserLogStatus('new');
            break;
        }
        case '수정': {
            logStatus = getUserLogStatus('mod');
            break;
        }
        case '삭제': {
            logStatus = getUserLogStatus('del');
            break;
        }
        default:
            break;
    }
    return <Chip className='status_chip' label={type} style={{fontWeight: 'bold', color: logStatus.listColor, backgroundColor: logStatus.backgroundColor}}/>
}


const HistoryTable = () => {
    const {userActivityLog, userLogMetaData} = useSelector(state => state.account);
    const {layoutMode} = useSelector(state => state.layout);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(localStorage.getItem('pageNum') ? localStorage.getItem('pageNum') : 10);
    const [skeletonLoader, setSkeletonLoader] = useState(false);


    const dispatch = useDispatch();
    useEffect(() => {
        getUserActivityLog(`/user/log?page=${0}&per_page=${20}`).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    setSkeletonLoader(true);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [])


    const handleChangePage = (event, newPage) => {
        let pageLink = '';
        if (page < newPage) pageLink = userLogMetaData.links.next
        else pageLink = userLogMetaData.links.previous
        getUserActivityLog(pageLink).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    setPage(newPage);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })

    };

    const handleChangeRowsPerPage = (event) => {
        getUserActivityLog(`/user/log?page=${0}&per_page=${event.target.value}`).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    const perNum = parseInt(event.target.value, 10);
                    changePerNum(perNum)
                    setRowsPerPage(perNum);
                    setPage(0);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })


    };

    return (
        <div className='history_table_wrapper'>
            <Paper sx={{width: '100%', overflow: 'hidden'}}>
                <ListSkeleton loading={skeletonLoader}>
                    <TableContainer>
                        <Table  className={isGameMode(layoutMode) ? 'dark_table_mode' : ''} >
                            <TableHead>
                                {isIterableArray(userActivityLog) &&
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{minWidth: column.minWidth}}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>

                                }

                            </TableHead>
                            <TableBody>
                                {isIterableArray(userActivityLog) ?
                                    <Fragment>
                                        {
                                            userActivityLog.map((row, index) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                        {columns.map((column) => {
                                                            const value = row[column.id];
                                                            if (column.id === 'ul_more') {
                                                                return (
                                                                    <TableCell style={{maxWidth: '70px'}} key={column.id} align={column.align}>
                                                                        <LogTypeChip type={value}/>
                                                                    </TableCell>
                                                                )
                                                            }
                                                            if (column.id === 'ul_message') {
                                                                return (
                                                                    <TableCell style={{color: '#00000082'}} key={column.id} align={column.align}>
                                                                        {value}
                                                                    </TableCell>
                                                                )
                                                            }
                                                            return (
                                                                <TableCell key={column.id} align={column.align}>
                                                                    {value}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </Fragment>
                                    :
                                    <TableRow>
                                        <TableCell>
                                            <div className='not_exist_activity_table'>
                                                <span>활동기록이 존재하지 않습니다.</span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ListSkeleton>
                <TablePagination
                    className={isGameMode(layoutMode) ? 'dark_table_pagination' : ''}
                    labelRowsPerPage={'페이지당 목록 수'}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={userLogMetaData.total_count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    )
}

export default HistoryTable;
