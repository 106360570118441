import React from "react";
import {Col, Row} from "reactstrap";
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {formatBytes} from "../../../../utils/common";
import {useDispatch, useSelector} from "react-redux";
import {getFileManagerDir} from "../../action";
import {checkErrorResponse} from "../../../../helpers/errorCheck";
import {useParams} from "react-router-dom";

const FileManagerItem = ({instanceId, file, route, itemNo, setRoute, setDirIndex}) => {
    const {sessionId,} = useSelector(state => state.gameDeploy);
    const dispatch = useDispatch();

    const handleDbClickDir = () => {
        getFileManagerDir(sessionId, instanceId, file.Filename).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    setRoute([...route, file.Filename]);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }

    const handleClickDir = () => {
        setDirIndex(itemNo);
    }

    return (
        <Row onDoubleClick={handleDbClickDir} onClick={handleClickDir} noGutters className='file_manager_item'>
            <Col xs={1}>
                <div className='file_manager_icon'>
                    {file.IsDirectory ? <FolderIcon/> : <InsertDriveFileIcon/>}
                </div>
            </Col>
            <Col xs={11}>
                <div className='file_manager_item_info'>
                    <span className='file_name'>{file.Filename}</span>
                    <span className='file_type'>{`${formatBytes(file.SizeBytes)}`}</span>

                </div>
            </Col>
        </Row>
    )
}


export default FileManagerItem;
