import * as types from './actionTypes';

export const changeLayoutMode = (mode) => {

    return {
        type: types._CHANGE_LAYOUT_MODE,
        mode
    }
}

export const changeLayoutLoader = (mode) => {

    return {
        type: types._CHANGE_LAYOUT_LOADER,
        mode
    }
}
