import React from "react";
import {useSelector} from "react-redux";
import {CircularProgressWithLabel} from "./CircleProgress";


const GameRamStatus = () => {
    const {gameInstanceInfo} = useSelector(state => state.gameDeploy);

    return (
        <div>
            <div className='game_status_title'>
                <span>메모리 사용량</span>
            </div>
        <div className='game_status_wrapper'>
            <CircularProgressWithLabel value={gameInstanceInfo.Status.Metrics['Memory Usage'].RawValue} type={'ram'}>
                {`${gameInstanceInfo.Status.Metrics['Memory Usage'].RawValue}MB`}
            </CircularProgressWithLabel>
            <div className='game_status_value'>
                <span>{`${gameInstanceInfo.Status.Metrics['Memory Usage'].RawValue}`}</span>
                <span>/</span>
                <span>{`${gameInstanceInfo.Status.Metrics['Memory Usage'].MaxValue}MB`}</span>
            </div>
        </div>
        </div>
    )
}

export default GameRamStatus;
