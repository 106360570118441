import React, {useEffect, useState} from "react";
import {Form, Label, FormGroup, Input,  Row, Col} from "reactstrap";
import {Link, useHistory} from "react-router-dom";
import passwordIcon from '../../assets/img/login/password.svg';
import GradientBack from "../common/GradientBack";
import {useForm} from "react-hook-form";
import classNames from "classnames";
import {loginRequest} from "./function";
import {generateRandomCode, isBrowserCheck, notificationAlert, sendMsg} from "../../helpers/common";
import GoogleReCaptcha from "../../hoc/GoogleReCaptcha";
import LoaderButton from "../common/LoaderButton";
import emailIcon from "../../assets/img/login/email.svg";
import {getAlreadySession} from "../../helpers/checkAuth";
import {setCookie} from "../../utils/common";
import {checkErrorResponse} from "../../helpers/errorCheck";
import Swal from "sweetalert2";
import {Button} from "@material-ui/core";

const Login = () => {
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const {register, handleSubmit, errors} = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });

    const onSubmitLogin = data => {
        setLoader(true);
        GoogleReCaptcha().then(result => {
            if (result) {
                if (result === 'human') {
                    if (data) {
                        const browser = isBrowserCheck();
                        const post = {
                            email: data.loginEmail,
                            password: data.loginPwd,
                            browser: browser
                        }

                        loginRequest(post).then(response => {
                            if (response) {
                                if (response.status === 200) {
                                    setLoader(false);
                                    localStorage.removeItem('session_expired');
                                    localStorage.setItem('auth_login', generateRandomCode(16));
                                    setCookie(`last_login_username=${data.loginEmail}`);
                                    window.location.href = '/';
                                }
                            }
                        }).catch(err => {
                            setLoader(false);
                            if (err) {
                                if (err.response) {
                                    if (err.response.data.message === '비활성화된 계정입니다. 본인 인증을 통해 비활성화를 풀어주세요.') {
                                        Swal.fire({
                                            title: '비활성화된 계정입니다.',
                                            text: "비밀번호 찾기를 통해 계정 비활성화를 풀어주세요.",
                                            showCancelButton: true,
                                            confirmButtonColor: '#4d99ff',
                                            cancelButtonColor: '#979797',
                                            confirmButtonText: '계정 활성화'
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                history.push('/find/pwd');
                                                // Swal.fire({
                                                //     title: '핸드폰 번호를 입력하여 주세요.',
                                                //     input: 'text',
                                                //     inputAttributes: {
                                                //         autocapitalize: 'off'
                                                //     },
                                                //     showCancelButton: true,
                                                //     confirmButtonText: '인증하기',
                                                //     showLoaderOnConfirm: true,
                                                //     preConfirm: (phoneNum) => {
                                                //         return sendMsg(phoneNum).then(response => {
                                                //             if (response) {
                                                //                 if (response.status === 200) {
                                                //                     console.log('//rere',response)
                                                //                     return response
                                                //                 }
                                                //             }
                                                //         }).catch(err => {
                                                //             console.log('//error',err);
                                                //             if (err) {
                                                //                 checkErrorResponse(err);
                                                //             }
                                                //         })
                                                //
                                                //     },
                                                //     allowOutsideClick: () => !Swal.isLoading()
                                                // }).then((result) => {
                                                //     console.log('//RESULT', result)
                                                //     if (result.isConfirmed) {
                                                //         Swal.fire({
                                                //             title: `${result.value.login}'s avatar`,
                                                //             imageUrl: result.value.avatar_url
                                                //         })
                                                //     }
                                                // })
                                            }
                                        })
                                    } else {
                                        notificationAlert('오류', err.response.data.message)
                                    }
                                }
                            }


                        })
                    }
                }
            }
        }).catch(err => {
            setLoader(false);
            checkErrorResponse(err);

        })

    };


    return (
        <GradientBack boxSize={450}>
            <div className='login_wrapper'>
                <div className='login_box'>
                    <div className='login_form_wrapper'>
                        <h2>로그인</h2>
                        {getAlreadySession() &&
                        <div className='text-center py-1'>
                            <span id='session_alreadyExpired'>세션이 만료되었습니다. 다시 로그인 해주세요.</span>
                        </div>
                        }
                        <Form onSubmit={handleSubmit(onSubmitLogin)}>
                            <FormGroup>
                                <Label className='login_label'>이메일</Label>
                                <div className='login_input_wrapper'>
                                    <img src={emailIcon}/>
                                    <Input
                                        placeholder={'이메일 주소를 입력해 주세요.'}
                                        id='loginEmail'
                                        name='loginEmail'
                                        type="email"
                                        maxLength={50}
                                        innerRef={register({
                                            required: '이메일 주소는 반드시 입력되어야 합니다',
                                            pattern: {
                                                value: /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
                                                message: '입력하신 이메일 주소의 형식이 올바르지 않습니다'
                                            }
                                        })}
                                        errors={errors}
                                        className={classNames({'border-danger': errors.loginEmail})}
                                    />
                                </div>
                                {errors.loginEmail && <span className="text-danger fs--1">{errors.loginEmail.message}</span>}
                            </FormGroup>
                            <FormGroup>
                                <Label className='login_label'>비밀번호</Label>
                                <div className='login_input_wrapper'>
                                    <img src={passwordIcon}/>
                                    <Input
                                        placeholder={'비밀번호를 입력해 주세요.'}
                                        type="password"
                                        id='loginPwd'
                                        name='loginPwd'
                                        autoComplete='new-password'
                                        innerRef={register({
                                            required: '비밀번호는 반드시 입력되어야 합니다',
                                        })}
                                        errors={errors}
                                        className={classNames({'border-danger': errors.loginPwd})}
                                    />
                                </div>
                                {errors.loginPwd && <span className="text-danger fs--1">{errors.loginPwd.message}</span>}
                            </FormGroup>
                            <div className='forget_pwd_wrapper'>
                                <Link to={'/register'}>
                                    <span>새로운 계정으로 </span><span id='signup_txt'>회원가입</span>
                                </Link>
                            </div>
                            <FormGroup>
                                <LoaderButton type='submit' color='primary' block className="mt-3 login_btn" loader={loader}>
                                    Login
                                </LoaderButton>
                            </FormGroup>
                            <Row className="justify-content-center align-items-center">
                                <Col xl="12" className="mt-3">
                                    <Row className='justify-content-center'>
                                        <Col xs="12" className='text-center'>

                                            <Link className="fs--1 login_forget_pwd" to={`/find/pwd`}>
                                                  <span>
                                            비밀번호를 잊어버리셨나요?
                                                </span>
                                                <span id='forget_txt'>비밀번호 찾기</span>
                                            </Link>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Form>
                    </div>
                </div>

            </div>
        </GradientBack>
    )
}


export default Login;
