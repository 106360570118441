import React, {useEffect, useState} from "react";
import {Bar} from "react-chartjs-2";
import {useSelector} from "react-redux";
import {formatBytes, isIterableArray} from "../../../utils/common";
import CardAvatar from "../../common/CardAvatar";
import trafficIcon from '../../../assets/img/common/trafficIcon.svg';

const TotalBandwidthChart = () => {
    const [inboundData, setInboundData] = useState([]);
    const [outboundData, setOutboundData] = useState([]);
    const [labels, setLabels] = useState([]);
    const {totalBandwidth} = useSelector(state => state.instance)
    useEffect(() => {
        if (isIterableArray(totalBandwidth)) {
            const newLabel = totalBandwidth.map(bw => bw.vb_date);
            const newInbound = totalBandwidth.map(bw => bw.incoming_byte);
            const newOutbound = totalBandwidth.map(bw => bw.outgoing_byte);
            setLabels(newLabel)
            setInboundData(newInbound)
            setOutboundData(newOutbound)
        }
    }, [totalBandwidth])

    return (
        <>
            <div className='chart_title'>
                <CardAvatar
                    height={35}
                    img={trafficIcon}
                    width={35}
                    backgroundColor={'#4d99ff'}/>
                <span>
                     트래픽 총 사용량
                </span>
            </div>
            <div className='traffic_total_wraper'>
                <div className='inBound_traffic_item'>
                    <span>{`인바운드 : ${formatBytes(inboundData.reduce((sum, num) => sum + num, 0))}`}</span>
                </div>
                <div className='outBound_traffic_item'>
                    <span>{`아웃바운드 : ${formatBytes(outboundData.reduce((sum, num) => sum + num, 0))}`}</span>
                </div>
            </div>
            <div className="total_bandwidth_chart_wrapper">
                <Bar
                    pointStyle="star"
                    data={{
                        labels: labels,
                        responsive: true,
                        offset: true,
                        datasets: [
                            {
                                label: "인바운드",
                                backgroundColor: "#6ED3FF",
                                barThickness: 15,
                                // pointStyle: "rectRounded",
                                // categoryPercentage: 1,
                                data: inboundData
                            },
                            {
                                label: "아웃바운드",
                                backgroundColor: "#1497FF",
                                barThickness: 15,
                                // categoryPercentage: 1,
                                // pointStyle: "triangle",
                                data: outboundData
                            }
                        ]
                    }}
                    height={300}
                    options={{
                        tooltips: {
                            mode: 'x-axis',
                            xPadding: 20,
                            yPadding: 10,
                            displayColors: false,
                            callbacks: {
                                label: tooltipItem => {
                                    const trafficType = inboundData[tooltipItem.index] === parseInt(tooltipItem.value) ? '인바운드' : '아웃바운드'
                                    return `${labels[tooltipItem.index]} - ${trafficType} : ${formatBytes(tooltipItem.value)}`
                                },
                                title: () => null
                            }
                        },
                        offsetGridLines: true,
                        drawTicks: true,
                        layout: {
                            padding: {
                                top: 30,
                                right: 40,
                                bottom: 40
                            }
                        },
                        legend: {
                            display: true,
                            position: "top",
                            align: "center",
                            labels: {
                                usePointStyle: false
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            xAxes: [
                                {
                                    stacked: true,
                                    ticks: {
                                        padding: 5
                                    },
                                    gridLines: {
                                        display: false
                                    }
                                }
                            ],
                            yAxes: [
                                {
                                    stacked: false,
                                    gridLines: {
                                        drawBorder: false
                                    },
                                    ticks: {
                                        beginAtZero: true,
                                        maxTicksLimit: 6,
                                        padding: 20,
                                        callback(n) {
                                            if (n < 1) {
                                                return `${n} Bytes`
                                            } else {
                                                return formatBytes(n)
                                            }

                                        }
                                    }
                                }
                            ]
                        }
                    }}
                />
            </div>
        </>
    );
}

export default TotalBandwidthChart
