import React, {useState, useEffect} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Chip from "@mui/material/Chip";
import {useHistory} from "react-router-dom";
import {FormGroup, Input} from 'reactstrap';
import searchIcon from '../../assets/img/support/search.svg';
import {isGameMode, isIterableArray, timeToString} from "../../utils/common";
import AddIcon from "@mui/icons-material/Add";
import ListSkeleton from "../common/ListSkeleton";
import {changePerNum, getTicketStatus} from "../../helpers/common";
import NotExistTable from "../common/NotExistTable";
import {defaultZendeskIpFieldId} from "../constant/DefaultValue";
import {Fab} from "@mui/material";
import {useMediaQuery} from "react-responsive";
import MobileTicketTable from "./mobile/MobileTicketTable";
import {useSelector} from "react-redux";

const columns = [
    {id: 'subject', label: 'SUBJECT', minWidth: 230},
    {id: 'id', align: 'center', label: 'TICKET', minWidth: 30},
    {
        id: 'updated_at',
        label: 'LAST REPLY',
        minWidth: 170,
        align: 'center',
    },
    {
        id: 'status',
        label: 'STATUS',
        minWidth: 170,
        align: 'center',
    }
];


export default function TicketTable({ticketList, toggle, loading}) {
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(localStorage.getItem('pageNum') ? localStorage.getItem('pageNum') : 10);
    const [tickets, setTickets] = useState([]);
    const [status, setStatus] = useState('All');
    const [searchTxt, setSearchTxt] = useState('');
    const {layoutMode} = useSelector(state => state.layout);
    useEffect(() => {
        //티켓 리스트 리듀서 저장할때부터 원하는 형태로 저장하기
        if (ticketList) {
            setTickets(ticketList);
        }
    }, [ticketList]);
    const isMobile = useMediaQuery({
        query: "(max-width:880px)"
    });


    const handleChange = (event) => {
        setStatus(event.target.value);
        switch (event.target.value) {
            case 'All':
                setTickets([...ticketList]);
                break;
            case 'new': {
                setTickets(ticketList.filter(ticket => ticket.status === 'new'));
                break;
            }
            case 'open': {
                const openList = ticketList.filter(ticket => ticket.status === 'open');
                const pendingList = ticketList.filter(ticket => ticket.status === 'pending');
                setTickets([...openList, ...pendingList]);
                break;
            }
            case 'solved': {
                const solvedList = ticketList.filter(ticket => ticket.status === 'solved');
                const closedList = ticketList.filter(ticket => ticket.status === 'closed');
                setTickets([...solvedList, ...closedList]);
                break;
            }
            default :
                setTickets([...ticketList]);
                break;
        }
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);

    };
    const handleSearchTicket = (input) => {
        setSearchTxt(input);
        const newTicketList = ticketList.filter((ticket) =>
            ticket.subject.toLowerCase().includes(input));
        setTickets(newTicketList);
    }
    const handleChangeRowsPerPage = (event) => {
        const perNum = parseInt(event.target.value, 10);
        changePerNum(perNum)
        setRowsPerPage(perNum);
        setPage(0);
    };

    return (
        <div>
            <div className={isGameMode(layoutMode) ? 'ticket_filter_wrapper dark_ticket_filter' : 'ticket_filter_wrapper'}>
                <FormGroup>
                    <Input type="select"
                           onChange={handleChange}
                           value={status}
                           name="select"
                           id="ticket_status_sel">
                        <option value={'All'}>전체</option>
                        <option value={'new'}>접수</option>
                        <option value={'open'}>처리중</option>
                        <option value={'solved'}>처리완료</option>
                    </Input>
                </FormGroup>
                <div className="ticket_search_wrapper">
                    <input
                        onChange={event => handleSearchTicket(event.target.value)}
                        style={{backgroundImage: `url(${searchIcon})`}}
                        type="text" className="form-control"
                        placeholder="Search..."/>
                    <div className={'add_ticket_btn'}>
                        <Fab onClick={toggle} color="primary" aria-label="add">
                            <AddIcon/>
                        </Fab>
                    </div>
                </div>
            </div>
            <ListSkeleton loading={loading}>
                {isIterableArray(tickets) ?
                    <TableContainer>
                        {isMobile ?
                            <MobileTicketTable page={page} rowsPerPage={rowsPerPage} tickets={tickets}/>
                            :
                            <Table className={isGameMode(layoutMode) ? 'dark_table_mode ticket_table' : 'ticket_table'} aria-label=" table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{minWidth: column.minWidth, borderBottom: 'none', color: '#8297AA'}}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {
                                    [...tickets]
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (<TableBody key={index}>
                                                    <TableRow className='ticket_row_wrapper' onClick={() => history.push(`/support/view/${row.id}`)} style={{height: '80px', backgroundColor: '#F7F9FA', cursor: 'pointer'}} role="checkbox" tabIndex={-1} key={index}>
                                                        {columns.map((column) => {
                                                            let value = row[column.id];
                                                            if (column.id === 'subject') {
                                                                let optionValue = '';
                                                                row.fields.map(field => {
                                                                    if (field.id === 4413163323407) {
                                                                        if (field.value) {
                                                                            optionValue = field.value;
                                                                        }
                                                                    }
                                                                    if (field.id === 4536097560463) {
                                                                        if (field.value) {
                                                                            optionValue = field.value;
                                                                        }
                                                                    }
                                                                    if (field.id === 4460782940559) {
                                                                        if (field.value) {
                                                                            optionValue = field.value;
                                                                        }
                                                                    }
                                                                })
                                                                return (
                                                                    <TableCell style={{borderBottom: 'none', padding: 0}} key={column.id} align={column.align}>
                                                                        <div className={'table_item'} style={{backgroundColor: 'white'}}>
                                                                            <span className='d-block fw-bold'>{value}</span>
                                                                            <div style={{minHeight: '16px'}}>
                                                                                <small style={{color: '#7b7b7b'}}>{optionValue}</small>
                                                                            </div>
                                                                        </div>
                                                                    </TableCell>
                                                                )
                                                            }
                                                            if (column.id === 'updated_at') value = timeToString(value);
                                                            const ticketStatus = getTicketStatus(value);
                                                            return (
                                                                <TableCell style={{borderBottom: 'none', padding: 0}} key={column.id} align={column.align}>
                                                                    <div className={column.id !== 'status' ? 'table_item' : 'table_status'} style={{backgroundColor: 'white'}}>
                                                                        {column.id === 'status' ? <Chip className='status_chip' label={ticketStatus.txt} style={{fontWeight: 'bold', color: ticketStatus.listColor, backgroundColor: ticketStatus.backgroundColor}}/>
                                                                            :
                                                                            value}
                                                                    </div>
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                </TableBody>
                                            );
                                        })
                                }
                            </Table>
                        }
                    </TableContainer>
                    :
                    <NotExistTable searchExist={(searchTxt.length !== 0) || (status !== 'All')} type={'ticket'} clickEvent={toggle}/>
                }
            </ListSkeleton>
            <TablePagination
                className={isGameMode(layoutMode) ? 'dark_table_pagination' : ''}
                labelRowsPerPage={'페이지당 목록 수'}
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={tickets.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}
